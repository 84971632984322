import pathnames from "routes/pathnames";

import PageSplashScreen from "pages/splash-screen";
import PageLogin from "pages/authentication/login";
import PageForgotPassword from "pages/authentication/forgot-password";
import PageChangePassword from "pages/authentication/change-password";
import PageHomePage from "pages/home-page";
import PageUserManagement from "pages/admin-settings/user-management/user-management";
import PageAddNewUser from "pages/admin-settings/user-management/add-new-user";
import PageUserDetail from "pages/admin-settings/user-management/update-user-details";
import PageUserProfile from "pages/admin-settings/user-management/update-user-details";
import Page404 from "pages/404";
import PageLoanSettings from "pages/admin-settings/loan-settings"; 

import PageApprovalMatrixSettings from "pages/admin-settings/initial-approval-matrix"; 
import PageCustomerList from "pages/database/customer-list";
import PageCustomerView from "pages/database/customer-view";
import PageLoanApplicationList from "pages/loan/loan-application-list";
import PageLoanApplicationView from "pages/loan/loan-application-view";
import PageCalendarSettings from "pages/admin-settings/calendar-settings/update-calendar-settings";
import PageLoanContractList from "pages/loan/loan-contract-list";
import PageLoanContractView from "pages/loan/loan-contract-view";
import PageMerchantList from "pages/database/merchant-list";
import PageMerchantView from "pages/database/merchant-view";
import PageMerchantAdd from "pages/database/merchant-add";
import PagePayment from "pages/report/payment";
import PageTransaction from "pages/report/transaction";
import PageReportOthers from "pages/report/others";

const routes = [
    {
        path: pathnames.pageSplashScreen,
        component: PageSplashScreen,
        navBarTitle: "",
        title: "Splash Screen",
        authenticate: false,
    },
    {
        path: pathnames.pageLogin,
        component: PageLogin,
        navBarTitle: "",
        title: "Login",
        authenticate: false,
    },
    {
        path: pathnames.pageForgotPassword,
        component: PageForgotPassword,
        navBarTitle: "",
        title: "Forgot Password",
        authenticate: false,
    },
    {
        path: pathnames.pageChangePassword,
        component: PageChangePassword,
        navBarTitle: "",
        title: "Change Password",
        authenticate: false,
    },
    {
        path: pathnames.pageHomePage,
        component: PageHomePage,
        navBarTitle: "Home Page",
        title: "Home Page",
        authenticate: true,
    },
    {
        path: pathnames.pageLoanApplicationList,
        component: PageLoanApplicationList,
        navBarTitle: "Loan",
        title: "Loan Application List",
        authenticate: true,
    },
    {
        path: pathnames.pageLoanApplicationView,
        component: PageLoanApplicationView,
        navBarTitle: "Loan",
        title: "Loan Application View",
        authenticate: true,
    },
    {
        path: pathnames.pageLoanContractList,
        component: PageLoanContractList,
        navBarTitle: "Loan",
        title: "Loan Contracts List",
        authenticate: true,
    },
    {
        path: pathnames.pageLoanContractView,
        component: PageLoanContractView,
        navBarTitle: "Loan",
        title: "Loan Contracts View",
        authenticate: true,
    },
    {
        path: pathnames.pageMerchantList,
        component: PageMerchantList,
        navBarTitle: "Database",
        title: "Merchant List",
        authenticate: true,
    },
    {
        path: pathnames.pageMerchantView,
        component: PageMerchantView,
        navBarTitle: "Database",
        title: "Merchant View",
        authenticate: true,
    },
    {
        path: pathnames.pageMerchantAdd,
        component: PageMerchantAdd,
        navBarTitle: "Database",
        title: "Merchant Add",
        authenticate: true,
    },
    {
        path: pathnames.pageCustomerList,
        component: PageCustomerList,
        navBarTitle: "Database",
        title: "Customer List",
        authenticate: true,
    },
    {
        path: pathnames.pageCustomerView,
        component: PageCustomerView,
        navBarTitle: "Database",
        title: "Customer View",
        authenticate: true,
    },
    // {
    //     path: pathnames.pageReport,
    //     component: PageReport,
    //     navBarTitle: "Report",
    //     title: "Report",
    //     authenticate: true,
    // },
    {
        path: pathnames.pagePayment,
        component: PagePayment,
        navBarTitle: "Report",
        title: "Payment",
        authenticate: true,
    },
    {
        path: pathnames.pageTransaction,
        component: PageTransaction,
        navBarTitle: "Report",
        title: "Transaction",
        authenticate: true,
    },
    {
        path: pathnames.pageReportOthers,
        component: PageReportOthers,
        navBarTitle: "Report",
        title: "Others",
        authenticate: true,
    },
    {
        path: pathnames.pageCalendarSettings,
        component: PageCalendarSettings,
        navBarTitle: "Settings",
        title: "Calendar Settings",
        authenticate: true,
    },
    {
        path: pathnames.pageUserManagement,
        component: PageUserManagement,
        navBarTitle: "Settings",
        title: "User Management",
        authenticate: true,
    },
    {
        path: pathnames.pageAddNewUser,
        component: PageAddNewUser,
        navBarTitle: "User Management",
        title: "Add New User",
        authenticate: true,
    },
    {
        path: pathnames.pageUserDetail,
        component: PageUserDetail,
        navBarTitle: "User Management",
        title: "Update User Details",
        authenticate: true,
    },
    {
        path: pathnames.pageUserProfile,
        component: PageUserProfile,
        navBarTitle: "User Profile",
        title: "Update User Profile",
        authenticate: true,
    },
    // routes path for loan-settings
    {
        path: pathnames.pageLoanSettings,
        component: PageLoanSettings,
        navBarTitle: "Settings",
        title: "Loan Settings",
        authenticate: true,
    },

    // routes path for initial-approval-matrix
    {
        path: pathnames.pageApprovalMatrixSettings,
        component: PageApprovalMatrixSettings,
        navBarTitle: "Settings",
        title: "Initial Approval Matrix",
        authenticate: true,
    },
    {
        path: pathnames.page404,
        component: Page404,
        navBarTitle: "",
        title: "404",
        authenticate: false,
    },
];

export default routes;