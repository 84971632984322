import React, { useContext, useState, useEffect } from "react";
import CONSTANSTS from "common/constansts";
import AppCustomToast, { PageContext } from "components/app-custom-toast";
// import moment from "moment";

const PageReportOthers = () => {
  const indicatorValue = useContext(PageContext);
  function messageDialog(indicator) {
    AppCustomToast({
      showIndicator: indicator,
      reintIndicator: indicatorValue[1](indicator),
    });
  }
  // const date = moment().format("YYYY-MM-DD");
  const dateFields = {
    dateFrom: null,
    dateTo: null,
  };

  const [reportOthers, setReportAccess] = useState({
    generate_aging: false,
    generate_billing_transaction: false,
    generate_fraud_detection: false,
    generate_loan_activity: false,
    generate_settled_contract: false
  });

  useEffect(() => {
    let reportAccess = null;
    reportAccess = JSON.parse(localStorage.getItem("accessMatrix"));

    //Testing 
    // reportAccess.accessMatrix.Other_Report.generate_aging = false;
    // reportAccess.accessMatrix.Other_Report.generate_billing_transaction = false;
    // reportAccess.accessMatrix.Other_Report.generate_fraud_detection = false;
    // reportAccess.accessMatrix.Other_Report.generate_loan_activity = true;
    // reportAccess.accessMatrix.Other_Report.generate_settled_contract = false;
    if(reportAccess) {
      setReportAccess({
        generate_aging: reportAccess.accessMatrix.Other_Report.generate_aging,
        generate_billing_transaction: reportAccess.accessMatrix.Other_Report.generate_billing_transaction,
        generate_fraud_detection: reportAccess.accessMatrix.Other_Report.generate_fraud_detection,
        generate_loan_activity: reportAccess.accessMatrix.Other_Report.generate_loan_activity,
        generate_settled_contract: reportAccess.accessMatrix.Other_Report.generate_settled_contract
      });
    }

    if(reportAccess.accessMatrix.Other_Report.generate_aging) {
      setFirstMenu("display");
    } else if(reportAccess.accessMatrix.Other_Report.generate_fraud_detection) {
      setSecondMenu("display");
    } else if(reportAccess.accessMatrix.Other_Report.generate_loan_activity) {
      setThirdMenu("display");
    } else if(reportAccess.accessMatrix.Other_Report.generate_billing_transaction) {
      setForthMenu("display");
    } else if(reportAccess.accessMatrix.Other_Report.generate_settled_contract) {
      setFifthMenu("display");
    }
  }, [])

  const [firstMenu, setFirstMenu] = useState("hide");
  const [secondMenu, setSecondMenu] = useState("hide");
  const [thirdMenu, setThirdMenu] = useState("hide");
  const [forthMenu, setForthMenu] = useState("hide");
  const [fifthMenu, setFifthMenu] = useState("hide");
  const tabClicked = (e) => {
    var menuNo = e;

    if (menuNo === "1") {
      setFirstMenu("display");
      setSecondMenu("hide");
      setThirdMenu("hide");
      setForthMenu("hide");
      setFifthMenu("hide");
    } else if (menuNo === "2") {
      setFirstMenu("hide");
      setSecondMenu("display");
      setThirdMenu("hide");
      setForthMenu("hide");
      setFifthMenu("hide");
    } else if (menuNo === "3") {
      setFirstMenu("hide");
      setSecondMenu("hide");
      setThirdMenu("display");
      setForthMenu("hide");
      setFifthMenu("hide");
    } else if (menuNo === "4") {
      setFirstMenu("hide");
      setSecondMenu("hide");
      setThirdMenu("hide");
      setForthMenu("display");
      setFifthMenu("hide");
    } else if (menuNo === "5") {
      setFirstMenu("hide");
      setSecondMenu("hide");
      setThirdMenu("hide");
      setForthMenu("hide");
      setFifthMenu("display");
    }
  };

  const [agingDate, setAgingDate] = useState(dateFields);
  const advacendSearch = {
    name: "",
    mobileNo: "",
    id: "",
  };
  const [agingKeyword, setAgingKeyword] = useState(advacendSearch);
  // const [minDate, setMinDate] = useState("");
  // const [maxDate, setMaxDate] = useState("");

  const ocCustomerName = (event) => {
    const value = event.currentTarget.value.toString();
    setAgingKeyword({
      ...agingKeyword,
      name: value,
    })
  }
  const ocMobileNo = (event) => {
    const value = event.currentTarget.value.toString();
    setAgingKeyword({
      ...agingKeyword,
      mobileNo: value.replace(/\D/, ""),
      // mobileNo: value.replace(/[a-zA-Z !@#$%^&*()_=.<>?/{}|,"':;]/, ""),
    })
  }
  const ocContractId = (event) => {
    const value = event.currentTarget.value.toString();
    setAgingKeyword({
      ...agingKeyword,
      id: value,
      // id: value.replace(/[!@#$%^&*()_+={}|;:'",<.>/? ]/,""),
    })
  }

  const onChangeDateStart = (event) => {
    const value = event.currentTarget.value.toString();
    // setMinDate(value);
    setAgingDate({
      ...agingDate,
      dateFrom: value,
    });
  };

  // const onChangeDateEnd = (event) => {
  //   const value = event.currentTarget.value.toString();
  //   setMaxDate(value);
  //   setAgingDate({
  //     ...agingDate,
  //     dateTo: value,
  //   });
  // };

  const [settledDate, setSettledDate] = useState(dateFields);
  const [settledKeyword, setSettledKeyword] = useState(advacendSearch);
  const [settledMinDate, setSettledMinDate] = useState("");
  const [settledMaxDate, setSettledMaxDate] = useState("");

  const scCustomerName = (event) => {
    const value = event.currentTarget.value.toString();
    setSettledKeyword({
      ...settledKeyword,
      name: value,
    })
  }
  const scMobileNo = (event) => {
    const value = event.currentTarget.value.toString();
    setSettledKeyword({
      ...settledKeyword,
      mobileNo: value.replace(/\D/, ""),
    })
  }
  const scContractId = (event) => {
    const value = event.currentTarget.value.toString();
    setSettledKeyword({
      ...settledKeyword,
      id: value,
    })
  }

  const onChangeSCDateStart = (event) => {
    const value = event.currentTarget.value.toString();
    setSettledMinDate(value);
    setSettledDate({
      ...settledDate,
      dateFrom: value,
    });
    if(value !== null){
      setContractStartDate("merchant-input-text");
    }
  };

  const onChangeSCDateEnd = (event) => {
    const value = event.currentTarget.value.toString();
    setSettledMaxDate(value);
    setSettledDate({
      ...settledDate,
      dateTo: value,
    });
    if(value !== null){
      setContractEndDate("merchant-input-text");
    }
  };

  const [fraudDate, setFraudDate] = useState(dateFields);
  const [minFraudDate, setMinFraudDate] = useState("");
  const [maxFraudDate, setMaxFraudDate] = useState("");

  const onChangeFraudDateStart = (event) => {
    const value = event.currentTarget.value.toString();
    setMinFraudDate(value);
    setFraudDate({
      ...fraudDate,
      dateFrom: value,
    });
  };

  const onChangeFraudDateEnd = (event) => {
    const value = event.currentTarget.value.toString();
    setMaxFraudDate(value);
    setFraudDate({
      ...fraudDate,
      dateTo: value,
    });
  };

  const [billingDate, setBillingDate] = useState(dateFields);
  const [minBillingDate, setMinBillingDate] = useState("");
  const [maxBillingDate, setMaxBillingDate] = useState("");

  const onChangeBillingDateStart = (event) => {
    const value = event.currentTarget.value.toString();
    setMinBillingDate(value);
    setBillingDate({
      ...billingDate,
      dateFrom: value,
    });
  };

  const onChangeBillingDateEnd = (event) => {
    const value = event.currentTarget.value.toString();
    setMaxBillingDate(value);
    setBillingDate({
      ...billingDate,
      dateTo: value,
    });
  };

  const [activityDate, setActivityDate] = useState(dateFields);
  const [activityKeyword, setActivityKeyword] = useState(advacendSearch);
  const [minActivityDate, setMinActivityDate] = useState("");
  const [maxActivityDate, setMaxActivityDate] = useState("");

  const ocACustomerName = (event) => {
    const value = event.currentTarget.value.toString();
    setActivityKeyword({
      ...activityKeyword,
      name: value,
    })
  }
  const ocAMobileNo = (event) => {
    const value = event.currentTarget.value.toString();
    setActivityKeyword({
      ...activityKeyword,
      mobileNo: value.replace(/\D/, ""),
      // mobileNo: value.replace(/[a-zA-Z !@#$%^&*()_=.<>?/{}|,"':;]/, ""),
    })
  }
  const ocApplicationId = (event) => {
    const value = event.currentTarget.value.toString();
    setActivityKeyword({
      ...activityKeyword,
      id: value,
    })
  }

  const onChangeActivityDateStart = (event) => {
    const value = event.currentTarget.value.toString();
    setMinActivityDate(value);
    setActivityDate({
      ...activityDate,
      dateFrom: value,
    });
  };

  const onChangeActivityDateEnd = (event) => {
    const value = event.currentTarget.value.toString();
    setMaxActivityDate(value);
    setActivityDate({
      ...activityDate,
      dateTo: value,
    });
  };

  const generateAgingReport = () => {
    let token = null;
    token = localStorage.getItem("@storage_token");
    let sliptStartDate = null;
    // let sliptStartEnd = null;

    if( agingDate.dateFrom !== null ){
      let startDate = null;
      startDate = agingDate.dateFrom;
      sliptStartDate = startDate.split("-");
    }
    // if( agingDate.dateTo !== null ){
    //   let endDate = null;
    //   endDate = agingDate.dateTo;
    //   sliptStartEnd = endDate.split("-");
    // }

    let payload = {
      agingAt: agingDate.dateFrom ? sliptStartDate[2] + "/" + sliptStartDate[1] + "/" + sliptStartDate[0] : null,
      // dateTo: agingDate.dateTo ? sliptStartEnd[2] + "/" + sliptStartEnd[1] + "/" + sliptStartEnd[0] : null,
      name: agingKeyword.name ? agingKeyword.name : "",
      mobileNumber: agingKeyword.mobileNo ? agingKeyword.mobileNo : "",
      contractId: agingKeyword.id ? agingKeyword.id : "",
    };
    fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.GENERATE_AGING, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        "Accept-language": "en",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(payload),
    }).then(function (response) {
      if (response.status === 200) {
      
        let filename = response.headers.get("Content-Disposition");
        let dialogMessage = response.headers.get("Message");
        if (filename != null) {
          filename = filename.split(";")[1].split("filename=")[1];
          //dialogMessage = dialogMessage;
        }

        response.blob().then((blob) => {
          messageDialog({
            isOpen: true,
            condition: CONSTANSTS.DIALOG.success,
            title: CONSTANSTS.DIALOG_TITLE.general_success,
            message: dialogMessage ?? CONSTANSTS.DIALOG_MESSAGE.download_successful,
            defaultOnClick: false,
          });
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = filename;
          a.click();
        });
      } else {
        response.json().then(function (data) {
          const response = data;

          messageDialog({
            isOpen: true,
            condition: CONSTANSTS.DIALOG.failed,
            title: CONSTANSTS.DIALOG_TITLE.general_failed,
           message: response.message,
            defaultOnClick: false,
          });
        });
      }
    });
  };

  const [contractStartDate, setContractStartDate] = useState("merchant-input-text");
  const [contractEndDate, setContractEndDate] = useState("merchant-input-text");

  const generateSettledReport = () => {
    let token = null;
    token = localStorage.getItem("@storage_token");
    let sliptStartDate = null;
    let sliptStartEnd = null;

    if( settledDate.dateFrom !== null ){
      let startDate = null;
      startDate = settledDate.dateFrom;
      sliptStartDate = startDate.split("-");
    } else {
      setContractStartDate("merchant-input-text-error");
      return;
    }
    if( settledDate.dateTo !== null ){
      let endDate = null;
      endDate = settledDate.dateTo;
      sliptStartEnd = endDate.split("-");
      setContractEndDate("merchant-input-text");
    } else {
      setContractEndDate("merchant-input-text-error");
      return;
    }

    let payload = {
      dateFrom: settledDate.dateFrom ? sliptStartDate[2] + "/" + sliptStartDate[1] + "/" + sliptStartDate[0] : null,
      dateTo: settledDate.dateTo ? sliptStartEnd[2] + "/" + sliptStartEnd[1] + "/" + sliptStartEnd[0] : null,
      name: settledKeyword.name ? settledKeyword.name : "",
      mobileNumber: settledKeyword.mobileNo ? settledKeyword.mobileNo : "",
      contractId: settledKeyword.id ? settledKeyword.id : "",
    };
    fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.GENERATE_SETTLED_CONTRACT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        "Accept-language": "en",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(payload),
    }).then(function (response) {
      if (response.status === 200) {
      
        let filename = response.headers.get("Content-Disposition");
        let dialogMessage = response.headers.get("Message");
        if (filename != null) {
          filename = filename.split(";")[1].split("filename=")[1];
        }

        response.blob().then((blob) => {
          messageDialog({
            isOpen: true,
            condition: CONSTANSTS.DIALOG.success,
            title: CONSTANSTS.DIALOG_TITLE.general_success,
            message: dialogMessage ?? CONSTANSTS.DIALOG_MESSAGE.download_successful,
            defaultOnClick: false,
          });
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = filename;
          a.click();
        });
      } else {
        response.json().then(function (data) {
          const response = data;

          messageDialog({
            isOpen: true,
            condition: CONSTANSTS.DIALOG.failed,
            title: CONSTANSTS.DIALOG_TITLE.general_failed,
           message: response.message,
            defaultOnClick: false,
          });
        });
      }
    });
  };

  const generateFraudDetection = () => {
    let token = null;
    token = localStorage.getItem("@storage_token");
    let sliptStartDate = null;
    let sliptStartEnd = null;

    if(fraudDate.dateFrom !== null){
      let startDate = null;
      startDate = fraudDate.dateFrom;
      sliptStartDate = startDate.split("-");
    }
    if(fraudDate.dateTo !== null){
      let endDate = null;
      endDate = fraudDate.dateTo;
      sliptStartEnd = endDate.split("-");
    }
    
    let payload = {
      dateFrom: fraudDate.dateFrom ? sliptStartDate[2] + "/" + sliptStartDate[1] + "/" + sliptStartDate[0] : null,
      dateTo: fraudDate.dateTo ? sliptStartEnd[2] + "/" + sliptStartEnd[1] + "/" + sliptStartEnd[0] : null,
      // keyword: fraudKeyword ? fraudKeyword : "",
    };
    fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.GENERATE_FRAUD_DETECTION, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        "Accept-language": "en",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(payload),
    }).then(function (response) {
   
      if (response.status === 200) {
        let filename = response.headers.get("Content-Disposition");
        let dialogMessage = response.headers.get("Message");
        if (filename != null) {
          filename = filename.split(";")[1].split("filename=")[1];
          //dialogMessage = dialogMessage
        }

        response.blob().then((blob) => {

          messageDialog({
            isOpen: true,
            condition: CONSTANSTS.DIALOG.success,
            title:  CONSTANSTS.DIALOG_TITLE.general_success,
            message:dialogMessage ?? CONSTANSTS.DIALOG_MESSAGE.download_successful,
            defaultOnClick: false,
          });
          let url = window.URL.createObjectURL(blob);

          let a = document.createElement("a");
          a.href = url;
          a.download = filename;
          a.click();

        
        });
      } else {
        response.json().then(function (data) {
          const response = data;

          messageDialog({
            isOpen: true,
            condition: CONSTANSTS.DIALOG.failed,
            title: CONSTANSTS.DIALOG_TITLE.general_failed,
           message: response.message,
            defaultOnClick: false,
          });
        });
      }
    });
  };

  const generateLoanApplicationActivity = () => {
    let token = null;
    token = localStorage.getItem("@storage_token");

    let sliptStartDate = null;
    let sliptStartEnd = null;

    if( activityDate.dateFrom !== null ){
      let startDate = null;
      startDate = activityDate.dateFrom;
      sliptStartDate = startDate.split("-");
    }
    if( activityDate.dateTo !== null ){
      let endDate = null;
      endDate = activityDate.dateTo;
      sliptStartEnd = endDate.split("-");
    }


    let payload = {
      dateFrom: activityDate.dateFrom ? sliptStartDate[2] + "/" + sliptStartDate[1] + "/" + sliptStartDate[0] : null,
      dateTo: activityDate.dateTo ? sliptStartEnd[2] + "/" + sliptStartEnd[1] + "/" + sliptStartEnd[0] : null,
      name: activityKeyword.name ? activityKeyword.name : "",
      mobileNumber: activityKeyword.mobileNo ? activityKeyword.mobileNo : "",
      applicationId: activityKeyword.id ? activityKeyword.id : "",
    };
    fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.GENERATE_LOAN_APP_ACTIVITY, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        "Accept-language": "en",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(payload),
    }).then(function (response) {
      if (response.status === 200) {
        let filename = response.headers.get("Content-Disposition");
        let dialogMessage = response.headers.get("Message");

        if (filename != null) {
          filename = filename.split(";")[1].split("filename=")[1];
          //dialogMessage = dialogMessage
        }

        response.blob().then((blob) => {

          messageDialog({
            isOpen: true,
            condition: CONSTANSTS.DIALOG.success,
            title: CONSTANSTS.DIALOG_TITLE.general_success,
            message: dialogMessage?? CONSTANSTS.DIALOG_MESSAGE.download_successful,
            defaultOnClick: false,
          });
          let url = window.URL.createObjectURL(blob);

          let a = document.createElement("a");
          a.href = url;
          a.download = filename;
          a.click();

      
        });
      } else {
        response.json().then(function (data) {
          const response = data;

          messageDialog({
            isOpen: true,
            condition: CONSTANSTS.DIALOG.failed,
            title: CONSTANSTS.DIALOG_TITLE.general_failed,
           message: response.message,
            defaultOnClick: false,
          });
        });
      }
    });
  };

  const generateBillingTransaction = () => {
    let token = null;
    token = localStorage.getItem("@storage_token");
    let sliptStartDate = null;
    let sliptStartEnd = null;

    if(billingDate.dateFrom !== null){
      let startDate = null;
      startDate = billingDate.dateFrom;
      sliptStartDate = startDate.split("-");
    }
    if(billingDate.dateTo !== null){
      let endDate = null;
      endDate = billingDate.dateTo;
      sliptStartEnd = endDate.split("-");
    }
    
    let payload = {
      dateFrom: billingDate.dateFrom ? sliptStartDate[2] + "/" + sliptStartDate[1] + "/" + sliptStartDate[0] : null,
      dateTo: billingDate.dateTo ? sliptStartEnd[2] + "/" + sliptStartEnd[1] + "/" + sliptStartEnd[0] : null,
      // keyword: fraudKeyword ? fraudKeyword : "",
      includeVoid: isApprove,
    };
    fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.GENERATE_BILLING_TRANSACTION, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        "Accept-language": "en",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(payload),
    }).then(function (response) {
      if (response.status === 200) {
        let filename = response.headers.get("Content-Disposition");
        let dialogMessage = response.headers.get("Message");

        if (filename != null) {
          filename = filename.split(";")[1].split("filename=")[1];
          //dialogMessage = dialogMessage;
        }

        response.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);

          let a = document.createElement("a");
          a.href = url;
          a.download = filename;
          a.click();

          messageDialog({
            isOpen: true,
            condition: CONSTANSTS.DIALOG.success,
            title: CONSTANSTS.DIALOG_TITLE.general_success,
            message: dialogMessage ?? CONSTANSTS.DIALOG_MESSAGE.download_successful,
            defaultOnClick: false,
          });
        });
      } else {
        response.json().then(function (data) {
          const response = data;

          messageDialog({
            isOpen: true,
            condition: CONSTANSTS.DIALOG.failed,
            title: CONSTANSTS.DIALOG_TITLE.general_failed,
           message: response.message,
            defaultOnClick: false,
          });
        });
      }
    });
  };

  // const [agingKeyword, setAgingKeyword] = useState(null);
  const [fraudKeyword, setFraudKeyword] = useState(null);

  // const onChangeAging = (e) => {
  //   const value = e.currentTarget.value;
  //   // setAgingKeyword(value.replace(/\D/, ""));
  //   setAgingKeyword(value);
  // }
  const onChangeFraud = (e) => {
    const value = e.currentTarget.value;
    // setFraudKeyword(value.replace(/\D/, ""));
    setFraudKeyword(value);
  }

  const [isApprove, setIsApprove] = useState(false);
  const handleApprover = () => {
    setIsApprove(!isApprove);
  };

  return (
    <div className="page-report-payment">
      <div className="row">
        <div className="column left-side">
          <div className="card">
            <div className="side-menu">
              {reportOthers.generate_aging && (<a href="#" onClick={() => tabClicked("1")} className={firstMenu=== "display" ? "clicked" : ""}>
                Aging Report
              </a>)}
              {reportOthers.generate_fraud_detection && (<a href="#" onClick={() => tabClicked("2")} className={secondMenu === "display" ? "clicked" : ""}>
                Fraud Detection Report 
              </a>)}
              {reportOthers.generate_loan_activity && (<a href="#" onClick={() => tabClicked("3")} className={thirdMenu === "display" ? "clicked" : ""}>
                Loan Application Activity Report
              </a>)}
              {reportOthers.generate_billing_transaction && (<a href="#" onClick={() => tabClicked("4")} className={forthMenu === "display" ? "clicked" : ""}>
                Billing Transaction Report
              </a>)}
              {reportOthers.generate_settled_contract && (<a href="#" onClick={() => tabClicked("5")} className={fifthMenu === "display" ? "clicked" : ""}>
                Settled Contract Report
              </a>)}
              <a href="#"> </a>
              <a href="#"> </a>
              <a href="#"> </a>
              <a href="#"> </a>
              <a href="#"> </a>
              <a href="#"> </a>
              <a href="#"> </a>
              <a href="#"> </a>
            </div>
          </div>
        </div>

        <div className="column right-side">
        {reportOthers.generate_aging && (<div className={firstMenu}>
            <p className="title-text">Aging Report</p>
            <div className="card third-tab">
              <div>
                <div className="half-menu">
                  <div className="date-select">
                    <div style={{ width: "250px", float: "left" }}>
                      <span>Customer Name</span>
                    </div>
                    <input type="text" onChange={ocCustomerName} value={agingKeyword.name}/>
                  </div>
                </div>
                <div className="half-menu">
                  <div className="date-select">
                    <div style={{ width: "120px", float: "left" }}>
                      <span>Mobile No</span>
                    </div>
                    <input type="text" onChange={ocMobileNo} value={agingKeyword.mobileNo} maxLength={15}/>
                  </div>
                </div>
              </div>
              <div>
                <div className="half-menu">
                  <div className="date-select">
                    <div style={{ width: "250px", float: "left" }}>
                      <span>Contract ID</span>
                    </div>
                    <input type="text" onChange={ocContractId} value={agingKeyword.id} maxLength={15}/>
                  </div>
                </div>
              </div>
              <div style={{marginTop: "50px"}}>
                <div className="half-menu">
                  <div className="date-select">
                    <div style={{ width: "250px", float: "left" }}>
                      <span>Aging as at</span>
                    </div>
                    <input type="date" className="merchant-input-text" onChange={onChangeDateStart} value={agingDate.dateFrom} />
                  </div>
                </div>
                {/* <div className="half-menu">
                  <div className="date-select">
                    <div style={{ width: "120px", float: "left" }}>
                      <span>End</span>
                    </div>
                    <input type="date" className="merchant-input-text" onChange={onChangeDateEnd} value={agingDate.dateTo} min={minDate} style={{ float: "left" }}/>
                  </div>
                </div> */}
              </div>
              <div>
                <div className="half-menu">
                  <div className="date-select">
                    <div style={{ width: "120px", float: "left" }}>
                      {/* <span>Search</span> */}
                    </div>
                    {/* <input type="text" name="agingKeyword" onChange={onChangeAging} value={agingKeyword}/> */}
                  </div>
                </div>
                <div className="half-menu">
                  <div style={{ marginLeft: "220px" }}>
                    <button
                      style={{ border: "none", borderRadius: "8px", backgroundColor: "#f5903d", color: "#FFFFFF", fontFamily: "gotham-bold", width: "200px" }}
                      onClick={() => generateAgingReport()} >
                      Generate
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>)}
          <div className={secondMenu}>
            <p className="title-text">Fraud Detection Report</p>
            <div style={{ position:"absolute", right:"70px", top:"62px"}}>
            </div>
            <div className="card third-tab">
              <div>
                <div className="half-menu">
                  <div className="date-select">
                    <div style={{ width: "120px", float: "left" }}>
                      <span>Start Date</span>
                    </div>
                    <input type="date" className="merchant-input-text" onChange={onChangeFraudDateStart} value={fraudDate.dateFrom} max={maxFraudDate} />
                  </div>
                </div>
                <div className="half-menu">
                  <div className="date-select">
                    <input type="date" className="merchant-input-text" onChange={onChangeFraudDateEnd} value={fraudDate.dateTo} min={minFraudDate} style={{ float: "right" }}/>
                    <div style={{ width: "120px", float: "right" }}>
                      <span>End Date</span>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="half-menu">
                  <div className="date-select">
                    <div style={{ width: "120px", float: "left", display: "none" }}>
                      <span>Search</span>
                    </div>
                    <input type="text" name="fraudKeyword" onChange={onChangeFraud} value={fraudKeyword} style={{display: "none"}}/>
                  </div>
                </div>
                <div className="half-menu">
                  <div style={{ float:"right" }}>
                    <button
                      style={{ border: "none", borderRadius: "8px", backgroundColor: "#f5903d", color: "#FFFFFF", fontFamily: "gotham-bold", width: "200px" }}
                      onClick={() => generateFraudDetection()} >
                      Generate
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={thirdMenu}>
            <p className="title-text">Loan Application Activity Report</p>
            <div className="card third-tab">
              <div>
                <div className="half-menu">
                  <div className="date-select">
                    <div style={{ width: "250px", float: "left" }}>
                      <span>Customer Name</span>
                    </div>
                    <input type="text" onChange={ocACustomerName} value={activityKeyword.name}/>
                  </div>
                </div>
                <div className="half-menu">
                  <div className="date-select">
                    <div style={{ width: "120px", float: "left" }}>
                      <span>Mobile No</span>
                    </div>
                    <input type="text" onChange={ocAMobileNo} value={activityKeyword.mobileNo}/>
                  </div>
                </div>
              </div>
              <div>
                <div className="half-menu">
                  <div className="date-select">
                    <div style={{ width: "250px", float: "left" }}>
                      <span>Application ID</span>
                    </div>
                    <input type="text" onChange={ocApplicationId} value={activityKeyword.id}/>
                  </div>
                </div>
              </div>
              <div style={{marginTop: "50px"}}>
                <div className="half-menu">
                  <div className="date-select">
                    <div style={{ width: "250px", float: "left" }}>
                      <span>Application Date Start</span>
                    </div>
                    <input type="date" className="merchant-input-text" onChange={onChangeActivityDateStart} value={activityDate.dateFrom} max={maxActivityDate} />
                  </div>
                </div>
                <div className="half-menu">
                  <div className="date-select">
                    <div style={{ width: "120px", float: "left" }}>
                      <span>End</span>
                    </div>
                    <input type="date" className="merchant-input-text" onChange={onChangeActivityDateEnd} value={activityDate.dateTo} min={minActivityDate} style={{ float: "left" }}/>
                  </div>
                </div>
              </div>
              <div>
                <div className="half-menu">
                  <div className="date-select">
                    <div style={{ width: "120px", float: "left" }}>
                      {/* <span>Search</span> */}
                    </div>
                    {/* <input type="text" name="agingKeyword" onChange={onChangeAging} value={agingKeyword}/> */}
                  </div>
                </div>
                <div className="half-menu">
                  <div style={{ marginLeft: "220px" }}>
                    <button
                      style={{ border: "none", borderRadius: "8px", backgroundColor: "#f5903d", color: "#FFFFFF", fontFamily: "gotham-bold", width: "200px" }}
                      onClick={() => generateLoanApplicationActivity()} >
                      Generate
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={forthMenu}>
            <p className="title-text">Billing Transaction Report</p>
            <div style={{ position:"absolute", right:"70px", top:"62px"}}>
            </div>
            <div className="card third-tab">
              <div>
                <div className="half-menu">
                  <div className="date-select">
                    <div style={{ width: "120px", float: "left" }}>
                      <span>Start Date</span>
                    </div>
                    <input type="date" className="merchant-input-text" onChange={onChangeBillingDateStart} value={billingDate.dateFrom} max={maxBillingDate} />
                  </div>
                </div>
                <div className="half-menu">
                  <div className="date-select">
                    <input type="date" className="merchant-input-text" onChange={onChangeBillingDateEnd} value={billingDate.dateTo} min={minBillingDate} style={{ float: "right" }}/>
                    <div style={{ width: "120px", float: "right" }}>
                      <span>End Date</span>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="half-menu">
                  <div className="check-void">
                    {/* <div style={{ float: "left" }}>
                      <span>Include Void Status</span>
                    </div> */}
                    <input type="checkbox" name="includeVoid" id="includeVoid" onChange={handleApprover}/>
                    <span>Include void invoice</span>
                  </div>
                </div>
                <div className="half-menu">
                  <div style={{ float:"right" }}>
                    <button
                      style={{ border: "none", borderRadius: "8px", backgroundColor: "#f5903d", color: "#FFFFFF", fontFamily: "gotham-bold", width: "200px" }}
                      onClick={() => generateBillingTransaction()} >
                      Generate
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={fifthMenu}>
            <p className="title-text">Settled Contract Report</p>
            <div className="card third-tab">
              <div>
                <div className="half-menu">
                  <div className="date-select">
                    <div style={{ width: "250px", float: "left" }}>
                      <span>Customer Name</span>
                    </div>
                    <input type="text" onChange={scCustomerName} value={settledKeyword.name}/>
                  </div>
                </div>
                <div className="half-menu">
                  <div className="date-select">
                    <div style={{ width: "120px", float: "left" }}>
                      <span>Mobile No</span>
                    </div>
                    <input type="text" onChange={scMobileNo} value={settledKeyword.mobileNo} maxLength={15}/>
                  </div>
                </div>
              </div>
              <div>
                <div className="half-menu">
                  <div className="date-select">
                    <div style={{ width: "250px", float: "left" }}>
                      <span>Contract ID</span>
                    </div>
                    <input type="text" onChange={scContractId} value={settledKeyword.id} maxLength={15}/>
                  </div>
                </div>
              </div>
              <div style={{marginTop: "50px"}}>
                <div className="half-menu">
                  <div className="date-select">
                    <div style={{ width: "250px", float: "left" }}>
                      <span>Settled As At</span>
                    </div>
                    <input type="date" className={contractStartDate} onChange={onChangeSCDateStart} value={settledDate.dateFrom} max={settledMaxDate} />
                  </div>
                </div>
                <div className="half-menu">
                  <div className="date-select">
                    <div style={{ width: "120px", float: "left" }}>
                      <span>End</span>
                    </div>
                    <input type="date" className={contractEndDate} onChange={onChangeSCDateEnd} value={settledDate.dateTo} min={settledMinDate} style={{ float: "left" }} />
                  </div>
                </div>
              </div>
              <div>
                <div className="half-menu">
                  <div className="date-select">
                    <div style={{ width: "120px", float: "left" }}>
                      {/* <span>Search</span> */}
                    </div>
                    {/* <input type="text" name="agingKeyword" onChange={onChangeAging} value={agingKeyword}/> */}
                  </div>
                </div>
                <div className="half-menu">
                  <div style={{ marginLeft: "220px" }}>
                    <button
                      style={{ border: "none", borderRadius: "8px", 
                      backgroundColor: "#f5903d",
                      color: "#FFFFFF", fontFamily: "gotham-bold", width: "200px" }}
                      onClick={() => generateSettledReport()} >
                      Generate
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageReportOthers;
