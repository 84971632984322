import { toast, Slide } from "react-toastify";

const toastConfig = {
  transition: Slide,
  position: "top-center",
  autoClose: 3000,
  hideProgressBar: true,
  newestOnTop: false,
  closeOnClick: true,
  rtl: true,
  pauseOnFocusLoss: false,
  draggable: false,
  pauseOnHover: true,
};

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const appToast = (message, condition, isLoading) => {
  if (message == "" && condition == true) {
    toast.dismiss();
  } else if (isLoading == null || isLoading == undefined) {
    toast.dismiss();
    sleep(800).then(() => toast(message, { ...toastConfig, className: condition ? "app-toast__success" : "app-toast__fail" }));
  } else {
    toast.dismiss();
    toast(message, { ...toastConfig, autoClose: false });
  }
};

export default appToast;
