// libraries
import React, { useRef, useCallback, useContext, useState, useEffect, Fragment } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { PieChart, Pie, Cell } from "recharts";
import { Modal } from "reactstrap";
import { BsDownload } from "react-icons/bs";
import moment from "moment";
import "video-react/dist/video-react.css";
import ZoomMtgEmbedded from "@zoomus/websdk/embedded";
import TextareaAutosize from "react-textarea-autosize";

//component
import AppButton from "components/app-button";
import pathnames from "routes/pathnames";
import { updateUserList } from "redux/slices/user-list-slice";
// api
// import api from "services/api";
import AppCustomToast, { PageContext } from "components/app-custom-toast";
// commons
import CONSTANSTS from "common/constansts";
// assests
import iconPlay from "assets/images/pages/icon-play-video.png";
import iconClose from "assets/images/pages/icons8-close-60.png";
import { IoEyeOutline } from "react-icons/io5";

import zoomClient from "services/zoom-client";

const getTableRowClassNames = (index) => {
	const classNames = ["audit-trail-modal__table-row"];
	if (index % 2 === 0) classNames.push("audit-trail-modal__table-row--gray");

	return classNames.join(" ");
};

const AuditTrailModal = ({ isOpen, toggle, dataList }) => {
	return (
		<Modal className="audit-trail-modal" contentClassName="audit-trail-modal__content" isOpen={isOpen}>
			<div className="audit-trail-modal__body">
				<div className="audit-trail-modal__header-row">
					<div className="audit-trail-modal__column-timestamp">
						<span>Timestamp</span>
					</div>
					<div className="audit-trail-modal__column-details">Details</div>
					<div className="audit-trail-modal__column-by">By</div>
				</div>
				<div className="audit-trail-modal__table-body">
					{dataList && dataList.length
						? dataList.map((o, i) => (
								<div key={i} className={getTableRowClassNames(i)}>
									<div className="audit-trail-modal__column-timestamp">
										<span>{o.timestamp}</span>
									</div>
									<div className="audit-trail-modal__column-details">{o.message}</div>
									<div className="audit-trail-modal__column-by">{o.updatedBy}</div>
								</div>
						  ))
						: null}
				</div>
			</div>
			<div className="audit-trail-modal__button-wrapper">
				<AppButton colorType="primary" label="Back" onClick={toggle} />
			</div>
		</Modal>
	);
};

const PageLoanApplicationView = () => {
	const indicatorValue = useContext(PageContext);
	function messageDialog(indicator) {
		AppCustomToast({
			showIndicator: indicator,
			reintIndicator: indicatorValue[1](indicator),
		});
	}
	const history = useHistory();
	const { staffId } = useParams();
	const dispatch = useDispatch();

	const inputField = {
		assign: {
			value: "",
		},
		applicantName: {
			error: false,
			value: "",
			placeholder: "Applicant Name",
			required: "Cannot be empty",
			invalid: "",
		},
		loanInterestRate: {
			error: false,
			value: "",
			placeholder: "Loan Interst Rate",
			required: "Cannot be empty",
			invalid: "",
		},
		requestDate: {
			error: false,
			value: "",
			placeholder: "",
			required: "Date required",
			invalid: "",
		},
		loanTenure: {
			error: false,
			value: "",
			placeholder: "Loan Tenure",
			required: "Loan Tenure required",
			invalid: "Invalid Role",
		},
		nric: {
			error: false,
			value: "",
			placeholder: "Enter NRIC",
			required: "Password required",
			invalid: "Invalid, please provide correct format",
		},
		mobile: {
			error: false,
			value: "",
			placeholder: "Mobile no.",
			required: "Mobile number required",
			invalid: "",
		},
		email: {
			error: false,
			value: "",
			placeholder: "Email",
			required: "Email required",
			invalid: "",
		},
		contactDetails: {
			error: false,
			value: "",
			placeholder: "Contact Details",
			required: "Contact Details required",
			invalid: "",
		},
		remarks: {
			error: false,
			value: "",
			placeholder: "Select Remarks",
			required: "Remarks required",
			invalid: "",
		},
		applicantEmail: {
			value: "",
		},
		loanAmount: {
			value: "",
		},
		username: {
			value: "",
		},
	};

	const labelField = {
		applicantId: {
			value: "",
		},
		preRecordedVideo: {
			value: "",
		},
		liveBriefing: {
			value: "",
		},
		liveBriefingPreferredDate: {
			value: "",
		},
		liveBriefingScheduledDate: {
			value: "",
		},
		eAgreement: {
			value: "",
		},
		contractId: {
			value: "",
		},
	};
	const [input, setInput] = useState(inputField);
	const [label, setLabel] = useState(labelField);
	const [viewData, setViewData] = useState({});
	const [rejectReason, setRejectReason] = useState([]);
	const [scoreTab, setScoreTab] = useState("ctos");
	const [tab, setTab] = useState("1");
	const [zoomOnCall, setZoomOnCall] = useState(false);
	const [videoTab, setVideoTab] = useState({
		// 0 = unselect
		// 1 = selected
		interview: 1,
		briefing: 0,
	});
	const [headerButton, setHeaderButton] = useState({
		middle: {
			label: "",
			disable: false,
		},
		right: {
			label: "Reject",
		},
	});
	const [auditTrailModal, setAuditTrailModal] = useState({
		isOpen: false,
	});

	const getTabClassNames = (type) => {
		const classNames = ["loan-application-view__score-button"];

		if (scoreTab === type) {
			classNames.push("loan-application-view__score-button--active");
		}

		return classNames.join(" ");
	};

	const getTabScoreClassNames = (type) => {
		const classNames = ["loan-application-view__score-point"];
		if (scoreTab === type) classNames.push("loan-application-view__score-point--active");

		return classNames.join(" ");
	};

	const headerButtonFunction = (status) => {
		switch (status.toLowerCase()) {
			case "new":
				setHeaderButton({
					middle: {
						label: "Initial Approval",
						disable: false,
					},
					right: {
						label: "Reject",
					},
				});
				break;

			case "initial approved":
				setHeaderButton({
					middle: {
						label: "Initial Approval",
						disable: true,
					},
					right: {
						label: "Reject",
					},
				});
				break;

			case "in review":
				setHeaderButton({
					middle: {
						label: "Initial Approval",
						disable: false,
					},
					right: {
						label: "Reject",
					},
				});
				break;

			case "approved":
				setHeaderButton({
					middle: {
						label: "Final Approval",
						disable: true,
					},
					right: {
						label: "Reject",
					},
				});

				break;

			case "live briefing":
				setHeaderButton({
					middle: {
						label: "Sign Agreement",
						disable: true,
					},
					right: {
						label: "Reject",
					},
				});
				setVideoTab({
					briefing: 1,
					interview: 0,
				});

				break;

			case "sign agreement":
				setHeaderButton({
					middle: {
						label: "Sign Agreement",
						disable: true,
					},
					right: {
						label: "Reject",
					},
				});
				break;

			case "completed":
				setHeaderButton({
					middle: {
						label: "Complete",
						disable: false,
					},
					right: {
						label: "View Contract",
					},
				});
				break;

			case "rejected":
				setHeaderButton({
					middle: {
						label: "",
						disable: true,
					},
					right: {
						label: "Reject",
					},
				});
				break;

			default:
				break;
		}
	};
	const [contractId, setContractId] = useState({
		value: null,
	});

	const [loanDetailsAccess, setLoanDetailsAccess] = useState({
		approval: false,
		assign_officer: false,
		audit_trail: false,
		generate_agreement: false,
		interview: false,
		is_recording_existed: false,
		leave: false,
		live_briefing: false,
		update_remark: false,
		update_status: false,
		view_ctos_score: false,
		view_ivc_score: false,
		view_loan_detail: false,
	});

	useEffect(() => {
		const appId = staffId;
		let loanAccess = null;
		loanAccess = JSON.parse(localStorage.getItem("accessMatrix"));
		if (loanAccess) {
			setLoanDetailsAccess(loanAccess.accessMatrix.Loan_Application_Detail);
			if (!loanAccess.accessMatrix.Loan_Application_Detail.interview && loanAccess.accessMatrix.Loan_Application_Detail.live_briefing) {
				setVideoTab({
					interview: 0,
					briefing: 1,
				});
			}
		}
		const loadData = async () => {
			try {
				let token = null;
				token = localStorage.getItem("@storage_token");
				let response = fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.GET_LOAN_BYID + appId, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Accept: "*/*",
						"Accept-language": "en",
						Authorization: "Bearer " + token,
					},
				})
					.then(function (response) {
						return response.json();
					})
					.then(function (data) {
						if (data.responseCode === 200) {
							if (data !== null || data !== undefined) {
								let result = data.result;
								console.log(data.result.zoomMeetingSDKAuthDTO);
								setContractId(Object.getOwnPropertyDescriptor(result, "contractId"));
								setViewData(result);
								headerButtonFunction(result.status);
								setRejectReason(Object.entries(result.rejectedReasonList));
								if (result.status.toLowerCase() === "initial approved") {
									if (result.interviewDate !== null && result.rejectedReason === null) {
										setHeaderButton({
											middle: {
												label: "Final Approval",
												disable: false,
											},
											right: {
												label: "Reject",
											},
										});
									}
								} else if (result.status.toLowerCase() === "live briefing") {
									if (result.liveBriefingDate !== null && result.rejectedReason === null) {
										setHeaderButton({
											middle: {
												label: "Sign Agreement",
												disable: false,
											},
											right: {
												label: "Reject",
											},
										});
									}
								}
								setInput({
									...input,
									applicantName: {
										error: false,
										value: result.applicantName,
									},
									loanInterestRate: {
										value: result.interestRate,
									},
									requestDate: {
										value: result.requestDate,
									},
									loanTenure: {
										value: result.loanTenure,
									},
									nric: {
										value: result.applicantNric,
									},
									email: {
										value: result.applicantEmail,
									},
									mobile: {
										value: result.applicantMobile,
									},
									applicantEmail: {
										value: result.applicantEmail,
									},
									remarks: {
										value: result.remark,
									},
									loanAmount: {
										value: result.loanAmount,
									},
									username: {
										value: result.username,
									},
								});
								setLabel({
									...label,
									applicantId: {
										value: result.loanApplicationReferenceNumber,
									},
									preRecordedVideo: {
										value: result.preRecordedVideoDate,
									},
									liveBriefing: {
										value: result.liveBriefingDate,
									},
									liveBriefingPreferredDate: {
										value: result.liveBriefingScheduledDate,
									},
									liveBriefingScheduledDate: {
										value: result.liveBriefingScheduledDate,
									},
									eAgreement: {
										value: result.loanAgreementDate,
									},
									contractId: {
										value: result.loanContractReferenceNumber,
									},
								});
							}
						} else {
							messageDialog({
								isOpen: true,
								condition: CONSTANSTS.DIALOG.failed,
								title: CONSTANSTS.DIALOG_TITLE.general_failed,
								message: data.message,
								defaultOnClick: false,
								onClick: () => history.push(pathnames.pageLoanApplicationList),
							});
						}
					});
				dispatch(updateUserList(response));
			} catch (error) {
				console.log(error);
			}
		};
		loadData();
	}, []);

	const [signAgreementOnClick, setSignAgreementOnClick] = useState(false);

	const statusUpdate = () => {
		let status = "";
		if (headerButton.middle.label.toLowerCase().replace(/\s/g, "") === "complete") {
			history.push(pathnames.pageLoanApplicationList);
		}
		if (headerButton.middle.label.toLowerCase().replace(/\s/g, "") === "initialapproval") {
			status = "INITIAL_APPROVED";
		}
		if (headerButton.middle.label.toLowerCase().replace(/\s/g, "") === "finalapproval") {
			status = "APPROVED";
		}
		if (headerButton.middle.label.toLowerCase().replace(/\s/g, "") === "signagreement") {
			// status = "SIGNAGREEMENT";
			if (signAgreementOnClick === false) {
				generateLoanAgreement();
			}
		}
		let payload = {
			referenceNumber: viewData.loanApplicationReferenceNumber,
			rejectedReason: "",
			status: status,
		};
		if (status) {
			fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.UPDATE_LOAN_STATUS, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "*/*",
					"Accept-language": "en",
					Authorization: "Bearer " + localStorage.getItem("@storage_token"),
				},
				body: JSON.stringify(payload),
			})
				.then((response) => response.json())
				.then((json) => {
					if (json.responseCode === 200) {
						messageDialog({ isOpen: true, condition: CONSTANSTS.DIALOG.success, title: CONSTANSTS.DIALOG_TITLE.general_success, message: json.message });
						// appToast("Successfully update status", true);
					} else {
						messageDialog({ isOpen: true, condition: CONSTANSTS.DIALOG.failed, title: CONSTANSTS.DIALOG_TITLE.general_failed, message: json.message, defaultOnClick: false });
					}
				});
		}
	};

	const rejectReasonUpdate = (rr) => {
		let payload = {
			referenceNumber: viewData.loanApplicationReferenceNumber,
			rejectedReason: rr,
			status: "REJECTED",
		};

		if (rr) {
			// fetch("http://49.0.203.50/ivc-app/bo/loanAppplication/api/v1/updateStatus", {
			fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.UPDATE_LOAN_STATUS, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "*/*",
					"Accept-language": "en",
					Authorization: "Bearer " + localStorage.getItem("@storage_token"),
				},
				body: JSON.stringify(payload),
			})
				.then((response) => response.json())
				.then((json) => {
					if (json.responseCode === 200) {
						// appToast("Successfully update reject status", true);
						history.go(0);
					} else {
						messageDialog({ isOpen: true, condition: CONSTANSTS.DIALOG.failed, title: CONSTANSTS.DIALOG_TITLE.general_failed, message: json.message, defaultOnClick: false });
					}
				});
		}
	};

	const auditTrail = () => {
		setAuditTrailModal({ isOpen: true });
	};

	const [style, setStyle] = useState("dropdown-content");
	const [buttonStyle, setButtonStyle] = useState("dropButton");
	const changeStyle = () => {
		if (style === "dropdown-content") {
			setStyle("dropdown-content show");
		} else {
			setStyle("dropdown-content");
		}
		if (buttonStyle === "dropButton") {
			setButtonStyle("dropButtonHover");
		} else {
			setButtonStyle("dropButton");
		}
	};

	const staffAssignedFunction = (staffUsername) => {
		let response = fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.ASSIGN_AGENT, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				"Accept-language": "en",
				Authorization: "Bearer " + localStorage.getItem("@storage_token"),
			},
			body: JSON.stringify({
				referenceNumber: viewData.loanApplicationReferenceNumber,
				username: staffUsername,
			}),
		})
			.then((response) => response.json())
			.then((json) => {
				if (json.responseCode === 200) {
					// appToast("Successfully update assigned staff", true);
					history.go(0);
				} else {
					messageDialog({ isOpen: true, condition: CONSTANSTS.DIALOG.failed, title: CONSTANSTS.DIALOG_TITLE.general_failed, message: json.message, defaultOnClick: false });
				}
			});
		dispatch(updateUserList(response));
	};

	const updateRemark = () => {
		let token = null;
		token = localStorage.getItem("@storage_token");
		try {
			// let response = fetch("http://49.0.203.50/ivc-app/bo/loanAppplication/api/v1/updateRemark", {
			let response = fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.UPDATE_LOAN_REMARK, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "*/*",
					"Accept-language": "en",
					Authorization: "Bearer " + token,
				},
				body: JSON.stringify({
					referenceNumber: viewData.loanApplicationReferenceNumber,
					remark: input.remarks.value,
				}),
			})
				.then((response) => response.json())
				.then((json) => {
					if (json.responseCode === 200) {
						messageDialog({ isOpen: true, condition: CONSTANSTS.DIALOG.success, title: CONSTANSTS.DIALOG_TITLE.general_success, message: json.message });

						// appToast("Remark successfully update", true);
					} else {
						messageDialog({ isOpen: true, condition: CONSTANSTS.DIALOG.failed, title: CONSTANSTS.DIALOG_TITLE.general_failed, message: json.message, defaultOnClick: false });
						// appToast(json.message, false);
					}
				});
			dispatch(updateUserList(response));
		} catch (error) {
			console.log("error", error);
		}
	};

	const onHandleOnChange = (event) => {
		const name = event.currentTarget.name;
		const value = event.currentTarget.value;

		setInput({
			...input,
			[name]: {
				...input[name],
				value: value,
			},
		});
	};

	const scoreTabClicked = (name) => {
		setScoreTab(name);
	};

	const pieDataCtos = [
		{
			name: "remaining",
			value: 100 - viewData.ctosCreditScore,
		},
		{
			name: "score",
			value: viewData.ctosCreditScore,
		},
	];

	const pieDataIvc = [
		{
			name: "remaining",
			value: 100 - viewData.internalScore,
		},
		{
			name: "score",
			value: viewData.internalScore,
		},
	];

	const pieColors = {
		default: {
			remaining: "#F6F6F6",
			score: "#FACB57",
		},
	};

	const videoTabHandler = (type) => {
		if (type === "interview") {
			setVideoTab({
				interview: 1,
				briefing: 0,
			});
		} else if (type === "briefing") {
			setVideoTab({
				interview: 0,
				briefing: 1,
			});
		} else {
			setVideoTab({
				interview: 0,
				briefing: 0,
			});
		}
	};

	const downloadIvcDocs = (documentUrl, code, label) => {
		fetch(documentUrl, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				"Accept-language": "en",
				Authorization: "Bearer " + localStorage.getItem("@storage_token"),
			},
		})
			.then((response) => response.blob())
			.then((responseBlob) => {
				const link = document.createElement("a");
				link.target = "_blank";
				link.download = `${code}_${label}`;
				link.href = URL.createObjectURL(new Blob([responseBlob], { type: responseBlob.type }));
				link.click();
			});
	};

	const headerThirdButton = (label) => {
		if (label === "View Contract") {
			// let contractNumber = viewData.loanContractReferenceNumber.substring(7).replace(/^[0]+/g, "");
			history.push(pathnames.pageLoanContractView.replace(":contractId", contractId.value));
		} else if (label === "Sign Agreement") {
			// generateLoanAgreement();
		} else {
			changeStyle();
		}
	};

	const generateLoanAgreement = () => {
		setSignAgreementOnClick(true);
		let token = null;
		token = localStorage.getItem("@storage_token");
		// fetch("http://49.0.203.50/ivc-app/bo/loanAppplication/api/v1/generateLoanAgreement/" + viewData.loanApplicationReferenceNumber, {
		fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.GENERATE_LOAN_AGREEMENT + viewData.loanApplicationReferenceNumber, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				"Accept-language": "en",
				Authorization: "Bearer " + token,
			},
		})
			.then(function (response) {
				return response.json();
			})
			.then(function (data) {
				if (data.responseCode === 200) {
					// console.log(items)
					messageDialog({ isOpen: true, condition: CONSTANSTS.DIALOG.success, title: CONSTANSTS.DIALOG_TITLE.general_success, message: data.message });

					history.go(0);
				} else {
					setSignAgreementOnClick(false);
					messageDialog({ isOpen: true, condition: CONSTANSTS.DIALOG.failed, title: CONSTANSTS.DIALOG_TITLE.general_failed, message: data.message, defaultOnClick: false });
				}
			});
	};

	const [interviewVideo, setInterviewVideo] = useState(false);
	const selectInterviewVideo = (e) => {
		setInterviewVideo(e);
	};
	const [liveBriefingVideo, setLiveBriefingVideo] = useState(false);
	const selectLiveBriefingVideo = (e) => {
		setLiveBriefingVideo(e);
	};

	const [nricImage, setnricImage] = useState(false);

	function PopUpNRIC(props) {
		return props.trigger ? (
			<div className="pop-up">
				<div className="pop-up-data">
					{props.children}
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<button className="pop-up-button" style={{ margin: "15px 0px 15px 0px" }} onClick={() => setnricImage(false)}>
							Back
						</button>
					</div>
				</div>
			</div>
		) : (
			<div></div>
		);
	}

	const renderUnauthorizedMsg = () => {
		return (
			<div style={{ display: "flex", justifyContent: "center", alignSelf: "center", paddingTop: "180px" }}>
				<div style={{ height: "100%", fontFamily: "gotham-bold", fontSize: "22px" }}>Sorry, but you do not have the authority to view this section.</div>
			</div>
		);
	};

	const getMainTabClassNames = (type) => {
		const classNames = ["tabs__tab"];

		if (tab === type) {
			classNames.push("tabs__tab--active");
		}

		return classNames.join(" ");
	};

	const onHandleStartMeeting = useCallback(() => {
		let zoomMeetingRoot = document.getElementById("zoomMeetingRoot");
		let root = document.getElementById("root");
		let meetingWrapper = document.getElementById("meeting-wrapper");

		meetingWrapper.style.display = "none";
		setZoomOnCall(true);
		if (zoomMeetingRoot && root && zoomClient) {
			zoomClient.init({
				debug: true,
				zoomAppRoot: zoomMeetingRoot,
				language: "en-US",
				customize: {
					video: {
						viewSizes: {
							default: {
								width: root.offsetWidth - 40,
								height: 100,
							},
						},
					},
					chat: {},
				},
			});

			zoomClient
				.join({
					sdkKey: viewData.zoomMeetingSDKAuthDTO.sdkKey,
					signature: viewData.zoomMeetingSDKAuthDTO.signature,
					meetingNumber: viewData.zoomMeetingSDKAuthDTO.meetingId,
					role: 0,
					password: "123456",
					userName: "admin",
					userEmail: "admin@admin.com",
				})
				.then((e) => {})
				.catch((e) => {
					console.log(e);
				});

			zoomClient.on("connection-change", (event) => {
				switch (event.state) {
					case "Connected":
						break;
					case "Fail":
					case "Closed":
						setZoomOnCall(false);
						window.location.reload();
						break;
					default:
						break;
				}
			});

			zoomMeetingRoot.classList.add("active-zoom");
		}
	}, [viewData.zoomMeetingSDKAuthDTO]);

	useEffect(() => {
		return () => {
			if (zoomClient) {
				ZoomMtgEmbedded.destroyClient();
			}
		};
	}, []);

	return (
		<div className="application-view">
			<AuditTrailModal dataList={viewData.auditTrails ? viewData.auditTrails : []} isOpen={auditTrailModal.isOpen} toggle={() => setAuditTrailModal({ isOpen: false })} />

			<div className="top-context">
				<div className="title">Loan Application</div>
				<div className="top-context__wrapper">
					{loanDetailsAccess.approval && (
						<>
							<div className="search-button">
								<div className="dropdown">
									<button className={"dropSpan " + buttonStyle} onClick={() => headerThirdButton(headerButton.right.label)}>
										{headerButton.right.label}
									</button>
									<div className={style} style={{ width: "350px", color: "#9D9D9C" }}>
										<ul className="dropdown-reject" onClick={() => changeStyle()}>
											<li>Reject</li>
										</ul>
										<div className="dropdown-divider-wrapper">
											<div className="dropdown-divider" />
										</div>
										{rejectReason.length &&
											rejectReason.map((a, i) => {
												return (
													<ul
														key={i}
														className="dropdown-selection"
														onClick={() => {
															rejectReasonUpdate(a[0]);
															changeStyle();
														}}
													>
														<li>{a[1]}</li>
													</ul>
												);
											})}
									</div>
								</div>
							</div>

							{viewData.status === "Rejected" ? null : (
								<div className="search-button">
									<AppButton colorType="primary" disableTransparent={headerButton.middle.disable} disabled={headerButton.middle.disable} label={headerButton.middle.label} onClick={statusUpdate}></AppButton>
								</div>
							)}
						</>
					)}
					<div className="search-button" hidden={!loanDetailsAccess.audit_trail}>
						<AppButton colorType="primary" label="Audit Trail" onClick={auditTrail}></AppButton>
					</div>
				</div>
			</div>

			<div className="application-detail">
				<div className="tabs">
					<div
						className={getMainTabClassNames("1") + " tab1"}
						onClick={() => {
							if (!zoomOnCall) setTab("1");
							else {
								alert("Zoom meeting is in progress, please end this session before you leave.");
							}
						}}
					>
						<span>Loan Details/Customer Details</span>
					</div>
					<div
						className={getMainTabClassNames("2") + " tab2"}
						onClick={() => {
							if (!zoomOnCall) setTab("2");
						}}
					>
						<span>Join Meeting</span>
					</div>
				</div>

				{tab === "1" && (
					<div className="wrapper">
						<div className="review">
							<div className="review__wrapper">
								<span className="assigned">Assigned</span>
								<span className="reviewing">
									{viewData.status}
									{viewData.rejectedReason ? ` - ${viewData.rejectedReason}` : ""}
								</span>
							</div>

							<div className="review__wrapper">
								<div className="input-wrapper">
									{viewData.officerList && Object.entries(viewData.officerList).length ? (
										<select
											className="select-field"
											value={viewData.officerUsername ? viewData.officerUsername : "none"}
											onChange={(e) => staffAssignedFunction(e.target.value)}
											disabled={!loanDetailsAccess.assign_officer}
											style={!loanDetailsAccess.assign_officer ? { cursor: "not-allowed" } : {}}
										>
											{Object.entries(viewData.officerList).map((a, i) => {
												return (
													<option key={i} value={a[0]}>
														{a[1]}
													</option>
												);
											})}
											<option disabled>none</option>
										</select>
									) : null}
								</div>
								<span className="largeText">{viewData !== undefined ? viewData.loanApplicationReferenceNumber : ""}</span>
							</div>
							<hr />

							<div className="input-form">
								<div className="input-content">
									<div className="input-container">
										<span>Applicant Name</span>
										<div className="input-wrapper">
											<input type="text" value={input.applicantName.value ? input.applicantName.value : ""} readOnly />
										</div>
									</div>
									<div className="input-container">
										<span>Loan Amount</span>
										<div className="input-wrapper">
											<input type="text" value={input.loanAmount.value ? "RM " + input.loanAmount.value.toLocaleString() : ""} readOnly />
										</div>
									</div>
								</div>

								<div className="input-content">
									<div className="input-container">
										<span>Request Date</span>
										<div className="input-wrapper">
											<input type="text" value={input.requestDate.value ? input.requestDate.value : ""} readOnly />
										</div>
									</div>
									<div className="input-container">
										<span>Loan Interest Rate</span>
										<div className="input-wrapper">
											<input type="text" value={input.loanInterestRate.value ? parseFloat(input.loanInterestRate.value).toFixed(2) + "%" : ""} readOnly />
										</div>
									</div>
								</div>

								<div className="input-content">
									<div className="input-container">
										<span>NRIC</span>
										<div className="input-wrapper">
											<input type="text" value={input.nric.value ? input.nric.value : ""} readOnly />
											<a style={{ position: "absolute", left: "480px", marginTop: "4px" }} onClick={() => setnricImage(true)}>
												<IoEyeOutline size={24} />
											</a>
										</div>
									</div>
									<div className="input-container">
										<span>Loan Tenure</span>
										<div className="input-wrapper">
											<input type="text" value={input.loanTenure.value ? input.loanTenure.value + " years" : ""} readOnly />
										</div>
									</div>
								</div>

								<div className="input-content">
									<div className="input-container">
										<span>User Id</span>
										<div className="input-wrapper">
											<input type="text" value={input.username.value ? input.username.value : ""} readOnly />
										</div>
									</div>
									<div className="input-container">
										<span>Email</span>
										<div className="input-wrapper">
											<input type="text" value={input.email.value ? input.email.value : ""} readOnly />
										</div>
									</div>
								</div>

								<div className="input-content">
									<div className="input-container">
										<div className="content">
											<span>Mobile Phone</span>
											<div className="input-wrapper">
												<input type="text" value={input.mobile.value ? input.mobile.value : ""} readOnly />
											</div>
										</div>
									</div>
								</div>
							</div>

							<PopUpNRIC trigger={nricImage}>
								<div>
									{viewData.nricFrontUrl !== null ? <img src={viewData.nricFrontUrl} alt="nricImageUrl" style={{ maxWidth: "650px" }} /> : <p style={{ padding: "10px" }}>There is no NRIC image to be display.</p>}
								</div>
							</PopUpNRIC>

							<div className="input-form">
								<div className="input-content">
									<div className="input-container">
										<div className="content">
											<span>Live Interview</span>
											<div className="input-wrapper">
												<p className="input-wrapper__text">{viewData && viewData.interviewDate ? moment(viewData.interviewDate, "DD/MM/YYYY hh:mm A").format("DD/MM/YYYY HH:mm A") : "dd/mm/yyyy hh:mm"}</p>
											</div>
										</div>
									</div>

									<div className="input-container">
										<div className="content">
											<span>Customer Preferred Date</span>
											<div className="input-wrapper">
												<p className="input-wrapper__text">
													{viewData && viewData.interviewScheduledDate ? moment(viewData.interviewScheduledDate, "DD/MM/YYYY hh:mm A").format("DD/MM/YYYY HH:mm A") : "dd/mm/yyyy hh:mm"}
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="input-form">
								<div className="input-content">
									<div className="input-container">
										<div className="content">
											<span>Pre-recorded video</span>
											<div className="input-wrapper input-wrapper--disabled">
												<input type="text" disabled value={label.preRecordedVideo.value ? label.preRecordedVideo.value : ""} />
											</div>
										</div>
									</div>
									<div className="input-container">
										<div className="content">
											<span>Live Briefing</span>
											<div className="input-wrapper input-wrapper--disabled">
												<input
													type="text"
													disabled
													value={label.liveBriefing && label.liveBriefing.value ? moment(label.liveBriefing.value, "DD/MM/YYYY hh:mm A").format("DD/MM/YYYY HH:mm A") : "dd/mm/yyyy hh:mm"}
												/>
											</div>
										</div>
									</div>
								</div>

								<div className="input-content">
									<div className="input-container">
										<div className="content">
											<span>e-Agreement</span>
											<div className="input-wrapper input-wrapper--disabled">
												<input type="text" disabled value={label.eAgreement.value ? label.eAgreement.value : ""} />
											</div>
										</div>
									</div>
									<div className="input-container">
										<div className="content">
											<span>Contract ID</span>
											<div className="input-wrapper input-wrapper--disabled">
												<input type="text" disabled value={label.contractId.value ? label.contractId.value : ""} />
											</div>
										</div>
									</div>
								</div>
								<div className="input-content">
									<div className="input-container textarea-container">
										<div className="content">
											<span>Remark</span>
											<div className="input-wrapper">
												<TextareaAutosize
													name="remarks"
													id="remarks"
													minRows={1}
													maxRows={6}
													onChange={onHandleOnChange}
													value={input.remarks.value ? input.remarks.value : ""}
													disabled={!loanDetailsAccess.update_remark}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="button-container">
								<AppButton colorType="warning" onClick={() => history.push(pathnames.pageLoanApplicationList)} label="Cancel" />
								<AppButton colorType="primary" onClick={updateRemark} label="Save" />
							</div>
						</div>

						<div className="loan-application-view__score-wrapper">
							<div className="loan-application-view__score-button-row">
								<div className={getTabClassNames("ctos")} onClick={() => scoreTabClicked("ctos")}>
									<div className="loan-application-view__score-label-column">
										<span className="loan-application-view__score-label-title">CTOS Score</span>
										<span className="loan-application-view__score-label-subtitle">CTOS info from API</span>
									</div>
									<div className="loan-application-view__score-graph-wrapper">
										<span className="loan-application-view__score-graph">
											<div className={getTabScoreClassNames(scoreTab)}>{viewData.ctosCreditScore}</div>
											<PieChart width={50} height={50}>
												<Pie line data={pieDataCtos} dataKey="value" nameKey="name" innerRadius={20} outerRadius={25} paddingAngle={1} strokeWidth={0}>
													{pieDataCtos.map((entry, index) => {
														return <Cell key={`cell-${index}`} fill={pieColors.default[entry.name]} />;
													})}
												</Pie>
											</PieChart>
										</span>
									</div>
								</div>
								<div className={getTabClassNames("ivc")} onClick={() => scoreTabClicked("ivc")}>
									<div className="loan-application-view__score-label-column">
										<span className="loan-application-view__score-label-title">APL Score</span>
										<span className="loan-application-view__score-label-subtitle">APL info (internal)</span>
									</div>
									<div className="loan-application-view__score-graph-wrapper">
										<div className="loan-application-view__score-graph">
											<div className={getTabScoreClassNames(scoreTab.ivc)}>{viewData.internalScore}</div>
											<PieChart width={50} height={50}>
												<Pie data={pieDataIvc} dataKey="value" nameKey="name" innerRadius={20} outerRadius={25} paddingAngle={1} strokeWidth={0}>
													{pieDataIvc.map((entry, index) => {
														return <Cell key={`cell-${index}`} fill={pieColors.default[entry.name]} />;
													})}
												</Pie>
											</PieChart>
										</div>
									</div>
								</div>
							</div>
							<div className="loan-application-view__score-content">
								{scoreTab === "ctos" && (
									<div className="loan-application-view__score-content-wrapper">
										{loanDetailsAccess.view_ctos_score ? (
											<>
												<div className="loan-application-view__score-body">
													<span className="loan-application-view__score-body-title">Score Description</span>
													{viewData.ctosDescription &&
														viewData.ctosDescription.map((e, i) => (
															<span key={i} className="loan-application-view__score-body-detail">
																{e}
															</span>
														))}
												</div>
												<a href={viewData.ctosScorecardUrl} target="_blank" rel="noreferrer" className="loan-application-view__score-ctos-report">
													View CTOS Report
												</a>
											</>
										) : (
											renderUnauthorizedMsg()
										)}
									</div>
								)}

								{scoreTab === "ivc" && (
									<div className="loan-application-view__score-content-wrapper">
										{loanDetailsAccess.view_ivc_score && viewData.internalScoreBreakdown && viewData.internalScoreBreakdown.length
											? viewData.internalScoreBreakdown.map((o, i) => {
													return (
														<div key={i} className="loan-application-view__score-content-row">
															<div className="loan-application-view__score-content-column-1">{o.code}</div>
															<div className="loan-application-view__score-content-column-2">
																{o.documentUrl
																	? Object.entries(o.documentUrl).map(([key, value], index) => {
																			return (
																				<div key={key + index} className="loan-application-view__download-wrapper" onClick={() => downloadIvcDocs(value, o.code, o.label)}>
																					<BsDownload />
																				</div>
																			);
																	  })
																	: null}
																<span>{o.label}</span>
															</div>
															<div className="loan-application-view__score-content-column-3">{o.value} pts</div>
														</div>
													);
											  })
											: renderUnauthorizedMsg()}
									</div>
								)}
							</div>
						</div>
					</div>
				)}

				{tab === "2" && (
					<Fragment>
						<div id="zoomMeetingRoot" />
						<div className="wrapper wrapper--meeting" id="meeting-wrapper">
							<button id="join-meeting-button" className="join-meeting-button" onClick={onHandleStartMeeting}>
								Join Meeting
							</button>
						</div>
					</Fragment>
				)}
			</div>
		</div>
	);
};

export default PageLoanApplicationView;
