import SuccessLogo from "assets/images/pages/check.png";
import WarningLogo from "assets/images/pages/warning.png";
import LoadingLogo from "assets/images/pages/loading.png";
import React from "react";
import CONSTANSTS from "common/constansts";

export const PageContext = React.createContext();

const AppCustomToast = ({ showIndicator, reintIndicator }) => {
	let logoSrc = "";

	let imageClass = "card-title";

	if (showIndicator == undefined || showIndicator == null || showIndicator.isOpen == false) {
		return "";
	}

	if (showIndicator.condition == CONSTANSTS.DIALOG.success) {
		logoSrc = SuccessLogo;
	} else if (showIndicator.condition == CONSTANSTS.DIALOG.failed) {
		logoSrc = WarningLogo;
	} else if (showIndicator.condition == CONSTANSTS.DIALOG.loading) {
		logoSrc = LoadingLogo;
		imageClass += " rotate";
	}
	window.addEventListener(
		"popstate",
		function () {
			if (reintIndicator != undefined || reintIndicator != null) {
				reintIndicator({ isOpen: false, condition: CONSTANSTS.DIALOG.default });
			}
		},
		false
	);
	return (
		<div className="pop-up-alert">
			<div className="pop-up-alert__pop-up-alert-content">
				<div className="card text-center msg-box card-border w-bold ">
					{imageClass == "card-title" ? <div className="card-header c-navy pb-1 pt-1">{showIndicator.title}</div> : ""}
					<div className="card-body card-body-font">
						<img className={imageClass} alt="Loading..." src={logoSrc} />
						<p className="card-text mt-1 mb-1">{showIndicator.message}</p>
					</div>
					{imageClass == "card-title" ? (
						<button
							className="card-footer  border-success c-navy pb-2 pt-2"
							type="button"
							onClick={() => {
								if (showIndicator.defaultOnClick == false) {
									try {
										showIndicator.onClick();
									} catch (ex) {
										if (CONSTANSTS.GLOBAL_SETTINGS.isDebugging) {
											console.log(CONSTANSTS.LOG_MESSAGE.info_title + CONSTANSTS.LOG_MESSAGE.custom_toast_message);
										}
									}
								} else {
									history.go(0);
								}
								reintIndicator({ isOpen: false, condition: CONSTANSTS.DIALOG.default });
							}}
						>
							OK
						</button>
					) : (
						""
					)}
				</div>
			</div>
		</div>
	);
};

export default AppCustomToast;
