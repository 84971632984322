import React, { useState, useEffect } from "react";
import AppReactTable from "components/app-react-table";
import { FiSearch } from "react-icons/fi";
import { useHistory } from "react-router-dom";

import AppSearchBar from "components/app-search-bar";
import AppButton from "components/app-button";

import pathnames from "routes/pathnames";
import moment from "moment";

// commons
import CONSTANSTS from "common/constansts";

const LoanApplicationList = () => {
	const [loanList, setLoanList] = useState({
		export_loan_list: false,
		view_loan_list: false,
	});
	const [viewLoanDetails, setViewLoanDetails] = useState(false);

	useEffect(() => {
		let loanAccess = null;
		loanAccess = JSON.parse(localStorage.getItem("accessMatrix"));
		if (loanAccess) {
			setLoanList({
				export_loan_list: loanAccess.accessMatrix.Loan_Application_List.export_loan_list,
				view_loan_list: loanAccess.accessMatrix.Loan_Application_List.view_loan_list,
			});
			setViewLoanDetails(loanAccess.accessMatrix.Loan_Application_Detail.view_loan_detail);
		}
	}, []);

	// tableSearchFilter are filter payload to be send to backend
	const [tableSearchFilter, setTableSearchFilter] = useState({
		// insert the key value requested by BE here
		pageNo: 1,
		pageSize: 50,
		sortBy: "dateRequested",
		sortDir: "DESCENDING",
		dateRangeType: null,
		dateFrom: "string",
		dateTo: "string",
		keyword: "",
		status: null,
	});

	// trigger when user click on header when sorting
	const headerFilterClick = (header) => {
		let column = header.column;
		if (tableSearchFilter.sortBy === column.searchFilterValue) {
			// if click on the same column previously clicked will enter here
			if (tableSearchFilter.sortDir === "ASCENDING") {
				// if previously the column is sort by ascending order, sortDir will change to sort by descending order
				setTableSearchFilter({
					...tableSearchFilter,
					sortBy: column.searchFilterValue,
					sortDir: "DESCENDING",
					pageNo: tableSearchFilter.pageNo,
					status: tableSearchFilter.status,
				});
			} else {
				// if previously the column is sort by descending order, sortDir will change to sort by ascending order
				setTableSearchFilter({
					...tableSearchFilter,
					sortBy: column.searchFilterValue,
					sortDir: "ASCENDING",
					pageNo: tableSearchFilter.pageNo,
					status: tableSearchFilter.status,
				});
			}
		} else {
			// if click on a new column, it will sort by ascending order with the column value (column.searchFilterValue)
			setTableSearchFilter({
				...tableSearchFilter,
				sortBy: column.searchFilterValue,
				sortDir: "ASCENDING",
				pageNo: tableSearchFilter.pageNo,
				status: tableSearchFilter.status,
			});
		}
	};

	const headerArrowRotate = (active) => {
		// current column header arrow icon will rotate depending on ascending order or descending order (tableSearchFilter.sortDir)
		const classNames = ["react-table__icon-wrapper"];
		if (active && tableSearchFilter.sortDir === "DESCENDING") classNames.push("react-table__icon-wrapper-down");
		if (active) classNames.push("react-table__icon-wrapper--show");
		return classNames.join(" ");
	};

	// tableCoumn is to create table column
	const tableColumn = [
		{
			id: "referenceNumber",
			// header is the table header for id column
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick(header)} className="react-table__header">
						<span className="react-table__header-title">Application ID</span>
						<div className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			// accessor will list out the table data based on the data key. In this case, the mockData key of id
			accessor: "referenceNumber",
			// searchFilterValue is the column value that will send to BE to inform BE to sort according to which column. it is use to update tableSearchFilter.searchValue
			searchFilterValue: "referenceNumber",
			// minWidth, width, maxWidth is to adjust the width of this current column
		},
		{
			id: "applicantName",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick(header)} className="react-table__header">
						<span className="react-table__header-title">Applicant Name</span>
						<div className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			searchFilterValue: "applicantName",
			accessor: "applicantName",
		},
		{
			id: "dateRequested",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick(header)} className="react-table__header">
						<span className="react-table__header-title">Request Date</span>
						<div className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			searchFilterValue: "dateRequested",
			accessor: "dateRequested",
		},
		{
			id: "status",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick(header)} className="react-table__header">
						<span className="react-table__header-title">Status</span>
						<div className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			searchFilterValue: "status",
			accessor: "status",
		},
		{
			id: "loanAmount",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick(header)} className="react-table__header">
						<span className="react-table__header-title">Loan Amount</span>
						<div className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			searchFilterValue: "loanAmount",
			accessor: "loanAmount",
		},
		{
			id: "interviewDateTime",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick(header)} className="react-table__header">
						<span className="react-table__header-title">Interview Date</span>
						<div className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			searchFilterValue: "interviewDateTime",
			// Cell is to customize the table row table data, once use Cell, accessor wont be needed
			Cell: (row) => {
				return <span>{row.row.original.interviewDateTime ? moment(row.row.original.interviewDateTime, "DD/MM/YYYY hh:mm A").format("DD/MM/YYYY hh:mm A") : ""}</span>;
			},
		},
		{
			id: "liveBriefingDateTime",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick(header)} className="react-table__header">
						<span className="react-table__header-title">Briefing Date</span>
						<div className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			searchFilterValue: "liveBriefingDateTime",
			// Cell is to customize the table row table data, once use Cell, accessor wont be needed
			Cell: (row) => {
				return <span>{row.row.original.liveBriefingDateTime ? moment(row.row.original.liveBriefingDateTime, "DD/MM/YYYY hh:mm A").format("DD/MM/YYYY hh:mm A") : ""}</span>;
			},
		},
		{
			id: "contractReferenceNumber",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick(header)} className="react-table__header">
						<span className="react-table__header-title">Contract ID</span>
						<div className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			searchFilterValue: "contractReferenceNumber",
			accessor: "contractReferenceNumber",
		},
		{
			id: "button",
			Header: "",
			Cell: (row) => {
				return (
					<>
						{viewLoanDetails && (
							<div onClick={(e) => e.stopPropagation()}>
								{row.row.original.status !== "Pending Ctos" ? (
									<button onClick={() => viewPage(row.row.original)} className="react-table__search-button">
										<FiSearch size={24} />
									</button>
								) : (
									""
								)}
							</div>
						)}
					</>
				);
			},
		},
	];

	const [appList, setAppList] = useState(undefined);

	const [searchValue, setSearchValue] = useState("");
	// console.log(searchValue)

	const nextPage = async () => {
		if (appList.currentPageNumber < appList.totalPageNumber && !paginationLocker) {
			setTableSearchFilter({
				pageNo: tableSearchFilter.pageNo + 1,
				pageSize: tableSearchFilter.pageSize,
				sortBy: tableSearchFilter.sortBy,
				sortDir: tableSearchFilter.sortDir,
				dateRangeType: tableSearchFilter.dateRangeType,
				dateFrom: tableSearchFilter.dateFrom,
				dateTo: tableSearchFilter.dateTo,
				keyword: tableSearchFilter.keyword,
				status: tableSearchFilter.status,
			});
			setPaginationLocker(true);
		} else if (appList.currentPageNumber === appList.totalPageNumber) {
			setPaginationLocker(false);
		}
	};

	const previousPage = async () => {
		if (appList.currentPageNumber > 1 && !paginationLocker) {
			setTableSearchFilter({
				pageNo: tableSearchFilter.pageNo - 1,
				pageSize: tableSearchFilter.pageSize,
				sortBy: tableSearchFilter.sortBy,
				sortDir: tableSearchFilter.sortDir,
				dateRangeType: tableSearchFilter.dateRangeType,
				dateFrom: tableSearchFilter.dateFrom,
				dateTo: tableSearchFilter.dateTo,
				keyword: tableSearchFilter.keyword,
				status: tableSearchFilter.status,
			});
			setPaginationLocker(true);
		} else if (appList.currentPageNumber === 1) {
			setPaginationLocker(false);
		}
	};

	const onKeyUp = (event) => {
		if (event.key === "Enter") {
			console.log("enter press here! ", event);
		}
	};

	const onBlur = (e) => {
		setSearchValue(e.target.value);
	};

	const onHandleSearch = () => {
		setTableSearchFilter({
			pageNo: tableSearchFilter.pageNo,
			pageSize: tableSearchFilter.pageSize,
			sortBy: tableSearchFilter.sortBy,
			sortDir: tableSearchFilter.sortDir,
			dateRangeType: selected == "- Select Range -" ? null : selected,
			dateFrom: tableSearchFilter.dateFrom != "string" ? tableSearchFilter.dateFrom : "string",
			dateTo: tableSearchFilter.dateTo != "string" ? tableSearchFilter.dateTo : "string",
			keyword: searchValue,
			status: tableSearchFilter.status,
		});
	};

	const dateFields = {
		dateRange: null,
		dateFrom: "",
		dateTo: "",
	};

	const inputDateProp = [
		{ type: "text", placeholder: "Start Date", value: "" },
		{ type: "text", placeholder: "End Date", value: "" },
	];

	const [dateInput, setDateInput] = useState(dateFields);
	const [selected, setSelected] = useState(null);
	const [dateType, setDateType] = useState(inputDateProp);
	const onHandleOnChange = (event) => {
		var value = event.currentTarget.value;
		if (value === "- Select Range -") {
			setSelected(null);
			// setDateType([{type:"text",placeholder:"start Date"}])
			value = null;
		} else {
			setSelected(value);
			// setDateType([{type:"text",placeholder:"start Date"}])
		}
		setDateType(inputDateProp);
		setMinDate("");
		setMaxDate("");

		setTableSearchFilter({
			pageNo: 1,
			pageSize: tableSearchFilter.pageSize,
			sortBy: tableSearchFilter.sortBy,
			sortDir: tableSearchFilter.sortDir,
			dateRangeType: value,
			dateFrom: "string",
			dateTo: "string",
			keyword: tableSearchFilter.keyword,
			status: tableSearchFilter.status,
		});
	};

	const onFocusDateStart = () => {
		// e.currentTarget.type = "date";
		// e.currentTarget.focus();
		setDateType([
			{
				type: "date",
				placeholder: dateType[0].placeholder,
				value: dateType[0].value,
			},
			{
				type: "date",
				placeholder: dateType[1].placeholder,
				value: dateType[1].value,
			},
		]);
	};

	const onFocusDateEnd = () => {
		// e.currentTarget.type = "date";
		// e.currentTarget.focus();
		setDateType([
			{
				type: "date",
				placeholder: dateType[0].placeholder,
				value: dateType[0].value,
			},
			{
				type: "date",
				placeholder: dateType[1].placeholder,
				value: dateType[1].value,
			},
		]);
	};

	const [minDate, setMinDate] = useState("");
	const [maxDate, setMaxDate] = useState("");

	const onChangeDateStart = (event) => {
		dateType[0].value = event.currentTarget.value.toString();
		const value = event.currentTarget.value.toString();
		setMinDate(value);
		let date = value;
		let dateSplit = date.split("-");
		setDateInput({
			dateFrom: dateSplit[2] + "/" + dateSplit[1] + "/" + dateSplit[0],
			dateTo: dateInput.dateTo,
		});
		setSelected("- Select Range -");

		if (maxDate !== "") {
			setTableSearchFilter({
				pageNo: tableSearchFilter.pageNo,
				pageSize: tableSearchFilter.pageSize,
				sortBy: tableSearchFilter.sortBy,
				sortDir: tableSearchFilter.sortDir,
				dateRangeType: null,
				dateFrom: dateSplit[2] + "/" + dateSplit[1] + "/" + dateSplit[0],
				dateTo: dateInput.dateTo,
				keyword: tableSearchFilter.keyword,
				status: tableSearchFilter.status,
			});
		}
	};

	const onChangeDateEnd = (event) => {
		dateType[1].value = event.currentTarget.value.toString();
		const value = event.currentTarget.value.toString();
		setMaxDate(value);
		let date = value;
		let dateSplit = date.split("-");
		setDateInput({
			dateFrom: dateInput.dateFrom,
			dateTo: dateSplit[2] + "/" + dateSplit[1] + "/" + dateSplit[0],
		});
		setSelected("- Select Range -");

		if (minDate !== "") {
			setTableSearchFilter({
				pageNo: tableSearchFilter.pageNo,
				pageSize: tableSearchFilter.pageSize,
				sortBy: tableSearchFilter.sortBy,
				sortDir: tableSearchFilter.sortDir,
				dateRangeType: null,
				dateFrom: dateInput.dateFrom,
				dateTo: dateSplit[2] + "/" + dateSplit[1] + "/" + dateSplit[0],
				keyword: tableSearchFilter.keyword,
				status: tableSearchFilter.status,
			});
		}
	};

	const [paginationLocker, setPaginationLocker] = useState(true);
	const [exportBtn, setExportBtn] = useState("primary");
	useEffect(() => {
		const fetchData = async () => {
			let token = null;
			token = localStorage.getItem("@storage_token");
			try {
				fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.GET_LOAN_LIST, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Accept: "*/*",
						"Accept-language": "en",
						Authorization: "Bearer " + token,
					},
					body: JSON.stringify({
						criteria: {
							dateFrom: tableSearchFilter.dateFrom,
							dateRangeType: tableSearchFilter.dateRangeType,
							dateTo: tableSearchFilter.dateTo,
							keyword: tableSearchFilter.keyword,
							status: tableSearchFilter.status,
						},
						pageNumber: tableSearchFilter.pageNo,
						pageSize: tableSearchFilter.pageSize,
						sortDirection: tableSearchFilter.sortDir,
						sortKey: tableSearchFilter.sortBy,
					}),
				})
					.then((response) => response.json())
					.then((json) => {
						let result = json.result;
						setAppList(result);
						setPaginationLocker(false);
						if (result.data.length === 0) {
							setExportBtn("dimmed");
						} else {
							setExportBtn("primary");
						}
					});
			} catch (error) {
				console.log(error);
			}
		};
		fetchData();
	}, [tableSearchFilter]);

	const [statusList, setStatusList] = useState(undefined);
	const [status1, setStatus1] = useState(null);
	const OnChangeStatus1 = (event) => {
		var value = event.currentTarget.value;
		if (value === "- Select Status -") {
			setStatus1(null);
			value = null;
		} else {
			setStatus1(value);
		}

		setTableSearchFilter({
			pageNo: 1,
			pageSize: tableSearchFilter.pageSize,
			sortKey: tableSearchFilter.sortKey,
			sortDir: tableSearchFilter.sortDir,
			dateRangeType: tableSearchFilter.dateRangeType,
			dateFrom: "string",
			dateTo: "string",
			keyword: tableSearchFilter.keyword,
			status: value,
		});
	};

	useEffect(() => {
		const fetchStatus = async () => {
			fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.COMMON_FIND_DROPDOWN_OPTION + "loanStatus", {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Accept: "*/*",
					"Accept-language": "en",
				},
			})
				.then((response) => response.json())
				.then((json) => {
					let res = json.result;
					setStatusList(res);
				});
		};
		fetchStatus();
	}, []);

	const history = useHistory();

	const viewPage = async (value) => {
		history.push(pathnames.pageLoanApplicationView.replace(":staffId", value.id.toString()));
	};

	const exportList = (btn) => {
		if (btn === "orange") {
			let token = null;
			token = localStorage.getItem("@storage_token");
			try {
				fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.GET_LOAN_EXPORT_LIST, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Accept: "*/*",
						"Accept-language": "en",
						Authorization: "Bearer " + token,
					},
					body: JSON.stringify({
						criteria: {
							dateFrom: tableSearchFilter.dateFrom,
							dateRangeType: tableSearchFilter.dateRangeType,
							dateTo: tableSearchFilter.dateTo,
							keyword: tableSearchFilter.keyword,
						},
						pageNumber: tableSearchFilter.pageNo,
						pageSize: tableSearchFilter.pageSize,
						sortDirection: tableSearchFilter.sortDir,
						sortKey: tableSearchFilter.sortBy,
					}),
				}).then((response) => {
					let filename = response.headers.get("Content-Disposition");
					if (filename != null) {
						filename = filename.split(";")[1].split("filename=")[1];
					}
					response.blob().then((blob) => {
						let url = window.URL.createObjectURL(blob);
						let a = document.createElement("a");
						a.href = url;
						a.download = filename;
						a.click();
					});
				});
			} catch (error) {
				console.log(error);
			}
		} else {
			return;
		}
	};

	return (
		<div className="page-loan-application-list">
			<div className="top-context">
				<div className="title">Loan Application List</div>

				<div className="top-context__wrapper">
					<div className="search-bar">
						<AppSearchBar placeholder="Search for Application..." onKeyEnter={onKeyUp} onBlur={onBlur} onClick={onHandleSearch}></AppSearchBar>
					</div>
					<div className="search-button">{loanList.export_loan_list && <AppButton colorType={exportBtn} label="Export" onClick={() => exportList(exportBtn)}></AppButton>}</div>
				</div>
			</div>

			<div className="input-container">
				<div className="input-wrapper">
					<select className="input-field" value={selected} onChange={onHandleOnChange}>
						<option value="- Select Range -"> - Select Range -</option>
						<option value="LAST_7_DAYS">Last 7 days</option>
						<option value="LAST_14_DAYS">Last 14 days</option>
						<option value="LAST_1_MONTH">Last month</option>
					</select>
				</div>

				<span className="custom-divider">Custom</span>

				<div className="input-wrapper">
					<input
						type="date"
						onFocus={onFocusDateStart}
						id="start"
						placeholder={dateType[0].placeholder}
						className="input-field"
						style={{ marginRight: "10px" }}
						onChange={onChangeDateStart}
						value={dateType[0].value}
						max={maxDate}
					/>
				</div>

				<div className="input-wrapper">
					<input type="date" onFocus={onFocusDateEnd} id="end" placeholder={dateType[1].placeholder} className="input-field" onChange={onChangeDateEnd} value={dateType[1].value} min={minDate} />
				</div>

				<div className="input-wrapper">
					{statusList != undefined ? (
						<select className="input-field" style={{ marginLeft: "10px" }} value={status1} onChange={OnChangeStatus1}>
							<option value="- Select Status -">- Select Status -</option>
							{statusList
								.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))
								.map((d, i) => (
									<option value={d.code} key={i}>
										{d.label}
									</option>
								))}
						</select>
					) : (
						<select className="input-field" style={{ marginLeft: "10px" }}>
							<option value="- Select Status -">- Select Status -</option>
						</select>
					)}
				</div>

				<div className="pagination-button">
					{appList !== undefined ? (
						<span>
							{tableSearchFilter.pageNo === 1 && appList.totalCount > 0
								? tableSearchFilter.pageNo
								: tableSearchFilter.pageNo === 1 && appList.totalCount === 0
								? appList.totalCount
								: 1 + appList.pageSize * (appList.currentPageNumber - 1)}{" "}
							- {""}
							{appList.pageSize * (appList.currentPageNumber - 1) + appList.currentCount} of {appList.totalCount}{" "}
						</span>
					) : (
						<></>
					)}
					<button onClick={() => previousPage()}>❮</button>
					<button onClick={() => nextPage()}>❯</button>
				</div>
			</div>
			<div className="page-loan-application-list__react-table-wrapper" style={{ marginTop: "10px" }}>
				{appList !== undefined && appList.data !== undefined ? <AppReactTable columns={tableColumn} data={appList.data} /> : <div></div>}
			</div>
		</div>
	);
};

export default LoanApplicationList;
