import React, { useState, useEffect } from "react";
import AppReactTable from "components/app-react-table";
import { FiSearch } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import AppSearchBar from "components/app-search-bar";
import AppButton from "components/app-button";
import pathnames from "routes/pathnames";

// commons
import CONSTANSTS from "common/constansts";

const PageCustomerList = () => {
	// tableSearchFilter are filter payload to be send to backend
	const [tableSearchFilter, setTableSearchFilter] = useState({
		// insert the key value requested by BE here
		pageNo: 1,
		pageSize: 50,
		sortBy: "id",
		sortDir: "ASCENDING",
		dateRangeType: null,
		dateFrom: "string",
		dateTo: "string",
		keyword: "",
	});

	// trigger when user click on header when sorting
	const headerFilterClick = (header) => {
		let column = header.column;
		if (tableSearchFilter.sortBy === column.searchFilterValue) {
			// if click on the same column previously clicked will enter here
			if (tableSearchFilter.sortDir === "ASCENDING") {
				// if previously the column is sort by ascending order, sortDir will change to sort by descending order
				setTableSearchFilter({
					...tableSearchFilter,

					sortBy: column.searchFilterValue,
					sortDir: "DESCENDING",
					pageNo: tableSearchFilter.pageNo,
				});
			} else {
				// if previously the column is sort by descending order, sortDir will change to sort by ascending order
				setTableSearchFilter({
					...tableSearchFilter,
					sortBy: column.searchFilterValue,
					sortDir: "ASCENDING",
					pageNo: tableSearchFilter.pageNo,
				});
			}
		} else {
			// if click on a new column, it will sort by ascending order with the column value (column.searchFilterValue)
			setTableSearchFilter({
				...tableSearchFilter,
				sortBy: column.searchFilterValue,
				sortDir: "ASCENDING",
				pageNo: tableSearchFilter.pageNo,
			});
		}
	};

	const headerArrowRotate = (active) => {
		// current column header arrow icon will rotate depending on ascending order or descending order (tableSearchFilter.sortDir)
		const classNames = ["react-table__icon-wrapper"];
		if (active && tableSearchFilter.sortDir === "DESCENDING") classNames.push("react-table__icon-wrapper-down");
		if (active) classNames.push("react-table__icon-wrapper--show");
		return classNames.join(" ");
	};

	// tableCoumn is to create table column
	const tableColumn = [
		{
			id: "id",
			// header is the table header for id column
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick(header)} className="react-table__header">
						<span className="react-table__header-title">No</span>
						<div className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			// accessor will list out the table data based on the data key. In this case, the mockData key of id
			accessor: "id",
			// searchFilterValue is the column value that will send to BE to inform BE to sort according to which column. it is use to update tableSearchFilter.searchValue
			searchFilterValue: "id",
			// minWidth, width, maxWidth is to adjust the width of this current column
			minWidth: 90,
			width: 90,
		},
		{
			id: "mobileNumber",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick(header)} className="react-table__header">
						<span className="react-table__header-title">Mobile Phone</span>
						<div className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			searchFilterValue: "mobileNumber",
			accessor: "mobileNumber",
			minWidth: 120,
			width: 120,
		},
		{
			id: "username",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick(header)} className="react-table__header">
						<span className="react-table__header-title">User ID</span>
						<div className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			searchFilterValue: "username",
			accessor: "username",
			minWidth: 150,
			width: 150,
		},
		{
			id: "name",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick(header)} className="react-table__header">
						<span className="react-table__header-title">Name</span>
						<div className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			searchFilterValue: "name",
			accessor: "name",
			minWidth: 200,
			width: 200,
		},
		{
			id: "nric",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick(header)} className="react-table__header">
						<span className="react-table__header-title">NRIC</span>
						<div className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			searchFilterValue: "nric",
			accessor: "nric",
			minWidth: 120,
			width: 120,
		},
		{
			id: "status",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick(header)} className="react-table__header">
						<span className="react-table__header-title">Status</span>
						<div className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			searchFilterValue: "status",
			accessor: "status",
			minWidth: 90,
			width: 90,
		},
		{
			id: "email",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick(header)} className="react-table__header">
						<span className="react-table__header-title">Email Address</span>
						<div className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			searchFilterValue: "email",
			accessor: "email",
			minWidth: 170,
			width: 170,
		},
		{
			id: "button",
			Header: "",
			Cell: (row) => {
				return (
					<div onClick={(e) => e.stopPropagation()} style={{ width: "100%", display: "flex", justifyContent: "center" }}>
						{row.row.original.status.toLowerCase() === "disabled"
							? ""
							: customerList.view_customer_detail && (
									<button onClick={() => viewPage(row.row.original)} className="react-table__search-button">
										<FiSearch size={24} />
									</button>
							  )}
					</div>
				);
			},
			minWidth: 60,
			width: 60,
		},
	];

	const [appList, setAppList] = useState(undefined);
	const [searchValue, setSearchValue] = useState("");
	// console.log(searchValue)

	const nextPage = async () => {
		if (appList.currentPageNumber < appList.totalPageNumber && !paginationLocker) {
			setTableSearchFilter({
				pageNo: tableSearchFilter.pageNo + 1,
				pageSize: tableSearchFilter.pageSize,
				sortBy: tableSearchFilter.sortBy,
				sortDir: tableSearchFilter.sortDir,
				dateRangeType: tableSearchFilter.dateRangeType,
				dateFrom: tableSearchFilter.dateFrom,
				dateTo: tableSearchFilter.dateTo,
				keyword: tableSearchFilter.keyword,
			});
			setPaginationLocker(true);
		} else if (appList.currentPageNumber === appList.totalPageNumber) {
			setPaginationLocker(false);
		}
	};

	const previousPage = async () => {
		if (appList.currentPageNumber > 1 && !paginationLocker) {
			setTableSearchFilter({
				pageNo: tableSearchFilter.pageNo - 1,
				pageSize: tableSearchFilter.pageSize,
				sortBy: tableSearchFilter.sortBy,
				sortDir: tableSearchFilter.sortDir,
				dateRangeType: tableSearchFilter.dateRangeType,
				dateFrom: tableSearchFilter.dateFrom,
				dateTo: tableSearchFilter.dateTo,
				keyword: tableSearchFilter.keyword,
			});
			setPaginationLocker(true);
		} else if (appList.currentPageNumber === 1) {
			setPaginationLocker(false);
		}
	};

	const onKeyUp = (event) => {
		if (event.key === "Enter") {
			console.log("enter press here! ", event);
		}
	};

	const onBlur = (e) => {
		setSearchValue(e.target.value);
	};

	const onHandleSearch = () => {
		setTableSearchFilter({
			pageNo: tableSearchFilter.pageNo,
			pageSize: tableSearchFilter.pageSize,
			sortBy: tableSearchFilter.sortBy,
			sortDir: tableSearchFilter.sortDir,
			dateRangeType: selected == "- Select Range -" ? null : selected,
			dateFrom: tableSearchFilter.dateFrom != "string" ? tableSearchFilter.dateFrom : "string",
			dateTo: tableSearchFilter.dateTo != "string" ? tableSearchFilter.dateTo : "string",
			keyword: searchValue,
		});
	};

	const dateFields = {
		dateRange: null,
		dateFrom: "",
		dateTo: "",
	};

	const inputDateProp = [
		{ type: "text", placeholder: "Start Date", value: "" },
		{ type: "text", placeholder: "End Date", value: "" },
	];

	const [dateInput, setDateInput] = useState(dateFields);
	const [selected, setSelected] = useState(null);
	const [dateType, setDateType] = useState(inputDateProp);
	const [minDate, setMinDate] = useState("");
	const [maxDate, setMaxDate] = useState("");

	const onHandleOnChange = (event) => {
		var value = event.currentTarget.value;
		if (value === "- Select Range -") {
			setSelected(null);
			value = null;
		} else {
			setSelected(value);
		}

		setDateType(inputDateProp);
		setMinDate("");
		setMaxDate("");

		setTableSearchFilter({
			pageNo: 1,
			pageSize: tableSearchFilter.pageSize,
			sortBy: tableSearchFilter.sortBy,
			sortDir: tableSearchFilter.sortDir,
			dateRangeType: value,
			dateFrom: "string",
			dateTo: "string",
			keyword: tableSearchFilter.keyword,
		});
	};

	const onFocusDateStart = () => {
		setDateType([
			{
				type: "date",
				placeholder: dateType[0].placeholder,
				value: dateType[0].value,
			},
			{
				type: "date",
				placeholder: dateType[1].placeholder,
				value: dateType[1].value,
			},
		]);
	};

	const onFocusDateEnd = () => {
		setDateType([
			{
				type: "date",
				placeholder: dateType[0].placeholder,
				value: dateType[0].value,
			},
			{
				type: "date",
				placeholder: dateType[1].placeholder,
				value: dateType[1].value,
			},
		]);
	};

	const onChangeDateStart = (event) => {
		dateType[0].value = event.currentTarget.value.toString();
		const value = event.currentTarget.value.toString();
		setMinDate(value);
		let date = value;
		let dateSplit = date.split("-");
		setDateInput({ dateFrom: dateSplit[2] + "/" + dateSplit[1] + "/" + dateSplit[0], dateTo: dateInput.dateTo });
		setSelected("- Select Range -");

		if (maxDate !== "") {
			setTableSearchFilter({
				pageNo: tableSearchFilter.pageNo,
				pageSize: tableSearchFilter.pageSize,
				sortBy: tableSearchFilter.sortBy,
				sortDir: tableSearchFilter.sortDir,
				dateRangeType: null,
				// dateFrom: dateInput.dateFrom,
				dateFrom: dateSplit[2] + "/" + dateSplit[1] + "/" + dateSplit[0],
				dateTo: dateInput.dateTo,
				keyword: tableSearchFilter.keyword,
			});
		}
	};

	const onChangeDateEnd = (event) => {
		dateType[1].value = event.currentTarget.value.toString();
		const value = event.currentTarget.value.toString();
		setMaxDate(value);
		let date = value;
		let dateSplit = date.split("-");
		setDateInput({ dateFrom: dateInput.dateFrom, dateTo: dateSplit[2] + "/" + dateSplit[1] + "/" + dateSplit[0] });
		setSelected("- Select Range -");

		if (minDate !== "") {
			setTableSearchFilter({
				pageNo: tableSearchFilter.pageNo,
				pageSize: tableSearchFilter.pageSize,
				sortBy: tableSearchFilter.sortBy,
				sortDir: tableSearchFilter.sortDir,
				dateRangeType: null,
				dateFrom: dateInput.dateFrom,
				dateTo: dateSplit[2] + "/" + dateSplit[1] + "/" + dateSplit[0],
				keyword: tableSearchFilter.keyword,
			});
		}
	};
	const [paginationLocker, setPaginationLocker] = useState(true);
	const [exportBtn, setExportBtn] = useState("orange");

	const [customerList, SetCustomerListAccess] = useState({
		export_customer_list: false,
		view_customer_list: false,
		view_customer_detail: false,
	});

	useEffect(() => {
		const fetchData = async () => {
			let token = null;
			token = localStorage.getItem("@storage_token");
			let roles = localStorage.getItem("decode_token");
			if (roles == CONSTANSTS.ROLES.finance) {
				console.log("Access Denied");
				history.push(pathnames.pageHomePage);
			}

			let customerAccess = null;
			customerAccess = JSON.parse(localStorage.getItem("accessMatrix"));
			if (customerAccess) {
				SetCustomerListAccess({
					export_customer_list: customerAccess.accessMatrix.Customer_List.export_customer_list,
					view_customer_list: customerAccess.accessMatrix.Customer_List.view_customer_list,
					view_customer_detail: customerAccess.accessMatrix.Customer_Detail.view_customer_detail,
				});
			}

			try {
				// let response = fetch( "http://49.0.203.50/ivc-app/bo/customer/api/v1/list", {
				fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.GET_CUSTOMER_LIST, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Accept: "*/*",
						"Accept-language": "en",
						Authorization: "Bearer " + token,
					},
					body: JSON.stringify({
						criteria: {
							dateFrom: tableSearchFilter.dateFrom,
							dateRangeType: tableSearchFilter.dateRangeType,
							dateTo: tableSearchFilter.dateTo,
							keyword: tableSearchFilter.keyword,
						},
						pageNumber: tableSearchFilter.pageNo,
						pageSize: tableSearchFilter.pageSize,
						sortDirection: tableSearchFilter.sortDir,
						sortKey: tableSearchFilter.sortBy,
					}),
				})
					.then((response) => response.json())
					.then((json) => {
						let result = json.result;
						setAppList(result);
						setPaginationLocker(false);
						if (result.data.length === 0) {
							setExportBtn("dimmed");
						} else {
							setExportBtn("orange");
						}
					});
			} catch (error) {
				console.log(error);
			}
		};
		fetchData();
	}, [tableSearchFilter]);

	const history = useHistory();

	const viewPage = async (value) => {
		history.push(pathnames.pageCustomerView.replace(":customerId", value.id.toString()));
	};

	/*
  // Parse our locale string to [date, time]
  var date = new Date().toLocaleString('ms', { hour12: false }).split(" ");

  // Now we can access our time at date[1], and monthdayyear @ date[0]
  // var time = date[1];
  var mdy = date[0];

  // We then parse  the mdy into parts
  mdy = mdy.split('/');
  var day = parseInt(mdy[0]);
  var month = parseInt(mdy[1]);
  var year = parseInt(mdy[2]);

  // Putting it all together
  // var formattedDate = day + '-' + month + '-' + year + ' ' + time;
  var formattedDate = "";
  if (day < 10 && month > 10) {
    formattedDate = '0' + day + '' + month + '' + year;
  }
  if (month < 10 && day > 10) {
    formattedDate = '' + day + '0' + month + '' + year;
  }
  if (day < 10 && month < 10) {
    formattedDate = '0' + day + '0' + month + '' + year;
  }
  const [currentDate] = useState(formattedDate.toString());
  */

	const exportList = (btn) => {
		if (btn === "orange") {
			let token = null;
			token = localStorage.getItem("@storage_token");
			try {
				// let response = fetch("http://49.0.203.50/ivc-app/bo/loanAppplication/api/v1/export", {
				fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.GET_CUSTOMER_EXPORT_LIST, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Accept: "*/*",
						"Accept-language": "en",
						Authorization: "Bearer " + token,
					},
					body: JSON.stringify({
						criteria: {
							dateFrom: tableSearchFilter.dateFrom,
							dateRangeType: tableSearchFilter.dateRangeType,
							dateTo: tableSearchFilter.dateTo,
							keyword: tableSearchFilter.keyword,
						},
						pageNumber: tableSearchFilter.pageNo,
						pageSize: tableSearchFilter.pageSize,
						sortDirection: tableSearchFilter.sortDir,
						sortKey: tableSearchFilter.sortBy,
					}),
				}).then((response) => {
					let filename = response.headers.get("Content-Disposition");
					if (filename != null) {
						filename = filename.split(";")[1].split("filename=")[1];
					}
					response.blob().then((blob) => {
						console.log(blob);
						let url = window.URL.createObjectURL(blob);
						let a = document.createElement("a");
						a.href = url;
						// a.download = 'Customer Database_' + currentDate + '.xlsx';
						a.download = filename;
						a.click();
					});
				});
			} catch (error) {
				console.log(error);
			}
		} else {
			return;
		}
	};

	return (
		<div className="page-loan-application-list">
			<div className="application-list">
				<div className="fixed-content">
					<div className="top-context">
						<div className="title">Customer Database</div>
						<div className="top-context__wrapper">
							<div className="search-bar">
								<AppSearchBar placeholder="Search for Customer..." onKeyEnter={onKeyUp} onBlur={onBlur} onClick={onHandleSearch}></AppSearchBar>
							</div>
							<div className="search-button">{customerList.export_customer_list && <AppButton colorType={exportBtn} label="Export" onClick={() => exportList(exportBtn)}></AppButton>}</div>
						</div>
					</div>
					<div className="input-container">
						<div className="input-wrapper">
							<select className="input-field" value={selected} onChange={onHandleOnChange}>
								<option value="- Select Range -"> - Select Range -</option>
								<option value="LAST_7_DAYS">Last 7 days</option>
								<option value="LAST_14_DAYS">Last 14 days</option>
								<option value="LAST_1_MONTH">Last month</option>
							</select>
						</div>
						<span className="custom-divider">Custom</span>
						<div className="input-wrapper">
							<input
								type="date"
								onFocus={onFocusDateStart}
								id="start"
								placeholder={dateType[0].placeholder}
								className="input-field"
								style={{ marginRight: "10px" }}
								onChange={onChangeDateStart}
								value={dateType[0].value}
								max={maxDate}
							/>
						</div>
						<div className="input-wrapper">
							<input type="date" onFocus={onFocusDateEnd} id="end" placeholder={dateType[1].placeholder} className="input-field" onChange={onChangeDateEnd} value={dateType[1].value} min={minDate} />
						</div>
						<div className="pagination-button">
							{appList !== undefined ? (
								<span>
									{tableSearchFilter.pageNo === 1 && appList.totalCount > 0
										? tableSearchFilter.pageNo
										: tableSearchFilter.pageNo === 1 && appList.totalCount === 0
										? appList.totalCount
										: 1 + appList.pageSize * (appList.currentPageNumber - 1)}{" "}
									- {""}
									{appList.pageSize * (appList.currentPageNumber - 1) + appList.currentCount} of {appList.totalCount}{" "}
								</span>
							) : (
								<></>
							)}
							<button onClick={() => previousPage()}>❮</button>
							<button onClick={() => nextPage()}>❯</button>
						</div>
					</div>

					<div className="page-loan-application-list__react-table-wrapper" style={{ marginTop: "10px" }}>
						{appList !== undefined && appList.data !== undefined ? <AppReactTable columns={tableColumn} data={appList.data} /> : <div></div>}
					</div>
				</div>
			</div>
		</div>
	);
};

export default PageCustomerList;
