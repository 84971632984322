import React, { useState } from "react";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";

const getInputClassName = (error, variation) => {
    const classNames = ["app-auth-input__input"];
    const classNamesWithIcon = ["app-auth-input__icon-input"];
    if (error) {
        classNames.push("app-auth-input--error");
        classNamesWithIcon.push("app-auth-input--error");
    }
    return variation ? classNamesWithIcon.join(" ") :classNames.join(" ");
};

const AppAuthInput = ({ iconSrc, iconAlt, error, type = "text", variation = "withoutIcon", ...props }) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
        <div className="app-auth-input">
            {variation === "withoutIcon"? (
                <div className="app-auth-input__container">
                <img className="app-auth-input__icon" src={iconSrc} alt={iconAlt} width={"25px"}/>
                <div className="app-auth-input__input-wrapper">
                    <input className={getInputClassName(error)} type={showPassword ? "text" : type} {...props} />
                    {type === "password" ? (
                        <div className="app-auth-input__password-eye" onClick={() => setShowPassword(!showPassword)}>
                            {showPassword ? <IoEyeOffOutline size={24} /> : <IoEyeOutline size={24} />}
                        </div>
                    ) : null}
                </div>
            </div>
            ) : (
                <div className={getInputClassName(error, variation)}>
                    <div className="icon"><img src={iconSrc} alt={iconAlt} width={"25px"}/></div>
                    <input className="input-70" type={showPassword ? "text" : type} {...props} />
                    {type === "password" ? (
                        <div className="icon icon-action" onClick={() => setShowPassword(!showPassword)}>
                            {showPassword ? <IoEyeOffOutline size={24} /> : <IoEyeOutline size={24} />}
                        </div>
                    ) : null}
                </div>
            )}
             
             
            <p className="app-auth-input__error">{error}</p>
        </div>
    );
};

export default AppAuthInput;
