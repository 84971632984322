import React from 'react';
import { useField } from 'formik';



const MyTextInput = ({ type,key, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input>. We can use field meta to show an error
    // message if the field is invalid and it has been touched (i.e. visited)
    const [field, meta] = useField(props);
    // React treats radios and checkbox inputs differently other input types, select, and textarea.
    // Formik does this too! When you specify `type` to useField(), it will
    // return the correct bag of props for you -- a `checked` prop will be included
    // in `field` alongside `name`, `value`, `onChange`, and `onBlur`
   
    switch (type) {
        case "text":
            return (
                <>

                    <input  type={type} {...field} {...props} />

                    {meta.touched && meta.error ? (
                        <span className="error">**{meta.error}</span>
                    ) : <span className="null"></span>}

                </>
            );
        case "select":
            return (
                <>

                    <select {...field} {...props} />
                    {meta.touched && meta.error ? (
                        <span className="error">**{meta.error}</span>
                    ) : null}
                </>
            );
        case "number-1":
     
            return (
                <>
         
                    <input type="number"   {...field} {...props} />

                    {meta.touched && meta.error ? (
                        <span className="error">**{meta.error}</span>
                    ) : <span className="null"></span>}

                </>
            );
        case "number-2":
     
                return (
                    <>
             
                        <input  type="number" {...field} {...props} />
    
                        {meta.touched && meta.error ? (
                            <span className="error">**{meta.error}</span>
                        ) : <span className="null"></span>}
    
                    </>
                );
        case "number-3":

                    return (
                        <>
        
                            <input className="input-field-number-3" type="number" key={key} {...field} {...props} />
        
                            {meta.touched && meta.error ? (
                                <span className="error">**{meta.error}</span>
                            ) : <span className="null" style={{height:"0px"}}></span>}
        
                        </>
                    );
    
        case "decimal-number":
            return (
                <>
                    <input  type="number" step='0.01'{...field} {...props} />
                    {meta.touched && meta.error ? (
                        <span className="error">**{meta.error}</span>
                    ) : <span className="null"></span>}
                  
                </>
            );
            case "checkbox":

                return (
    
                    <>
                       
                            <input type="checkbox" className="input-checkbox" checked={field.value} {...field} {...props} />
                          
                       
                        {meta.touched && meta.error ? (
                            <div className="error">{meta.error}</div>
                        ) : null}
                    </>
                );
    
    
    



    }

};
export default MyTextInput;
