import React from "react";
import { Redirect, useLocation } from "react-router-dom";

import pathnames from "routes/pathnames";

const withAuthenticate =
    (WrappedComponent, authenticate) =>
    ({ ...props }) => {
        // const auth = localStorage.getItem("refresh_token");
        const auth = localStorage.getItem("@storage_token");
        const location = useLocation();

        if (!auth && authenticate) {
            return (
                <Redirect
                    to={{
                        search: "",
                        pathname: pathnames.pageLogin,
                        state: { referrer: location.pathname },
                    }}
                />
            );
        }

        if (auth && !authenticate) {
            return <Redirect to={pathnames.pageHomePage} />;
        }

        return <WrappedComponent {...props} />;
    };
export default withAuthenticate;
