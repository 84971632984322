import base64 from "base-64";
import pathnames from "routes/pathnames";
import appToast from "components/app-toast";
import CONSTANSTS from "common/constansts";

const refreshToken = (history, timeDelay, loginRefreshToken) => {
  let refreshTokenRecorded = loginRefreshToken ? loginRefreshToken : localStorage.getItem("refresh_token");

  const refreshTokenHandler = () => {
    if (localStorage.getItem("refresh_token")) {
      const formData = new URLSearchParams();
      formData.append("refresh_token", refreshTokenRecorded);
      formData.append("grant_type", "refresh_token");
      // console.log('endpoint', process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.LOGIN)
      // fetch("http://49.0.203.50/ivc-auth/login", {
      fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.LOGIN, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "*/*",
          "Accept-Encoding": "gzip, deflate, br",
          Connection: "keep-alive",
          Authorization: "Basic " + base64.encode("clientapp:123"),
        },
        body: formData.toString(),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.refresh_token && response.access_token) {
            localStorage.setItem("@storage_token", response.access_token);
            localStorage.setItem("refresh_token", response.refresh_token);
            refreshTokenRecorded = response.refresh_token;
            setTimeout(refreshTokenHandler, (response.expires_in - 15) * 1000);
          } else {
            localStorage.clear();
            appToast("Token has expired. Please login again", false);
            if (history) {
              history.push(pathnames.pageLogin);
            }
          }
        });
    } else {
      history.push(pathnames.pageLogin);
    }
  };

  if (timeDelay) {
    setTimeout(() => {
      refreshTokenHandler();
    }, (timeDelay - 15) * 1000);
  } else {
    refreshTokenHandler();
  }
};

export default refreshToken;
