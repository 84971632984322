import React, { useContext, useState, useRef, useEffect } from "react";
import CONSTANSTS from "common/constansts";
import AppCustomToast, { PageContext } from "components/app-custom-toast";
import AppReactTable from "components/app-react-table";
import iconUpload from "assets/images/pages/upload.png";
import iconDownload from "assets/images/pages/icon-download-video.png";
import moment from "moment";
import { FiEye } from "react-icons/fi";
import { IoClose } from "react-icons/io5";
import IconUpload from "../../assets/images/pages/icon-upload.png";
import IconRemove from "../../assets/images/pages/icon-remove.png";
import { useHistory } from "react-router-dom";
import pathnames from "routes/pathnames";

const PagePayment = () => {
	const indicatorValue = useContext(PageContext);
	function messageDialog(indicator) {
		AppCustomToast({
			showIndicator: indicator,
			reintIndicator: indicatorValue[1](indicator),
		});
	}
	const date = moment().format("YYYY-MM-DD");
	const dateFields = {
		dateFrom: date,
		dateTo: date,
	};
	const [uploadDate, setUploadDate] = useState(dateFields);
	const fileDownloadUrl = (fileData) => {
		let token = null;
		token = localStorage.getItem("@storage_token");
		if (fileData != null) {
			fetch(fileData, {
				method: "GET",
				headers: {
					"Accept-language": "en",
					Authorization: "Bearer " + token,
				},
			}).then((response) => {
				let filename = response.headers.get("Content-Disposition");
				if (filename != null) {
					filename = filename.split(";")[1].split("filename=")[1];
				}
				response.blob().then((blob) => {
					let url = window.URL.createObjectURL(blob);
					let a = document.createElement("a");
					a.href = url;
					a.download = filename;
					a.click();
				});
			});
		}
	};

	const history = useHistory();

	useEffect(() => {
		let token = null;
		token = localStorage.getItem("@storage_token");
		let bulkData = null;
		bulkData = JSON.parse(sessionStorage.getItem("searchBulk"));
		console.log("bulkData", bulkData);
		let dateCreated = null;

		let roles = localStorage.getItem("decode_token");
		if (roles !== CONSTANSTS.ROLES.finance && roles !== CONSTANSTS.ROLES.superAdmin) {
			console.log("Access Denied");
			history.push(pathnames.pageHomePage);
		}

		if (bulkData === null || bulkData === undefined || bulkData === "") {
			return;
		}

		dateCreated = bulkData[0].dateCreated.split("/");

		if (bulkData[0].paymentType?.toLowerCase() === "customer") {
			setFirstMenu("hide");
			setSecondMenu("display");
			setBatchId2(bulkData[0].batchId);
			setCustomerDate({
				...customerDate,
				dateFrom: dateCreated[2] + "-" + dateCreated[1] + "-" + dateCreated[0],
			});

			let endDate = null;
			endDate = customerDate.dateTo;
			let sliptStartEnd = endDate.split("-");
			let payload = {
				batchId: bulkData[0].batchId ? bulkData[0].batchId : null,
				dateFrom: dateCreated[0] + "/" + dateCreated[1] + "/" + dateCreated[2],
				dateTo: sliptStartEnd[2] + "/" + sliptStartEnd[1] + "/" + sliptStartEnd[0],
			};
			fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.LIST_CUSTOMER_DISBURSEMENT_REPORT, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"Accept-language": "en",
					Authorization: "Bearer " + token,
				},
				body: JSON.stringify(payload),
			})
				.then(function (response) {
					return response.json();
				})
				.then(function (data) {
					const response = data;
					if (response.responseCode === 200) {
						setSearchBatchData2(response.result);
					} else {
						messageDialog({ isOpen: true, condition: CONSTANSTS.DIALOG.failed, title: CONSTANSTS.DIALOG_TITLE.general_failed, message: response.message, defaultOnClick: false });
					}
				});
		} else {
			setBatchId1(bulkData[0].batchId);
			setMerchantDate({
				...merchantDate,
				dateFrom: dateCreated[2] + "-" + dateCreated[1] + "-" + dateCreated[0],
			});

			searchMerchantDR();
			let endDate = null;
			endDate = merchantDate.dateTo;
			let sliptStartEnd = endDate.split("-");
			let payload = {
				batchId: bulkData[0].batchId ? bulkData[0].batchId : null,
				dateFrom: dateCreated[0] + "/" + dateCreated[1] + "/" + dateCreated[2],
				dateTo: sliptStartEnd[2] + "/" + sliptStartEnd[1] + "/" + sliptStartEnd[0],
			};
			fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.LIST_MERCHANT_DISBURSEMENT_REPORT, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"Accept-language": "en",
					Authorization: "Bearer " + token,
				},
				body: JSON.stringify(payload),
			})
				.then(function (response) {
					return response.json();
				})
				.then(function (data) {
					const response = data;
					if (response.responseCode === 200) {
						setSearchBatchData1(response.result);
					} else {
						console.log(searchBatchData1);
						messageDialog({ isOpen: true, condition: CONSTANSTS.DIALOG.failed, title: CONSTANSTS.DIALOG_TITLE.general_failed, message: response.message, defaultOnClick: false });
					}
				});
		}
	}, []);

	function Download(value) {
		if (value.value != null) {
			return <img src={iconDownload} alt="fileDownloadUrl" onClick={() => fileDownloadUrl(value.value)} width="50px" height="50px" style={{ cursor: "pointer" }} />;
		} else {
			return <div>—</div>;
		}
	}

	const [getReport, setGetReport] = useState(false);
	const [reportDetail, setReportDetail] = useState();
	function PopUpGetReport(props) {
		return props.trigger ? (
			<div className="pop-up-payment">
				<div className="pop-up-data-report">{props.children}</div>
			</div>
		) : (
			<div></div>
		);
	}

	const closeGetReport = () => {
		setGetReport(false);
		setCheckIcon([]);
	};

	const transactionReport = [
		{
			beneficiaryAccountNo: "",
			beneficiaryBank: "",
			beneficiaryName: "",
			errorMessage: "",
			id: 0,
			idNumber: "",
			idType: "",
			paymentAmount: 0,
			paymentDescription: "",
			paymentReference: "",
			remark: "",
			transactionStatus: false,
		},
	];

	const listTransactionReportResponse = {
		bulkPaymentType: "",
		dateCreated: "",
		documentDownloadUrl: "",
		documentName: "",
		paymentDescription: "",
		paymentReference: "",
		transactionReportResponse: transactionReport,
	};

	const [reportTransaction, setReportTransaction] = useState(listTransactionReportResponse);

	const viewBulk = async (value) => {
		setMandatory("upload-file-input");
		reportTransaction.documentName = null;
		let id = null;
		id = value.batchId;
		sessionStorage.setItem("batchId", id);
		let token = null;
		token = localStorage.getItem("@storage_token");

		fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.GET_BATCHID + id, {
			method: "GET",
			headers: {
				"Accept-language": "en",
				Authorization: "Bearer " + token,
			},
		})
			.then(function (response) {
				return response.json();
			})
			.then(function (data) {
				const response = data;
				if (response.responseCode === 200) {
					console.log(response);
					setReportDetail(response.result);
					setGetReport(true);
					let transaction = null;
					transaction = response.result;
					for (let i = 0; i < transaction.transactionReportResponse.length; i++) {
						transactionReport.push({
							beneficiaryAccountNo: transaction.transactionReportResponse[i].beneficiaryAccountNo,
							beneficiaryBank: transaction.transactionReportResponse[i].beneficiaryBank,
							beneficiaryName: transaction.transactionReportResponse[i].beneficiaryName,
							errorMessage: transaction.transactionReportResponse[i].errorMessage,
							id: transaction.transactionReportResponse[i].id,
							idNumber: transaction.transactionReportResponse[i].idNumber,
							idType: transaction.transactionReportResponse[i].idType,
							paymentAmount: transaction.transactionReportResponse[i].paymentAmount,
							paymentDescription: transaction.transactionReportResponse[i].paymentDescription,
							paymentReference: transaction.transactionReportResponse[i].paymentReference,
							remark: transaction.transactionReportResponse[i].remark,
							transactionStatus: transaction.transactionReportResponse[i].transactionStatus,
						});
					}

					setReportTransaction({
						...reportTransaction,
						bulkPaymentType: transaction.bulkPaymentType,
						dateCreated: transaction.dateCreated,
						documentDownloadUrl: transaction.documentDownloadUrl,
						documentName: transaction.documentName,
						paymentDescription: transaction.paymentDescription,
						paymentReference: transaction.paymentReference,
						transactionReportResponse: transactionReport,
					});
				}
			});
	};

	const viewPaymentVoucher = (url) => {
		if (url) {
			window.open(url, "");
		}
	};

	const tableColumnBulk = [
		{
			id: "batchId",
			Header: () => {
				return <span className="react-table__header-title">Batch ID</span>;
			},
			accessor: "batchId",
			width: 150,
		},
		{
			id: "filename",
			Header: () => {
				return <span className="react-table__header-title">Filename</span>;
			},
			accessor: "filename",
			minWidth: 230,
			width: 230,
		},
		{
			id: "dateCreated",
			Header: () => {
				return <span className="react-table__header-title">Generated Date</span>;
			},
			accessor: "dateCreated",
			Cell: (row) => {
				return <span>{row.row.original.dateCreated ? moment(row.row.original.dateCreated, "DD/MM/YYYY hh:mm A").format("DD/MM/YYYY hh:mm A") : ""}</span>;
			},
			minWidth: 220,
			width: 220,
		},
		{
			id: "approved",
			Header: () => {
				return <span className="react-table__header-title">Approved</span>;
			},
			accessor: "approved",
			minWidth: 110,
			width: 110,
		},
		{
			id: "downloadUrl",
			Header: () => {
				return <span className="react-table__header-title">Download</span>;
			},
			Cell: (row) => {
				return <Download value={row.row.original.downloadUrl}></Download>;
			},
			minWidth: 150,
			width: 150,
		},
		{
			id: "view",
			Header: () => {
				return <span className="react-table__header-title">View</span>;
			},
			Cell: (row) => {
				return (
					<div onClick={(e) => e.stopPropagation()}>
						<button className="react-table__search-button" onClick={() => viewBulk(row.row.original)}>
							<FiEye size={24} />
						</button>
						<PopUpGetReport trigger={getReport}>
							<div className="pop-up-message">
								{reportDetail !== undefined ? (
									<div>
										<div className="header">
											<p>Employer Info</p>
											<IoClose size={24} style={{ cursor: "pointer" }} onClick={closeGetReport} />
										</div>
										<div className="row" style={{ textAlign: "left", fontSize: "22px" }}>
											<div className="column-popup">
												<div>
													<span className="span-title">Crediting Date</span>{" "}
													<span className="span-detail">
														: {reportDetail.dateCreated ? moment(new Date(reportDetail.dateCreated), "DD/MM/YYYY hh:mm A").format("DD/MM/YYYY HH:mm A") : CONSTANSTS.DISPLAY_TEXT.empty}
													</span>
												</div>
												<div>
													<span className="span-title">Payment Report</span> <span className="span-detail">: {reportDetail.paymentReference ?? CONSTANSTS.DISPLAY_TEXT.empty}</span>
												</div>
											</div>
											<div className="column-popup">
												<div>
													<span className="span-title">Payment Description</span> <span className="span-detail">: {reportDetail.paymentDescription ?? CONSTANSTS.DISPLAY_TEXT.empty}</span>
												</div>
												<div>
													<span className="span-title">Bulk Payment Type</span> <span className="span-detail">: {reportDetail.bulkPaymentType ?? CONSTANSTS.DISPLAY_TEXT.empty}</span>
												</div>
											</div>
										</div>

										<div className="table-invoice">
											<table>
												<thead className="tr-head">
													<tr>
														<th>Beneficiary Name</th>
														<th>Beneficiary Bank</th>
														<th>Beneficiary Account No</th>
														<th>ID Type</th>
														<th>Payment Amount</th>
														<th>Payment Reference</th>
														<th>Payment Description</th>
														<th>Successful Transaction</th>
														<th>Payment Voucher</th>
													</tr>
												</thead>
												<tbody className="tr-body">
													{reportDetail.transactionReportResponse.map((item, index) => (
														<tr key={index}>
															<td>{item.beneficiaryName}</td>
															<td>{item.beneficiaryBank}</td>
															<td>{item.beneficiaryAccountNo}</td>
															<td>{item.idType}</td>
															<td>{item.paymentAmount}</td>
															<td>{item.paymentReference}</td>
															<td>{item.paymentDescription}</td>
															<td>
																<div>
																	<input
																		type="checkbox"
																		className="check-box"
																		id={index}
																		name={index}
																		onClick={(e) => box(index, e)}
																		defaultChecked={item.transactionStatus}
																		onChange={(e) => handleSingleCheck(e)}
																		checked={checkIcon !== undefined ? checkIcon[index] : item.transactionStatus}
																		disabled={item.transactionStatus === true ? true : false}
																	/>
																</div>
															</td>
															<td>
																{item.paymentVoucherUrl ? (
																	<button className="react-table__search-button" onClick={() => viewPaymentVoucher(item.paymentVoucherUrl)}>
																		<FiEye size={24} />
																	</button>
																) : (
																	<button className="react-table__search-button-disable">
																		<FiEye size={24} />
																	</button>
																)}
															</td>
														</tr>
													))}
												</tbody>
											</table>
										</div>
										<div className="file-input">
											<div className="file-input-wrapper">
												<input
													type="text"
													name="uploadPdf"
													className={mandatory}
													value={reportTransaction.documentName ? reportTransaction.documentName : fileName}
													onChange={reportTransaction.documentName ? null : onHandleOnChange}
													onClick={reportTransaction.documentName ? null : openFile}
													readOnly
												/>
												{reportTransaction.documentName ? (
													<img src={iconDownload} className="upload-icon" alt="upload icon" onClick={() => downloadDoc()} />
												) : (
													<img className="upload-icon" src={IconUpload} alt="upload icon" />
												)}
												<img
													src={IconRemove}
													alt="upload icon"
													className="upload-icon"
													onClick={() => {
														setFileName(null), setFile(null), (reportTransaction.documentName = null);
													}}
												/>
												<p className="app-auth-input__error">
													{exceedFileSize} {mandatory === "upload-file-input-error" ? "Upload file is required" : null}
												</p>
											</div>
											{reportTransaction.documentName ? null : (
												<input type="file" name="file-input" id="file-input" ref={inputFile} accept="application/pdf" style={{ display: "none" }} onChange={handleFileChange} />
											)}
										</div>
										<div className="button-container">
											<button className="pop-up-button" onClick={() => confirmTransaction()}>
												Save
											</button>
											<button className="pop-up-button" onClick={() => closeGetReport()}>
												Cancel
											</button>
										</div>
										<PopupConfirm trigger={confirmPopUp}>
											<div className="pop-up-message" style={{ wordBreak: "break-word" }}>
												Are you sure that all transaction status are updated correctly?
											</div>
										</PopupConfirm>
									</div>
								) : (
									""
								)}
							</div>
						</PopUpGetReport>
					</div>
				);
			},
		},
	];

	const confirmTransaction = () => {
		console.log("1", reportTransaction.documentName);
		if ((file === null || file === undefined) && reportTransaction.documentName === null) {
			setMandatory("upload-file-input-error");
			setExceedFileSize(null);
		} else {
			setConfirmPopUp(true);
		}
	};

	const [confirmPopUp, setConfirmPopUp] = useState(false);

	function PopupConfirm(props) {
		return props.trigger ? (
			<div className="pop-up">
				<div className="pop-up-data">
					{props.children}
					<div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
						<button className="pop-up-button" style={{ marginRight: "10px" }} onClick={() => [setConfirmPopUp(false), updateTransactionListReport()]}>
							Yes
						</button>
						<button className="pop-up-button" style={{ marginLeft: "10px" }} onClick={() => [setConfirmPopUp(false)]}>
							No
						</button>
					</div>
				</div>
			</div>
		) : (
			<div></div>
		);
	}

	const downloadDoc = () => {
		let token = null;
		token = localStorage.getItem("@storage_token");
		let fileName = "";
		fileName = reportTransaction.documentName;
		fetch(reportTransaction.documentDownloadUrl, {
			method: "GET",
			headers: {
				"Content-Disposition": "attachment; filename=" + fileName + "_Merchant Agreement.pdf",
				"Content-Type": "application/pdf",
				Vary: "Origin,Access-Control-Request-Method,Access-Control-Request-Headers",
				"X-Content-Type-Options": "nosniff",
				"X-XSS-Protection": "1; mode=block",
				"Cache-Control": "no-cache, no-store, max-age=0, must-revalidate",
				"Keep-Alive": "timeout=5, max=100",
				Connection: "Keep-Alive",
				Authorization: "Bearer " + token,
			},
		}).then((response) => {
			let filename = response.headers.get("Content-Disposition");
			if (filename != null) {
				filename = filename.split(";")[1].split("filename=")[1];
			}
			response.blob().then((blob) => {
				let url = window.URL.createObjectURL(blob);
				let a = document.createElement("a");
				a.href = url;
				a.download = filename;
				a.click();
			});
		});
	};

	const inputField = {
		uploadPdf: {
			value: "",
			error: "",
		},
	};

	const [input, setInput] = useState(inputField);
	const inputFile = useRef();

	const openFile = () => {
		inputFile.current.click();
	};

	const onHandleOnChange = (event) => {
		const name = event.currentTarget.name;
		const value = event.currentTarget.value;

		setInput({
			...input,
			[name]: {
				...input[name],
				value: value,
			},
		});
	};

	const [file, setFile] = useState();
	const [fileName, setFileName] = useState("");
	const [exceedFileSize, setExceedFileSize] = useState("");
	const handleFileChange = (event) => {
		let inputFile = event.target.files[0];
		var _size = inputFile.size;
		var fSExt = new Array("Bytes", "KB", "MB", "GB"),
			i = 0;
		while (_size > 900) {
			_size /= 1024;
			i++;
		}
		var num = Math.round(_size * 100) / 100;
		var exactSize = num + " " + fSExt[i];
		console.log("FILE SIZE = ", exactSize);

		if (i > 2) {
			// GB
			setExceedFileSize("Uploaded file or image cannot exceed 10 MB");
			setMandatory("upload-file-input-error");
		} else if (i === 2 && num > 10) {
			// exceed 500 MB
			setExceedFileSize("Uploaded file or image cannot exceed 10 MB");
			setMandatory("upload-file-input-error");
		} else {
			setFile(inputFile);
			setFileName(inputFile.name.toString());
			setExceedFileSize(null);
			setMandatory("upload-file-input");
		}
	};

	const [checkIcon, setCheckIcon] = useState();

	const handleSingleCheck = (e) => {
		setCheckIcon({ ...checkIcon, [e.currentTarget.name]: e.target.checked });
		console.log("name", e.currentTarget.name, checkIcon);
	};

	let checked = null;

	const box = (i, e) => {
		checked = e.target.checked;
		console.log(">", i, checked);
		// setReportTransaction({
		//   ...reportTransaction.transactionReportResponse[i+1].transactionStatus = checked,
		// });
		reportTransaction.transactionReportResponse[i + 1].transactionStatus = checked;
		console.log("?", reportTransaction.transactionReportResponse[i + 1]);
	};

	const tableColumn = [
		{
			id: "filename",
			Header: () => {
				return <span className="react-table__header-title">Filename</span>;
			},
			accessor: "filename",
			minWidth: 250,
			width: 250,
		},
		{
			id: "uploadDate",
			Header: () => {
				return <span className="react-table__header-title">Upload Date</span>;
			},

			accessor: "uploadDate",
			Cell: (row) => {
				return <span>{row.row.original.uploadDate ? moment(row.row.original.uploadDate, "DD/MM/YYYY hh:mm A").format("DD/MM/YYYY hh:mm A") : ""}</span>;
			},
			minWidth: 200,
			width: 200,
		},
		{
			id: "status",
			Header: () => {
				return <span className="react-table__header-title">Status </span>;
			},
			accessor: "status",
			minWidth: 110,
			width: 110,
		},
		{
			id: "fileDownloadUrl",
			Header: () => {
				return <span className="react-table__header-title">Download</span>;
			},

			Cell: (row) => {
				return <Download value={row.row.original.fileDownloadUrl}></Download>;
			},
			minWidth: 150,
			width: 150,
		},

		{
			id: "errorFileDownloadUrl",
			Header: () => {
				return <span className="react-table__header-title">Error File</span>;
			},

			Cell: (row) => {
				return <Download value={row.row.original.errorFileDownloadUrl}></Download>;
			},
			minWidth: 150,
			width: 150,
		},
	];
	const [appList, setAppList] = useState(undefined);

	const [firstMenu, setFirstMenu] = useState("display");
	const [secondMenu, setSecondMenu] = useState("hide");
	const [thirdMenu, setThirdMenu] = useState("hide");
	const [forthMenu, setForthMenu] = useState("hide");
	const [fifthMenu, setFifthMenu] = useState("hide");
	const tabClicked = (e) => {
		var menuNo = e;

		if (menuNo === "1") {
			setFirstMenu("display");
			setSecondMenu("hide");
			setThirdMenu("hide");
			setForthMenu("hide");
			setFifthMenu("hide");
		} else if (menuNo === "2") {
			setFirstMenu("hide");
			setSecondMenu("display");
			setThirdMenu("hide");
			setForthMenu("hide");
			setFifthMenu("hide");
		} else if (menuNo === "3") {
			setFirstMenu("hide");
			setSecondMenu("hide");
			setThirdMenu("display");
			setForthMenu("hide");
			setFifthMenu("hide");
			setDefaultPaymentReturnUpload(menuNo);
		} else if (menuNo === "4") {
			setFirstMenu("hide");
			setSecondMenu("hide");
			setThirdMenu("hide");
			setForthMenu("display");
			setFifthMenu("hide");
			setDefaultPaymentReturnUpload(menuNo);
		} else if (menuNo === "5") {
			setFirstMenu("hide");
			setSecondMenu("hide");
			setThirdMenu("hide");
			setForthMenu("hide");
			setFifthMenu("display");
		}
	};

	const [merchantDate, setMerchantDate] = useState(dateFields);

	const [minDate, setMinDate] = useState("");
	const [maxDate, setMaxDate] = useState("");

	const [batchId1, setBatchId1] = useState(null);
	const [batchId2, setBatchId2] = useState(null);
	const [searchBatchData1, setSearchBatchData1] = useState(undefined);
	const [searchBatchData2, setSearchBatchData2] = useState(undefined);

	const onChangeBatchId1 = (e) => {
		const value = e.currentTarget.value;
		setBatchId1(value.replace(/\D/, ""));
	};
	const onChangeBatchId2 = (e) => {
		const value = e.currentTarget.value;
		setBatchId2(value.replace(/\D/, ""));
	};

	const searchMerchantDR = () => {
		let token = null;
		token = localStorage.getItem("@storage_token");
		//
		let startDate = null;
		startDate = merchantDate.dateFrom;
		let sliptStartDate = startDate.split("-");
		let endDate = null;
		endDate = merchantDate.dateTo;
		let sliptStartEnd = endDate.split("-");
		//
		let payload = {
			batchId: batchId1 ? batchId1 : null,
			dateFrom: sliptStartDate[2] + "/" + sliptStartDate[1] + "/" + sliptStartDate[0],
			dateTo: sliptStartEnd[2] + "/" + sliptStartEnd[1] + "/" + sliptStartEnd[0],
		};
		fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.LIST_MERCHANT_DISBURSEMENT_REPORT, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"Accept-language": "en",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload),
		})
			.then(function (response) {
				return response.json();
			})
			.then(function (data) {
				const response = data;
				if (response.responseCode === 200) {
					setSearchBatchData1(response.result);
				} else {
					console.log(searchBatchData1);
					messageDialog({ isOpen: true, condition: CONSTANSTS.DIALOG.failed, title: CONSTANSTS.DIALOG_TITLE.general_failed, message: response.message, defaultOnClick: false });
				}
			});
	};

	const searchCustomerDR = () => {
		let token = null;
		token = localStorage.getItem("@storage_token");
		//
		let startDate = null;
		startDate = customerDate.dateFrom;
		let sliptStartDate = startDate.split("-");
		let endDate = null;
		endDate = customerDate.dateTo;
		let sliptStartEnd = endDate.split("-");
		//
		let payload = {
			batchId: batchId2 ? batchId2 : null,
			dateFrom: sliptStartDate[2] + "/" + sliptStartDate[1] + "/" + sliptStartDate[0],
			dateTo: sliptStartEnd[2] + "/" + sliptStartEnd[1] + "/" + sliptStartEnd[0],
		};
		fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.LIST_CUSTOMER_DISBURSEMENT_REPORT, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"Accept-language": "en",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload),
		})
			.then(function (response) {
				return response.json();
			})
			.then(function (data) {
				const response = data;
				if (response.responseCode === 200) {
					setSearchBatchData2(response.result);
				} else {
					console.log(searchBatchData2);
					messageDialog({ isOpen: true, condition: CONSTANSTS.DIALOG.failed, title: CONSTANSTS.DIALOG_TITLE.general_failed, message: response.message, defaultOnClick: false });
				}
			});
	};

	const onChangeDateStart = (event) => {
		const value = event.currentTarget.value.toString();
		setMinDate(value);
		setMerchantDate({
			...merchantDate,
			dateFrom: value,
		});
	};

	const onChangeDateEnd = (event) => {
		const value = event.currentTarget.value.toString();
		setMaxDate(value);
		setMerchantDate({
			...merchantDate,
			dateTo: value,
		});
	};

	const [customerDate, setCustomerDate] = useState(dateFields);
	const [minCustomerDate, setMinCustomerDate] = useState("");
	const [maxCustomerDate, setMaxCustomerDate] = useState("");

	const onChangeCDateStart = (event) => {
		const value = event.currentTarget.value.toString();
		setMinCustomerDate(value);
		setCustomerDate({
			...customerDate,
			dateFrom: value,
		});
	};

	const onChangeCDateEnd = (event) => {
		const value = event.currentTarget.value.toString();
		setMaxCustomerDate(value);
		setCustomerDate({
			...customerDate,
			dateTo: value,
		});
	};

	const generateMerchantDisbursement = () => {
		console.log(customerTransactionDate.dateFrom, customerTransactionDate.dateTo);
		if (customerTransactionDate.dateFrom === "" || customerTransactionDate.dateTo === "") {
			return;
		}
		let token = null;
		token = localStorage.getItem("@storage_token");

		let startDate = null;
		startDate = customerTransactionDate.dateFrom;
		let sliptStartDate = startDate.split("-");
		let endDate = null;
		endDate = customerTransactionDate.dateTo;
		let sliptStartEnd = endDate.split("-");

		let payload = {
			dateFrom: sliptStartDate[2] + "/" + sliptStartDate[1] + "/" + sliptStartDate[0],
			dateTo: sliptStartEnd[2] + "/" + sliptStartEnd[1] + "/" + sliptStartEnd[0],
		};
		fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.GENERATE_MERCHANT_DISBURSEMENT, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				"Accept-language": "en",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload),
		}).then(function (response) {
			if (response.status === 200) {
				let filename = response.headers.get("Content-Disposition");
				let dialogMessage = response.headers.get("Message");

				if (filename != null) {
					filename = filename.split(";")[1].split("filename=")[1];
				}

				response.blob().then((blob) => {
					let url = window.URL.createObjectURL(blob);

					let a = document.createElement("a");
					a.href = url;
					a.download = filename;
					a.click();

					messageDialog({
						isOpen: true,
						condition: CONSTANSTS.DIALOG.success,
						title: CONSTANSTS.DIALOG_TITLE.general_success,
						message: dialogMessage ?? CONSTANSTS.DIALOG_MESSAGE.download_successful,
						defaultOnClick: false,
					});
				});
			} else {
				response.json().then(function (data) {
					const response = data;

					messageDialog({
						isOpen: true,
						condition: CONSTANSTS.DIALOG.failed,
						title: CONSTANSTS.DIALOG_TITLE.general_failed,
						message: response.message,
						defaultOnClick: false,
					});
				});
			}
		});
	};

	const generateCustomerDisbursement = () => {
		if (customerDate.dateFrom === "" || customerDate.dateTo === "") {
			return;
		}
		let token = null;
		token = localStorage.getItem("@storage_token");

		let startDate = null;
		startDate = customerTransactionDate.dateFrom;
		let sliptStartDate = startDate.split("-");
		let endDate = null;
		endDate = customerTransactionDate.dateTo;
		let sliptStartEnd = endDate.split("-");

		let payload = {
			dateFrom: sliptStartDate[2] + "/" + sliptStartDate[1] + "/" + sliptStartDate[0],
			dateTo: sliptStartEnd[2] + "/" + sliptStartEnd[1] + "/" + sliptStartEnd[0],
		};

		fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.GENERATE_CUSTOMER_DISBURSEMENT, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				"Accept-language": "en",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload),
		}).then(function (response) {
			if (response.status === 200) {
				let filename = response.headers.get("Content-Disposition");
				let dialogMessage = response.headers.get("Message");

				if (filename != null) {
					filename = filename.split(";")[1].split("filename=")[1];
				}

				response.blob().then((blob) => {
					let url = window.URL.createObjectURL(blob);

					let a = document.createElement("a");
					a.href = url;
					a.download = filename;
					a.click();

					messageDialog({
						isOpen: true,
						condition: CONSTANSTS.DIALOG.success,
						title: CONSTANSTS.DIALOG_TITLE.general_success,
						message: dialogMessage ?? CONSTANSTS.DIALOG_MESSAGE.download_successful,
						defaultOnClick: false,
					});
				});
			} else {
				response.json().then(function (data) {
					const response = data;

					messageDialog({
						isOpen: true,
						condition: CONSTANSTS.DIALOG.failed,
						title: CONSTANSTS.DIALOG_TITLE.general_failed,
						message: response.message,
						defaultOnClick: false,
					});
				});
			}
		});
	};

	const [minUMDate, setMinUMDate] = useState("");
	const [maxUMDate, setMaxUMDate] = useState("");

	const onChangeUMDateStart = (event) => {
		const value = event.currentTarget.value.toString();
		setMinUMDate(value);
		setUploadDate({
			...uploadDate,
			dateFrom: value,
		});
	};

	const onChangeUMDateEnd = (event) => {
		const value = event.currentTarget.value.toString();
		setMaxUMDate(value);
		setUploadDate({
			...uploadDate,
			dateTo: value,
		});
	};

	//default date value for third tab and forth tab (PRUM and PRUC)
	const setDefaultPaymentReturnUpload = (tab) => {
		const date = moment().format("YYYY-MM-DD");
		uploadDate.dateFrom = date;
		uploadDate.dateTo = date;
		uploadSearch(tab);
	};

	//Search function for third and forth tab "search" button
	//Will create body,path,headers in this function
	//Split the data from uploadDate which is the search date for PRUC and PRUM
	const [lockUpload, setLockUpload] = useState(false);
	const uploadSearch = (tab) => {
		let dateFrom = uploadDate.dateFrom;
		let dateTo = uploadDate.dateTo;
		let body = null;
		let path = null;
		let token = null;

		token = localStorage.getItem("@storage_token");
		dateFrom = dateFrom.split("-");
		dateTo = dateTo.split("-");
		let headers = {
			"Content-Type": "application/json",
			Accept: "*/*",
			"Accept-language": "en",
			Authorization: "Bearer " + token,
		};
		uploadDate.dateFrom = dateFrom[2] + "/" + dateFrom[1] + "/" + dateFrom[0];
		uploadDate.dateTo = dateTo[2] + "/" + dateTo[1] + "/" + dateTo[0];
		if (tab === "3") {
			path = process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.LIST_PAYMENT_RETURN;
			body = JSON.stringify(uploadDate);
			fetchData(path, body, headers).then((response) => {
				let result = response.result;
				setAppList(result);
			});
		} else if (tab === "4") {
			path = process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.LIST_CUSTOMER_RETURN;
			body = JSON.stringify(uploadDate);
			fetchData(path, body, headers).then((response) => {
				let result = response.result;
				setAppList(result);
			});
		}

		dateFrom = uploadDate.dateFrom.split("/");
		dateTo = uploadDate.dateTo.split("/");
		uploadDate.dateFrom = dateFrom[2] + "-" + dateFrom[1] + "-" + dateFrom[0];
		uploadDate.dateTo = dateTo[2] + "-" + dateTo[1] + "-" + dateTo[0];
	};

	//Handling for pick and select file after click on iconUpload
	const [selectedFile, setSelectedFile] = useState();
	const [isFilePicked, setIsFilePicked] = useState(false);
	const changeHandler = (event) => {
		setSelectedFile(event.target.files[0]);
		setIsFilePicked(true);
	};

	//Handling for create path, body, header before fetching data
	//Path will based on Tab clicked / Currently In
	const SubmitUplaodedFile = (menuNo) => {
		setLockUpload(true);
		let path = null;
		let body = null;
		let token = null;
		token = localStorage.getItem("@storage_token");
		let formData = new FormData();
		formData.append("paymentReturnDocument", selectedFile);

		let headers = {
			Accept: "*/*",
			"Accept-language": "en",
			Authorization: "Bearer " + token,
		};

		if (menuNo === "3") {
			path = process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.UPLOAD_PAYMENT_RETURN_MERCHANT;
			body = formData;
			fetchData(path, body, headers).then((response) => {
				if (response.responseCode === 200) {
					messageDialog({
						isOpen: true,
						condition: CONSTANSTS.DIALOG.success,
						title: CONSTANSTS.DIALOG_TITLE.general_success,
						message: response.message,
					});

					setIsFilePicked(false);
					setLockUpload(false);
				} else {
					messageDialog({
						isOpen: true,
						condition: CONSTANSTS.DIALOG.failed,
						title: CONSTANSTS.DIALOG_TITLE.general_failed,
						message: response.message ?? response.error_description,
						defaultOnClick: false,
					});
					setLockUpload(false);
				}
			});
		} else if (menuNo === "4") {
			path = process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.UPLOAD_PAYMENT_RETURN_CUSTOMER;
			body = formData;
			fetchData(path, body, headers).then((response) => {
				if (response.responseCode === 200) {
					messageDialog({
						isOpen: true,
						condition: CONSTANSTS.DIALOG.success,
						title: CONSTANSTS.DIALOG_TITLE.general_success,
						message: response.message,
					});

					setIsFilePicked(false);
					setLockUpload(false);
				} else {
					messageDialog({
						isOpen: true,
						condition: CONSTANSTS.DIALOG.failed,
						title: CONSTANSTS.DIALOG_TITLE.general_failed,
						message: response.message ?? response.error_description,
						defaultOnClick: false,
					});
					setLockUpload(false);
				}
			});
		}
	};

	//Fetch data which called from SubmitUplaodedFile and UploadSearch
	const fetchData = async (path, body, headers) => {
		if (path === null || path === undefined) {
			console.log("path is null");
			return;
		}

		if (body === null || body === undefined) {
			console.log("body is null");
			return;
		}

		if (headers === null || headers === undefined) {
			console.log("headers is null");
			return;
		}

		const response = await fetch(path, {
			method: "POST",
			headers: headers,
			body: body,
		});
		const result = await response.json();
		//console.log(result);
		return result;
	};

	const UploadFile = (values) => {
		return (
			<div style={{ height: "50px", paddingRight: "20px", marginTop: "10px" }}>
				<div
					style={{
						width: "140px",
						float: "left",
						display: "flex",
						alignItems: "center",
						height: "50px",
					}}
				>
					Select File
				</div>

				<div style={{ width: "300px", height: "45px", float: "left" }}>
					<div className="filename">
						{!isFilePicked ? <span className="selected-filename">No file chosen</span> : <span className="selected-filename">{selectedFile.name}</span>}

						<div style={{ float: "right" }}>
							<label>
								<img src={iconUpload} alt=" " width={50} height={50} style={{ cursor: "pointer" }}></img>
								<input type="file" name="file" onChange={changeHandler} style={{ display: "none" }} />
							</label>
						</div>
					</div>
				</div>
				<div
					style={{
						height: "50px",
						display: "flex",
						alignItems: "center",
						float: "right",
					}}
				>
					<button
						style={{
							border: "none",
							borderRadius: "8px",
							backgroundColor: "#f5903d",
							color: "#FFFFFF",
							fontFamily: "gotham-bold",
							width: "200px",
						}}
						disabled={lockUpload ? true : false}
						onClick={() => SubmitUplaodedFile(values.tab)}
					>
						{lockUpload ? "Processing..." : "Upload"}
					</button>
				</div>
			</div>
		);
	};

	const SelectDate = (values) => {
		return (
			<div className="top-date">
				<div className="split-block">
					<div className="span-input">
						<span>Start Date</span>
					</div>
					<input type="date" onChange={onChangeUMDateStart} value={uploadDate.dateFrom} max={maxUMDate} />
				</div>
				<div className="split-block">
					<div style={{ float: "right" }}>
						<div className="span-input">
							<span>End Date</span>
						</div>
						<input type="date" onChange={onChangeUMDateEnd} value={uploadDate.dateTo} min={minUMDate} />
					</div>
				</div>

				<div className="search-button">
					<button onClick={() => uploadSearch(values.tab)}>Search</button>
				</div>
			</div>
		);
	};

	const [merchantBulkPayment, setMerchantBulkPayment] = useState(false);
	const [customerTransactionDate, setCustomerTransactionDate] = useState(dateFields);
	const [minCTDate, setMinCTDate] = useState();
	const [maxCTDate, setMaxCTDate] = useState();

	const onChangeCTDateStart = (event) => {
		const value = event.currentTarget.value.toString();
		setMinCTDate(value);
		setCustomerTransactionDate({
			...customerTransactionDate,
			dateFrom: value,
		});
	};

	const onChangeCTDateEnd = (event) => {
		const value = event.currentTarget.value.toString();
		setMaxCTDate(value);
		setCustomerTransactionDate({
			...customerTransactionDate,
			dateTo: value,
		});
	};

	const closeGBMP = () => {
		setMerchantBulkPayment(false);
		setCustomerBulkPayment(false);
	};

	function PopupGenerateBulkMerchantPayment(props) {
		return props.trigger ? (
			<div className="pop-up">
				<div className="pop-up-data-pay">
					<IoClose size={24} style={{ cursor: "pointer", float: "right" }} onClick={closeGBMP} />
					{props.children}
					<div>
						<div className="payment-form">
							<div className="input-wrapper">
								<span className="label">Start Date</span>
								<input type="date" className="payment-input" onChange={onChangeCTDateStart} value={customerTransactionDate.dateFrom} max={maxCTDate} />
							</div>
							<div className="input-wrapper">
								<span className="label">End Date</span>
								<input type="date" className="payment-input" onChange={onChangeCTDateEnd} value={customerTransactionDate.dateTo} min={minCTDate} />
							</div>
						</div>

						<div className="button-container">
							<button className="pop-up-button" style={{ marginLeft: "10px" }} onClick={() => [generateMerchantDisbursement(), setMerchantBulkPayment(false)]}>
								Generate
							</button>
						</div>
					</div>
				</div>
			</div>
		) : (
			<div></div>
		);
	}

	const [customerBulkPayment, setCustomerBulkPayment] = useState(false);

	function PopupGenerateBulkCustomerPayment(props) {
		return props.trigger ? (
			<div className="pop-up">
				<div className="pop-up-data-pay">
					<IoClose size={24} style={{ cursor: "pointer", float: "right" }} onClick={closeGBMP} />
					{props.children}
					<div className="payment-form">
						<div className="input-wrapper">
							<span className="label">Start Date</span>
							<input className="payment-input" type="date" onChange={onChangeCTDateStart} value={customerTransactionDate.dateFrom} max={maxCTDate} />
						</div>
						<div className="input-wrapper">
							<span className="label">End Date</span>
							<input type="date" className="payment-input" onChange={onChangeCTDateEnd} value={customerTransactionDate.dateTo} min={minCTDate} />
						</div>
					</div>
					<div className="button-container">
						<button className="pop-up-button" onClick={() => [generateCustomerDisbursement(), setCustomerBulkPayment(false)]}>
							Generate
						</button>
					</div>
				</div>
			</div>
		) : (
			<div></div>
		);
	}

	const uploadDoc = () => {
		console.log("file", file);
		if (file === undefined) {
			return;
		}
		let batchId = null;
		batchId = sessionStorage.getItem("batchId");
		let token = null;
		token = localStorage.getItem("@storage_token");
		const formData = new FormData();
		formData.append("reportDocument", file);

		fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.UPLOAD_REPORT_DOC + "?batchId=" + batchId, {
			method: "POST",
			headers: {
				Accept: "*/*",
				"Accept-language": "en",
				Authorization: "Bearer " + token,
			},
			body: formData,
		})
			.then(function (response) {
				return response.json();
			})
			.then(function (data) {
				const response = data;
				if (response.responseCode === 200) {
					console.log("file uploaded");
				}
			});
	};

	const [mandatory, setMandatory] = useState("upload-file-input");

	const updateTransactionListReport = () => {
		if ((file === null || file === undefined) && reportTransaction.documentName === null) {
			setMandatory("upload-file-input-error");
		} else {
			uploadDoc();
			reportTransaction.transactionReportResponse.shift();
			console.log("reportTransaction", reportTransaction);
			let payRef = [];
			for (let i = 0; i < reportTransaction.transactionReportResponse.length; i++) {
				if (reportTransaction.transactionReportResponse[i].transactionStatus === true) {
					payRef[i] = reportTransaction.transactionReportResponse[i].paymentReference;
				}
			}
			let updateDisbursementRequest = {
				batchId: sessionStorage.getItem("batchId"),
				paymentDescription: reportTransaction.paymentDescription,
				// paymentReferences: [
				//   reportTransaction.paymentReference,
				// ]
				paymentReferences: payRef,
			};
			let token = null;
			token = localStorage.getItem("@storage_token");
			fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.UPDATE_REPORT_STATUS, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"Accept-language": "en",
					Authorization: "Bearer " + token,
				},
				body: JSON.stringify(updateDisbursementRequest),
			})
				.then(function (response) {
					return response.json();
				})
				.then(function (data) {
					const response = data;
					if (response.responseCode === 200) {
						searchMerchantDR();
						messageDialog({
							isOpen: true,
							condition: CONSTANSTS.DIALOG.success,
							title: CONSTANSTS.DIALOG_TITLE.general_success,
							message: response.message,
							defaultOnClick: false,
						});
						closeGetReport();
					}
				});
		}
	};

	return (
		<div className="page-report-payment">
			<div className="row">
				<div className="column left-side">
					<div className="card">
						<div className="side-menu">
							<a href="#" onClick={() => tabClicked("1")} className={firstMenu === "display" ? "clicked" : ""}>
								Bulk Merchant Payment
							</a>
							<a href="#" onClick={() => tabClicked("2")} className={secondMenu === "display" ? "clicked" : ""}>
								Bulk Customer Disbursement
							</a>
							<a href="#"> </a>
							<a href="#"> </a>
							<a href="#"> </a>
							<a href="#"> </a>
							<a href="#"> </a>
							<a href="#"> </a>
							<a href="#"> </a>
							<a href="#"> </a>
						</div>
					</div>
				</div>

				<div className="column right-side">
					<div className={firstMenu}>
						<div className="header">
							<p className="title-text">Bulk Merchant Payment</p>
							<button className="generate-button" onClick={() => setMerchantBulkPayment(true)}>
								Generate Bulk Payment
							</button>
						</div>
						<PopupGenerateBulkMerchantPayment trigger={merchantBulkPayment}>
							<div className="pop-up-message">Customer Transaction Date</div>
						</PopupGenerateBulkMerchantPayment>
						<div className="card">
							<div>
								<div className="half-menu">
									<div className="date-select">
										<span className="label">Start Date</span>
										<input className="payment-input" type="date" onChange={onChangeDateStart} value={merchantDate.dateFrom} max={maxDate} style={{ width: "258px" }} />
									</div>
								</div>
								<div className="half-menu">
									<div className="date-select">
										<span className="label">End Date</span>
										<input className="payment-input" type="date" onChange={onChangeDateEnd} value={merchantDate.dateTo} min={minDate} style={{ float: "right", width: "258px" }} />
									</div>
								</div>
							</div>
							<div>
								<div className="half-menu">
									<div className="date-select">
										<span className="label">Batch ID</span>
										<input className="payment-input" type="text" name="batchId" onChange={onChangeBatchId1} value={batchId1} />
									</div>
								</div>
								<div className="half-menu">
									<div>
										<button className="search-button" onClick={() => searchMerchantDR()}>
											Search
										</button>
									</div>
								</div>
							</div>
							<div className="page-loan-application-list__react-table-wrapper" style={{ marginTop: "10px" }}>
								{searchBatchData1 !== undefined ? (
									<AppReactTable
										columns={tableColumnBulk}
										data={searchBatchData1}
										headerClass="paymentReturnUploadHeader1"
										headerRowClass="paymentReturnUploadHeader1__row"
										rowHeaderClass="paymentReturnUploadHeader1__row-header"
										bodyClass="paymentReturnUploadBody"
										bodyRowClass="paymentReturnUploadBody__row"
										bodyDataClass="paymentReturnUploadBody__row-data"
									/>
								) : (
									<div></div>
								)}
							</div>
						</div>
					</div>
					<div className={secondMenu}>
						<div className="header">
							<p className="title-text">Bulk Customer Disbursement</p>
							<button className="generate-button" onClick={() => setCustomerBulkPayment(true)}>
								Generate Bulk Payment
							</button>
						</div>
						<PopupGenerateBulkCustomerPayment trigger={customerBulkPayment}>
							<div className="pop-up-message" style={{ marginBottom: "25px" }}>
								Customer Transaction Date
							</div>
						</PopupGenerateBulkCustomerPayment>
						<div className="card payment-table">
							<div>
								<div className="half-menu">
									<div className="date-select">
										<span className="label">Start Date</span>
										<input className="payment-input" type="date" onChange={onChangeCDateStart} value={customerDate.dateFrom} max={maxCustomerDate} style={{ width: "258px" }} />
									</div>
								</div>
								<div className="half-menu">
									<div className="date-select">
										<span className="label">End Date</span>
										<input className="payment-input" type="date" onChange={onChangeCDateEnd} value={customerDate.dateTo} min={minCustomerDate} style={{ float: "right", width: "258px" }} />
									</div>
								</div>
							</div>
							<div>
								<div className="half-menu">
									<div className="date-select">
										<span className="label">Batch ID</span>
										<input className="payment-input" type="text" name="batchId" onChange={onChangeBatchId2} value={batchId2} />
									</div>
								</div>
								<div className="half-menu">
									<button className="search-button" onClick={() => searchCustomerDR()}>
										Search
									</button>
								</div>
							</div>
							<div className="page-loan-application-list__react-table-wrapper">
								{searchBatchData2 !== undefined ? (
									<AppReactTable
										columns={tableColumnBulk}
										data={searchBatchData2}
										headerClass="paymentReturnUploadHeader1"
										headerRowClass="paymentReturnUploadHeader1__row"
										rowHeaderClass="paymentReturnUploadHeader1__row-header"
										bodyClass="paymentReturnUploadBody"
										bodyRowClass="paymentReturnUploadBody__row"
										bodyDataClass="paymentReturnUploadBody__row-data"
									/>
								) : (
									<div></div>
								)}
							</div>
						</div>
					</div>
					<div className={thirdMenu}>
						<p className="title-text">Payment Return Upload Merchant</p>
						<div className="card third-tab">
							<SelectDate tab="3"></SelectDate>

							<div className="page-loan-application-list__react-table-wrapper" style={{ marginTop: "10px" }}>
								{appList !== undefined ? (
									<AppReactTable
										columns={tableColumn}
										data={appList}
										headerClass="paymentReturnUploadHeader"
										headerRowClass="paymentReturnUploadHeader__row"
										rowHeaderClass="paymentReturnUploadHeader__row-header"
										bodyClass="paymentReturnUploadBody"
										bodyRowClass="paymentReturnUploadBody__row"
										bodyDataClass="paymentReturnUploadBody__row-data"
									/>
								) : (
									<div></div>
								)}
							</div>

							<UploadFile tab="3"></UploadFile>
						</div>
					</div>
					<div className={forthMenu}>
						<p className="title-text">Payment Return Upload Customer</p>
						<div className="card third-tab">
							<SelectDate tab="4"></SelectDate>
							<div className="page-loan-application-list__react-table-wrapper">
								{appList !== undefined ? (
									<AppReactTable
										columns={tableColumn}
										data={appList}
										headerClass="paymentReturnUploadHeader"
										headerRowClass="paymentReturnUploadHeader__row"
										rowHeaderClass="paymentReturnUploadHeader__row-header"
										bodyClass="paymentReturnUploadBody"
										bodyRowClass="paymentReturnUploadBody__row"
										bodyDataClass="paymentReturnUploadBody__row-data"
									/>
								) : (
									<div></div>
								)}
							</div>

							<UploadFile tab="4"></UploadFile>
						</div>
					</div>
					<div className={fifthMenu}>
						<p className="title-text"> </p>
						<div className="card">
							<p> </p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PagePayment;
