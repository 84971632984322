import React, { useContext, useState, useEffect, useRef } from "react";
// component
import { AiOutlineClose } from "react-icons/ai";
import AppSearchBar from "components/app-search-bar";
import AppButton from "components/app-button";
import AppReactTable from "components/app-react-table";
// commons
import pathnames from "routes/pathnames";
import { useHistory } from "react-router-dom";
import CONSTANSTS from "common/constansts";
import { IoClose } from "react-icons/io5";
import { capitalize } from "lodash";
// import { useHistory } from "react-router-dom";
import { RiArrowGoBackFill } from "react-icons/ri";
import { FiEye } from "react-icons/fi";
// assets
import IconDownload from "../../assets/images/pages/icon-download-video.png";
import IconRemove from "../../assets/images/pages/icon-remove.png";
import IconUpload from "../../assets/images/pages/icon-upload.png";

import AppCustomToast, { PageContext } from "components/app-custom-toast";

const PageTransaction = () => {
	const [receivableList, setReceivableList] = useState({
		export_receivable_transaction_list: false,
		view_receivable_transaction_list: false,
	});

	// const [role, setRole] = useState({
	//   roles: ""
	// });

	const [tableSearchFilter1, setTableSearchFilter1] = useState({
		pageNo: 1,
		pageSize: 50,
		sortKey: "dateCreated",
		sortDir: "DESCENDING",
		dateRangeType: null,
		dateFrom: "string",
		dateTo: "string",
		keyword: "",
		status: null,
	});
	const [tableSearchFilter2, setTableSearchFilter2] = useState({
		pageNo: 1,
		pageSize: 50,
		sortKey: "dateCreated",
		sortDir: "DESCENDING",
		dateRangeType: null,
		dateFrom: "string",
		dateTo: "string",
		keyword: "",
		status: null,
	});
	const [tableSearchFilter3, setTableSearchFilter3] = useState({
		pageNo: 1,
		pageSize: 50,
		sortKey: "dateCreated",
		sortDir: "DESCENDING",
		dateRangeType: null,
		dateFrom: "string",
		dateTo: "string",
		keyword: "",
		status: null,
	});
	useEffect(() => {
		let roles = localStorage.getItem("decode_token");

		if (roles === CONSTANSTS.ROLES.processOfficer || roles === CONSTANSTS.ROLES.creditOfficer) {
			console.log("Access Denied");
			history.push(pathnames.pageHomePage);
		} else {
			const fetchStatus = async () => {
				fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.COMMON_FIND_DROPDOWN_OPTION + "transactionStatus", {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Accept: "*/*",
						"Accept-language": "en",
					},
				})
					.then((response) => response.json())
					.then((json) => {
						let res = json.result;
						setStatusList(res);
						// console.log("status:", res)
					});
			};
			if (tabCol1 === "tab-selected") {
				console.log("tableSearchFilter1", JSON.stringify(tableSearchFilter1));
				fetchReceivablesData();
			} else if (tabCol2 === "tab-selected") {
				console.log("tableSearchFilter2", JSON.stringify(tableSearchFilter2));
				fetchDisbursementData();
			} else {
				console.log("tableSearchFilter3", JSON.stringify(tableSearchFilter3));
				fetchAllTransactionData();
			}
			fetchStatus();
		}
		// if(roles != "") {
		//   setRole({
		//     roles: roles
		//   })
		// }
		console.log(receivableList);
		let reportReceivableAccess = null;
		reportReceivableAccess = JSON.parse(localStorage.getItem("accessMatrix"));
		if (reportReceivableAccess) {
			setReceivableList({
				export_loan_list: reportReceivableAccess.accessMatrix.Receivable_Transaction.export_receivable_transaction_list,
				view_loan_list: reportReceivableAccess.accessMatrix.Receivable_Transaction.view_receivable_transaction_list,
			});
		}
	}, [tableSearchFilter1, tableSearchFilter2, tableSearchFilter3]);

	const indicatorValue = useContext(PageContext);
	function messageDialog(indicator) {
		AppCustomToast({
			showIndicator: indicator,
			reintIndicator: indicatorValue[1](indicator),
		});
	}

	const headerFilterClick1 = (header) => {
		let column1 = header.column;
		if (tableSearchFilter1.sortKey === column1.searchFilterValue) {
			if (tableSearchFilter1.sortDir === "ASCENDING") {
				setTableSearchFilter1({
					...tableSearchFilter1,
					sortKey: column1.searchFilterValue,
					sortDir: "DESCENDING",
					pageNo: 1,
				});
			} else {
				setTableSearchFilter1({
					...tableSearchFilter1,
					sortKey: column1.searchFilterValue,
					sortDir: "ASCENDING",
					pageNo: 1,
				});
			}
		} else {
			setTableSearchFilter1({
				...tableSearchFilter1,
				sortKey: column1.searchFilterValue,
				sortDir: "ASCENDING",
				pageNo: 1,
			});
		}
	};

	const headerArrowRotate1 = (active) => {
		const classNames = ["react-table__icon-wrapper"];
		if (active && tableSearchFilter1.sortDir === "DESCENDING") classNames.push("react-table__icon-wrapper-down");
		if (active) classNames.push("react-table__icon-wrapper--show");
		return classNames.join(" ");
	};

	const headerFilterClick2 = (header) => {
		let column2 = header.column;
		if (tableSearchFilter2.sortKey === column2.searchFilterValue) {
			if (tableSearchFilter2.sortDir === "ASCENDING") {
				setTableSearchFilter2({
					...tableSearchFilter2,
					sortKey: column2.searchFilterValue,
					sortDir: "DESCENDING",
					pageNo: 1,
				});
			} else {
				setTableSearchFilter2({
					...tableSearchFilter2,
					sortKey: column2.searchFilterValue,
					sortDir: "ASCENDING",
					pageNo: 1,
				});
			}
		} else {
			setTableSearchFilter2({
				...tableSearchFilter2,
				sortKey: column2.searchFilterValue,
				sortDir: "ASCENDING",
				pageNo: 1,
			});
		}
	};

	const headerArrowRotate2 = (active) => {
		const classNames = ["react-table__icon-wrapper"];
		if (active && tableSearchFilter2.sortDir === "DESCENDING") classNames.push("react-table__icon-wrapper-down");
		if (active) classNames.push("react-table__icon-wrapper--show");
		return classNames.join(" ");
	};

	const headerFilterClick3 = (header) => {
		let column3 = header.column;
		if (tableSearchFilter3.sortKey === column3.searchFilterValue) {
			if (tableSearchFilter3.sortDir === "ASCENDING") {
				setTableSearchFilter3({
					...tableSearchFilter3,
					sortKey: column3.searchFilterValue,
					sortDir: "DESCENDING",
					pageNo: 1,
				});
			} else {
				setTableSearchFilter3({
					...tableSearchFilter3,
					sortKey: column3.searchFilterValue,
					sortDir: "ASCENDING",
					pageNo: 1,
				});
			}
		} else {
			setTableSearchFilter3({
				...tableSearchFilter3,
				sortKey: column3.searchFilterValue,
				sortDir: "ASCENDING",
				pageNo: 1,
			});
		}
	};

	const headerArrowRotate3 = (active) => {
		const classNames = ["react-table__icon-wrapper"];
		if (active && tableSearchFilter3.sortDir === "DESCENDING") classNames.push("react-table__icon-wrapper-down");
		if (active) classNames.push("react-table__icon-wrapper--show");
		return classNames.join(" ");
	};

	const tableColumn1 = [
		{
			id: "referenceNumber",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick1(header)} className="react-table__header">
						<span className="react-table__header-title">Transaction Ref</span>
						<div className={headerArrowRotate1(tableSearchFilter1.sortKey === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			accessor: "referenceNumber",
			searchFilterValue: "referenceNumber",
			minWidth: 180,
			width: 180,
		},
		{
			id: "party",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick1(header)} className="react-table__header">
						<span className="react-table__header-title">Payment Type</span>
						<div className={headerArrowRotate1(tableSearchFilter1.sortKey === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			searchFilterValue: "party",
			Cell: (row) => {
				return <span>{capitalize(row.row.original.party)}</span>;
			},
			minWidth: 100,
			width: 100,
		},
		{
			id: "name",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick1(header)} className="react-table__header">
						<span className="react-table__header-title">Name</span>
						<div className={headerArrowRotate1(tableSearchFilter1.sortKey === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			searchFilterValue: "name",
			accessor: "name",
			minWidth: 180,
			width: 180,
		},
		{
			id: "loanContract",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick1(header)} className="react-table__header">
						<span className="react-table__header-title">Contract ID</span>
						<div className={headerArrowRotate1(tableSearchFilter1.sortKey === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			searchFilterValue: "loanContract",
			accessor: "loanContract",
			minWidth: 150,
			width: 150,
		},
		{
			id: "amount",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick1(header)} className="react-table__header">
						<span className="react-table__header-title">Amount</span>
						<div className={headerArrowRotate1(tableSearchFilter1.sortKey === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			searchFilterValue: "amount",
			accessor: "amount",
			minWidth: 140,
			width: 140,
		},
		{
			id: "dateCreated",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick1(header)} className="react-table__header">
						<span className="react-table__header-title">Transaction Date</span>
						<div className={headerArrowRotate1(tableSearchFilter1.sortKey === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			searchFilterValue: "dateCreated",
			Cell: (row) => {
				return <span>{row.row.original.dateCreated}</span>;
			},
			minWidth: 120,
			width: 120,
		},
		{
			id: "status",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick1(header)} className="react-table__header">
						<span className="react-table__header-title">Status</span>
						<div className={headerArrowRotate1(tableSearchFilter1.sortKey === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			searchFilterValue: "status",
			Cell: (row) => {
				return <span>{capitalize(row.row.original.status)}</span>;
			},
			minWidth: 100,
			width: 100,
		},
		{
			id: "paidDate",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick1(header)} className="react-table__header">
						<span className="react-table__header-title">Paid/Received Date</span>
						<div className={headerArrowRotate1(tableSearchFilter1.sortKey === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			searchFilterValue: "paidDate",
			accessor: "paidDate",
			minWidth: 150,
			width: 150,
		},
	];

	const tableColumn2 = [
		{
			id: "referenceNumber",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick2(header)} className="react-table__header">
						<span className="react-table__header-title">Transaction Ref</span>
						<div className={headerArrowRotate2(tableSearchFilter2.sortKey === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			accessor: "referenceNumber",
			searchFilterValue: "referenceNumber",
			minWidth: 200,
			width: 200,
		},
		{
			id: "party",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick2(header)} className="react-table__header">
						<span className="react-table__header-title">Payment Type</span>
						<div className={headerArrowRotate2(tableSearchFilter2.sortKey === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			searchFilterValue: "party",
			Cell: (row) => {
				return <span>{capitalize(row.row.original.party)}</span>;
			},
			minWidth: 100,
			width: 100,
		},
		{
			id: "name",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick2(header)} className="react-table__header">
						<span className="react-table__header-title">Name</span>
						<div className={headerArrowRotate2(tableSearchFilter2.sortKey === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			searchFilterValue: "name",
			accessor: "name",
			minWidth: 180,
			width: 180,
		},
		{
			id: "loanContract",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick2(header)} className="react-table__header">
						<span className="react-table__header-title">Contract ID</span>
						<div className={headerArrowRotate2(tableSearchFilter2.sortKey === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			searchFilterValue: "loanContract",
			accessor: "loanContract",
			minWidth: 150,
			width: 150,
		},
		{
			id: "amount",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick2(header)} className="react-table__header">
						<span className="react-table__header-title">Amount</span>
						<div className={headerArrowRotate2(tableSearchFilter2.sortKey === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			searchFilterValue: "amount",
			accessor: "amount",
			minWidth: 110,
			width: 110,
		},
		{
			id: "dateCreated",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick2(header)} className="react-table__header">
						<span className="react-table__header-title">Transaction Date</span>
						<div className={headerArrowRotate2(tableSearchFilter2.sortKey === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			searchFilterValue: "dateCreated",
			Cell: (row) => {
				return <span>{row.row.original.dateCreated}</span>;
			},
			minWidth: 120,
			width: 120,
		},
		{
			id: "status",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick2(header)} className="react-table__header">
						<span className="react-table__header-title">Status</span>
						<div className={headerArrowRotate2(tableSearchFilter2.sortKey === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			searchFilterValue: "status",
			Cell: (row) => {
				return <span>{capitalize(row.row.original.status)}</span>;
			},
			minWidth: 100,
			width: 100,
		},
		{
			id: " payableDate",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick2(header)} className="react-table__header">
						<span className="react-table__header-title">Disbursable Date</span>
						<div className={headerArrowRotate2(tableSearchFilter2.sortKey === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			searchFilterValue: " payableDate",
			Cell: (row) => {
				return <span>{row.row.original.payableDate}</span>;
			},
			minWidth: 110,
			width: 110,
		},
		{
			id: "remark",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick2(header)} className="react-table__header">
						<span className="react-table__header-title">Remarks</span>
						<div className={headerArrowRotate2(tableSearchFilter2.sortKey === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			searchFilterValue: "remark",
			accessor: "remark",
			minWidth: 110,
			width: 110,
		},
		{
			id: "proof",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick2(header)} className="react-table__header">
						<span className="react-table__header-title">Proof</span>
						<div className={headerArrowRotate2(tableSearchFilter2.sortKey === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			searchFilterValue: "proof",
			Cell: (row) => {
				return (
					<div>
						<div onClick={(e) => e.stopPropagation()}>
							{row.row.original.proof !== null ? (
								<button onClick={() => viewPage(row.row.original)} className="react-table__search-button">
									{row.row.original.proof}
								</button>
							) : (
								<span> —</span>
							)}
						</div>
						<PopupInvoice2 trigger={invoicePopUp2}>
							<div className="pop-up-message">
								{invoiceDetail !== undefined ? (
									<div>
										<div
											style={{
												display: "flex",
												justifyContent: "flex-end",
												color: "#1D1D1B",
											}}
										>
											<IoClose size={24} style={{ cursor: "pointer" }} onClick={closeUpload} />
										</div>
										<div>
											<div>
												<div className="row" style={{ textAlign: "left", fontSize: "20px" }}>
													<div className="column">
														<div>
															<span className="span-title">Contract ID</span> <span className="span-detail">: {invoiceDetail.contractId ?? CONSTANSTS.DISPLAY_TEXT.empty}</span>
														</div>
														<div>
															<span className="span-title">Customer Name</span> <span className="span-detail">: {invoiceDetail.customerName ?? CONSTANSTS.DISPLAY_TEXT.empty}</span>
														</div>
														<div>
															<span className="span-title">Merchant Name</span> <span className="span-detail">: {invoiceDetail.merchantName ?? CONSTANSTS.DISPLAY_TEXT.empty}</span>
														</div>
													</div>
													<div className="column">
														<div>
															<span className="span-title">Transaction Date</span> <span className="span-detail">: {invoiceDetail.transactionDate ?? CONSTANSTS.DISPLAY_TEXT.empty}</span>
														</div>
														<div>
															<span className="span-title">Transaction Amt</span>{" "}
															<span className="span-detail">
																: RM
																{invoiceDetail.transactionAmount ?? CONSTANSTS.DISPLAY_TEXT.empty}
															</span>
														</div>
													</div>
												</div>
											</div>
										</div>

										<div className="table-invoice">
											<table style={{ width: "100%", textAlign: "left" }}>
												<thead className="tr-head">
													<tr>
														<th>Image File</th>
														<th>Upload Date</th>
														<th></th>
														<th></th>
													</tr>
												</thead>
												<tbody className="tr-body">
													{invoiceDetail.transactionInvoiceList.map((item, index) => (
														<tr key={index}>
															<td>{item.filename}</td>
															<td>{item.uploadedDatetime}</td>
															<td>
																<img src={IconDownload} alt="download icon" style={{ width: "30px", height: "30px", cursor: "pointer" }} onClick={() => downloadImage(item)} />
															</td>
															<td>
																<img src={IconRemove} alt="delete icon" style={{ width: "30px", height: "30px", cursor: "pointer" }} onClick={() => deleteImage(item)} />
															</td>
														</tr>
													))}
												</tbody>
											</table>
										</div>
									</div>
								) : (
									""
								)}
							</div>
						</PopupInvoice2>
					</div>
				);
			},
			minWidth: 70,
			width: 70,
		},
		{
			id: "paidDate",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick2(header)} className="react-table__header">
						<span className="react-table__header-title">Paid/Received Date</span>
						<div className={headerArrowRotate2(tableSearchFilter2.sortKey === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			searchFilterValue: "paidDate",
			accessor: "paidDate",
			minWidth: 110,
			width: 110,
		},
		{
			id: "button",
			Header: "Cancel Transaction",
			Cell: (row) => {
				return (
					<div onClick={(e) => e.stopPropagation()}>
						{row.row.original.status == CONSTANSTS.CONTRACT_STATUS.pending || (row.row.original.status == CONSTANSTS.CONTRACT_STATUS.disbursed && row.row.original.status !== "Cancelled") ? (
							"   —"
						) : row.row.original.status === "Cancelled" ? (
							<button onClick={() => [setUndoCancelPopUp2(true), setTransactionId(row.row.original)]} className="react-table__search-button">
								<RiArrowGoBackFill size={24} />
							</button>
						) : (
							<button onClick={() => [setCancelPopUp2(true), setTransactionId(row.row.original)]} className="react-table__search-button">
								<IoClose size={24} />
							</button>
						)}
						<PopupCancel2 trigger={cancelPopUp2}>
							<div className="pop-up-message">
								<textarea type="text" placeholder="Please provide remarks" name="remark2" onChange={handleOnChange2} />
							</div>
						</PopupCancel2>
						<PopupUndoCancel2 trigger={undoCancelPopUp2}>
							<div className="pop-up-message">Are you sure you want to undo this cancellation?</div>
						</PopupUndoCancel2>
						<PopupUndoCancelRemark2 trigger={undoCancelPopUpRemark2}>
							<div className="pop-up-message">
								<textarea type="text" placeholder="Please provide remarks" name="remark2" onChange={handleOnChange2} />
							</div>
						</PopupUndoCancelRemark2>
					</div>
				);
			},
			minWidth: 90,
			width: 90,
		},
	];

	const tableColumn3 = [
		{
			id: "referenceNumber",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick3(header)} className="react-table__header">
						<span className="react-table__header-title">Transaction Ref</span>
						<div className={headerArrowRotate3(tableSearchFilter3.sortKey === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			accessor: "referenceNumber",
			searchFilterValue: "referenceNumber",
			minWidth: 200,
			width: 200,
		},
		{
			id: "party",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick3(header)} className="react-table__header">
						<span className="react-table__header-title">Payment Type</span>
						<div className={headerArrowRotate3(tableSearchFilter3.sortKey === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			searchFilterValue: "party",
			Cell: (row) => {
				return <span>{capitalize(row.row.original.party)}</span>;
			},
			minWidth: 100,
			width: 100,
		},
		{
			id: "name",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick3(header)} className="react-table__header">
						<span className="react-table__header-title">Name</span>
						<div className={headerArrowRotate3(tableSearchFilter3.sortKey === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			searchFilterValue: "name",
			accessor: "name",
			minWidth: 180,
			width: 180,
		},
		{
			id: "loanContract",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick3(header)} className="react-table__header">
						<span className="react-table__header-title">Contract ID</span>
						<div className={headerArrowRotate3(tableSearchFilter3.sortKey === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			searchFilterValue: "loanContract",
			accessor: "loanContract",
			minWidth: 150,
			width: 150,
		},
		{
			id: "amount",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick3(header)} className="react-table__header">
						<span className="react-table__header-title">Amount</span>
						<div className={headerArrowRotate3(tableSearchFilter3.sortKey === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			searchFilterValue: "amount",
			accessor: "amount",
			minWidth: 140,
			width: 140,
		},
		{
			id: "dateCreated",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick3(header)} className="react-table__header">
						<span className="react-table__header-title">Transaction Date</span>
						<div className={headerArrowRotate3(tableSearchFilter3.sortKey === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			searchFilterValue: "dateCreated",
			Cell: (row) => {
				return <span>{row.row.original.dateCreated}</span>;
			},
			minWidth: 120,
			width: 120,
		},
		{
			id: "status",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick3(header)} className="react-table__header">
						<span className="react-table__header-title">Status</span>
						<div className={headerArrowRotate3(tableSearchFilter3.sortKey === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			searchFilterValue: "status",
			Cell: (row) => {
				return <span>{capitalize(row.row.original.status)}</span>;
			},
			minWidth: 100,
			width: 100,
		},
		{
			id: "payableDate",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick3(header)} className="react-table__header">
						<span className="react-table__header-title">Disbursable Date</span>
						<div className={headerArrowRotate3(tableSearchFilter3.sortKey === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			searchFilterValue: "payableDate",
			Cell: (row) => {
				return <span>{row.row.original.payableDate}</span>;
			},
			minWidth: 120,
			width: 120,
		},
		{
			id: "remark",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick3(header)} className="react-table__header">
						<span className="react-table__header-title">Remarks</span>
						<div className={headerArrowRotate3(tableSearchFilter3.sortKey === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			searchFilterValue: "remark",
			accessor: "remark",
			minWidth: 140,
			width: 140,
		},
		{
			id: "proof",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick3(header)} className="react-table__header">
						<span className="react-table__header-title">Proof</span>
						<div className={headerArrowRotate3(tableSearchFilter3.sortKey === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			searchFilterValue: "proof",
			Cell: (row) => {
				return (
					<div>
						<div onClick={(e) => e.stopPropagation()}>
							{row.row.original.proof !== null ? (
								<button onClick={() => viewPage(row.row.original)} className="react-table__search-button">
									{row.row.original.proof}
								</button>
							) : (
								<span> —</span>
							)}
						</div>
						<PopupInvoice2 trigger={invoicePopUp2}>
							<div className="pop-up-message">
								{invoiceDetail !== undefined ? (
									<div>
										<div
											style={{
												display: "flex",
												justifyContent: "end",
												color: "#1D1D1B",
											}}
										>
											<IoClose size={24} style={{ cursor: "pointer" }} onClick={closeUpload} />
										</div>
										<div style={{ display: "flex", justifyContent: "center" }}>
											<div style={{ width: "900px" }}>
												<div className="row" style={{ textAlign: "left", fontSize: "20px" }}>
													<div className="column">
														<div>
															<span className="span-title">Contract ID</span> <span className="span-detail">: {invoiceDetail.contractId ?? CONSTANSTS.DISPLAY_TEXT.empty}</span>
														</div>
														<div>
															<span className="span-title">Customer Name</span> <span className="span-detail">: {invoiceDetail.customerName ?? CONSTANSTS.DISPLAY_TEXT.empty}</span>
														</div>
														<div>
															<span className="span-title">Merchant Name</span> <span className="span-detail">: {invoiceDetail.merchantName ?? CONSTANSTS.DISPLAY_TEXT.empty}</span>
														</div>
													</div>
													<div className="column">
														<div>
															<span className="span-title">Transaction Date</span> <span className="span-detail">: {invoiceDetail.transactionDate ?? CONSTANSTS.DISPLAY_TEXT.empty}</span>
														</div>
														<div>
															<span className="span-title">Transaction Amt</span>{" "}
															<span className="span-detail">
																: RM
																{invoiceDetail.transactionAmount ?? CONSTANSTS.DISPLAY_TEXT.empty}
															</span>
														</div>
													</div>
												</div>
											</div>
										</div>

										<div className="table-invoice">
											<table style={{ width: "100%", textAlign: "left" }}>
												<thead className="tr-head">
													<tr
														style={{
															backgroundColor: "856E49",
															color: "white",
															textDecoration: "underline",
														}}
													>
														<th style={{ width: "35%" }}>Upload Date</th>
														<th style={{ width: "5%" }}></th>
														<th style={{ width: "5%" }}></th>
													</tr>
												</thead>
												<tbody className="tr-body">
													{invoiceDetail.transactionInvoiceList.map((item, index) => (
														<tr key={index}>
															<td>{item.filename}</td>
															<td>{item.uploadedDatetime}</td>
															<td>
																<img src={IconDownload} alt="download icon" style={{ width: "30px", cursor: "pointer" }} onClick={() => downloadImage(item)} />
															</td>
															<td>
																<img src={IconRemove} alt="delete icon" style={{ width: "30px", cursor: "pointer" }} onClick={() => deleteImage(item)} />
															</td>
														</tr>
													))}
												</tbody>
											</table>
										</div>
									</div>
								) : (
									""
								)}
							</div>
						</PopupInvoice2>
					</div>
				);
			},
			minWidth: 90,
			width: 90,
		},
		{
			id: "paidDate",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick3(header)} className="react-table__header">
						<span className="react-table__header-title">Paid/Received Date</span>
						<div className={headerArrowRotate3(tableSearchFilter3.sortKey === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			searchFilterValue: "paidDate",
			accessor: "paidDate",
			minWidth: 150,
			width: 150,
		},
		{
			id: "button",
			Header: "Cancel Transaction",
			Cell: (row) => {
				return (
					<div onClick={(e) => e.stopPropagation()}>
						{row.row.original.status !== CONSTANSTS.CONTRACT_STATUS.disbursed && row.row.original.status !== CONSTANSTS.CONTRACT_STATUS.pending && row.row.original.status !== "Cancelled" ? (
							<button style={{ marginLeft: "15px" }} onClick={() => [setCancelPopUp3(true), setTransactionId(row.row.original)]} className="react-table__search-button">
								<IoClose size={24} />
							</button>
						) : row.row.original.status === "Cancelled" ? (
							<button style={{ marginLeft: "15px" }} onClick={() => [setUndoCancelPopUp3(true), setTransactionId(row.row.original)]} className="react-table__search-button">
								<RiArrowGoBackFill size={24} />
							</button>
						) : (
							<span style={{ marginLeft: "15px" }}> —</span>
						)}
						<PopupCancel3 trigger={cancelPopUp3}>
							<div className="pop-up-message">
								{/* Are you sure you want to cancel this transaction? */}
								<textarea type="text" placeholder="Please provide remarks" name="remark3" onChange={handleOnChange3} />
							</div>
						</PopupCancel3>

						<PopupUndoCancel3 trigger={undoCancelPopUp3}>
							<div className="pop-up-message">Are you sure you want to un-do this cancellation?</div>
						</PopupUndoCancel3>
						<PopupUndoCancelRemark3 trigger={undoCancelPopUpRemark3}>
							<div className="pop-up-message">
								<input
									type="text"
									placeholder="Please provide remarks"
									style={{ fontSize: "20px", width: "80%" }}
									name="remark3"
									onChange={handleOnChange3}
									// ref={(input)=>{if(input){input.focus();}}} value={input.remark3.value}
								/>
							</div>
						</PopupUndoCancelRemark3>
					</div>
				);
			},
			minWidth: 110,
			width: 110,
		},
		{
			id: "view",
			Header: "View Payment",
			Cell: (row) => {
				return (
					<div>
						{row.row.original.updatable === false && (row.row.original.status.toLowerCase() === "disbursed" || row.row.original.status.toLowerCase() === "pending") ? (
							<button style={{ marginLeft: "15px" }} className="react-table__search-button" onClick={() => viewBulk(row.row.original)}>
								<FiEye size={24} />
							</button>
						) : null}
					</div>
				);
			},
			minWidth: 90,
			width: 90,
		},
	];

	const history = useHistory();
	const searchBulk = [];

	const viewBulk = (e) => {
		let bulkData = null;
		bulkData = e;
		searchBulk.push({
			batchId: bulkData.batchId,
			dateCreated: bulkData.dateCreated,
			party: bulkData.party,
		});
		console.log("updatable", bulkData);
		history.push(pathnames.pagePayment);
		sessionStorage.setItem("searchBulk", JSON.stringify(searchBulk));
	};

	// const history = useHistory();
	const [receivablesList, setReceivablesList] = useState(undefined);
	const [disbursementList, setDisbursementList] = useState(undefined);
	const [transactionList, setTransactionList] = useState(undefined);
	const [invoiceDetail, setInvoiceDetail] = useState();
	const [proofId, setProof] = useState();
	const [invoiceId, setInvoiceId] = useState();

	const viewPage = (value) => {
		let token = null;
		token = localStorage.getItem("@storage_token");
		setInvoiceId(value.id);

		fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.FIND_INVOICE + value.id, {
			method: "GET",
			headers: {
				"Accept-language": "en",
				Authorization: "Bearer " + token,
			},
		})
			.then((response) => response.json())
			.then((json) => {
				let result = json.result;
				setInvoiceDetail(result);
			});
		setInvoicePopUp2(true);
		setProof(value.id);
		console.log(invoiceDetail);
	};

	const downloadImage = (item) => {
		let token = null;
		token = localStorage.getItem("@storage_token");
		// let fileName = "";
		// fileName = input.referenceNumber.value
		fetch(item.downloadUrl, {
			method: "GET",
			headers: {
				// "Content-Disposition": "attachment; filename=" + fileName + "_Merchant Agreement.pdf",
				"Content-Type": "application/pdf",
				Vary: "Origin,Access-Control-Request-Method,Access-Control-Request-Headers",
				"X-Content-Type-Options": "nosniff",
				"X-XSS-Protection": "1; mode=block",
				"Cache-Control": "no-cache, no-store, max-age=0, must-revalidate",
				"Keep-Alive": "timeout=5, max=100",
				Connection: "Keep-Alive",
				Authorization: "Bearer " + token,
			},
		}).then((response) => {
			let filename = response.headers.get("Content-Disposition");
			if (filename != null) {
				filename = filename.split(";")[1].split("filename=")[1];
			}
			response.blob().then((blob) => {
				// console.log(blob)
				let url = window.URL.createObjectURL(blob);
				let a = document.createElement("a");
				a.href = url;
				// a.download = item.filename;
				a.download = filename;
				a.click();
			});
		});
	};

	const deleteImage = (item) => {
		let token = null;
		token = localStorage.getItem("@storage_token");
		fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.DELETE_INVOICE + item.invoiceId, {
			method: "GET",
			headers: {
				"Accept-language": "en",
				Authorization: "Bearer " + token,
			},
		})
			.then((response) => response.json())
			.then((json) => {
				let response = json;
				console.log(response);
				if (response.responseCode === 200) {
					messageDialog({
						isOpen: true,
						condition: CONSTANSTS.DIALOG.success,
						title: CONSTANSTS.DIALOG_TITLE.general_success,
						message: response.message,
						defaultOnClick: false,
					});

					fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.FIND_INVOICE + invoiceId, {
						method: "GET",
						headers: {
							"Accept-language": "en",
							Authorization: "Bearer " + token,
						},
					})
						.then((response) => response.json())
						.then((json) => {
							let result = json.result;
							setInvoiceDetail(result);
						});
					fetchDisbursementData();
					fetchAllTransactionData();
				} else {
					messageDialog({
						isOpen: true,
						condition: CONSTANSTS.DIALOG.failed,
						title: CONSTANSTS.DIALOG_TITLE.general_failed,
						message: response.message,
						defaultOnClick: false,
					});
				}
			});
	};

	// const [cancelPopUp1, setCancelPopUp1] = useState(false); // #291 03/06/2022
	const [cancelPopUp2, setCancelPopUp2] = useState(false);
	const [cancelPopUp3, setCancelPopUp3] = useState(false);
	// const [undoCancelPopUp1, setUndoCancelPopUp1] = useState(false); // #291 03/06/2022
	const [undoCancelPopUp2, setUndoCancelPopUp2] = useState(false);
	const [undoCancelPopUp3, setUndoCancelPopUp3] = useState(false);
	const [undoCancelPopUpRemark2, setUndoCancelPopUpRemark2] = useState(false);
	const [undoCancelPopUpRemark3, setUndoCancelPopUpRemark3] = useState(false);
	const [transactionId, setTransactionId] = useState("");
	const [invoicePopUp2, setInvoicePopUp2] = useState(false);

	const [file, setFile] = useState();
	const [fileName, setFileName] = useState();
	const handleFileChange = (event) => {
		let inputFile = event.target.files[0];
		setFile(inputFile);
		setFileName(inputFile.name.toString());
		// console.log("->", inputFile)
	};
	function bytesToMegaBytes(bytes) {
		return bytes / (1024 * 1024);
	}
	function clearFile() {
		setFile("");
		setFileName("");
	}
	const uploadInvoice = () => {
		if (!file) {
			messageDialog({
				isOpen: true,
				condition: CONSTANSTS.DIALOG.failed,
				title: CONSTANSTS.DIALOG_TITLE.general_failed,
				message: CONSTANSTS.DIALOG_MESSAGE.upload_empty,
				defaultOnClick: false,
				onClick: () => clearFile(),
			});
			return;
		}
		if (bytesToMegaBytes(file.size) > 5) {
			messageDialog({
				isOpen: true,
				condition: CONSTANSTS.DIALOG.failed,
				title: CONSTANSTS.DIALOG_TITLE.general_failed,
				message: CONSTANSTS.DIALOG_MESSAGE.upload_invalid_size,
				defaultOnClick: false,
				onClick: () => clearFile(),
			});

			return;
		}

		let token = null;
		token = localStorage.getItem("@storage_token");
		const formData = new FormData();
		formData.append("id", proofId);
		formData.append("invoices[]", file);
		fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.UPLOAD_INVOICE, {
			method: "POST",
			headers: {
				// "Content-Type": "application/json",
				Accept: "*/*",
				"Accept-language": "en",
				Authorization: "Bearer " + token,
			},
			body: formData,
		})
			.then(function (response) {
				return response.json();
			})
			.then(function (data) {
				const items = data;
				// console.log(items)
				if (items.responseCode === 200) {
					clearFile();
					fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.FIND_INVOICE + proofId, {
						method: "GET",
						headers: {
							"Accept-language": "en",
							Authorization: "Bearer " + token,
						},
					})
						.then((response) => response.json())
						.then((json) => {
							let result = json.result;
							setInvoiceDetail(result);

							messageDialog({
								isOpen: true,
								condition: CONSTANSTS.DIALOG.success,
								title: CONSTANSTS.DIALOG_TITLE.general_success,
								message: items.message,
							});
						});
					fetchReceivablesData();
					fetchDisbursementData();
					fetchAllTransactionData();
				} else {
					messageDialog({
						isOpen: true,
						condition: CONSTANSTS.DIALOG.failed,
						title: CONSTANSTS.DIALOG_TITLE.general_failed,
						message: items.message,
						defaultOnClick: false,
						onClick: () => clearFile(),
					});
				}
			});
	};
	const closeUpload = () => {
		setInvoicePopUp2(false);
	};

	// const clockPopUp2 = () => {
	//   setInvoicePopUp2(false);
	// };

	const inputFile = useRef();

	const openFile = () => {
		inputFile.current.click();
	};

	function PopupInvoice2(props) {
		return props.trigger ? (
			<div className="pop-up">
				<div className="pop-up-data">
					{props.children}
					<div>
						<div className="input-wrapper">
							<input type="text" value={fileName} name="inputPopUp2" onClick={openFile} readOnly />
							<button
								className="delete-button"
								onClick={() => {
									inputFile.current.value = "";
									setFile(null);
									setFileName("");
								}}
							>
								<IoClose size={24} />
							</button>
							<div>
								<div>
									<img src={IconUpload} alt="upload icon" className="upload-icon" onClick={openFile} />
								</div>
							</div>
						</div>
						<button className="pop-up-button" onClick={() => [uploadInvoice(transactionId)]}>
							Upload
						</button>
						<input type="file" name="upload-image" id="upload-image" ref={inputFile} accept="image/*,application/pdf" style={{ display: "none" }} onChange={handleFileChange} />
					</div>
				</div>
			</div>
		) : (
			<div></div>
		);
	}

	function PopupCancel2(props) {
		return props.trigger ? (
			<div className="pop-up">
				<div className="pop-up-data">
					{props.children}
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<button className="pop-up-button" style={{ marginRight: "10px" }} onClick={() => cancelTransaction(transactionId)}>
							OK
						</button>
						<button className="pop-up-button" style={{ marginLeft: "10px" }} onClick={() => [setCancelPopUp2(false)]}>
							Cancel
						</button>
					</div>
				</div>
			</div>
		) : (
			<div></div>
		);
	}

	function PopupUndoCancel2(props) {
		return props.trigger ? (
			<div className="pop-up">
				<div className="pop-up-data">
					{props.children}
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<button className="pop-up-button" style={{ marginRight: "10px" }} onClick={() => [setUndoCancelPopUp2(false), setUndoCancelPopUpRemark2(true)]}>
							OK
						</button>
						<button className="pop-up-button" style={{ marginLeft: "10px" }} onClick={() => [setUndoCancelPopUp2(false)]}>
							Cancel
						</button>
					</div>
				</div>
			</div>
		) : (
			<div></div>
		);
	}

	function PopupUndoCancelRemark2(props) {
		return props.trigger ? (
			<div className="pop-up">
				<div className="pop-up-data">
					{props.children}
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<button className="pop-up-button" style={{ marginRight: "10px" }} onClick={() => undoCancelTransaction(transactionId)}>
							Yes
						</button>
						<button className="pop-up-button" style={{ marginLeft: "10px" }} onClick={() => [setUndoCancelPopUpRemark2(false)]}>
							No
						</button>
					</div>
				</div>
			</div>
		) : (
			<div></div>
		);
	}

	function PopupCancel3(props) {
		return props.trigger ? (
			<div className="pop-up">
				<div className="pop-up-data">
					{props.children}
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<button className="pop-up-button" style={{ marginRight: "10px" }} onClick={() => cancelTransaction(transactionId)}>
							OK
						</button>
						<button className="pop-up-button" style={{ marginLeft: "10px" }} onClick={() => [setCancelPopUp3(false)]}>
							Cancel
						</button>
					</div>
				</div>
			</div>
		) : (
			<div></div>
		);
	}

	function PopupUndoCancel3(props) {
		return props.trigger ? (
			<div className="pop-up">
				<div className="pop-up-data">
					{props.children}
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<button className="pop-up-button" style={{ marginRight: "10px" }} onClick={() => [setUndoCancelPopUp3(false), setUndoCancelPopUpRemark3(true)]}>
							Yes
						</button>
						<button className="pop-up-button" style={{ marginLeft: "10px" }} onClick={() => [setUndoCancelPopUp3(false)]}>
							No
						</button>
					</div>
				</div>
			</div>
		) : (
			<div></div>
		);
	}

	function PopupUndoCancelRemark3(props) {
		return props.trigger ? (
			<div className="pop-up">
				<div className="pop-up-data">
					{props.children}
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<button className="pop-up-button" style={{ marginRight: "10px" }} onClick={() => undoCancelTransaction(transactionId)}>
							Yes
						</button>
						<button className="pop-up-button" style={{ marginLeft: "10px" }} onClick={() => [setUndoCancelPopUpRemark3(false)]}>
							No
						</button>
					</div>
				</div>
			</div>
		) : (
			<div></div>
		);
	}

	const cancelTransaction = async (value) => {
		let savedRemark = "";
		if (cancelPopUp2 === true) {
			savedRemark = input.remark2.value;
		}
		if (cancelPopUp3 === true) {
			savedRemark = input.remark3.value;
		}
		let id = "";
		id = value.id;
		// console.log(id)
		let token = null;
		token = localStorage.getItem("@storage_token");
		fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.CANCEL_TRANSACTION, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				"Accept-language": "en",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify({
				id: id,
				remark: savedRemark,
			}),
		})
			.then(function (response) {
				return response.json();
			})
			.then(function (data) {
				const items = data;
				// console.log(items)
				if (items.responseCode === 200) {
					setCancelPopUp2(false);
					setCancelPopUp3(false);
					savedRemark = "";
					setInput({
						remark2: { value: "" },
						remark3: { value: "" },
					});
					// history.go(0);
					fetchReceivablesData();
					fetchDisbursementData();
					fetchAllTransactionData();
					messageDialog({
						isOpen: true,
						condition: CONSTANSTS.DIALOG.success,
						title: CONSTANSTS.DIALOG_TITLE.general_success,
						message: items.message,
						defaultOnClick: false,
					});
				} else {
					messageDialog({
						isOpen: true,
						condition: CONSTANSTS.DIALOG.failed,
						title: CONSTANSTS.DIALOG_TITLE.general_failed,
						message: items.message,
						defaultOnClick: false,
					});
				}
			});
	};

	const undoCancelTransaction = async (value) => {
		let savedRemark = "";
		if (undoCancelPopUpRemark2 === true) {
			savedRemark = input.remark2.value;
		}
		if (undoCancelPopUpRemark3 === true) {
			savedRemark = input.remark3.value;
		}
		let id = "";
		id = value.id;
		let token = null;
		token = localStorage.getItem("@storage_token");
		fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.UNDO_CANCEL_INVOICE, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				"Accept-language": "en",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify({
				id: id,
				remark: savedRemark,
			}),
		})
			.then(function (response) {
				return response.json();
			})
			.then(function (data) {
				const items = data;
				// console.log(items)
				if (items.responseCode === 200) {
					setUndoCancelPopUpRemark2(false);
					setUndoCancelPopUpRemark3(false);
					savedRemark = "";
					setInput({
						remark2: { value: "" },
						remark3: { value: "" },
					});
					// history.go(0);
					fetchReceivablesData();
					fetchDisbursementData();
					fetchAllTransactionData();
					messageDialog({
						isOpen: true,
						condition: CONSTANSTS.DIALOG.success,
						title: CONSTANSTS.DIALOG_TITLE.general_success,
						message: items.message,
						defaultOnClick: false,
					});
				} else {
					messageDialog({
						isOpen: true,
						condition: CONSTANSTS.DIALOG.failed,
						title: CONSTANSTS.DIALOG_TITLE.general_failed,
						message: items.message,
						defaultOnClick: false,
					});
				}
			});
	};
	const [paginationLocker, setPaginationLocker] = useState(true);
	const [exportBtn1, setExportBtn1] = useState("orange");
	const [exportBtn2, setExportBtn2] = useState("orange");
	const [exportBtn3, setExportBtn3] = useState("orange");
	const [statusList, setStatusList] = useState(undefined);

	// useEffect(() => {
	//   if(role === CONSTANSTS.ROLES.finance || role === CONSTANSTS.ROLES.superAdmin) {
	//     const fetchStatus = async () => {
	//       fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.COMMON_FIND_DROPDOWN_OPTION + "transactionStatus", {
	//         method: "GET",
	//         headers: {
	//           "Content-Type": "application/json",
	//           Accept: "*/*",
	//           "Accept-language": "en",
	//         },
	//       })
	//         .then((response) => response.json())
	//         .then((json) => {
	//           let res = json.result;
	//           setStatusList(res);
	//           // console.log("status:", res)
	//         });
	//     };
	//     if (tabCol1 === "tab-selected") {
	//       console.log("tableSearchFilter1", JSON.stringify(tableSearchFilter1));
	//       fetchReceivablesData();
	//     } else if (tabCol2 === "tab-selected") {
	//       console.log("tableSearchFilter2", JSON.stringify(tableSearchFilter2));
	//       fetchDisbursementData();
	//     } else {
	//       console.log("tableSearchFilter3", JSON.stringify(tableSearchFilter3));
	//       fetchAllTransactionData();
	//     }
	//     fetchStatus();
	//   }
	// }, [tableSearchFilter1, tableSearchFilter2, tableSearchFilter3]);

	const fetchReceivablesData = () => {
		let token = null;
		token = localStorage.getItem("@storage_token");
		fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.RECEIVABLE_TRANSACTION_LIST, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"Accept-language": "en",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify({
				criteria: {
					dateFrom: tableSearchFilter1.dateFrom,
					dateRangeType: tableSearchFilter1.dateRangeType,
					dateTo: tableSearchFilter1.dateTo,
					keyword: tableSearchFilter1.keyword,
					status: tableSearchFilter1.status,
				},
				pageNumber: tableSearchFilter1.pageNo,
				pageSize: tableSearchFilter1.pageSize,
				sortDirection: tableSearchFilter1.sortDir,
				sortKey: tableSearchFilter1.sortKey,
			}),
		})
			.then(function (response) {
				return response.json();
			})
			.then(function (data) {
				const response = data;

				// console.log(response)
				if (response.responseCode === 200) {
					// appToast(response.message, true);
					setReceivablesList(response.result);
					setPaginationLocker(false);
					if (response.result.totalCount === 0) {
						setExportBtn1("dimmed");
					} else {
						setExportBtn1("orange");
					}
				} else {
					messageDialog({
						isOpen: true,
						condition: CONSTANSTS.DIALOG.failed,
						title: CONSTANSTS.DIALOG_TITLE.general_failed,
						message: response.message,
						defaultOnClick: false,
					});
				}
			});
	};

	const fetchDisbursementData = () => {
		let token = null;
		token = localStorage.getItem("@storage_token");
		fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.DISBURSEMENT_TRANSACTION_LIST, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"Accept-language": "en",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify({
				criteria: {
					dateFrom: tableSearchFilter2.dateFrom,
					dateRangeType: tableSearchFilter2.dateRangeType,
					dateTo: tableSearchFilter2.dateTo,
					keyword: tableSearchFilter2.keyword,
					status: tableSearchFilter2.status,
				},
				pageNumber: tableSearchFilter2.pageNo,
				pageSize: tableSearchFilter2.pageSize,
				sortDirection: tableSearchFilter2.sortDir,
				sortKey: tableSearchFilter2.sortKey,
			}),
		})
			.then(function (response) {
				return response.json();
			})
			.then(function (data) {
				const response = data;
				// console.log(response)
				if (response.responseCode === 200) {
					// appToast(response.message, true);
					setDisbursementList(response.result);
					setPaginationLocker(false);
					if (response.result.totalCount === 0) {
						setExportBtn2("dimmed");
					} else {
						setExportBtn2("orange");
					}
				} else {
					messageDialog({
						isOpen: true,
						condition: CONSTANSTS.DIALOG.failed,
						title: CONSTANSTS.DIALOG_TITLE.general_failed,
						message: response.message,
						defaultOnClick: false,
					});
				}
			});
	};

	const fetchAllTransactionData = () => {
		let token = null;
		token = localStorage.getItem("@storage_token");
		fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.PAYMENT_TRANSACTION_LIST, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"Accept-language": "en",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify({
				criteria: {
					dateFrom: tableSearchFilter3.dateFrom,
					dateRangeType: tableSearchFilter3.dateRangeType,
					dateTo: tableSearchFilter3.dateTo,
					keyword: tableSearchFilter3.keyword,
					status: tableSearchFilter3.status,
				},
				pageNumber: tableSearchFilter3.pageNo,
				pageSize: tableSearchFilter3.pageSize,
				sortDirection: tableSearchFilter3.sortDir,
				sortKey: tableSearchFilter3.sortKey,
			}),
		})
			.then(function (response) {
				return response.json();
			})
			.then(function (data) {
				const response = data;
				// console.log(response)
				if (response.responseCode === 200) {
					// appToast(response.message, true);
					setTransactionList(response.result);
					setPaginationLocker(false);
					if (response.result.totalCount === 0) {
						setExportBtn3("dimmed");
					} else {
						setExportBtn3("orange");
					}
				} else {
					messageDialog({
						isOpen: true,
						condition: CONSTANSTS.DIALOG.failed,
						title: CONSTANSTS.DIALOG_TITLE.general_failed,
						message: response.message,
						defaultOnClick: false,
					});
				}
			});
	};

	let selectedTab1 = "tab1";
	let selectedTab2 = "tab2";
	if (sessionStorage.getItem("disbursementTab") === "2tab") {
		selectedTab2 = "tab-selected";
		selectedTab1 = "tab1";
		setTimeout(() => {
			sessionStorage.removeItem("disbursementTab");
		}, 1000);
	} else {
		selectedTab1 = "tab-selected";
		selectedTab2 = "tab2";
		setTimeout(() => {
			sessionStorage.removeItem("disbursementTab");
		}, 1000);
	}

	const [tabCol1, setTabCol1] = useState(selectedTab1);
	const [tabCol2, setTabCol2] = useState(selectedTab2);
	const [tabCol3, setTabCol3] = useState("tab3");
	const tabClicked = (e) => {
		var tabNo = e;
		if (tabNo === "1") {
			setTabCol1("tab-selected");
			setTabCol2("tab2");
			setTabCol3("tab3");
			fetchReceivablesData();
		}
		if (tabNo === "2") {
			setTabCol1("tab1");
			setTabCol2("tab-selected");
			setTabCol3("tab3");
			fetchDisbursementData();
		}
		if (tabNo === "3") {
			setTabCol1("tab1");
			setTabCol2("tab2");
			setTabCol3("tab-selected");
			fetchAllTransactionData();
		}
	};

	const onKeyUp1 = (event) => {
		if (event.key === "Enter") {
			console.log("enter press here! ", event);
		}
	};

	const onKeyUp2 = (event) => {
		if (event.key === "Enter") {
			console.log("enter press here! ", event);
		}
	};

	const onKeyUp3 = (event) => {
		if (event.key === "Enter") {
			console.log("enter press here! ", event);
		}
	};
	const [searchValue, setSearchValue] = useState("");
	const onBlur = (e) => {
		setSearchValue(e.target.value);
		console.log("searchValue" + e.target.value);
	};

	const onHandleSearch = (type) => {
		if (type === "Receivables") {
			setTableSearchFilter1({
				pageNo: tableSearchFilter1.pageNo,
				pageSize: tableSearchFilter1.pageSize,
				sortKey: tableSearchFilter1.sortKey,
				sortDir: tableSearchFilter1.sortDir,
				dateRangeType: selected1 == "- Select Range -" ? null : selected1,
				dateFrom: tableSearchFilter1.dateFrom != "string" ? tableSearchFilter1.dateFrom : "string",
				dateTo: tableSearchFilter1.dateTo != "string" ? tableSearchFilter1.dateTo : "string",
				keyword: searchValue,
				status: status1 == "- Select Status -" ? null : status1,
			});
		} else if (type === "Disbursement") {
			setTableSearchFilter2({
				pageNo: tableSearchFilter2.pageNo,
				pageSize: tableSearchFilter2.pageSize,
				sortKey: tableSearchFilter2.sortKey,
				sortDir: tableSearchFilter2.sortDir,
				dateRangeType: selected2 == "- Select Range -" ? null : selected2,
				dateFrom: tableSearchFilter2.dateFrom != "string" ? tableSearchFilter2.dateFrom : "string",
				dateTo: tableSearchFilter2.dateTo != "string" ? tableSearchFilter2.dateTo : "string",
				keyword: searchValue,
				status: status2 == "- Select Status -" ? null : status2,
			});
		} else if (type === "All Transaction") {
			setTableSearchFilter3({
				pageNo: tableSearchFilter3.pageNo,
				pageSize: tableSearchFilter3.pageSize,
				sortKey: tableSearchFilter3.sortKey,
				sortDir: tableSearchFilter3.sortDir,
				dateRangeType: selected3 == "- Select Range -" ? null : selected3,
				dateFrom: tableSearchFilter3.dateFrom != "string" ? tableSearchFilter3.dateFrom : "string",
				dateTo: tableSearchFilter3.dateTo != "string" ? tableSearchFilter3.dateTo : "string",
				keyword: searchValue,
				status: status3 == "- Select Status -" ? null : status3,
			});
		}
		console.log(type);
	};
	/*
  var date = new Date().toLocaleString("ms", { hour12: false }).split(" ");
  var mdy = date[0];
  mdy = mdy.split("/");
  var day = parseInt(mdy[0]);
  var month = parseInt(mdy[1]);
  var year = parseInt(mdy[2]);
  var formattedDate = "";
  if (day < 10 && month > 10) {
    formattedDate = "0" + day + "" + month + "" + year;
  }
  if (month < 10 && day > 10) {
    formattedDate = "" + day + "0" + month + "" + year;
  }
  if (day < 10 && month < 10) {
    formattedDate = "0" + day + "0" + month + "" + year;
  }
  const [currentDate] = useState(formattedDate.toString());
  */

	const exportReceivablesList = (btn) => {
		if (btn === "orange") {
			let token = null;
			token = localStorage.getItem("@storage_token");
			fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.EXPORT_RECEIVABLE_TRANSACTION_LIST, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "*/*",
					"Accept-language": "en",
					Authorization: "Bearer " + token,
				},
				body: JSON.stringify({
					criteria: {
						dateFrom: tableSearchFilter1.dateFrom,
						dateRangeType: tableSearchFilter1.dateRangeType,
						dateTo: tableSearchFilter1.dateTo,
						keyword: tableSearchFilter1.keyword,
						status: tableSearchFilter1.status,
					},
					pageNumber: tableSearchFilter1.pageNo,
					pageSize: tableSearchFilter1.pageSize,
					sortDirection: tableSearchFilter1.sortDir,
					sortKey: tableSearchFilter1.sortKey,
				}),
			}).then((response) => {
				let filename = response.headers.get("Content-Disposition");
				if (filename != null) {
					filename = filename.split(";")[1].split("filename=")[1];
				}
				response.blob().then((blob) => {
					console.log(blob);
					let url = window.URL.createObjectURL(blob);
					let a = document.createElement("a");
					a.href = url;
					// a.download = "Receivable_Transaction_List_" + currentDate + ".xlsx";
					a.download = filename;
					a.click();
				});
			});
		} else {
			return;
		}
	};
	const exportDisbursementList = (btn) => {
		if (btn === "orange") {
			let token = null;
			token = localStorage.getItem("@storage_token");
			fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.EXPORT_DISBURSEMENT_TRANSACTION_LIST, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "*/*",
					"Accept-language": "en",
					Authorization: "Bearer " + token,
				},
				body: JSON.stringify({
					criteria: {
						dateFrom: tableSearchFilter2.dateFrom,
						dateRangeType: tableSearchFilter2.dateRangeType,
						dateTo: tableSearchFilter2.dateTo,
						keyword: tableSearchFilter2.keyword,
						status: tableSearchFilter2.status,
					},
					pageNumber: tableSearchFilter2.pageNo,
					pageSize: tableSearchFilter2.pageSize,
					sortDirection: tableSearchFilter2.sortDir,
					sortKey: tableSearchFilter2.sortKey,
				}),
			}).then((response) => {
				let filename = response.headers.get("Content-Disposition");
				if (filename != null) {
					filename = filename.split(";")[1].split("filename=")[1];
				}
				response.blob().then((blob) => {
					console.log(blob);
					let url = window.URL.createObjectURL(blob);
					let a = document.createElement("a");
					a.href = url;
					// a.download = "Disbursement_Transaction_List_" + currentDate + ".xlsx";
					a.download = filename;
					a.click();
				});
			});
		} else {
			return;
		}
	};
	const exportTransactionList = (btn) => {
		if (btn === "orange") {
			let token = null;
			token = localStorage.getItem("@storage_token");
			fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.EXPORT_TRANSACTION_LIST, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "*/*",
					"Accept-language": "en",
					Authorization: "Bearer " + token,
				},
				body: JSON.stringify({
					criteria: {
						dateFrom: tableSearchFilter3.dateFrom,
						dateRangeType: tableSearchFilter3.dateRangeType,
						dateTo: tableSearchFilter3.dateTo,
						keyword: tableSearchFilter3.keyword,
						status: tableSearchFilter3.status,
					},
					pageNumber: tableSearchFilter3.pageNo,
					pageSize: tableSearchFilter3.pageSize,
					sortDirection: tableSearchFilter3.sortDir,
					sortKey: tableSearchFilter3.sortKey,
				}),
			}).then((response) => {
				let filename = response.headers.get("Content-Disposition");
				if (filename != null) {
					filename = filename.split(";")[1].split("filename=")[1];
				}
				response.blob().then((blob) => {
					console.log(blob);
					let url = window.URL.createObjectURL(blob);
					let a = document.createElement("a");
					a.href = url;
					// a.download = "Payment_Transaction_List_" + currentDate + ".xlsx";
					a.download = filename;
					a.click();
				});
			});
		} else {
			return;
		}
	};

	const onFocusDateStart1 = () => {
		setDateType1([
			{
				type: "date",
				placeholder: dateType1[0].placeholder,
				value: dateType1[0].value,
			},
			{
				type: "date",
				placeholder: dateType1[1].placeholder,
				value: dateType1[1].value,
			},
		]);
	};

	const onFocusDateEnd1 = () => {
		setDateType1([
			{
				type: "date",
				placeholder: dateType1[0].placeholder,
				value: dateType1[0].value,
			},
			{
				type: "date",
				placeholder: dateType1[1].placeholder,
				value: dateType1[1].value,
			},
		]);
	};

	const onFocusDateStart2 = () => {
		setDateType2([
			{
				type: "date",
				placeholder: dateType2[0].placeholder,
				value: dateType2[0].value,
			},
			{
				type: "date",
				placeholder: dateType2[1].placeholder,
				value: dateType2[1].value,
			},
		]);
	};

	const onFocusDateEnd2 = () => {
		setDateType2([
			{
				type: "date",
				placeholder: dateType2[0].placeholder,
				value: dateType2[0].value,
			},
			{
				type: "date",
				placeholder: dateType2[1].placeholder,
				value: dateType2[1].value,
			},
		]);
	};

	const onFocusDateStart3 = () => {
		setDateType3([
			{
				type: "date",
				placeholder: dateType3[0].placeholder,
				value: dateType3[0].value,
			},
			{
				type: "date",
				placeholder: dateType3[1].placeholder,
				value: dateType3[1].value,
			},
		]);
	};

	const onFocusDateEnd3 = () => {
		setDateType3([
			{
				type: "date",
				placeholder: dateType3[0].placeholder,
				value: dateType3[0].value,
			},
			{
				type: "date",
				placeholder: dateType3[1].placeholder,
				value: dateType3[1].value,
			},
		]);
	};

	const dateFields = {
		dateRange: null,
		dateFrom: "",
		dateTo: "",
	};

	const inputDateProp = [
		{ type: "text", placeholder: "Start Date", value: "" },
		{ type: "text", placeholder: "End Date", value: "" },
	];

	const [dateInput1, setDateInput1] = useState(dateFields);
	const [dateInput2, setDateInput2] = useState(dateFields);
	const [dateInput3, setDateInput3] = useState(dateFields);
	const [selected1, setSelected1] = useState(null);
	const [selected2, setSelected2] = useState(null);
	const [selected3, setSelected3] = useState(null);
	const [dateType1, setDateType1] = useState(inputDateProp);
	const [dateType2, setDateType2] = useState(inputDateProp);
	const [dateType3, setDateType3] = useState(inputDateProp);
	const [minDate1, setMinDate1] = useState("");
	const [maxDate1, setMaxDate1] = useState("");
	const [minDate2, setMinDate2] = useState("");
	const [maxDate2, setMaxDate2] = useState("");
	const [minDate3, setMinDate3] = useState("");
	const [maxDate3, setMaxDate3] = useState("");

	const onHandleOnChange1 = (event) => {
		var value = event.currentTarget.value;
		// if (value === "") {
		//     setSelected1({ selected: null })
		// }
		if (value === "- Select Range -") {
			setSelected1(null);
			value = null;
		} else {
			setSelected1(value);
		}

		setDateType1(inputDateProp);
		setMinDate1("");
		setMaxDate1("");

		setTableSearchFilter1({
			pageNo: 1,
			pageSize: tableSearchFilter1.pageSize,
			sortKey: tableSearchFilter1.sortKey,
			sortDir: tableSearchFilter1.sortDir,
			dateRangeType: value,
			dateFrom: "string",
			dateTo: "string",
			keyword: tableSearchFilter1.keyword,
			status: tableSearchFilter1.status,
		});
	};

	const onChangeDateStart1 = (event) => {
		dateType1[0].value = event.currentTarget.value.toString();
		const value = event.currentTarget.value.toString();
		console.log("start date", value);
		setMinDate1(value);
		let date = value;
		let dateSplit = date.split("-");
		console.log(dateSplit[2] + "/" + dateSplit[1] + "/" + dateSplit[0]);
		setDateInput1({ dateFrom: dateSplit[2] + "/" + dateSplit[1] + "/" + dateSplit[0], dateTo: dateInput1.dateTo });
		setSelected1("- Select Range -");

		if (maxDate1 !== "") {
			setTableSearchFilter1({
				pageNo: 1,
				pageSize: tableSearchFilter1.pageSize,
				sortKey: tableSearchFilter1.sortKey,
				sortDir: tableSearchFilter1.sortDir,
				dateRangeType: null,
				dateFrom: dateSplit[2] + "/" + dateSplit[1] + "/" + dateSplit[0],
				dateTo: dateInput1.dateTo,
				keyword: tableSearchFilter1.keyword,
				status: tableSearchFilter1.status,
			});
		}
	};

	const onChangeDateEnd1 = (event) => {
		dateType1[1].value = event.currentTarget.value.toString();
		const value = event.currentTarget.value.toString();
		console.log("end date ", value);
		setMaxDate1(value);
		let date = value;
		let dateSplit = date.split("-");
		console.log(dateSplit[2] + "/" + dateSplit[1] + "/" + dateSplit[0]);
		setDateInput1({ dateFrom: dateInput1.dateFrom, dateTo: dateSplit[2] + "/" + dateSplit[1] + "/" + dateSplit[0] });
		setSelected1("- Select Range -");

		if (minDate1 !== "") {
			setTableSearchFilter1({
				pageNo: 1,
				pageSize: tableSearchFilter1.pageSize,
				sortKey: tableSearchFilter1.sortKey,
				sortDir: tableSearchFilter1.sortDir,
				dateRangeType: null,
				dateFrom: dateInput1.dateFrom,
				dateTo: dateSplit[2] + "/" + dateSplit[1] + "/" + dateSplit[0],
				keyword: tableSearchFilter1.keyword,
				status: tableSearchFilter1.status,
			});
		}
	};

	const onHandleOnChange2 = (event) => {
		var value = event.currentTarget.value;
		// if (value === "") {
		//     setSelected2({ selected: null })
		// }
		if (value === "- Select Range -") {
			setSelected2(null);
			value = null;
		} else {
			setSelected2(value);
		}

		setDateType2(inputDateProp);
		setMinDate2("");
		setMaxDate2("");

		setTableSearchFilter2({
			pageNo: 1,
			pageSize: tableSearchFilter2.pageSize,
			sortKey: tableSearchFilter2.sortKey,
			sortDir: tableSearchFilter2.sortDir,
			dateRangeType: value,
			dateFrom: "string",
			dateTo: "string",
			keyword: tableSearchFilter2.keyword,
			status: tableSearchFilter2.status,
		});
	};

	const onChangeDateStart2 = (event) => {
		dateType2[0].value = event.currentTarget.value.toString();
		const value = event.currentTarget.value.toString();
		console.log("start date", value);
		setMinDate2(value);
		let date = value;
		let dateSplit = date.split("-");
		console.log(dateSplit[2] + "/" + dateSplit[1] + "/" + dateSplit[0]);
		setDateInput2({ dateFrom: dateSplit[2] + "/" + dateSplit[1] + "/" + dateSplit[0], dateTo: dateInput2.dateTo });
		setSelected2("- Select Range -");

		if (maxDate2 !== "") {
			setTableSearchFilter2({
				pageNo: 1,
				pageSize: tableSearchFilter2.pageSize,
				sortKey: tableSearchFilter2.sortKey,
				sortDir: tableSearchFilter2.sortDir,
				dateRangeType: null,
				dateFrom: dateSplit[2] + "/" + dateSplit[1] + "/" + dateSplit[0],
				dateTo: dateInput2.dateTo,
				keyword: tableSearchFilter2.keyword,
				status: tableSearchFilter2.status,
			});
		}
	};

	const onChangeDateEnd2 = (event) => {
		dateType2[1].value = event.currentTarget.value.toString();
		const value = event.currentTarget.value.toString();
		console.log("end date ", value);
		setMaxDate2(value);
		let date = value;
		let dateSplit = date.split("-");
		console.log(dateSplit[2] + "/" + dateSplit[1] + "/" + dateSplit[0]);
		setDateInput2({ dateFrom: dateInput2.dateFrom, dateTo: dateSplit[2] + "/" + dateSplit[1] + "/" + dateSplit[0] });
		setSelected2("- Select Range -");

		if (minDate2 !== "") {
			setTableSearchFilter2({
				pageNo: 1,
				pageSize: tableSearchFilter2.pageSize,
				sortKey: tableSearchFilter2.sortKey,
				sortDir: tableSearchFilter2.sortDir,
				dateRangeType: null,
				dateFrom: dateInput2.dateFrom,
				dateTo: dateSplit[2] + "/" + dateSplit[1] + "/" + dateSplit[0],
				keyword: tableSearchFilter2.keyword,
				status: tableSearchFilter2.status,
			});
		}
	};

	const onHandleOnChange3 = (event) => {
		var value = event.currentTarget.value;
		// if (value === "") {
		//     setSelected3({ selected: null })
		// }
		if (value === "- Select Range -") {
			setSelected3(null);
			value = null;
		} else {
			setSelected3(value);
		}

		setDateType3(inputDateProp);
		setMinDate3("");
		setMaxDate3("");
		setTableSearchFilter3({
			pageNo: 1,
			pageSize: tableSearchFilter3.pageSize,
			sortKey: tableSearchFilter3.sortKey,
			sortDir: tableSearchFilter3.sortDir,
			dateRangeType: value,
			dateFrom: "string",
			dateTo: "string",
			keyword: tableSearchFilter3.keyword,
			status: tableSearchFilter3.status,
		});
	};

	const onChangeDateStart3 = (event) => {
		dateType3[0].value = event.currentTarget.value.toString();
		const value = event.currentTarget.value.toString();
		console.log("start date", value);
		setMinDate3(value);
		let date = value;
		let dateSplit = date.split("-");
		console.log(dateSplit[2] + "/" + dateSplit[1] + "/" + dateSplit[0]);
		setDateInput3({ dateFrom: dateSplit[2] + "/" + dateSplit[1] + "/" + dateSplit[0], dateTo: dateInput3.dateTo });
		setSelected3("- Select Range -");

		if (maxDate1 !== "") {
			setTableSearchFilter3({
				pageNo: 1,
				pageSize: tableSearchFilter3.pageSize,
				sortKey: tableSearchFilter3.sortKey,
				sortDir: tableSearchFilter3.sortDir,
				dateRangeType: null,
				dateFrom: dateSplit[2] + "/" + dateSplit[1] + "/" + dateSplit[0],
				dateTo: dateInput3.dateTo,
				keyword: tableSearchFilter3.keyword,
				status: tableSearchFilter3.status,
			});
		}
	};

	const onChangeDateEnd3 = (event) => {
		dateType3[1].value = event.currentTarget.value.toString();
		const value = event.currentTarget.value.toString();
		console.log("end date ", value);
		setMaxDate3(value);
		let date = value;
		let dateSplit = date.split("-");
		console.log(dateSplit[2] + "/" + dateSplit[1] + "/" + dateSplit[0]);
		setDateInput3({ dateFrom: dateInput3.dateFrom, dateTo: dateSplit[2] + "/" + dateSplit[1] + "/" + dateSplit[0] });
		setSelected3("- Select Range -");

		if (minDate3 !== "") {
			setTableSearchFilter3({
				pageNo: 1,
				pageSize: tableSearchFilter3.pageSize,
				sortKey: tableSearchFilter3.sortKey,
				sortDir: tableSearchFilter3.sortDir,
				dateRangeType: null,
				dateFrom: dateInput3.dateFrom,
				dateTo: dateSplit[2] + "/" + dateSplit[1] + "/" + dateSplit[0],
				keyword: tableSearchFilter3.keyword,
				status: tableSearchFilter3.status,
			});
		}
	};

	const nextPage1 = async () => {
		if (receivablesList.currentPageNumber < receivablesList.totalPageNumber && !paginationLocker) {
			setTableSearchFilter1({
				pageNo: tableSearchFilter1.pageNo + 1,
				pageSize: tableSearchFilter1.pageSize,
				sortKey: tableSearchFilter1.sortKey,
				sortDir: tableSearchFilter1.sortDir,
				dateRangeType: tableSearchFilter1.dateRangeType,
				dateFrom: tableSearchFilter1.dateFrom,
				dateTo: tableSearchFilter1.dateTo,
				keyword: tableSearchFilter1.keyword,
				status: tableSearchFilter1.status,
			});
			setPaginationLocker(true);
		} else if (receivablesList.currentPageNumber === receivablesList.totalPageNumber) {
			setPaginationLocker(false);
		}
	};

	const previousPage1 = async () => {
		if (receivablesList.currentPageNumber > 1 && !paginationLocker) {
			setTableSearchFilter1({
				pageNo: tableSearchFilter1.pageNo - 1,
				pageSize: tableSearchFilter1.pageSize,
				sortKey: tableSearchFilter1.sortKey,
				sortDir: tableSearchFilter1.sortDir,
				dateRangeType: tableSearchFilter1.dateRangeType,
				dateFrom: tableSearchFilter1.dateFrom,
				dateTo: tableSearchFilter1.dateTo,
				keyword: tableSearchFilter1.keyword,
				status: tableSearchFilter1.status,
			});
			setPaginationLocker(true);
		} else if (receivablesList.currentPageNumber === 1) {
			setPaginationLocker(false);
		}
	};

	const nextPage2 = async () => {
		if (disbursementList.currentPageNumber < disbursementList.totalPageNumber && !paginationLocker) {
			setTableSearchFilter2({
				pageNo: tableSearchFilter2.pageNo + 1,
				pageSize: tableSearchFilter2.pageSize,
				sortKey: tableSearchFilter2.sortKey,
				sortDir: tableSearchFilter2.sortDir,
				dateRangeType: tableSearchFilter2.dateRangeType,
				dateFrom: tableSearchFilter2.dateFrom,
				dateTo: tableSearchFilter2.dateTo,
				keyword: tableSearchFilter2.keyword,
				status: tableSearchFilter2.status,
			});
			setPaginationLocker(true);
		} else if (disbursementList.currentPageNumber === disbursementList.totalPageNumber && !paginationLocker) {
			setPaginationLocker(false);
		}
	};

	const previousPage2 = async () => {
		if (disbursementList.currentPageNumber > 1 && !paginationLocker) {
			setTableSearchFilter2({
				pageNo: tableSearchFilter2.pageNo - 1,
				pageSize: tableSearchFilter2.pageSize,
				sortKey: tableSearchFilter2.sortKey,
				sortDir: tableSearchFilter2.sortDir,
				dateRangeType: tableSearchFilter2.dateRangeType,
				dateFrom: tableSearchFilter2.dateFrom,
				dateTo: tableSearchFilter2.dateTo,
				keyword: tableSearchFilter2.keyword,
				status: tableSearchFilter2.status,
			});
			setPaginationLocker(true);
		} else if (disbursementList.currentPageNumber === 1) {
			setPaginationLocker(false);
		}
	};

	const nextPage3 = async () => {
		if (transactionList.currentPageNumber < transactionList.totalPageNumber && !paginationLocker) {
			setTableSearchFilter3({
				pageNo: tableSearchFilter3.pageNo + 1,
				pageSize: tableSearchFilter3.pageSize,
				sortKey: tableSearchFilter3.sortKey,
				sortDir: tableSearchFilter3.sortDir,
				dateRangeType: tableSearchFilter3.dateRangeType,
				dateFrom: tableSearchFilter3.dateFrom,
				dateTo: tableSearchFilter3.dateTo,
				keyword: tableSearchFilter3.keyword,
				status: tableSearchFilter3.status,
			});
			setPaginationLocker(true);
		} else if (transactionList.currentPageNumber === transactionList.totalPageNumber) {
			setPaginationLocker(false);
		}
	};

	const previousPage3 = async () => {
		if (transactionList.currentPageNumber > 1 && !paginationLocker) {
			setTableSearchFilter3({
				pageNo: tableSearchFilter3.pageNo - 1,
				pageSize: tableSearchFilter3.pageSize,
				sortKey: tableSearchFilter3.sortKey,
				sortDir: tableSearchFilter3.sortDir,
				dateRangeType: tableSearchFilter3.dateRangeType,
				dateFrom: tableSearchFilter3.dateFrom,
				dateTo: tableSearchFilter3.dateTo,
				keyword: tableSearchFilter3.keyword,
				status: tableSearchFilter3.status,
			});
			setPaginationLocker(true);
		} else if (transactionList.currentPageNumber === 1) {
			setPaginationLocker(false);
		}
	};

	const inputRemark = {
		remark1: {
			value: "",
			error: false,
		},
		remark2: {
			value: "",
			error: false,
		},
		remark3: {
			value: "",
			error: false,
		},
	};

	const [status1, setStatus1] = useState(null);
	const [status2, setStatus2] = useState(null);
	const [status3, setStatus3] = useState(null);
	const OnChangeStatus1 = (event) => {
		var value = event.currentTarget.value;
		if (value === "- Select Status -") {
			setStatus1(null);
			value = null;
		} else {
			setStatus1(value);
		}

		// setDateType3(inputDateProp);
		// setMinDate3("");
		// setMaxDate3("");
		setTableSearchFilter1({
			pageNo: 1,
			pageSize: tableSearchFilter1.pageSize,
			sortKey: tableSearchFilter1.sortKey,
			sortDir: tableSearchFilter1.sortDir,
			dateRangeType: tableSearchFilter1.dateRangeType,
			dateFrom: "string",
			dateTo: "string",
			keyword: tableSearchFilter1.keyword,
			status: value,
		});
	};
	const OnChangeStatus2 = (event) => {
		var value = event.currentTarget.value;
		if (value === "- Select Status -") {
			setStatus2(null);
			value = null;
		} else {
			setStatus2(value);
		}

		// setDateType3(inputDateProp);
		// setMinDate3("");
		// setMaxDate3("");
		setTableSearchFilter2({
			pageNo: 1,
			pageSize: tableSearchFilter2.pageSize,
			sortKey: tableSearchFilter2.sortKey,
			sortDir: tableSearchFilter2.sortDir,
			dateRangeType: tableSearchFilter2.dateRangeType,
			dateFrom: "string",
			dateTo: "string",
			keyword: tableSearchFilter2.keyword,
			status: value,
		});
	};
	const OnChangeStatus3 = (event) => {
		var value = event.currentTarget.value;
		if (value === "- Select Status -") {
			setStatus3(null);
			value = null;
		} else {
			setStatus3(value);
		}

		// setDateType3(inputDateProp);
		// setMinDate3("");
		// setMaxDate3("");
		setTableSearchFilter3({
			pageNo: 1,
			pageSize: tableSearchFilter3.pageSize,
			sortKey: tableSearchFilter3.sortKey,
			sortDir: tableSearchFilter3.sortDir,
			dateRangeType: tableSearchFilter3.dateRangeType,
			dateFrom: "string",
			dateTo: "string",
			keyword: tableSearchFilter3.keyword,
			status: value,
		});
	};

	const [input, setInput] = useState(inputRemark);

	// const handleOnChange = (event) => {
	//     const name = event.currentTarget.name;
	//     const value = event.currentTarget.value;

	//     setInput({
	//         ...input,
	//         [name]: {
	//             ...input[name],
	//             value: value,
	//             error: false,
	//         },
	//     });

	// }

	const handleOnChange2 = (event) => {
		// const name = event.currentTarget.name;
		const value = event.currentTarget.value;

		input.remark2.value = value;
	};

	const handleOnChange3 = (event) => {
		// const name = event.currentTarget.name;
		const value = event.currentTarget.value;

		input.remark3.value = value;
	};

	return (
		<div className="transaction-page">
			<div className="top-content">
				<div className="tabs">
					<div className={"tablinks tab1 " + tabCol1} onClick={() => tabClicked("1")}>
						<span>Receivables</span>
					</div>
					<div className={"tablinks tab2 " + tabCol2} onClick={() => tabClicked("2")}>
						<span>Disbursement</span>
					</div>
					<div className={"tablinks tab3 " + tabCol3} onClick={() => tabClicked("3")}>
						<span>All Transaction</span>
					</div>
				</div>
				<div className="side-tabs">
					<div className="side-tabs-wrapper">
						<div className="search-bar">
							{tabCol1 === "tab-selected" ? <AppSearchBar placeholder="Search for..." onKeyEnter={onKeyUp1} onBlur={onBlur} onClick={() => onHandleSearch("Receivables")}></AppSearchBar> : ""}
							{tabCol2 === "tab-selected" ? <AppSearchBar placeholder="Search for..." onKeyEnter={onKeyUp2} onBlur={onBlur} onClick={() => onHandleSearch("Disbursement")}></AppSearchBar> : ""}
							{tabCol3 === "tab-selected" ? <AppSearchBar placeholder="Search for..." onKeyEnter={onKeyUp3} onBlur={onBlur} onClick={() => onHandleSearch("All Transaction")}></AppSearchBar> : ""}
						</div>
						<div className="search-button">
							{tabCol1 === "tab-selected" ? <AppButton colorType={exportBtn1} label="Export" onClick={() => exportReceivablesList(exportBtn1)}></AppButton> : ""}
							{tabCol2 === "tab-selected" ? <AppButton colorType={exportBtn2} label="Export" onClick={() => exportDisbursementList(exportBtn2)}></AppButton> : ""}
							{tabCol3 === "tab-selected" ? <AppButton colorType={exportBtn3} label="Export" onClick={() => exportTransactionList(exportBtn3)}></AppButton> : ""}
						</div>
					</div>
					{tabCol1 === "tab-selected" ? (
						<div className="input-container">
							<div className="input-wrapper">
								<select className="input-field" value={selected1} onChange={onHandleOnChange1}>
									<option value="- Select Range -"> - Select Range -</option>
									<option value="LAST_7_DAYS">Last 7 days</option>
									<option value="LAST_14_DAYS">Last 14 days</option>
									<option value="LAST_1_MONTH">Last month</option>
								</select>
							</div>

							<span className="custom-divider">Custom</span>
							<div className="input-wrapper">
								<input
									type={dateType1[0].type}
									onFocus={onFocusDateStart1}
									id="start"
									placeholder={dateType1[0].placeholder}
									className="input-field"
									style={{ marginRight: "10px" }}
									onChange={onChangeDateStart1}
									value={dateType1[0].value}
									max={maxDate1}
								/>
							</div>
							<div className="input-wrapper">
								<input
									type={dateType1[1].type}
									onFocus={onFocusDateEnd1}
									id="end"
									placeholder={dateType1[1].placeholder}
									className="input-field"
									onChange={onChangeDateEnd1}
									value={dateType1[1].value}
									min={minDate1}
								/>
							</div>
							<div className="input-wrapper">
								<select className="input-field" style={{ marginLeft: "10px" }} value={status1} onChange={OnChangeStatus1}>
									<option value="- Select Status -">- Select Status -</option>
									{statusList
										?.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))
										?.map((d, i) => (
											<option value={d.code} key={i}>
												{d.label}
											</option>
										))}
								</select>
							</div>
						</div>
					) : (
						""
					)}
					{tabCol2 === "tab-selected" ? (
						<div className="input-container">
							<div className="input-wrapper">
								<select className="input-field" value={selected2} onChange={onHandleOnChange2}>
									<option value="- Select Range -"> - Select Range -</option>
									<option value="LAST_7_DAYS">Last 7 days</option>
									<option value="LAST_14_DAYS">Last 14 days</option>
									<option value="LAST_1_MONTH">Last month</option>
								</select>
							</div>
							<span className="custom-divider">Custom</span>
							<div className="input-wrapper">
								<input
									type={dateType2[0].type}
									onFocus={onFocusDateStart2}
									id="start"
									placeholder={dateType2[0].placeholder}
									className="input-field"
									style={{ marginRight: "10px" }}
									onChange={onChangeDateStart2}
									value={dateType2[0].value}
									max={maxDate2}
								/>
							</div>
							<div className="input-wrapper">
								<input
									type={dateType2[1].type}
									onFocus={onFocusDateEnd2}
									id="end"
									placeholder={dateType2[1].placeholder}
									className="input-field"
									onChange={onChangeDateEnd2}
									value={dateType2[1].value}
									min={minDate2}
								/>
							</div>
							<div className="input-wrapper">
								<select className="input-field" style={{ marginLeft: "10px" }} value={status2} onChange={OnChangeStatus2}>
									<option value="- Select Status -">- Select Status -</option>
									{statusList
										?.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))
										?.map((d, i) => (
											<option value={d.code} key={i}>
												{d.label}
											</option>
										))}
								</select>
							</div>
						</div>
					) : (
						""
					)}
					{tabCol3 === "tab-selected" ? (
						<div className="input-container">
							<div className="input-wrapper">
								<select className="input-field" value={selected3} onChange={onHandleOnChange3}>
									<option value="- Select Range -"> - Select Range -</option>
									<option value="LAST_7_DAYS">Last 7 days</option>
									<option value="LAST_14_DAYS">Last 14 days</option>
									<option value="LAST_1_MONTH">Last month</option>
								</select>
							</div>
							<span className="custom-divider">Custom</span>
							<div className="input-wrapper">
								<input
									type={dateType3[0].type}
									onFocus={onFocusDateStart3}
									id="start"
									placeholder={dateType3[0].placeholder}
									className="input-field"
									style={{ marginRight: "10px" }}
									onChange={onChangeDateStart3}
									value={dateType3[0].value}
									max={maxDate3}
								/>
							</div>
							<div className="input-wrapper">
								<input
									type={dateType3[1].type}
									onFocus={onFocusDateEnd3}
									id="end"
									placeholder={dateType3[1].placeholder}
									className="input-field"
									onChange={onChangeDateEnd3}
									value={dateType3[1].value}
									min={minDate3}
								/>
							</div>
							<div className="input-wrapper">
								<select className="input-field" style={{ marginLeft: "10px" }} value={status3} onChange={OnChangeStatus3}>
									<option value="- Select Status -">- Select Status -</option>
									{statusList
										?.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))
										?.map((d, i) => (
											<option value={d.code} key={i}>
												{d.label}
											</option>
										))}
								</select>
							</div>
						</div>
					) : (
						""
					)}
				</div>
			</div>
			<div className="table-content">
				{tabCol1 === "tab-selected" ? (
					<div>
						<div className="page-loan-application-list__react-table-wrapper">
							{receivablesList !== undefined && receivablesList.data !== undefined ? <AppReactTable columns={tableColumn1} data={receivablesList.data} /> : <div></div>}
						</div>
						<div className="pagination-button">
							{receivablesList !== undefined ? (
								<span>
									{tableSearchFilter1.pageNo === 1 && receivablesList.totalCount > 0
										? tableSearchFilter1.pageNo
										: tableSearchFilter1.pageNo === 1 && receivablesList.totalCount === 0
										? receivablesList.totalCount
										: 1 + receivablesList.pageSize * (receivablesList.currentPageNumber - 1)}{" "}
									- {receivablesList.pageSize * (receivablesList.currentPageNumber - 1) + receivablesList.currentCount} of {receivablesList.totalCount}{" "}
								</span>
							) : (
								<></>
							)}
							<button onClick={() => previousPage1()}>❮</button>
							<button onClick={() => nextPage1()}>❯</button>
						</div>
					</div>
				) : (
					""
				)}
				{tabCol2 === "tab-selected" ? (
					<div>
						<div className="page-loan-application-list__react-table-wrapper">
							{disbursementList !== undefined && disbursementList.data !== undefined ? <AppReactTable columns={tableColumn2} data={disbursementList.data} /> : <div></div>}
						</div>
						<div className="pagination-button">
							{disbursementList !== undefined ? (
								<span>
									{tableSearchFilter2.pageNo === 1 && disbursementList.totalCount > 0
										? tableSearchFilter2.pageNo
										: tableSearchFilter2.pageNo === 1 && disbursementList.totalCount === 0
										? disbursementList.totalCount
										: 1 + disbursementList.pageSize * (disbursementList.currentPageNumber - 1)}{" "}
									- {disbursementList.pageSize * (disbursementList.currentPageNumber - 1) + disbursementList.currentCount} of {disbursementList.totalCount}{" "}
								</span>
							) : (
								<></>
							)}
							<button onClick={() => previousPage2()}>❮</button>
							<button onClick={() => nextPage2()}>❯</button>
						</div>
					</div>
				) : (
					""
				)}
				{tabCol3 === "tab-selected" ? (
					<div>
						<div className="page-loan-application-list__react-table-wrapper">
							{transactionList !== undefined && transactionList.data !== undefined ? <AppReactTable columns={tableColumn3} data={transactionList.data} /> : <div></div>}
						</div>
						<div className="pagination-button">
							{transactionList !== undefined ? (
								<span>
									{tableSearchFilter3.pageNo === 1 && transactionList.totalCount > 0
										? tableSearchFilter3.pageNo
										: tableSearchFilter3.pageNo === 1 && transactionList.totalCount === 0
										? transactionList.totalCount
										: 1 + transactionList.pageSize * (transactionList.currentPageNumber - 1)}{" "}
									- {transactionList.pageSize * (transactionList.currentPageNumber - 1) + transactionList.currentCount} of {transactionList.totalCount}{" "}
								</span>
							) : (
								<></>
							)}
							<button onClick={() => previousPage3()}>❮</button>
							<button onClick={() => nextPage3()}>❯</button>
						</div>
					</div>
				) : (
					""
				)}
			</div>
		</div>
	);
};

export default PageTransaction;
