import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { createLogger } from "redux-logger";
import authReducer from "redux/slices/auth-slice";
import { isDevelopmentMode } from "common/utilities";

const persistConfig = { key: "root", storage };
const logger = createLogger({ collapsed: true });
const reducers = { auth: authReducer };
const combinedReducers = combineReducers(reducers);
const debug = isDevelopmentMode();
const middleware = [debug && logger].filter(Boolean);

const rootReducer = (state, action) => {
    if (action.type === "logout") {
        state = undefined;
    }
    return combinedReducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({ reducer: persistedReducer, middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(middleware) });
export const persistor = persistStore(store);

export default store;
