import React, { useContext, useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import CONSTANSTS from "common/constansts";
import MyTextInput from "components/input";
import AppCustomToast, { PageContext } from "components/app-custom-toast";

const PageApprovalMatrixSettings = () => {
	const indicatorValue = useContext(PageContext);
	function messageDialog(indicator) {
		AppCustomToast({
			showIndicator: indicator,
			reintIndicator: indicatorValue[1](indicator),
		});
	}
	const initialValues = {
		passing_score: {
			dsr_percentage: [
				{
					code: "",
					label: "",
					value: 0,
				},
			],
			internal: [
				{
					code: "",
					maxScore: 0,
					label: "",
					value: 0,
					isMandatory: false,
				},
			],
			ctos: [
				{
					code: "",
					maxScore: 0,
					label: "",
					value: 0,
					isMandatory: false,
				},
			],
		},
		internal_scorecard: {
			race: [
				{
					code: "",
					label: "",
					value: 0,
				},
				{
					code: "",
					label: "",
					value: 0,
				},
				{
					code: "",
					label: "",
					value: 0,
				},
				{
					code: "",
					label: "",
					value: 0,
				},
			],
			age_group: [
				{
					code: "",
					label: "",
					value: 13,
				},
				{
					code: "",
					label: "",
					value: 5,
				},
				{
					code: "",
					label: "",
					value: 0,
				},
			],
			ptptn: [
				{
					code: "",
					label: "",
					value: 0,
				},
				{
					code: "",
					label: "",
					value: 0,
				},
				{
					code: "",
					label: "",
					value: 0,
				},
			],
			residential_area: [
				{
					code: "",
					label: "",
					value: 0,
				},
				{
					code: "",
					label: "",
					value: 0,
				},
				{
					code: "OTHERS",
					label: "",
					value: 0,
				},
			],
			repeated_borrower: [
				{
					code: "",
					label: "",
					value: 0,
				},
				{
					code: "",
					label: "",
					value: 0,
				},
			],
			education_level: [
				{
					code: "",
					label: "",
					value: 0,
				},
				{
					code: "",
					label: "",
					value: 0,
				},
				{
					code: "",
					label: "",
					value: 0,
				},
				{
					code: "",
					label: "",
					value: 0,
				},
			],
			employment_status: [
				{
					code: "",
					label: "",
					value: 0,
				},
				{
					code: "",
					label: "",
					value: 0,
				},
				{
					code: "",
					label: "",
					value: 0,
				},
				{
					code: "",
					label: "",
					value: 0,
				},
				{
					code: "",
					label: "",
					value: 0,
				},
			],
		},
		key_criteria: {
			required_documentation: [
				{
					code: "",
					label: "",
					value: true,
				},
				{
					code: "",
					label: "",
					value: true,
				},
				{
					code: "",
					label: "",
					value: true,
				},
			],
			loan_facilities_record: [
				{
					code: "",
					label: "",
					value: false,
				},
				{
					code: "",
					label: "",
					value: false,
				},
				{
					code: "",
					label: "",
					value: false,
				},
			],
		},
	};

	const validationMsg = {
		field_required: "This field cannot be empty",
		integer: "Value must be an integer",
		minimum_value: "Value cannot be lower than",
		maximum_value: "Value cannot be more than",
		more_than: "Value more than",
	};

	const validation = Yup.object({
		passing_score: Yup.object({
			ctos: Yup.array().of(
				Yup.object().shape({
					value: Yup.number()
						.max(Yup.ref("maxScore"), validationMsg.maximum_value + " maximum")
						.min(1, validationMsg.minimum_value + " 1")
						.required(validationMsg.field_required)
						.integer(validationMsg.integer),
				})
			),
			internal: Yup.array().of(
				Yup.object().shape({
					value: Yup.number()
						.max(Yup.ref("maxScore"), validationMsg.maximum_value + " maximum")
						.min(1, validationMsg.minimum_value + " 1")
						.required(validationMsg.field_required)
						.integer(validationMsg.integer),
				})
			),
			dsr_percentage: Yup.array().of(
				Yup.object().shape({
					value: Yup.number()
						.required(validationMsg.field_required)
						.integer(validationMsg.integer)
						.min(0, validationMsg.minimum_value + " 0"),
				})
			),
		}),
		internal_scorecard: Yup.object({
			age_group: Yup.array().of(
				Yup.object().shape({
					value: Yup.number()
						.required(validationMsg.field_required)
						.integer(validationMsg.integer)
						.min(0, validationMsg.minimum_value + " 0"),
				})
			),
			residential_area: Yup.array().of(
				Yup.object().shape({
					value: Yup.number()
						.required(validationMsg.field_required)
						.integer(validationMsg.integer)
						.min(0, validationMsg.minimum_value + " 0"),
				})
			),
			race: Yup.array().of(
				Yup.object().shape({
					value: Yup.number()
						.required(validationMsg.field_required)
						.integer(validationMsg.integer)
						.min(0, validationMsg.minimum_value + " 0"),
				})
			),
			ptptn: Yup.array().of(
				Yup.object().shape({
					value: Yup.number()
						.required(validationMsg.field_required)
						.integer(validationMsg.integer)
						.min(0, validationMsg.minimum_value + " 0"),
				})
			),
			education_level: Yup.array().of(
				Yup.object().shape({
					value: Yup.number()
						.required(validationMsg.field_required)
						.integer(validationMsg.integer)
						.min(0, validationMsg.minimum_value + " 0"),
				})
			),
			repeated_borrower: Yup.array().of(
				Yup.object().shape({
					value: Yup.number()
						.required(validationMsg.field_required)
						.integer(validationMsg.integer)
						.min(0, validationMsg.minimum_value + " 0"),
				})
			),
			employment_status: Yup.array().of(
				Yup.object().shape({
					value: Yup.number()
						.required(validationMsg.field_required)
						.integer(validationMsg.integer)
						.min(0, validationMsg.minimum_value + " 0"),
				})
			),
		}),
	});

	const [popUpValues, setPopUpValues] = useState([]);
	const [data, setData] = useState(initialValues);
	useEffect(async () => {
		try {
			let token = null;
			token = localStorage.getItem("@storage_token");
			let code = "RESIDENTIAL_AREA";
			fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.GET_APPROVAL_MATRIX, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "*/*",
					"Accept-language": "en",
					Authorization: "Bearer " + token,
				},
			})
				.then((response) => response.json())
				.then((result) => {
					if (result !== null || result !== undefined) {
						setData(result.result);
					} else {
						setData(initialValues);
					}
				});

			fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.FIND_DROPDOWN_OPTION + code, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "*/*",
					"Accept-language": "en",
					Authorization: "Bearer " + token,
				},
			})
				.then((response) => response.json())
				.then((result) => {
					if (result !== null || result !== undefined) {
						setPopUpValues(result.result);
					} else {
						setPopUpValues(null);
					}
				});
		} catch (error) {
			console.log(error);
		}
	}, []);

	var ctos_max,
		internal_max,
		area_1,
		area_1_short_word,
		area_1_1_short_word,
		split_area_1,
		area_2_short_word,
		split_area_1_short_word,
		area_2,
		split_area_2,
		split_area_2_short_word,
		area_2_1_short_word = null;

	if (data !== undefined) {
		// console.log("Pulled Data :" + JSON.stringify(data));
		ctos_max = data.passing_score.ctos[0].maxScore;
		internal_max = data.passing_score.internal[0].maxScore;

		//get short form of word eg Kuala Lumpur is KUL
		//KUL;PJY;PNG;SGR
		area_1_short_word = data.internal_scorecard.residential_area[0].label;
		area_2_short_word = data.internal_scorecard.residential_area[1].label;

		//get gull sentence of word eg Kuala Lumpur
		//"FEDERAL_TERRITORY_KUALA_LUMPUR;FEDERAL_TERRITORY_PUTRAJAYA;PENANG;SELANGOR",
		area_1 = data.internal_scorecard.residential_area[0].code;
		area_2 = data.internal_scorecard.residential_area[1].code;

		HandlingTwoBoxes();
	} else {
		ctos_max = null;
		internal_max = null;
	}

	//first area and second area which used to control the pop up of select area
	const [popUpArea1, setPopUpArea1] = useState(false);
	const [popUpArea2, setPopUpArea2] = useState(false);
	//   const [updateAlert, setUpdateAlert] = useState(false);
	function Popup(props) {
		return props.trigger ? (
			<div className="residential-area-pop-up">
				<div className="pop-up-data">
					<div className="pop-up-data-wrapper">{props.children}</div>
					<div className="pop-up-data-button-container">
						<button type="button" onClick={() => [setPopUpArea1(false), setPopUpArea2(false), HandlingTwoBoxes()]}>
							OK
						</button>
					</div>
				</div>
			</div>
		) : (
			""
		);
	}

	//   function UpdateAlert(props) {
	//     return props.trigger ? (
	//       <div className="pop-up-alert">
	//         <div className="pop-up-alert-content">
	//           {/* <div>Hello Im Pop up</div> */}
	//           {props.children}
	//         </div>
	//       </div>
	//     ) : (
	//       ""
	//     );
	//   }

	function HandlingTwoBoxes() {
		//first select area
		if (area_1_short_word.includes(";")) {
			//convert string to array using split for comparing code which call area
			//from KUL;PJY;PNG;SGR to [KUL,PJY,PNG,SGR] for label
			//from FEDERAL_TERRITORY_KUALA_LUMPUR;FEDERAL_TERRITORY_PUTRAJAYA;PENANG;SELANGOR to
			//[FEDERAL_TERRITORY_KUALA_LUMPUR,FEDERAL_TERRITORY_PUTRAJAYA,PENANG,SELANGOR] for code
			split_area_1 = area_1.split(";");
			split_area_1_short_word = area_1_short_word.split(";");
			// replacing ';' to ','and getting the seperate the string to two column
			//use for displaying
			area_1_short_word = area_1_short_word.replace(/;/g, ",");
			area_1_1_short_word = area_1_short_word.substring(16, area_1_short_word.length);
			area_1_short_word = area_1_short_word.substring(0, 16);
		} else if (area_1 === "") {
			//to avoid second last area and last area unselect at the same time due to the empty of string
			split_area_1 = [];
			split_area_1_short_word = [];
		} else {
			split_area_1 = [area_1];
			split_area_1_short_word = [area_1_short_word];
		}

		//second select area
		if (area_2_short_word.includes(";")) {
			//similar to area 1 above
			split_area_2 = area_2.split(";");
			split_area_2_short_word = area_2_short_word.split(";");

			area_2_short_word = area_2_short_word.replace(/;/g, ",");

			area_2_1_short_word = area_2_short_word.substring(16, area_2_short_word.length);
			area_2_short_word = area_2_short_word.substring(0, 16);
		} else if (area_2 === "") {
			split_area_2 = [];
			split_area_2_short_word = [];
		} else {
			split_area_2 = [area_2];
			split_area_2_short_word = [area_2_short_word];
		}
	}

	//do mapping for select area button

	function SelectArea(values) {
		const select_area = [];
		var selected_area, exist_area;

		//popUpArea1 is first select area button, if it is true it will be the area 1 else will be area 2
		if (popUpArea1) {
			selected_area = split_area_1;
			exist_area = split_area_2;
		} else {
			selected_area = split_area_2;
			exist_area = split_area_1;
		}

		values.select.map((popUpValue, index) => {
			if (selected_area.includes(popUpValue.code)) {
				select_area.push(
					<div
						className="col-2 area-container selected-area-container m-2"
						key={index}
						id={popUpValue.abbreviation}
						value={popUpValue.code}
						onClick={(e) => {
							HandleClick(e, values.inputData);
						}}
					>
						{popUpValue.label}
					</div>
				);
			} else if (exist_area.includes(popUpValue.code)) {
				select_area.push(
					<div
						className="col-2 area-container exist-area-container m-2"
						key={index}
						id={popUpValue.abbreviation}
						value={popUpValue.code}
						onClick={(e) => {
							HandleClick(e, values.inputData);
						}}
					>
						{popUpValue.label}
					</div>
				);
			} else {
				select_area.push(
					<div
						className="col-2 area-container m-2"
						key={index}
						value={popUpValue.code}
						id={popUpValue.abbreviation}
						onClick={(e) => {
							HandleClick(e, values.inputData);
						}}
					>
						{popUpValue.label}
					</div>
				);
			}
		});
		return select_area;
	}

	const HandleClick = (e, values) => {
		var class_name = e.currentTarget.className;
		var clicked_area_code, clicked_area_abbreviation;
		var white_color = "white";
		var yellow_color = "yellow";

		clicked_area_code = e.target.getAttribute("value");
		clicked_area_abbreviation = e.target.getAttribute("id");

		//compare with className to know which 1 is selected [yellow color] exist [gray color]
		if (class_name === "col-2 area-container selected-area-container m-2") {
			ChangeColor(e, yellow_color, white_color);
			RemoveResidentialArea(clicked_area_code, clicked_area_abbreviation, values);
		} else if (class_name === "col-2 area-container m-2") {
			ChangeColor(e, white_color, yellow_color);
			AddResidentialArea(clicked_area_code, clicked_area_abbreviation, values);
		}
	};

	function ChangeColor(e, default_color, change_to_color) {
		e.currentTarget.style.backgroundColor = default_color;
		if (e.currentTarget.style.backgroundColor === default_color) {
			e.currentTarget.style.backgroundColor = change_to_color;
		} else {
			e.currentTarget.style.backgroundColor = default_color;
		}
	}

	//update with unchange when clicked okey without select/unselect any of area
	//it also will be the array when update the residential area
	let new_residential_area_array = [
		{
			...data.internal_scorecard.residential_area[0],
		},
		{
			...data.internal_scorecard.residential_area[1],
		},
		{
			...data.internal_scorecard.residential_area[2],
		},
	];

	//remove residential area when unselect
	function RemoveResidentialArea(clicked_area_code, clicked_area_abbreviation, values) {
		var selected_area, split_area_short_word;

		//check is first select area button or second select area button
		if (popUpArea1) {
			selected_area = split_area_1;
			split_area_short_word = split_area_1_short_word;
		} else {
			selected_area = split_area_2;
			split_area_short_word = split_area_2_short_word;
		}

		//check whether area that need to remove is exist in the residential area 1/ area 2
		//if exists performing remove
		if (selected_area.includes(clicked_area_code)) {
			selected_area = selected_area.filter((area) => area !== clicked_area_code);
			split_area_short_word = split_area_short_word.filter((area_abbreviation) => area_abbreviation !== clicked_area_abbreviation);

			//convert back the residential area to string before set to the data
			//set the , back to ; before set the data
			var new_residential_area_code = selected_area.toString();
			var new_residential_area_abbreviation = split_area_short_word.toString();
			if (new_residential_area_code.includes(",")) {
				new_residential_area_code = new_residential_area_code.replace(/,/g, ";");
			}

			if (new_residential_area_abbreviation.includes(",")) {
				new_residential_area_abbreviation = new_residential_area_abbreviation.replace(/,/g, ";");
			}
			//set to the data with JSON format before update
			setArrayData(new_residential_area_code, new_residential_area_abbreviation, values);
		}
		//update it
		UpdateResidentInData(values);
	}

	//set the residential area which removed/ added in area 1 / area 2
	function setArrayData(new_residential_area_code, new_residential_area_abbreviation, values) {
		if (popUpArea1) {
			new_residential_area_array = [
				{
					code: new_residential_area_code,
					label: new_residential_area_abbreviation,
					value: values.internal_scorecard.residential_area[0].value,
				},
				{
					...data.internal_scorecard.residential_area[1],
				},
				{
					code: "OTHERS",
					label: "null",
					value: values.internal_scorecard.residential_area[2].value,
				},
			];
		} else {
			new_residential_area_array = [
				{
					code: values.internal_scorecard.residential_area[0].code,
					label: values.internal_scorecard.residential_area[0].label,
					value: values.internal_scorecard.residential_area[0].value,
				},
				{
					code: new_residential_area_code,
					label: new_residential_area_abbreviation,
					value: values.internal_scorecard.residential_area[1].value,
				},
				{
					code: "OTHERS",
					label: "null",
					value: values.internal_scorecard.residential_area[2].value,
				},
			];
		}
	}

	//add the residential area when select it
	function AddResidentialArea(clicked_area_code, clicked_area_abbreviation, values) {
		var selected_area, split_area_short_word;
		if (popUpArea1) {
			selected_area = split_area_1;
			split_area_short_word = split_area_1_short_word;
		} else {
			selected_area = split_area_2;
			split_area_short_word = split_area_2_short_word;
		}
		//check is it exists in the area1/area2 before add to avoid redundant
		if (!selected_area.includes(clicked_area_code)) {
			//since it is array convert to string the maybe the space infront
			// eg [""."Kuala Lumpur"] which will effect the display such as ,KualaLumpur
			//checking with empty after convert to string to avoid case above happen
			if (selected_area.toString() === "") {
				selected_area = clicked_area_code;
				split_area_short_word = clicked_area_abbreviation;
			} else {
				//will add, if the first array not empty
				selected_area = selected_area.toString() + "," + clicked_area_code;
				split_area_short_word = split_area_short_word.toString() + "," + clicked_area_abbreviation;
			}

			//convert back the , to ; same as RemoveResidentialArea() above
			var new_residential_area_code = selected_area;
			var new_residential_area_abbreviation = split_area_short_word;
			if (new_residential_area_code.includes(",")) {
				new_residential_area_code = new_residential_area_code.replace(/,/g, ";");
				selected_area = new_residential_area_code.split(";");
			} else {
				selected_area = new_residential_area_code;
			}

			if (new_residential_area_abbreviation.includes(",")) {
				new_residential_area_abbreviation = new_residential_area_abbreviation.replace(/,/g, ";");
				split_area_short_word = new_residential_area_abbreviation.split(";");
				// console.log("replaced new_residential_area:" + new_residential_area_abbreviation);
			} else {
				split_area_short_word = new_residential_area_abbreviation;
			}
			setArrayData(new_residential_area_code, new_residential_area_abbreviation, values);

			if (popUpArea1) {
				split_area_1 = selected_area;
				split_area_1_short_word = split_area_short_word;
			} else {
				split_area_2 = selected_area;
				split_area_2_short_word = split_area_short_word;
			}
		}
		UpdateResidentInData(values);
	}

	//Update the data
	function UpdateResidentInData(values) {
		// console.log("set data:" + JSON.stringify(data));
		// console.log("inputValues:" + JSON.stringify(values.internal_scorecard.repeated_borrower));
		// console.log("inputValues:" + values.internal_scorecard.repeated_borrower);
		setData({
			...values,
			internal_scorecard: {
				residential_area: new_residential_area_array,
				ptptn: values.internal_scorecard.ptptn,
				age_group: values.internal_scorecard.age_group,
				race: values.internal_scorecard.race,
				repeated_borrower: values.internal_scorecard.repeated_borrower,
				education_level: values.internal_scorecard.education_level,
				employment_status: values.internal_scorecard.employment_status,
			},
		});
	}

	const onHandlingSubmit = (values, { setSubmitting }) => {
		let token = null;
		token = localStorage.getItem("@storage_token");

		setTimeout(() => {
			fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.UPDATE_APPROVAL_MATRIX, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "*/*",
					"Accept-language": "en",
					Authorization: "Bearer " + token,
				},
				body: JSON.stringify(values),
			})
				.then((response) => response.json())
				.then((response) => {
					if (response.message !== null || response.message !== undefined) {
						if (response.success) {
							messageDialog({
								isOpen: true,
								condition: CONSTANSTS.DIALOG.success,
								title: CONSTANSTS.DIALOG_TITLE.general_success,
								message: response.message,
							});
						} else {
							messageDialog({
								isOpen: true,
								condition: CONSTANSTS.DIALOG.failed,
								title: CONSTANSTS.DIALOG_TITLE.general_failed,
								message: response.message,
								defaultOnClick: false,
							});
						}
					} else {
						messageDialog({
							isOpen: true,
							condition: CONSTANSTS.DIALOG.failed,
							title: CONSTANSTS.DIALOG_TITLE.general_failed,
							message: response.message,
							defaultOnClick: false,
						});
					}
				});
			setSubmitting(false);
		}, 400);
	};
	return (
		<>
			<Formik enableReinitialize={true} initialValues={data} validationSchema={validation} onSubmit={onHandlingSubmit}>
				{({ values }) => (
					<Form>
						<div className="page-initial-approval-matrix">
							<div className="approval-matrix">
								<div className="approval-matrix__header">
									<div className="approval-matrix__title">Initial Approval Matrix</div>
									<button className="approval-matrix__update-button" type="submit">
										Update
									</button>
								</div>

								<div className="approval-matrix__body">
									<div className="content">
										<div className="content__header">Overall Scoring / Key Criteria</div>

										<div className="content__form">
											<div className="content__wrapper">
												<p className="content__label">Minimum Score</p>
												<div className="content__input">
													<p className="content__ctos">{data.passing_score.ctos[0].label}</p>
													<MyTextInput className="content__field" placeholder="XX" type="number-1" name="passing_score.ctos[0].value" />
													<p className="content__score">/ {ctos_max}</p>
												</div>
												<div className="content__input">
													<MyTextInput className="content__checkbox" type="checkbox" name="passing_score.ctos[0].isMandatory" />
													<p className="content__text">Mandatory</p>
												</div>
												<div className="content__input">
													<p className="content__ctos">{data.passing_score.internal[0].label}</p>
													<MyTextInput className="content__field" placeholder="XX" type="number-1" name="passing_score.internal[0].value" />
													<p className="content__score">/ {internal_max}</p>
												</div>
												<div className="content__input">
													<MyTextInput className="content__checkbox" type="checkbox" name="passing_score.internal[0].isMandatory" />
													<p className="content__text">Mandatory</p>
												</div>

												<p className="content__sub-title">Required Documentation</p>
												{data.key_criteria.required_documentation.map((o, i) => {
													return (
														<div className="content__input" key={i}>
															<MyTextInput className="content__checkbox" type="checkbox" name={`key_criteria.required_documentation[${i}].value`} />
															<p className="content__text">{o.label}</p>
														</div>
													);
												})}
												<p className="content__note">Check this box to allow to proceed</p>
											</div>
										</div>
									</div>

									<div className="content">
										<div className="content__header">IVC Scorecard</div>
										<div className="content__form">
											<div className="content__wrapper content__wrapper--form">
												<p className="content__label">Age</p>
												{data.internal_scorecard.age_group.map((o, i) => {
													return (
														<div className="content__input content__input--ivc" key={i}>
															<p className="content__sub-label">{o.label}</p>
															<MyTextInput className="content__field" placeholder="XXX" type="number-3" name={`internal_scorecard.age_group[${0}].value`} />
														</div>
													);
												})}

												<p className="content__label">Residential Area</p>
												<div className="content__input content__input--ivc">
													<p className="content__sub-label">{area_1_short_word}</p>
													<MyTextInput className="content__field content__field--button" placeholder="XXX" type="number-3" name="internal_scorecard.residential_area[0].value" />
													<button
														type="button"
														className="content__button"
														onClick={() => {
															setPopUpArea1(true);
														}}
													>
														Select Area
													</button>
												</div>

												<div className="content__input content__input--ivc">
													<p className="content__sub-label">{area_2_short_word}</p>
													<MyTextInput className="content__field content__field--button" placeholder="XXX" type="number-3" name="internal_scorecard.residential_area[1].value" />
													<button
														type="button"
														className="content__button"
														onClick={() => {
															setPopUpArea2(true);
														}}
													>
														Select Area
													</button>
												</div>

												<div className="content__input content__input--ivc">
													<p className="content__sub-label">{data.internal_scorecard.residential_area[2].label}</p>
													<MyTextInput className="content__field" placeholder="XXX" type="number-3" name="internal_scorecard.residential_area[2].value" />
												</div>

												<p className="content__label">Race</p>
												{data.internal_scorecard.race.map((o, i) => {
													return (
														<div className="content__input content__input--ivc" key={i}>
															<p className="content__sub-label">{o.label}</p>
															<MyTextInput className="content__field" placeholder="XXX" type="number-3" name={`internal_scorecard.race[${i}].value`} />
														</div>
													);
												})}

												<p className="content__label">PTPTN</p>
												{data.internal_scorecard.education_level.map((o, i) => {
													return (
														<div className="content__input content__input--ivc">
															<p className="content__sub-label">{o.label}</p>
															<MyTextInput className="content__field" placeholder="XXX" type="number-3" name={`internal_scorecard.ptptn[${i}].value`} />
														</div>
													);
												})}
											</div>

											<div className="content__wrapper content__wrapper--form">
												<p className="content__label">Education</p>
												{data.internal_scorecard.education_level.map((o, i) => {
													return (
														<div className="content__input content__input--ivc" key={i}>
															<p className="content__sub-label">{o.label}</p>
															<MyTextInput className="content__field" placeholder="XXX" type="number-3" name={`internal_scorecard.education_level[${i}].value`} />
														</div>
													);
												})}

												<p className="content__label">Employment</p>
												<div className="content__input content__input--ivc">
													<p className="content__sub-label">{data.internal_scorecard.employment_status[0].label}</p>
													<MyTextInput className="content__field" placeholder="XXX" type="number-3" name="internal_scorecard.employment_status[0].value" />
												</div>
												<div className="content__input content__input--ivc">
													<MyTextInput className="content__checkbox" type="checkbox" name="key_criteria.required_documentation[1].value" />
													<p className="content__text">{data.key_criteria.required_documentation[2].label}</p>
												</div>

												{/* DSR */}
												<div className="content__input content__input--ivc">
													<p className="content__label">{data.passing_score.dsr_percentage[0].label}</p>
													<MyTextInput className="content__field" placeholder="XXX" type="number-3" name="passing_score.dsr_percentage[0].value" />
												</div>

												{/* Employed DSR > */}
												<div className="content__input content__input--ivc">
													<p className="content__label">{data.internal_scorecard.employment_status[1].label}</p>
													<MyTextInput className="content__field" placeholder="XXX" type="number-3" name="internal_scorecard.employment_status[1].value" />
												</div>
												<div className="content__input content__input--ivc">
													<MyTextInput className="content__checkbox" type="checkbox" name="key_criteria.required_documentation[0].value" />
													<p className="content__text">{data.key_criteria.required_documentation[0].label}</p>
												</div>
												<div className="content__input content__input--ivc">
													<MyTextInput className="content__checkbox" type="checkbox" name="key_criteria.required_documentation[1].value" />
													<p className="content__text">{data.key_criteria.required_documentation[2].label}</p>
												</div>

												{/* Employed DSR < */}
												<div className="content__input content__input--ivc">
													<div className="content__label">{data.internal_scorecard.employment_status[2].label}</div>
													<MyTextInput className="content__field" placeholder="XXX" type="number-3" name="internal_scorecard.employment_status[2].value" />
												</div>
												<div className="content__input content__input--ivc">
													<MyTextInput className="content__checkbox" type="checkbox" name="key_criteria.required_documentation[0].value" />
													<p className="content__text">{data.key_criteria.required_documentation[0].label}</p>
												</div>
												<div className="content__input content__input--ivc">
													<MyTextInput className="content__checkbox" type="checkbox" name="key_criteria.required_documentation[1].value" />
													<p className="content__text">{data.key_criteria.required_documentation[2].label}</p>
												</div>

												{/* Student */}
												<div className="content__input content__input--ivc">
													<p className="content__label"> {data.internal_scorecard.employment_status[3].label}</p>
													<MyTextInput className="content__field" placeholder="XXX" type="number-3" name="internal_scorecard.employment_status[3].value" />
												</div>

												<div className="content__input content__input--ivc">
													<MyTextInput className="content__checkbox" type="checkbox" name="key_criteria.required_documentation[1].value" />
													<p className="content__text">{data.key_criteria.required_documentation[1].label}</p>
												</div>

												{/* Unemployed */}
												<div className="content__input content__input--ivc">
													<p className="content__label"> {data.internal_scorecard.employment_status[4].label}</p>
													<MyTextInput className="content__field" placeholder="XXX" type="number-3" name="internal_scorecard.employment_status[4].value" />
												</div>

												<p className="content__label">Repeat borrower</p>
												<div className="content__input content__input--ivc">
													<div className="content__text"> {data.internal_scorecard.repeated_borrower[0].label}</div>
													<MyTextInput className="content__field" placeholder="XXX" type="number-3" name="internal_scorecard.repeated_borrower[0].value" />
												</div>
												<div className="content__input content__input--ivc">
													<div className="content__text"> {data.internal_scorecard.repeated_borrower[1].label}</div>
													<MyTextInput className="content__field" placeholder="XXX" type="number-3" name="internal_scorecard.repeated_borrower[1].value" />
												</div>
											</div>
										</div>
									</div>
								</div>

								<Popup trigger={popUpArea1 || popUpArea2}>
									<SelectArea select={popUpValues} inputData={values}></SelectArea>
								</Popup>
							</div>
						</div>
					</Form>
				)}
			</Formik>
		</>
	);
};

export default PageApprovalMatrixSettings;
