import React, { useState } from "react";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";

const getInputClassName = (error, variation) => {
	const classNames = ["app-forgot-password-input__input"];
	const classNamesWithIcon = ["app-forgot-password-input__icon-input"];
	if (error) {
		classNames.push("app-forgot-password-input--error");
		classNamesWithIcon.push("app-forgot-password-input--error");
	}
	return variation ? classNamesWithIcon.join(" ") : classNames.join(" ");
};

const AppForgotPwdInput = ({ error, type = "text", variation = "withoutIcon", ...props }) => {
	const [showPassword, setShowPassword] = useState(false);

	return (
		<div className="app-forgot-password-input">
			{variation === "withoutIcon" ? (
				<div className="app-forgot-password-input__container">
					<div className="app-forgot-password-input__input-wrapper">
						<input className={getInputClassName(error)} type={showPassword ? "text" : type} {...props} />
						{type === "password" ? (
							<div className="app-forgot-password-input__password-eye" onClick={() => setShowPassword(!showPassword)}>
								{showPassword ? <IoEyeOffOutline size={24} color="#9D9D9C" /> : <IoEyeOutline size={24} color="#9D9D9C" />}
							</div>
						) : null}
					</div>
				</div>
			) : (
				<div className={getInputClassName(error, variation)}>
					<input className="input-100" type={showPassword ? "text" : type} {...props} />
					{type === "password" ? (
						<div className="icon icon-action" onClick={() => setShowPassword(!showPassword)}>
							{showPassword ? <IoEyeOffOutline size={24} color="#9D9D9C" /> : <IoEyeOutline size={24} color="#9D9D9C" />}
						</div>
					) : null}
				</div>
			)}

			<p className="app-forgot-password-input__error">{error}</p>
		</div>
	);
};

export default AppForgotPwdInput;
