const pathnames = {
    pageSplashScreen: "/",
    pageLogin: "/login",
    pageForgotPassword: "/forgot-password",
    pageChangePassword: "/change-password",
    pageHomePage: "/home-page",
    pageLoanApplicationList: "/loan/loan-application-list",
    pageLoanApplicationView: "/loan/loan-application-view/:staffId",
    pageLoanContractList: "/loan/loan-contract-list",
    pageLoanContractView: "/loan/loan-contract-view/:contractId",
    pageLoanContracts: "/loan/loan-contracts",
    pageMerchantList: "/database/merchant-list",
    pageMerchantView: "/database/merchant-view/:merchantId",
    pageMerchantAdd: "/database/merchant-add",
    pageCustomerList: "/database/customer-list",
    pageCustomerView: "/database/customer-list/:customerId",
    pageReport: "/report",
    pagePayment: "/report/payment",
    pageTransaction: "/report/transaction",
    pageReportOthers: "/report/others",
    pageLoanSettings: "/admin-settings/loan-settings",
    pageCalendarSettings: "/admin-settings/calendar-settings",
    pageApprovalMatrixSettings: "/admin-settings/approval-matrix-settings",
    pageUserManagement: "/admin-settings/user-management",
    pageUserProfile: "/user-profile",
    pageSignOut: "/sign-out",
    page404: "/404",
    pageAddNewUser: "/admin-settings/user-management/add-user",
    pageUserDetail: "/admin-settings/user-management/update-user",
};

export default pathnames;