// libraries
import React, { useContext, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";

//component
import AppButton from "components/app-button";
import pathnames from "routes/pathnames";
import { useEffect } from "react";
// import { updateUserList } from "redux/slices/user-list-slice";
import IconDownload from "../../assets/images/pages/icon-download-border-none.png";
// import { FiSearch } from "react-icons/fi";
import AppReactTable from "components/app-react-table";
import { IoClose } from "react-icons/io5";
import AppSearchBar from "components/app-search-bar";
import Calendar from "react-calendar";
// import 'react-calendar/dist/Calendar.css';
// commons
import CONSTANSTS from "common/constansts";
import classNames from "common/class-names";
import AppCustomToast, { PageContext } from "components/app-custom-toast";

const PageMerchantView = () => {
	const indicatorValue = useContext(PageContext);
	function messageDialog(indicator) {
		AppCustomToast({
			showIndicator: indicator,
			reintIndicator: indicatorValue[1](indicator),
		});
	}
	const history = useHistory();
	const { merchantId } = useParams();
	const [accessMerchantDetails, setAccessMerchantDetails] = useState({
		download_agreement: false,
		terminate_merchant: false,
		update_merchant: false,
		view_merchant_detail: false,
	});
	const [accessMerchantTrx, setAccessMerchantTrx] = useState({
		export_transaction_list: false,
		view_transaction_list: false,
	});
	const [accessMerchantTerminal, setAccessMerchantTerminal] = useState({
		create_merchant_terminal: false,
		delete_merchant_terminal: false,
		view_merchant_terminal_list: false,
	});

	useEffect(() => {
		let dbMerchantAccess = null;
		dbMerchantAccess = JSON.parse(localStorage.getItem("accessMatrix"));

		if (dbMerchantAccess) {
			setAccessMerchantDetails(dbMerchantAccess.accessMatrix.Merchant_Detail);
			setAccessMerchantTrx(dbMerchantAccess.accessMatrix.Merchant_Transaction);
			setAccessMerchantTerminal(dbMerchantAccess.accessMatrix.Merchant_Terminal);
		}
	}, []);

	const inputText = {
		name: {
			placeholder: "Merchant Name",
			required: "Merchant Name required",
			invalid: "Invalid merchant name",
			regex: /^[a-zA-Z0-9 ./-]*$/,
		},
		email: {
			placeholder: "Email",
			required: "Email required",
			invalid: "Invalid email",
			regex: CONSTANSTS.REGEX.email,
		},
		mobile: {
			placeholder: "Mobile Phone",
			required: "Mobile Phone required",
			invalid: "Invalid mobile phone",
			regex: /^.{10,}$/,
		},
		category: {
			placeholder: "Category",
			required: "Category required",
			invalid: "Invalid category",
			regex: CONSTANSTS.REGEX.checkEmpty,
		},
		address1: {
			placeholder: "Address 1",
			required: "Address 1 required",
			invalid: "Invalid address 1",
			regex: CONSTANSTS.REGEX.checkEmpty,
		},
		address2: {
			placeholder: "Address 2",
			required: "Address 2 required",
			invalid: "Invalid address 2",
			regex: CONSTANSTS.REGEX.checkEmpty,
		},
		city: {
			placeholder: "City",
			required: "City required",
			invalid: "Invalid city",
			regex: CONSTANSTS.REGEX.checkEmpty,
		},
		postcode: {
			placeholder: "Postcode",
			required: "Postcode required",
			invalid: "Invalid Postcode",
			regex: /^.{5,}$/,
		},
		state: {
			placeholder: "State",
			required: "State required",
			invalid: "Invalid state",
			regex: /^.{5,}$/,
		},
		bankCode: {
			placeholder: "Bank Name",
			required: "Bank Name required",
			invalid: "Invalid bank name",
			regex: CONSTANSTS.REGEX.checkEmpty,
		},
		accName: {
			placeholder: "Account Name",
			required: "Account Name required",
			invalid: "Invalid account name",
			regex: /^[a-zA-Z0-9 ./-]*$/,
		},
		accNumber: {
			placeholder: "Account No",
			required: "Account No required",
			invalid: "Invalid Account No",
			regex: /^.{7,}$/,
		},
		effectiveDate: {
			placeholder: "Effective Date",
			required: "Effective Date required",
			invalid: "Invalid effective date",
			regex: CONSTANSTS.REGEX.checkEmpty,
		},
		businessRegNo: {
			placeholder: "Business Registration Number",
			required: "Business Reg. No required",
			invalid: "Invalid Business Reg. No",
			regex: /^[a-zA-Z0-9 ()-]*$/,
		},
		headquarters: {
			placeholder: "Headquarters Name",
			required: "Headquarters Name required",
			invalid: "Invalid headquarters name",
			regex: CONSTANSTS.REGEX.checkEmpty,
		},
	};

	const inputField = {
		accName: {
			value: "",
			error: "",
		},
		accNumber: {
			value: "",
			error: "",
		},
		address1: {
			value: "",
			error: "",
		},
		address2: {
			value: "",
			error: "",
		},
		agreementDownloadUrl: {
			value: "",
			error: "",
		},
		bankName: {
			value: "",
			error: "",
		},
		bankCode: {
			value: "",
			error: "",
		},
		category: {
			value: "",
			error: "",
		},
		city: {
			value: "",
			error: "",
		},
		effectiveDate: {
			value: "",
			error: "",
		},
		endDate: {
			value: "",
			error: "",
		},
		name: {
			value: "",
			error: "",
		},
		postcode: {
			value: 0,
			error: "",
		},
		qrImageUrl: {
			value: "",
			error: "",
		},
		qrDownloadUrl: {
			value: null,
			error: "",
		},
		referenceNumber: {
			value: "",
			error: "",
		},
		state: {
			value: "",
			error: "",
		},
		status: {
			value: "",
			error: "",
		},
		merchantId: {
			value: "",
			error: "",
		},
		email: {
			value: "",
			error: "",
		},
		mobile: {
			value: "",
			error: "",
		},
		businessRegNo: {
			value: "",
			error: "",
		},
		subsidiary: {
			value: false,
			error: "",
		},
		headquarters: {
			value: "",
			error: "",
		},
	};

	const [input, setInput] = useState(inputField);
	const [categoryList, setCategoryList] = useState(undefined);
	const [stateList, setStateList] = useState(undefined);
	const [bankList, setBankList] = useState(undefined);
	const [hqList, setHqList] = useState(undefined);

	const reloadGetMerchant = async () => {
		const appId = merchantId;
		let token = null;
		token = localStorage.getItem("@storage_token");
		// fetch("http://49.0.203.50/ivc-app/bo/merchant/api/v1/get/" + appId, {
		fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.GET_MERCHANT_BYID + appId, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				"Accept-language": "en",
				Authorization: "Bearer " + token,
			},
		})
			.then(function (response) {
				if (response.ok) {
					return response.json();
				} else {
					return;
				}
			})
			.then(function (data) {
				if (data !== null || data !== undefined) {
					// appToast("Sucessfully", true);
					let result = data.result;
					// console.warn("res: ", result);
					let date = result.effectiveDate;
					let modifydate = "";
					modifydate = date.substring(6, 10) + "-" + date.substring(3, 5) + "-" + date.substring(0, 2);
					//
					let dmy = new Date(modifydate);
					let dateFormat = dmy.toLocaleString("ms", { hour12: false }).split(" ");
					var dt = dateFormat[0].split("/");
					// console.log(dt)
					var day2 = parseInt(dt[0]);
					var month2 = parseInt(dt[1]);
					var year2 = parseInt(dt[2]);
					var formattedDate2 = "";
					if (day2 < 10 && month2 >= 10) {
						formattedDate2 = "0" + day2 + "/" + month2 + "/" + year2;
					}
					if (month2 < 10 && day2 >= 10) {
						formattedDate2 = "" + day2 + "/0" + month2 + "/" + year2;
					}
					if (day2 < 10 && month2 < 10) {
						formattedDate2 = "0" + day2 + "/0" + month2 + "/" + year2;
					}
					setDMY(formattedDate2.toString());
					setInput({
						...input,
						accName: {
							value: result.accName,
						},
						accNumber: {
							value: result.accNumber,
						},
						address1: {
							value: result.address1,
						},
						address2: {
							value: result.address2,
						},
						agreementDownloadUrl: {
							value: result.agreementDownloadUrl,
						},
						bankName: {
							value: result.bankName,
						},
						bankCode: {
							value: result.bankCode,
						},
						category: {
							value: result.category,
						},
						city: {
							value: result.city,
						},
						effectiveDate: {
							value: result.effectiveDate,
							// value: modifydate,
						},
						endDate: {
							value: result.endDate,
						},
						name: {
							value: result.name,
						},
						postcode: {
							value: result.postcode,
						},
						qrImageUrl: {
							value: result.qrImageUrl,
						},
						qrDownloadUrl: {
							value: result.qrDownloadUrl,
						},
						referenceNumber: {
							value: result.referenceNumber,
						},
						state: {
							value: result.state,
						},
						status: {
							value: result.status,
						},
						merchantId: {
							value: result.merchantId,
						},
						email: {
							value: result.email,
						},
						mobile: {
							value: result.mobileNumber,
						},
					});
					if (result.status === "ACTIVE") {
						setIsChecked(true);
					} else {
						setIsChecked(false);
					}
					// setReactDate(new Date(modifydate));
					return;
				}
			});
	};

	const [modifyDate, setModifyDate] = useState();

	useEffect(() => {
		// const appId = id.location.state;
		const appId = merchantId;
		// console.log("ID", appId)
		const loadData = async () => {
			try {
				let token = null;
				token = localStorage.getItem("@storage_token");
				// let response = fetch("http://49.0.203.50/ivc-app/bo/merchant/api/v1/get/" + appId, {
				fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.GET_MERCHANT_BYID + appId, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Accept: "*/*",
						"Accept-language": "en",
						Authorization: "Bearer " + token,
					},
				})
					.then(function (response) {
						if (response.ok) {
							return response.json();
						} else {
							return;
						}
					})
					.then(function (data) {
						if (data !== null || data !== undefined) {
							// appToast("Sucessfully", true);
							let result = data.result;
							// console.warn("result: ", result);

							let date = result.effectiveDate;
							let modifydate = "";
							// modifydate = date.substring(6, 10) + "-" + date.substring(3, 5) + "-" + date.substring(0, 2);
							modifydate = date.substring(3, 5) + "-" + date.substring(0, 2) + "-" + date.substring(6, 10);
							setModifyDate(modifydate);
							// console.log("modifydate -> ", modifydate)
							// console.log("date -> ", new Date(modifydate))

							// let dmy = new Date(modifydate);
							// let dateFormat = dmy.toLocaleString("ms", { hour12: false }).split(" ");
							// var dt = dateFormat[0].split("/");
							// console.log(dt);
							// var day2 = parseInt(dt[0]);
							// var month2 = parseInt(dt[1]);
							// var year2 = parseInt(dt[2]);
							// var formattedDate2 = "";
							// if (day2 < 10 && month2 > 10) {
							//   formattedDate2 = "0" + day2 + "/" + month2 + "/" + year2;
							// }
							// if (month2 < 10 && day2 > 10) {
							//   formattedDate2 = "" + day2 + "/0" + month2 + "/" + year2;
							// }
							// if (day2 < 10 && month2 < 10) {
							//   formattedDate2 = "0" + day2 + "/0" + month2 + "/" + year2;
							// }
							// setDMY(formattedDate2.toString());
							setInput({
								...input,
								accName: {
									value: result.accName,
								},
								accNumber: {
									value: result.accNumber,
								},
								address1: {
									value: result.address1,
								},
								address2: {
									value: result.address2,
								},
								agreementDownloadUrl: {
									value: result.agreementDownloadUrl,
								},
								bankName: {
									value: result.bankName,
								},
								bankCode: {
									value: result.bankCode,
								},
								category: {
									value: result.category,
								},
								city: {
									value: result.city,
								},
								effectiveDate: {
									value: result.effectiveDate,
									// value: modifydate,
								},
								endDate: {
									value: result.endDate,
								},
								name: {
									value: result.name,
								},
								postcode: {
									value: result.postcode,
								},
								qrImageUrl: {
									value: result.qrImageUrl,
								},
								qrDownloadUrl: {
									value: result.qrDownloadUrl,
								},
								referenceNumber: {
									value: result.referenceNumber,
								},
								state: {
									value: result.state,
								},
								status: {
									value: result.status,
								},
								merchantId: {
									value: result.merchantId,
								},
								email: {
									value: result.email,
								},
								mobile: {
									value: result.mobileNumber,
								},
								businessRegNo: {
									value: result.businessRegNo,
								},
								subsidiary: {
									value: result.mainMerchantName !== null,
								},
								headquarters: {
									value: result.mainMerchantName || "",
								},
							});
							if (result.status === "ACTIVE") {
								setIsChecked(true);
							} else {
								setIsChecked(false);
							}
							// setReactDate(new Date(modifydate));
							return;
						}
					});
			} catch (error) {
				console.log("error", error);
			}
		};
		loadData();
		const fetchCategory = async () => {
			// fetch("http://49.0.203.50/ivc-app/common/api/v1/findDropdownOption/" + "MERCHANT_CATEGORY", {
			fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.COMMON_FIND_DROPDOWN_OPTION + "MERCHANT_CATEGORY", {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Accept: "*/*",
					"Accept-language": "en",
				},
			})
				.then((response) => response.json())
				.then((json) => {
					let res = json.result;
					setCategoryList(res);
					// console.log("category:", res)
				});
		};
		const fetchState = async () => {
			// fetch("http://49.0.203.50/ivc-app/common/api/v1/findDropdownOption/" + "STATE", {
			fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.COMMON_FIND_DROPDOWN_OPTION + "STATE", {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Accept: "*/*",
					"Accept-language": "en",
				},
			})
				.then((response) => response.json())
				.then((json) => {
					let res = json.result;
					setStateList(res);
					// console.log("state:", res, stateList)
				});
		};
		const fetchBank = async () => {
			// fetch("http://49.0.203.50/ivc-app/common/api/v1/findDropdownOption/" + "BANK", {
			fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.COMMON_FIND_DROPDOWN_OPTION + "BANK", {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Accept: "*/*",
					"Accept-language": "en",
				},
			})
				.then((response) => response.json())
				.then((json) => {
					let res = json.result;
					setBankList(res);
					// console.log("bank:", res, bankList)
				});
		};
		const fetchHeadquarters = async () => {
			let token = null;
			token = localStorage.getItem("@storage_token");
			fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.GET_MAIN_MERCHANT_LIST, {
				method: "GET",
				headers: {
					// "Content-Type": "application/json",
					Accept: "*/*",
					"Accept-language": "en",
					Authorization: "Bearer " + token,
				},
			})
				.then((response) => response.json())
				.then((json) => {
					let res = json.result;
					setHqList(res);
				});
		};
		fetchCategory();
		fetchState();
		fetchBank();
		fetchHeadquarters();
	}, []);

	const [nameValidate, setNameValidate] = useState("app-input-text");
	const [emailValidate, setEmailValidate] = useState("app-input-text");
	const [mobilePhoneValidate, setMobilePhoneValidate] = useState("app-input-text");
	const [categoryValidate, setCategoryValidate] = useState("app-input-select app-input-select__input");
	const [address1Validate, setAddress1Validate] = useState("select-input");
	const [cityValidate, setCityValidate] = useState("app-input-text");
	const [postcodeValidate, setPostcodeValidate] = useState("app-input-text");
	const [stateValidate, setStateValidate] = useState("app-input-select app-input-select__input");
	const [bankCodeValidate, setBankCodeValidate] = useState("app-input-select app-input-select__input");
	const [accNameValidate, setAccNameValidate] = useState("app-input-text");
	const [accNumberValidate, setAccNumberValidate] = useState("app-input-text");
	const [disableBtn, setDisableBtn] = useState(false);
	const [businessRegNoValidate, setBusinessRegNoValidate] = useState("app-input-text");
	const [hqNameValidate, setHqNameValidate] = useState("app-input-select app-input-select__input");

	const updateMerchantDetail = async () => {
		const nameValidate = CONSTANSTS.REGEX.checkEmpty.test(input.name.value);
		if (nameValidate === false && input.name.value === "") {
			setNameValidate("app-input-text-error");
		} else {
			setNameValidate("app-input-text");
		}
		const emailValidate = CONSTANSTS.REGEX.email.test(input.email.value);
		if (emailValidate === false && input.email.value === "") {
			setEmailValidate("app-input-text-error");
		} else {
			setEmailValidate("app-input-text");
		}
		const mobilePhoneValidate = inputText.mobile.regex.test(input.mobile.value);
		if (mobilePhoneValidate === false && input.mobile.value === "") {
			setMobilePhoneValidate("app-input-text-error");
		} else {
			setMobilePhoneValidate("app-input-text");
		}
		const categoryValidate = CONSTANSTS.REGEX.checkEmpty.test(input.category.value);
		if (categoryValidate === false) {
			setCategoryValidate("app-input-select-error app-input-select-error__input");
		} else {
			setCategoryValidate("app-input-select app-input-select__input");
		}
		const address1Validate = CONSTANSTS.REGEX.checkEmpty.test(input.address1.value);
		if (address1Validate === false) {
			setAddress1Validate("select-input-error");
		} else {
			setAddress1Validate("select-input");
		}
		const postcodeValidate = inputText.postcode.regex.test(input.postcode.value);
		if (postcodeValidate === false && input.postcode.value === "") {
			setPostcodeValidate("app-input-text-error");
		} else {
			setPostcodeValidate("app-input-text");
		}
		const cityValidate = CONSTANSTS.REGEX.checkEmpty.test(input.city.value);
		if (cityValidate === false && input.city.value === "") {
			setCityValidate("app-input-text-error");
		} else {
			setCityValidate("app-input-text");
		}
		const stateValidate = CONSTANSTS.REGEX.checkEmpty.test(input.state.value);
		if (stateValidate === false) {
			setStateValidate("app-input-select-error app-input-select-error__input");
		} else {
			setStateValidate("app-input-select app-input-select__input");
		}
		const bankCodeValidate = CONSTANSTS.REGEX.checkEmpty.test(input.bankCode.value);
		if (bankCodeValidate === false) {
			setBankCodeValidate("app-input-select-error app-input-select-error__input");
		} else {
			setBankCodeValidate("app-input-select app-input-select__input");
		}
		const accNameValidate = CONSTANSTS.REGEX.checkEmpty.test(input.accName.value);
		if (accNameValidate === false && input.accName.value === "") {
			setAccNameValidate("app-input-text-error");
		} else {
			setAccNameValidate("app-input-text");
		}
		const accNumberValidate = inputText.accNumber.regex.test(input.accNumber.value);
		if (accNumberValidate === false && input.accNumber.value === "") {
			setAccNumberValidate("app-input-text-error");
		} else {
			setAccNumberValidate("app-input-text");
		}
		const businessRegNoValidate = CONSTANSTS.REGEX.checkEmpty.test(input.businessRegNo.value);
		if (businessRegNoValidate === false && input.businessRegNo.value === "") {
			setBusinessRegNoValidate("app-input-text-error");
		} else {
			setBusinessRegNoValidate("app-input-text");
		}
		const hqNameValidate = CONSTANSTS.REGEX.checkEmpty.test(input.headquarters.value);
		if (hqNameValidate === false && input.headquarters.value === "" && input.subsidiary.value) {
			setHqNameValidate("app-input-text-error");
		} else {
			setHqNameValidate("app-input-text");
		}

		if (
			nameValidate === true &&
			input.name.value !== "" &&
			inputText.name.regex.test(input.name.value) === true &&
			emailValidate === true &&
			input.email.value !== "" &&
			mobilePhoneValidate === true &&
			input.mobile.value !== "" &&
			inputText.mobile.regex.test(input.mobile.value) === true &&
			categoryValidate === true &&
			input.category.value !== "" &&
			address1Validate === true &&
			input.address1.value !== "" &&
			cityValidate === true &&
			input.city.value !== "" &&
			postcodeValidate === true &&
			input.postcode.value !== "" &&
			inputText.postcode.regex.test(input.postcode.value) === true &&
			stateValidate === true &&
			input.state.value !== "" &&
			bankCodeValidate === true &&
			input.bankCode.value !== "" &&
			accNameValidate === true &&
			input.accName.value !== "" &&
			inputText.accName.regex.test(input.accName.value) === true &&
			accNumberValidate === true &&
			input.accNumber.value !== "" &&
			inputText.accNumber.regex.test(input.accNumber.value) === true &&
			businessRegNoValidate === true &&
			input.businessRegNo.value !== "" &&
			inputText.businessRegNo.regex.test(input.businessRegNo.value) === true &&
			input.subsidiary.value
				? hqNameValidate === true && input.headquarters.value !== ""
				: true
		) {
			setDisableBtn(true);
			setTimeout(() => {
				setDisableBtn(false);
			}, 6000);
			// const merchantId = id.location.state
			let token = null;
			token = localStorage.getItem("@storage_token");
			// fetch("http://49.0.203.50/ivc-app/bo/merchant/api/v1/update/" + merchantId, {
			fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.UPDATE_MERCHANT + merchantId, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Accept: "*/*",
					"Accept-language": "en",
					Authorization: "Bearer " + token,
				},
				body: JSON.stringify({
					address1: input.address1.value.trim(),
					address2: input.address2.value.trim(),
					bankAccName: input.accName.value.trim(),
					bankAccNumber: input.accNumber.value,
					bankCode: input.bankCode.value,
					category: input.category.value,
					city: input.city.value.trim(),
					name: input.name.value.trim(),
					postcode: input.postcode.value,
					state: input.state.value,
					status: isChecked === true ? "ACTIVE" : "DISABLED",
					effectiveDate: input.effectiveDate.value,
					email: input.email.value,
					mobileNumber: input.mobile.value,
					businessRegNo: input.businessRegNo.value.toUpperCase(),
					mainMerchantName: input.subsidiary.value ? input.headquarters.value : "",
				}),
			})
				.then(function (response) {
					return response.json();
				})
				.then(function (data) {
					const items = data;
					if (items.responseCode === 200) {
						// history.push(pathnames.pageMerchantList);
						messageDialog({ isOpen: true, condition: CONSTANSTS.DIALOG.success, title: CONSTANSTS.DIALOG_TITLE.general_success, message: items.message });
					} else {
						messageDialog({ isOpen: true, condition: CONSTANSTS.DIALOG.failed, title: CONSTANSTS.DIALOG_TITLE.general_failed, message: items.message, defaultOnClick: false });
					}
				});
		} else {
			return;
		}
	};

	const back = async () => {
		history.push(pathnames.pageMerchantList);
	};

	const [tab, setTab] = useState("1");
	const [tabCol1, setTabCol1] = useState("tab-selected");
	const [tabCol2, setTabCol2] = useState("tab2");
	const [tabCol3, setTabCol3] = useState("tab3");
	const [firstTab, setFirstTab] = useState("display");
	const [secondTab, setSecondTab] = useState("hide");
	const [thirdTab, setThirdTab] = useState("hide");
	// const [cdTab, setCDTab] = useState("contents");
	const [contentStyling, setContentStyling] = useState("merchant-detail");
	const tabClicked = (e) => {
		var tabNo = e;
		setTab(tabNo);
	};

	const onHandleSearch = () => {
		setTableSearchFilter({
			pageNo: tableSearchFilter.pageNo,
			pageSize: tableSearchFilter.pageSize,
			sortBy: tableSearchFilter.sortBy,
			sortDir: tableSearchFilter.sortDir,
			dateRangeType: selected == "- Select Range -" ? null : selected,
			dateFrom: tableSearchFilter.dateFrom != "string" ? tableSearchFilter.dateFrom : "string",
			dateTo: tableSearchFilter.dateTo != "string" ? tableSearchFilter.dateTo : "string",
			keyword: searchValue,
		});
	};

	const openFile = () => {
		let token = null;
		token = localStorage.getItem("@storage_token");
		let fileName = "";
		fileName = input.referenceNumber.value;
		fetch(input.agreementDownloadUrl.value, {
			method: "GET",
			headers: {
				"Content-Disposition": "attachment; filename=" + fileName + "_Merchant Agreement.pdf",
				"Content-Type": "application/pdf",
				Vary: "Origin,Access-Control-Request-Method,Access-Control-Request-Headers",
				"X-Content-Type-Options": "nosniff",
				"X-XSS-Protection": "1; mode=block",
				"Cache-Control": "no-cache, no-store, max-age=0, must-revalidate",
				"Keep-Alive": "timeout=5, max=100",
				Connection: "Keep-Alive",
				Authorization: "Bearer " + token,
			},
		}).then((response) => {
			let filename = response.headers.get("Content-Disposition");
			if (filename != null) {
				filename = filename.split(";")[1].split("filename=")[1];
			}
			response.blob().then((blob) => {
				let url = window.URL.createObjectURL(blob);
				let a = document.createElement("a");
				a.href = url;
				// a.download = fileName + "_Merchant Agreement.pdf";
				a.download = filename;
				a.click();
			});
		});
	};

	const openPDF = () => {
		let token = null;
		token = localStorage.getItem("@storage_token");
		let fileName = "";
		fileName = input.referenceNumber.value;
		// console.log(input.qrDownloadUrl.value)
		fetch(input.qrDownloadUrl.value, {
			method: "GET",
			headers: {
				"Content-Disposition": "attachment; filename=" + fileName + "_Merchant QR.pdf",
				"Content-Type": "application/pdf",
				Vary: "Origin,Access-Control-Request-Method,Access-Control-Request-Headers",
				"X-Content-Type-Options": "nosniff",
				"X-XSS-Protection": "1; mode=block",
				"Cache-Control": "no-cache, no-store, max-age=0, must-revalidate",
				"Keep-Alive": "timeout=5, max=100",
				Connection: "Keep-Alive",
				Authorization: "Bearer " + token,
			},
		}).then((response) => {
			let filename = response.headers.get("Content-Disposition");
			if (filename != null) {
				filename = filename.split(";")[1].split("filename=")[1];
			}
			response.blob().then((blob) => {
				// console.log(blob)
				let url = window.URL.createObjectURL(blob);
				let a = document.createElement("a");
				a.href = url;
				// a.download = fileName + "_Merchant QR.pdf";
				a.download = filename;
				a.click();
			});
		});
	};

	const terminate = (endDate) => {
		console.log(dmy);
		const appId = merchantId;
		let token = null;
		token = localStorage.getItem("@storage_token");
		let terminateDate = endDate.replace("/", "%2F").replace("/", "%2F").replace(",", "");
		console.log("terminateDate", terminateDate, endDate);

		// let payload = {
		//   id: Number(appId),
		//   endDate: endDate,
		// }

		// fetch("http://49.0.203.50/ivc-app/bo/merchant/api/v1/terminate/" + appId, {
		fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.TERMINATE_MERCHANT + appId + "?endDate=" + terminateDate, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				"Accept-language": "en",
				Authorization: "Bearer " + token,
			},
			// body: JSON.stringify(payload),
		})
			.then(function (response) {
				return response.json();
			})
			.then(function (data) {
				const items = data;
				// console.log(items)
				if (items.responseCode === 200) {
					// history.push(pathnames.pageMerchantList);
					messageDialog({ isOpen: true, condition: CONSTANSTS.DIALOG.success, title: CONSTANSTS.DIALOG_TITLE.general_success, message: items.message, defaultOnClick: false });
					setTerminatePopUp(false);
					reloadGetMerchant();
				} else {
					setTerminatePopUp(false);
				}
			});
	};

	const [isChecked, setIsChecked] = useState(null);
	const handleOnChange = () => {
		setStatusPopUp(true);
		// setIsChecked(!isChecked);
	};

	const onHandle = (event) => {
		const name = event.currentTarget.name;
		const value = event.currentTarget.value;

		if (name === "mobile" || name === "postcode" || name === "accNumber") {
			setInput({
				...input,
				[name]: {
					...input[name],
					error: errorMessage(name, value),
					value: value.replace(/\D/, ""),
				},
			});
		} else {
			setInput({
				...input,
				[name]: {
					...input[name],
					error: errorMessage(name, value),
					value: value,
				},
			});
		}
		checkEmpty(name, value);
	};

	const checkEmpty = (name, value) => {
		// console.log(name, value)
		if (name === "name") {
			if (value === "") {
				setNameValidate("app-input-text-error");
			} else {
				setNameValidate("app-input-text");
			}
		}
		if (name === "email") {
			if (value === "") {
				setEmailValidate("app-input-text-error");
			} else {
				setEmailValidate("app-input-text");
			}
		}
		if (name === "mobile") {
			if (value === "") {
				setMobilePhoneValidate("app-input-text-error");
			} else {
				setMobilePhoneValidate("app-input-text");
			}
		}
		if (name === "category") {
			if (value === "") {
				setCategoryValidate("app-input-select-error app-input-select-error__input");
			} else {
				setCategoryValidate("app-input-select app-input-select__input");
			}
		}
		if (name === "address1") {
			if (value === "") {
				setAddress1Validate("select-input-error");
			} else {
				setAddress1Validate("select-input");
			}
		}
		if (name === "city") {
			if (value === "") {
				setCityValidate("app-input-text-error");
			} else {
				setCityValidate("app-input-text");
			}
		}
		if (name === "postcode") {
			if (value === "") {
				setPostcodeValidate("app-input-text-error");
			} else {
				setPostcodeValidate("app-input-text");
			}
		}
		if (name === "state") {
			if (value === "") {
				setStateValidate("app-input-select-error app-input-select-error__input");
			} else {
				setStateValidate("app-input-select app-input-select__input");
			}
		}
		if (name === "bankCode") {
			if (value === "") {
				setBankCodeValidate("app-input-select-error app-input-select-error__input");
			} else {
				setBankCodeValidate("app-input-select app-input-select__input");
			}
		}
		if (name === "accName") {
			if (value === "") {
				setAccNameValidate("app-input-text-error");
			} else {
				setAccNameValidate("app-input-text");
			}
		}
		if (name === "accNumber") {
			if (value === "") {
				setAccNumberValidate("app-input-text-error");
			} else {
				setAccNumberValidate("app-input-text");
			}
		}
		if (name === "businessRegNo") {
			if (value === "") {
				setBusinessRegNoValidate("app-input-text-error");
			} else {
				setBusinessRegNoValidate("app-input-text");
			}
		}
		// if (name === "terminalId") {
		//   if (value === "") {
		//     setTerminalIdValidate("app-input-text-error");
		//   } else {
		//     setTerminalIdValidate("app-input-text");
		//   }
		// }
		if (name === "deviceName") {
			if (value === "") {
				setDeviceNameValidate("app-input-text-error");
			} else {
				setDeviceNameValidate("app-input-text");
			}
		}
		if (name === "headquarters") {
			if (value === "" && input.subsidiary.value) {
				setHqNameValidate("app-input-text-error");
			} else {
				setHqNameValidate("app-input-text");
			}
		}
	};

	const errorMessage = (name, value) => {
		if (!value) {
			return inputText[name].required;
		} else if (!inputText[name].regex.test(value)) {
			return inputText[name].invalid;
		} else {
			return "";
		}
	};

	// secondTab //

	const [tableSearchFilter, setTableSearchFilter] = useState({
		// insert the key value requested by BE here
		pageNo: 1,
		pageSize: 50,
		sortBy: "id",
		sortDir: "ASCENDING",
		dateRangeType: null,
		dateFrom: "string",
		dateTo: "string",
		keyword: "",
	});

	// trigger when user click on header when sorting
	const headerFilterClick = (header) => {
		let column = header.column;
		if (tableSearchFilter.sortBy === column.searchFilterValue) {
			// if click on the same column previously clicked will enter here
			if (tableSearchFilter.sortDir === "ASCENDING") {
				// if previously the column is sort by ascending order, sortDir will change to sort by descending order
				setTableSearchFilter({
					...tableSearchFilter,
					sortBy: column.searchFilterValue,
					sortDir: "DESCENDING",
					pageNo: 1,
				});
			} else {
				// if previously the column is sort by descending order, sortDir will change to sort by ascending order
				setTableSearchFilter({
					...tableSearchFilter,
					sortBy: column.searchFilterValue,
					sortDir: "ASCENDING",
					pageNo: 1,
				});
			}
		} else {
			// if click on a new column, it will sort by ascending order with the column value (column.searchFilterValue)
			setTableSearchFilter({
				...tableSearchFilter,
				sortBy: column.searchFilterValue,
				sortDir: "ASCENDING",
				pageNo: 1,
			});
		}
	};

	const headerArrowRotate = (active) => {
		// current column header arrow icon will rotate depending on ascending order or descending order (tableSearchFilter.sortDir)
		const classNames = ["react-table__icon-wrapper"];
		if (active && tableSearchFilter.sortDir === "DESCENDING") classNames.push("react-table__icon-wrapper-down");
		if (active) classNames.push("react-table__icon-wrapper--show");
		return classNames.join(" ");
	};

	// tableCoumn is to create table column
	const tableColumn = [
		{
			id: "referenceNumber",
			// header is the table header for id column
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick(header)} className="react-table__header">
						<span className="react-table__header-title">Transaction Ref</span>
						<div className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			// accessor will list out the table data based on the data key. In this case, the mockData key of id
			accessor: "referenceNumber",
			// searchFilterValue is the column value that will send to BE to inform BE to sort according to which column. it is use to update tableSearchFilter.searchValue
			searchFilterValue: "referenceNumber",
			// minWidth, width, maxWidth is to adjust the width of this current column
			minWidth: 200,
			width: 200,
		},
		{
			id: "customerName",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick(header)} className="react-table__header">
						<span className="react-table__header-title">Customer Name</span>
						<div className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			searchFilterValue: "customerName",
			accessor: "customerName",
			minWidth: 200,
		},
		{
			id: "amount",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick(header)} className="react-table__header">
						<span className="react-table__header-title">Amount</span>
						<div className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			searchFilterValue: "amount",
			accessor: "amount",
			minWidth: 120,
			width: 120,
		},
		{
			id: "dateCreated",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick(header)} className="react-table__header">
						<span className="react-table__header-title">Transaction Date</span>
						<div className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			searchFilterValue: "dateCreated",
			accessor: "dateCreated",
			minWidth: 160,
			width: 160,
		},
		{
			id: "status",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick(header)} className="react-table__header">
						<span className="react-table__header-title">Status</span>
						<div className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			searchFilterValue: "status",
			accessor: "status",
			minWidth: 160,
			width: 160,
		},
		{
			id: "remarks",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick(header)} className="react-table__header">
						<span className="react-table__header-title">Remarks</span>
						<div className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			searchFilterValue: "remarks",
			accessor: "remarks",
			minWidth: 160,
			width: 160,
		},
	];

	// third tab
	const [terminalTable, setTerminalTable] = useState({
		// insert the key value requested by BE here
		pageNo: 1,
		pageSize: 50,
		sortDir: "ASCENDING",
		sortBy: "id",
	});

	const [terminalId, setTerminalId] = useState("");
	const [cancelPopUp2, setCancelPopUp2] = useState(false);

	function PopupCancel2(props) {
		return props.trigger ? (
			<div className="pop-up">
				<div className="pop-up-data">
					{props.children}
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<button className="pop-up-button" style={{ marginRight: "10px" }} onClick={() => cancelTransaction(terminalId)}>
							OK
						</button>
						<button className="pop-up-button" style={{ marginLeft: "10px" }} onClick={() => [setCancelPopUp2(false)]}>
							Cancel
						</button>
					</div>
				</div>
			</div>
		) : (
			<div></div>
		);
	}

	const cancelTransaction = async (value) => {
		let id = "";
		id = value.id;
		// console.log(id)
		let token = null;
		token = localStorage.getItem("@storage_token");
		fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.DELETE_MERCHANT_TERMINAL + "?id=" + id, {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				"Accept-language": "en",
				Authorization: "Bearer " + token,
			},
		})
			.then(function (response) {
				return response.json();
			})
			.then(function (data) {
				const items = data;
				if (items.responseCode === 200) {
					setCancelPopUp2(false);
					messageDialog({
						isOpen: true,
						condition: CONSTANSTS.DIALOG.success,
						title: CONSTANSTS.DIALOG_TITLE.general_success,
						message: items.message,
						defaultOnClick: false,
					});
					getMerchantTerminalList();
				} else {
					messageDialog({
						isOpen: true,
						condition: CONSTANSTS.DIALOG.failed,
						title: CONSTANSTS.DIALOG_TITLE.general_failed,
						message: items.message,
						defaultOnClick: false,
					});
				}
			});
	};

	const terminalTableColumn = [
		{
			id: "terminalId",
			// header is the table header for id column
			Header: (header) => {
				return (
					<div className="react-table__header">
						<span className="react-table__header-title">Terminal ID</span>
						<div className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			// accessor will list out the table data based on the data key. In this case, the mockData key of id
			accessor: "terminalId",
			// searchFilterValue is the column value that will send to BE to inform BE to sort according to which column. it is use to update tableSearchFilter.searchValue
			searchFilterValue: "terminalId",
			// minWidth, width, maxWidth is to adjust the width of this current column
			minWidth: 200,
			width: 200,
		},
		{
			id: "deviceName",
			Header: (header) => {
				return (
					<div className="react-table__header">
						<span className="react-table__header-title">Device</span>
						<div className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			searchFilterValue: "deviceName",
			accessor: "deviceName",
			minWidth: 200,
		},
		{
			id: "button",
			Header: "",
			Cell: (row) => {
				return (
					accessMerchantTerminal.delete_merchant_terminal && (
						<div onClick={(e) => e.stopPropagation()}>
							{/* {row.row.original.status == CONSTANSTS.CONTRACT_STATUS.pending || row.row.original.status == CONSTANSTS.CONTRACT_STATUS.disbursed &&row.row.original.status !== "Cancelled" ? (
              "   —"
            ) :row.row.original.status === "Cancelled" ?(
              <button onClick={() => [setUndoCancelPopUp2(true), setTransactionId(row.row.original)]} className="react-table__search-button">
                <RiArrowGoBackFill size={24} />
              </button>
            ) : ( */}
							<button onClick={() => [setCancelPopUp2(true), setTerminalId(row.row.original)]} className="react-table__search-button">
								<IoClose size={24} />
							</button>
							{/* )} */}
							<PopupCancel2 trigger={cancelPopUp2}>
								<div className="pop-up-message">
									{/* <input
                  type="text"
                  placeholder="Please provide remarks"
                  style={{ fontSize: "20px", width: "80%" }}
                  name="remark2"
                  onChange={handleOnChange2}
                /> */}
									<p>Are you sure you want to cancel this terminal?</p>
								</div>
							</PopupCancel2>
						</div>
					)
				);
			},
			minWidth: 90,
			width: 90,
		},
	];

	const [appList, setAppList] = useState(undefined);
	const [terminalList, setTerminalList] = useState(undefined);

	const [searchValue, setSearchValue] = useState("");

	const [paginationLocker, setPaginationLocker] = useState(true);
	const [paginationTerminalLocker, setPaginationTerminalLocker] = useState(true);
	const [exportBtn, setExportBtn] = useState("primary");

	useEffect(() => {
		const fetchData = async () => {
			let token = null;
			token = localStorage.getItem("@storage_token");
			try {
				// let response = fetch("http://49.0.203.50/ivc-app/bo/merchant/api/v1/transactionList", {
				let response = fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.GET_MERCHANT_TRANSACTION_LIST + "?merchantId=" + merchantId, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Accept: "*/*",
						"Accept-language": "en",
						Authorization: "Bearer " + token,
					},
					body: JSON.stringify({
						criteria: {
							dateFrom: tableSearchFilter.dateFrom,
							dateRangeType: tableSearchFilter.dateRangeType,
							dateTo: tableSearchFilter.dateTo,
							keyword: tableSearchFilter.keyword,
						},
						pageNumber: tableSearchFilter.pageNo,
						pageSize: tableSearchFilter.pageSize,
						sortDirection: tableSearchFilter.sortDir,
						sortKey: tableSearchFilter.sortBy,
					}),
				})
					.then((response) => response.json())
					.then((json) => {
						let result = json;
						// console.log(result)
						if (result.responseCode === 200) {
							setAppList(result.result);
							setPaginationLocker(false);
							if (result.result.totalCount === 0) {
								setExportBtn("dimmed");
							} else {
								setExportBtn("primary");
							}
						} else {
							messageDialog({ isOpen: true, condition: CONSTANSTS.DIALOG.failed, title: CONSTANSTS.DIALOG_TITLE.general_failed, message: response.message, defaultOnClick: false });
						}
					});
			} catch (error) {
				console.log(error);
			}
		};
		fetchData();
	}, [tableSearchFilter]);

	const getMerchantTerminalList = () => {
		const fetchData = async () => {
			let token = null;
			token = localStorage.getItem("@storage_token");
			try {
				let response = fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.LIST_MERCHANT_TERMINAL + "?merchantId=" + merchantId, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Accept: "*/*",
						"Accept-language": "en",
						Authorization: "Bearer " + token,
					},
					body: JSON.stringify({
						criteria: {
							keyword: "",
						},
						pageNumber: terminalTable.pageNo,
						pageSize: terminalTable.pageSize,
						sortDirection: terminalTable.sortDir,
						sortKey: terminalTable.sortBy,
					}),
				})
					.then((response) => response.json())
					.then((json) => {
						let result = json;
						console.log("terminal table", result);
						if (result.responseCode === 200) {
							setTerminalList(result.result);
							setPaginationTerminalLocker(false);
							if (result.result.totalCount === 0) {
								setExportBtn("dimmed");
							} else {
								setExportBtn("orange");
							}
						} else {
							messageDialog({ isOpen: true, condition: CONSTANSTS.DIALOG.failed, title: CONSTANSTS.DIALOG_TITLE.general_failed, message: response.message, defaultOnClick: false });
						}
					});
			} catch (error) {
				console.log(error);
			}
		};
		fetchData();
	};

	useEffect(() => {
		setTerminalInput(terminalInputField);
		getMerchantTerminalList();
	}, []);

	const onKeyUp = (event) => {
		if (event.key === "Enter") {
			console.log("enter press here! ", event);
		}
	};

	const onBlur = (e) => {
		setSearchValue(e.target.value);
	};

	const dateFields = {
		dateRange: null,
		dateFrom: "",
		dateTo: "",
	};

	const inputDateProp = [
		{ type: "text", placeholder: "Start Date", value: "" },
		{ type: "text", placeholder: "End Date", value: "" },
	];

	const [dateInput, setDateInput] = useState(dateFields);
	const [selected, setSelected] = useState(null);
	const [dateType, setDateType] = useState(inputDateProp);
	const [mindate, setMinDate] = useState("");
	const [maxDate, setMaxDate] = useState("");

	const onHandleOnChange = (event) => {
		var value = event.currentTarget.value;
		if (value === "- Select Range -") {
			setSelected(null);
			value = null;
		} else {
			setSelected(value);
		}

		setDateType(inputDateProp);
		setMinDate("");
		setMaxDate("");

		setTableSearchFilter({
			pageNo: 1,
			pageSize: tableSearchFilter.pageSize,
			sortBy: tableSearchFilter.sortBy,
			sortDir: tableSearchFilter.sortDir,
			dateRangeType: value,
			dateFrom: "string",
			dateTo: "string",
			keyword: tableSearchFilter.keyword,
		});
	};

	const onHandleCheckbox = () => {
		setInput({
			...input,
			subsidiary: {
				value: !input.subsidiary.value,
				error: "",
			},
		});
	};

	const onFocusDateStart = () => {
		setDateType([
			{
				type: "date",
				placeholder: dateType[0].placeholder,
				value: dateType[0].value,
			},
			{
				type: "date",
				placeholder: dateType[1].placeholder,
				value: dateType[1].value,
			},
		]);
	};

	const onFocusDateEnd = () => {
		setDateType([
			{
				type: "date",
				placeholder: dateType[0].placeholder,
				value: dateType[0].value,
			},
			{
				type: "date",
				placeholder: dateType[1].placeholder,
				value: dateType[1].value,
			},
		]);
	};

	const onChangeDateStart = (event) => {
		dateType[0].value = event.currentTarget.value.toString();
		const value = event.currentTarget.value.toString();
		console.log("start date", value);
		setMinDate(value);
		let date = value;
		let dateSplit = date.split("-");
		console.log(dateSplit[2] + "/" + dateSplit[1] + "/" + dateSplit[0]);
		setDateInput({ dateFrom: dateSplit[2] + "/" + dateSplit[1] + "/" + dateSplit[0], dateTo: dateInput.dateTo });
		setSelected("- Select Range -");

		if (maxDate !== "") {
			setTableSearchFilter({
				pageNo: tableSearchFilter.pageNo,
				pageSize: tableSearchFilter.pageSize,
				sortBy: tableSearchFilter.sortBy,
				sortDir: tableSearchFilter.sortDir,
				dateRangeType: null,
				// dateFrom: dateInput.dateFrom,
				dateFrom: dateSplit[2] + "/" + dateSplit[1] + "/" + dateSplit[0],
				dateTo: dateInput.dateTo,
				keyword: tableSearchFilter.keyword,
			});
		}
	};

	const onChangeDateEnd = (event) => {
		dateType[1].value = event.currentTarget.value.toString();
		const value = event.currentTarget.value.toString();
		console.log("end date ", value);
		setMaxDate(value);
		let date = value;
		let dateSplit = date.split("-");
		console.log(dateSplit[2] + "/" + dateSplit[1] + "/" + dateSplit[0]);
		setDateInput({ dateFrom: dateInput.dateFrom, dateTo: dateSplit[2] + "/" + dateSplit[1] + "/" + dateSplit[0] });
		setSelected("- Select Range -");

		if (mindate !== "") {
			setTableSearchFilter({
				pageNo: tableSearchFilter.pageNo,
				pageSize: tableSearchFilter.pageSize,
				sortBy: tableSearchFilter.sortBy,
				sortDir: tableSearchFilter.sortDir,
				dateRangeType: null,
				dateFrom: dateInput.dateFrom,
				dateTo: dateSplit[2] + "/" + dateSplit[1] + "/" + dateSplit[0],
				keyword: tableSearchFilter.keyword,
			});
		}
	};

	const nextPage = async () => {
		if (appList.currentPageNumber < appList.totalPageNumber && !paginationLocker) {
			setTableSearchFilter({
				pageNo: tableSearchFilter.pageNo + 1,
				pageSize: tableSearchFilter.pageSize,
				sortBy: tableSearchFilter.sortBy,
				sortDir: tableSearchFilter.sortDir,
				dateRangeType: tableSearchFilter.dateRangeType,
				dateFrom: tableSearchFilter.dateFrom,
				dateTo: tableSearchFilter.dateTo,
				keyword: tableSearchFilter.keyword,
			});
			setPaginationLocker(true);
		} else if (appList.currentPageNumber === appList.totalPageNumber) {
			setPaginationLocker(false);
		}
	};

	const previousPage = async () => {
		if (appList.currentPageNumber > 1 && !paginationLocker) {
			setTableSearchFilter({
				pageNo: tableSearchFilter.pageNo - 1,
				pageSize: tableSearchFilter.pageSize,
				sortBy: tableSearchFilter.sortBy,
				sortDir: tableSearchFilter.sortDir,
				dateRangeType: tableSearchFilter.dateRangeType,
				dateFrom: tableSearchFilter.dateFrom,
				dateTo: tableSearchFilter.dateTo,
				keyword: tableSearchFilter.keyword,
			});
			setPaginationLocker(true);
		} else if (appList.currentPageNumber === 1) {
			setPaginationLocker(false);
		}
	};

	/*
  // Parse our locale string to [date, time]
  var date = new Date().toLocaleString("ms", { hour12: false }).split(" ");

  // Now we can access our time at date[1], and monthdayyear @ date[0]
  // var time = date[1];
  var mdy = date[0];

  // We then parse  the mdy into parts
  mdy = mdy.split("/");
  var day = parseInt(mdy[0]);
  var month = parseInt(mdy[1]);
  var year = parseInt(mdy[2]);

  // Putting it all together
  // var formattedDate = day + '-' + month + '-' + year + ' ' + time;
  var formattedDate = "";
  if (day < 10 && month > 10) {
    formattedDate = "0" + day + "" + month + "" + year;
  }
  if (month < 10 && day > 10) {
    formattedDate = "" + day + "0" + month + "" + year;
  }
  if (day < 10 && month < 10) {
    formattedDate = "0" + day + "0" + month + "" + year;
  }
  const [currentDate] = useState(formattedDate.toString());
  */

	const nextTerminalPage = async () => {
		if (terminalList.currentPageNumber < terminalList.totalPageNumber && !paginationTerminalLocker) {
			setTerminalTable({
				pageNo: terminalTable.pageNo + 1,
				pageSize: terminalTable.pageSize,
				sortBy: terminalTable.sortBy,
				sortDir: terminalTable.sortDir,
			});
			setPaginationTerminalLocker(true);
		} else if (terminalList.currentPageNumber === terminalList.totalPageNumber) {
			setPaginationTerminalLocker(false);
		}
	};

	const previousTerminalPage = async () => {
		if (terminalList.currentPageNumber > 1 && !paginationTerminalLocker) {
			setTerminalTable({
				pageNo: terminalTable.pageNo - 1,
				pageSize: terminalTable.pageSize,
				sortBy: terminalTable.sortBy,
				sortDir: terminalTable.sortDir,
			});
			setPaginationTerminalLocker(true);
		} else if (terminalList.currentPageNumber === 1) {
			setPaginationTerminalLocker(false);
		}
	};

	const exportList = (btn) => {
		if (btn === "primary") {
			let token = null;
			token = localStorage.getItem("@storage_token");
			try {
				// let response = fetch("http://49.0.203.50/ivc-app/bo/merchant/api/v1/exportTransactionList", {
				fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.GET_MERCHANT_EXPORT_TRANSACTION_LIST + "?merchantId=" + merchantId, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Accept: "*/*",
						"Accept-language": "en",
						Authorization: "Bearer " + token,
					},
					body: JSON.stringify({
						criteria: {
							dateFrom: tableSearchFilter.dateFrom,
							dateRangeType: tableSearchFilter.dateRangeType,
							dateTo: tableSearchFilter.dateTo,
							keyword: tableSearchFilter.keyword,
						},
						pageNumber: tableSearchFilter.pageNo,
						pageSize: tableSearchFilter.pageSize,
						sortDirection: tableSearchFilter.sortDir,
						sortKey: tableSearchFilter.sortBy,
					}),
				}).then((response) => {
					let filename = response.headers.get("Content-Disposition");
					if (filename != null) {
						filename = filename.split(";")[1].split("filename=")[1];
					}
					response.blob().then((blob) => {
						console.log(blob);
						let url = window.URL.createObjectURL(blob);
						let a = document.createElement("a");
						a.href = url;
						// a.download = "Merchant_Transaction_" + currentDate + ".xlsx";
						a.download = filename;
						a.click();
					});
				});
			} catch (error) {
				console.log(error);
			}
		} else {
			return;
		}
	};

	// const [showSelectTime, setShowSelectTime] = useState(false);
	// const selectTimeToggle = () => {
	//     setShowSelectTime(!showSelectTime);
	// };

	const [terminatePopUp, setTerminatePopUp] = useState(false);
	const [statusPopUp, setStatusPopUp] = useState(false);
	const [cancelPopUp, setCancelPopUp] = useState(false);
	const [calendarPopUp, setCalendarPopUp] = useState(false);
	// const [transactionId, setTransactionId] = useState();
	function Popup(props) {
		return props.trigger ? (
			<div className="pop-up">
				<div className="pop-up-data">
					{props.children}
					<div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
						<button className="pop-up-button" style={{ marginRight: "10px" }} onClick={() => [setTerminatePopUp(false), setCalendarPopUp(true)]}>
							{/* onClick={terminate} */}
							Yes
						</button>
						<button className="pop-up-button" style={{ marginLeft: "10px" }} onClick={() => [setTerminatePopUp(false)]}>
							No
						</button>
					</div>
				</div>
			</div>
		) : (
			<div></div>
		);
	}

	function PopupStatus(props) {
		return props.trigger ? (
			<div className="pop-up">
				<div className="pop-up-data">
					{props.children}
					<div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
						<button className="pop-up-button" style={{ marginRight: "10px" }} onClick={() => [setIsChecked(!isChecked), setStatusPopUp(false)]}>
							Yes
						</button>
						<button className="pop-up-button" style={{ marginLeft: "10px" }} onClick={() => setStatusPopUp(false)}>
							No
						</button>
					</div>
				</div>
			</div>
		) : (
			<div></div>
		);
	}

	function PopupCancel(props) {
		return props.trigger ? (
			<div className="pop-up">
				<div className="pop-up-data">
					{props.children}
					<div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", marginTop: "20px" }}>
						<button className="pop-up-button" style={{ marginRight: "10px" }} onClick={() => createNewTerminal()}>
							Add
						</button>
						<button className="pop-up-button" style={{ marginLeft: "10px" }} onClick={() => [setCancelPopUp(false)]}>
							Cancel
						</button>
					</div>
				</div>
			</div>
		) : (
			<div></div>
		);
	}

	function PopUpCalendar(props) {
		return props.trigger ? (
			<div className="pop-up">
				<div className="pop-up-data">
					<div style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
						<IoClose
							style={{ cursor: "pointer" }}
							size={30}
							onClick={() => {
								setCalendarPopUp(false);
							}}
						/>
					</div>
					{props.children}
				</div>
			</div>
		) : (
			<div></div>
		);
	}

	// const [reactDate, setReactDate] = useState(new Date());
	const [dmy, setDMY] = useState("");
	const changeDate = (reactDate) => {
		// setReactDate(reactDate);
		//
		let dateFormat = reactDate.toLocaleString("ms", { hour12: false }).split(" ");
		var dt = dateFormat[0].split("/");
		// console.log(dt);
		var day3 = parseInt(dt[0]);
		var month3 = parseInt(dt[1]);
		var year3 = parseInt(dt[2]);
		var formattedDate3 = "";
		if (day3 < 10 && month3 < 10) {
			formattedDate3 = "0" + day3 + "/0" + month3 + "/" + year3;
		} else if (day3 < 10 && month3 >= 10) {
			formattedDate3 = "0" + day3 + "/" + month3 + "/" + year3;
		} else if (month3 < 10 && day3 >= 10) {
			formattedDate3 = "" + day3 + "/0" + month3 + "/" + year3;
		} else {
			formattedDate3 = "" + day3 + "/" + month3 + "/" + year3;
		}
		console.log("reactDate", reactDate);
		console.log("dateFormat", dateFormat[0], dt);
		console.log("get terminate date", formattedDate3.toString());
		// setDMY(formattedDate3.toString());
		setCalendarPopUp(false);
		terminate(formattedDate3.toString());
	};

	const createNewTerminal = () => {
		let id = merchantId;
		let token = null;
		token = localStorage.getItem("@storage_token");
		fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.CREATE_MERCHANT_TERMINAL, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				"Accept-language": "en",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify({
				deviceId: terminalInput.deviceName.value,
				merchantId: Number(id),
				terminalId: terminalInput.terminalId.value,
			}),
		})
			.then(function (response) {
				return response.json();
			})
			.then(function (data) {
				const items = data;
				if (items.responseCode === 200) {
					setCancelPopUp(false);
					messageDialog({
						isOpen: true,
						condition: CONSTANSTS.DIALOG.success,
						title: CONSTANSTS.DIALOG_TITLE.general_success,
						message: items.message,
						defaultOnClick: false,
					});
					getMerchantTerminalList();
				} else {
					messageDialog({
						isOpen: true,
						condition: CONSTANSTS.DIALOG.failed,
						title: CONSTANSTS.DIALOG_TITLE.general_failed,
						message: items.message,
						defaultOnClick: false,
					});
				}
			});
	};

	const terminalInputField = {
		terminalId: {
			value: "",
			error: "",
		},
		deviceName: {
			value: "",
			error: "",
		},
	};

	const [terminalInput, setTerminalInput] = useState();
	// const [terminalIdValidate, setTerminalIdValidate] = useState("app-input-text");
	const [deviceNameValidate, setDeviceNameValidate] = useState("app-input-text");

	const onHandleCreateTerminal = (event) => {
		const name = event.currentTarget.name;
		const value = event.currentTarget.value;
		terminalInput.deviceName.error = errorMessageForTerminal(name, value);
		terminalInput.deviceName.value = value;
		// setTerminalInput({
		//   ...terminalInput,
		//   [name]: {
		//     ...terminalInput[name],
		//     error: errorMessageForTerminal(name, value),
		//     value: value,
		//   },
		// });

		checkEmpty(name, value);
	};

	const terminalInputText = {
		terminalId: {
			placeholder: "Terminal Id",
			required: "Terminal Id required",
			invalid: "Invalid Terminal Id",
			regex: CONSTANSTS.REGEX.checkEmpty,
		},
		deviceName: {
			placeholder: "Device Name",
			required: "Device Name required",
			invalid: "Invalid Device Name",
			regex: CONSTANSTS.REGEX.checkEmpty,
		},
	};

	const errorMessageForTerminal = (name, value) => {
		if (!value) {
			return terminalInputText[name].required;
		} else if (!terminalInputText[name].regex.test(value)) {
			return terminalInputText[name].invalid;
		} else {
			return "";
		}
	};

	const tabClassNames = (no) => {
		return classNames({
			tablinks: true,
			[`tab${no}`]: true,
			"tab-selected": tab === no,
		});
	};

	return (
		<div className="merchant-view">
			<div className="top-context">
				<div className="tabs">
					<div className={tabClassNames("1")} onClick={() => tabClicked("1")}>
						<span>Merchant Details</span>
					</div>
					<div className={tabClassNames("2")} onClick={() => tabClicked("2")}>
						<span>Transaction</span>
					</div>
					<div className={tabClassNames("3")} onClick={() => tabClicked("3")}>
						<span>Terminal</span>
					</div>

					{tab === "1" && accessMerchantDetails.update_merchant && (
						<div className="tabs-action">
							<div className="search-button search-button--cancel">
								<AppButton colorType="primary" label="Cancel" onClick={back}></AppButton>
							</div>
							<div className="search-button">
								<AppButton colorType="primary" label="Update" onClick={updateMerchantDetail} disabled={disableBtn}></AppButton>
							</div>
						</div>
					)}

					{tab === "2" && (
						<div className="tabs-action">
							<div className="search-bar">
								<AppSearchBar placeholder="Search for..." onKeyEnter={onKeyUp} onBlur={onBlur} onClick={onHandleSearch}></AppSearchBar>
							</div>
							{accessMerchantTrx.export_transaction_list && (
								<div className="search-button">
									<AppButton colorType={exportBtn} label="Export" onClick={() => exportList(exportBtn)}></AppButton>
								</div>
							)}
						</div>
					)}
					{tab === "3" && accessMerchantTerminal.create_merchant_terminal && (
						<div className="tabs-action">
							<div className="search-button">
								<AppButton colorType="primary" label="Add New Terminal" onClick={() => setCancelPopUp(true)} disabled={disableBtn}></AppButton>
							</div>
							<PopupCancel trigger={cancelPopUp}>
								<p className="pop-up-message">Add New Terminal</p>
								<div className="pop-up-message" style={{ display: "flex", justifyContent: "center" }}>
									<div className="app-input">
										<span style={{ fontSize: "20px", marginRight: "15px" }}>Device Name: </span>
									</div>
									<div className="app-input">
										<input
											type="text"
											placeholder={terminalInputText.deviceName.placeholder}
											className={deviceNameValidate}
											name="deviceName"
											onChange={onHandleCreateTerminal}
											style={{ width: "400px", height: "50px" }}
										/>
									</div>
								</div>
							</PopupCancel>
						</div>
					)}
				</div>
			</div>
			<div className="merchant-detail__content">
				{tab === "1" && (
					<div className="merchant-detail__wrapper">
						<div className="input-form">
							<div className="input-content">
								<div className="input-container">
									<span>Merchant ID</span>
									<div className="input-wrapper">
										<input type="text" className="app-input-text" name="referenceNumber" value={input.referenceNumber.value} readOnly />
									</div>
									<p className="app-auth-input__error"></p>
								</div>
								<div className="input-container">
									<span>Merchant Name</span>
									<div className="input-wrapper">
										<input type="text" className={nameValidate} name="name" value={input.name.value} onChange={onHandle} maxLength={50} readOnly={!accessMerchantDetails.update_merchant} />
									</div>
									<p className="app-auth-input__error">{input.name.error}</p>
								</div>
							</div>
							<div className="input-content">
								<div className="input-container">
									<span>Merchant Email</span>
									<div className="input-wrapper">
										<input type="text" className={emailValidate} name="email" value={input.email.value} onChange={onHandle} readOnly={!accessMerchantDetails.update_merchant} />
									</div>
									<p className="app-auth-input__error">{input.email.error}</p>
								</div>
								<div className="input-container">
									<span>Mobile Phone</span>
									<div className="input-wrapper">
										<input type="text" className={mobilePhoneValidate} name="mobile" value={input.mobile.value} onChange={onHandle} maxLength={11} readOnly={!accessMerchantDetails.update_merchant} />
									</div>
									<p className="app-auth-input__error">{input.mobile.error}</p>
								</div>
							</div>
							<div className="input-content">
								<div className="input-container">
									<span>Category</span>
									<div className="input-wrapper">
										<select name="category" id="category" className={categoryValidate} value={input.category.value} onChange={onHandle} disabled={!accessMerchantDetails.update_merchant}>
											<option value="" selected>
												-- Category --
											</option>
											{categoryList
												?.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))
												?.map((d, i) => (
													<option value={d.code} key={i}>
														{d.label}
													</option>
												))}
										</select>
									</div>
									<p className="app-auth-input__error">{input.category.error}</p>
								</div>
								<div className="input-container" />
							</div>
							<div className="input-content">
								<div className="input-container">
									<span>Address 1</span>
									<div className="input-wrapper">
										<textarea
											name="address1"
											id="address1"
											className={address1Validate}
											value={input.address1.value}
											onChange={onHandle}
											maxLength={100}
											readOnly={!accessMerchantDetails.update_merchant}
										></textarea>
									</div>
									<p className="app-auth-input__error">{input.address1.error}</p>
								</div>
								<div className="input-container">
									<span>Address 2</span>
									<div className="input-wrapper">
										<textarea
											name="address2"
											id="address2"
											className="select-input"
											value={input.address2.value}
											onChange={onHandle}
											maxLength={100}
											readOnly={!accessMerchantDetails.update_merchant}
										></textarea>
									</div>
									<p className="app-auth-input__error">{input.address2.error}</p>
								</div>
							</div>
							<div className="input-content">
								<div className="input-container">
									<span>City / Town</span>
									<div className="input-wrapper">
										<input type="text" className={cityValidate} name="city" value={input.city.value} onChange={onHandle} maxLength={50} readOnly={!accessMerchantDetails.update_merchant} />
									</div>
									<p className="app-auth-input__error">{input.city.error}</p>
								</div>
								<div className="input-container">
									<span>Postcode</span>
									<div className="input-wrapper">
										<input type="text" className={postcodeValidate} name="postcode" value={input.postcode.value} onChange={onHandle} maxLength={5} readOnly={!accessMerchantDetails.update_merchant} />
									</div>
									<p className="app-auth-input__error">{input.postcode.error}</p>
								</div>
							</div>
							<div className="input-content">
								<div className="input-container">
									<span>State</span>
									<div className="input-wrapper">
										<select name="state" id="state" className={stateValidate} value={input.state.value} onChange={onHandle} disabled={!accessMerchantDetails.update_merchant}>
											<option value="" selected>
												-- State --
											</option>
											{stateList
												?.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))
												?.map((d, i) => (
													<option value={d.code} key={i}>
														{d.label}
													</option>
												))}
										</select>
									</div>
									<p className="app-auth-input__error">{input.state.error}</p>
								</div>
								<div className="input-container" />
							</div>
							<div className="input-content">
								<div className="input-container">
									<span>Subsidiary</span>
									<div className="input-wrapper">
										<input type="text" className={postcodeValidate} name="postcode" value={input.postcode.value} onChange={onHandle} maxLength={5} readOnly={!accessMerchantDetails.update_merchant} />
									</div>
									<p className="app-auth-input__error">{input.postcode.error}</p>
								</div>
								<div className="input-container">
									<span>Headquarters</span>
									<div className="input-wrapper">
										<select
											name="headquarters"
											id="headquarters"
											className={hqNameValidate}
											style={{ textAlign: "left" }}
											value={input.headquarters.value}
											onChange={onHandle}
											disabled={!accessMerchantDetails.update_merchant}
										>
											<option value="" selected>
												-- Headquarters --
											</option>
											{hqList
												?.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))
												?.map((d, i) => (
													<option value={d.name} key={i}>
														{d.name}
													</option>
												))}
										</select>
									</div>
									<p className="app-auth-input__error">{input.headquarters.error}</p>
								</div>
							</div>
							<div className="input-content">
								<div className="input-container">
									<span>Business Reg. No</span>
									<div className="input-wrapper">
										<input
											type="text"
											className={businessRegNoValidate}
											name="businessRegNo"
											value={input.businessRegNo.value}
											onChange={onHandle}
											maxLength={24}
											readOnly={!accessMerchantDetails.update_merchant}
										/>
									</div>
									<p className="app-auth-input__error">{input.businessRegNo.error}</p>
								</div>
								<div className="input-container">
									<span>Bank Name</span>
									<div className="input-wrapper">
										<select
											id="bankName"
											className={bankCodeValidate}
											name="bankCode"
											style={{ textAlign: "left" }}
											value={input.bankCode.value}
											onChange={onHandle}
											disabled={!accessMerchantDetails.update_merchant}
										>
											<option value="" selected>
												-- Bank Name --
											</option>
											{bankList
												?.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))
												?.map((d, i) => (
													<option value={d.code} key={i}>
														{d.label}
													</option>
												))}
										</select>
									</div>
									<p className="app-auth-input__error">{input.bankCode.error}</p>
								</div>
							</div>
							<div className="input-content">
								<div className="input-container">
									<span>Account Name</span>
									<div className="input-wrapper">
										<input type="text" className={accNameValidate} name="accName" value={input.accName.value} onChange={onHandle} maxLength={50} readOnly={!accessMerchantDetails.update_merchant} />
									</div>
									<p className="app-auth-input__error">{input.accName.error}</p>
								</div>
								<div className="input-container">
									<span>Account No</span>
									<div className="input-wrapper">
										<input type="text" className={accNumberValidate} name="accNumber" value={input.accNumber.value} onChange={onHandle} maxLength={19} readOnly={!accessMerchantDetails.update_merchant} />
									</div>
									<p className="app-auth-input__error">{input.accNumber.error}</p>
								</div>
							</div>
							<div className="input-content">
								<div className="input-container">
									<span>Merchant Agreement</span>
									<div className="input-wrapper input-wrapper__download" onClick={openFile}>
										<p>Download</p>
										<img src={IconDownload} alt="Download Icon" onClick={openFile} />
									</div>
									<p className="app-auth-input__error"></p>
								</div>
								<div className="input-container">
									<span>Effective Date</span>
									<div className="input-wrapper">
										<input type="date" className="app-input-text" name="effectiveDate" onChange={changeDate} value={moment(input.effectiveDate.value, "DD/MM/YYYY").format("YYYY-MM-DD")} />
									</div>
									<p className="app-auth-input__error">{input.effectiveDate.error}</p>
								</div>
							</div>
							<div className="input-content">
								<div className="input-container">
									<span>End Date</span>
									{input.endDate.value && (
										<div className="input-wrapper">
											<input type="text" className="app-input-text" value={input.endDate.value} readOnly />
										</div>
									)}
									{!input.endDate.value && (
										<div className="input-wrapper input-wrapper__terminate">
											<button
												type="button"
												className="terminate-btn"
												onClick={() => {
													setTerminatePopUp(true);
												}}
												disabled={!accessMerchantDetails.terminate_merchant}
												style={!accessMerchantDetails.terminate_merchant ? { backgroundColor: "grey" } : {}}
											>
												Terminate
											</button>
											<Popup trigger={terminatePopUp}>
												<div className="pop-up-message">Are you sure you want to terminate this merchant?</div>
											</Popup>
										</div>
									)}
									<p className="app-auth-input__error"></p>
								</div>
								<div className="input-container">
									<span>Status</span>
									<div className="input-wrapper input-wrapper__radio">
										<div className="input-radio">
											<input type="radio" name="active" id="active" className="radio-check" onChange={handleOnChange} checked={isChecked} disabled={!accessMerchantDetails.update_merchant} />
											<label htmlFor="active">Active</label>
										</div>
										<div className="input-radio">
											<input type="radio" name="disabled" id="disabled" className="radio-check" onChange={handleOnChange} checked={!isChecked} disabled={!accessMerchantDetails.update_merchant} />
											<label htmlFor="disabled">Disabled</label>
										</div>
										<PopupStatus trigger={statusPopUp}>
											<div className="pop-up-message">{isChecked === true ? "Are you sure you want to disable this merchant?" : "Are you sure you want to reactivate this merchant?"}</div>
										</PopupStatus>
									</div>
								</div>
							</div>
						</div>
						<div className="qrcode">
							<div className="qrcode__container">
								<img src={input.qrImageUrl.value} alt="QR Code" />
								<button className="save-pdf" onClick={openPDF}>
									Save as PDF
								</button>
							</div>
						</div>
					</div>
				)}
			</div>
			<div className="merchant-detail__content">
				{tab === "2" && (
					<div className="merchant-detail__wrapper">
						<div className="transaction">
							<p className="transaction__title">Merchant ID: {input.referenceNumber.value}</p>

							<div className="input-container">
								<div className="input-wrapper">
									<select className="input-field" value={selected} onChange={onHandleOnChange}>
										<option value="- Select Range -"> - Select Range -</option>
										<option value="LAST_7_DAYS">Last 7 days</option>
										<option value="LAST_14_DAYS">Last 14 days</option>
										<option value="LAST_1_MONTH">Last month</option>
									</select>
								</div>
								<span className="custom-divider">Custom</span>
								<div className="input-wrapper">
									<input
										type="date"
										onFocus={onFocusDateStart}
										id="start"
										placeholder={dateType[0].placeholder}
										className="input-field"
										style={{ marginRight: "10px" }}
										onChange={onChangeDateStart}
										value={dateType[0].value}
										max={maxDate}
									/>
								</div>
								<div className="input-wrapper">
									<input type="date" onFocus={onFocusDateEnd} id="end" placeholder={dateType[1].placeholder} className="input-field" onChange={onChangeDateEnd} value={dateType[1].value} min={mindate} />
								</div>
							</div>
							<div className="page-loan-application-list__react-table-wrapper">
								{accessMerchantTrx.view_transaction_list && appList !== undefined && appList.data !== undefined ? (
									<AppReactTable
										columns={tableColumn}
										data={appList.data}
										headerClass="userContractDetailsHeader"
										headerRowClass="userContractDetailsHeader__row"
										rowHeaderClass="userContractDetailsHeader__row-header"
										bodyClass="userContractDetailsBody"
										bodyRowClass="userContractDetailsBody__row"
										bodyDataClass="userContractDetailsBody__row-data"
									/>
								) : (
									<div></div>
								)}
							</div>
							<div className="pagination-button" style={{ float: "right" }}>
								{appList !== undefined ? (
									<span>
										{tableSearchFilter.pageNo === 1 && appList.totalCount > 0
											? tableSearchFilter.pageNo
											: tableSearchFilter.pageNo === 1 && appList.totalCount === 0
											? appList.totalCount
											: 1 + appList.pageSize * (appList.currentPageNumber - 1)}{" "}
										- {""}
										{appList.pageSize * (appList.currentPageNumber - 1) + appList.currentCount} of {appList.totalCount}{" "}
									</span>
								) : (
									<></>
								)}
								<button onClick={() => previousPage()}>❮</button>
								<button onClick={() => nextPage()}>❯</button>
							</div>
						</div>
					</div>
				)}
				<div className="merchant-detail__content">
					{tab === "3" && (
						<div className="merchant-detail__wrapper">
							<div className="terminal-table">
								{accessMerchantTerminal.view_merchant_terminal_list && terminalList !== undefined && terminalList.data !== undefined ? (
									<AppReactTable
										columns={terminalTableColumn}
										data={terminalList.data}
										headerClass="userContractDetailsHeader"
										headerRowClass="userContractDetailsHeader__row"
										rowHeaderClass="userContractDetailsHeader__row-header"
										bodyClass="userContractDetailsBody"
										bodyRowClass="userContractDetailsBody__row"
										bodyDataClass="userContractDetailsBody__row-data"
									/>
								) : (
									<div></div>
								)}
								<div className="pagination-button">
									{terminalList !== undefined ? (
										<span>
											{tableSearchFilter.pageNo === 1 && terminalList.totalCount > 0
												? tableSearchFilter.pageNo
												: tableSearchFilter.pageNo === 1 && terminalList.totalCount === 0
												? terminalList.totalCount
												: 1 + terminalList.pageSize * (terminalList.currentPageNumber - 1)}{" "}
											- {""}
											{terminalList.pageSize * (terminalList.currentPageNumber - 1) + terminalList.currentCount} of {terminalList.totalCount}{" "}
										</span>
									) : (
										<></>
									)}
									<button onClick={() => previousTerminalPage()}>❮</button>
									<button onClick={() => nextTerminalPage()}>❯</button>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default PageMerchantView;
