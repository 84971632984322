// libraries
import React, { useContext, useState, useEffect } from "react";
import AppButton from "components/app-button";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { AiOutlineClose } from "react-icons/ai";
// commons
import CONSTANSTS from "common/constansts";
import AppCustomToast, { PageContext } from "components/app-custom-toast";

const PageCalendarSettings = () => {
	// const history = useHistory();
	const indicatorValue = useContext(PageContext);
	function messageDialog(indicator) {
		AppCustomToast({
			showIndicator: indicator,
			reintIndicator: indicatorValue[1](indicator),
		});
	}
	const week = [
		{
			label: "Monday",
			id: 1,
		},
		{
			label: "Tuesday",
			id: 2,
		},
		{
			label: "Wednesday",
			id: 3,
		},
		{
			label: "Thursday",
			id: 4,
		},
		{
			label: "Friday",
			id: 5,
		},
		{
			label: "Saturday",
			id: 6,
		},
		{
			label: "Sunday",
			id: 0,
		},
	];

	const initialData = {
		scheduleResponseList: [
			{
				dayOfWeek: "",
				timeSlots: [],
			},
			{
				dayOfWeek: "",
				timeSlots: [],
			},
			{
				dayOfWeek: "",
				timeSlots: [],
			},
			{
				dayOfWeek: "",
				timeSlots: [],
			},
			{
				dayOfWeek: "",
				timeSlots: [],
			},
			{
				dayOfWeek: "",
				timeSlots: [],
			},
			{
				dayOfWeek: "",
				timeSlots: [],
			},
		],
		latestDayAfter: "",
		maxSlot: "",
		auditTrail: {
			timestamp: "",
			message: null,
			updatedBy: "",
		},
	};
	const initialTimeSlot = {
		result: [""],
	};

	const [days] = useState(week);
	const [meetingSchedule, setMeetingSchedule] = useState(initialData);
	const [timeSlots, setTimeSlots] = useState(initialTimeSlot);

	useEffect(async () => {
		try {
			let token = null;
			token = localStorage.getItem("@storage_token");
			fetch(
				// "http://49.0.203.50/ivc-app/bo/meeting/api/v1/meetingSchedule/find",
				process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.FIND_MEETING_SCHEDULE,
				{
					headers: {
						"Content-Type": "application/json",
						Accept: "*/*",
						"Accept-language": "en",
						Authorization: "Bearer " + token,
					},
				}
			)
				.then((response) => response.json())
				.then((result) => {
					if (result !== null || result !== undefined) {
						setMeetingSchedule(result.result);
					} else {
						setMeetingSchedule(meetingSchedule);
					}
				});
			fetch(
				// "http://49.0.203.50/ivc-app/bo/meeting/api/v1/meetingTimeSlot/find",
				process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.FINDALL_MEETING_TIMESLOT,
				{
					headers: {
						"Content-Type": "application/json",
						Accept: "*/*",
						"Accept-language": "en",
						Authorization: "Bearer " + token,
					},
				}
			)
				.then((response) => response.json())
				.then((result) => {
					if (result !== null || result !== undefined) {
						setTimeSlots(result.result);
					} else {
						setTimeSlots(initialTimeSlot);
					}
				});
		} catch (e) {
			console.log(e);
		}
	}, []);

	const [showSelectTime, setShowSelectTime] = useState(false);
	const [selectedWhichDay, setselectedWhichDay] = useState(false);
	const selectTimeToggle = () => {
		setShowSelectTime(!showSelectTime);
	};

	//get clicked which button (0~6) total 7 buttons
	const getSelectedDay = (e) => {
		var selected_day = e.target.value;
		setselectedWhichDay(selected_day);
	};

	//show color depend on pulled scheduleRequestList.timSlots
	//change color depend select or unselect
	//remove and add timeSlots
	function SelectTime(values) {
		const time_slot_selection = [];
		var selected_day = selectedWhichDay;
		values.select.map((timeSlot, index) => {
			if (meetingSchedule.scheduleResponseList[selected_day].timeSlots.includes(timeSlot)) {
				time_slot_selection.push(
					<div className="col body__schedule time active m-2" key={index} value={timeSlot} onClick={(e) => changeColor(e)}>
						{timeSlot}
					</div>
				);
			} else {
				time_slot_selection.push(
					<div className="col body__schedule time m-2" key={index} value={timeSlot} onClick={(e) => changeColor(e)}>
						{timeSlot}
					</div>
				);
			}
		});

		return time_slot_selection;
	}

	function removeScheduleTime(e) {
		var selected_time = e.target.getAttribute("value");
		if (meetingSchedule.scheduleResponseList[selectedWhichDay].timeSlots.includes(selected_time)) {
			meetingSchedule.scheduleResponseList[selectedWhichDay].timeSlots = meetingSchedule.scheduleResponseList[selectedWhichDay].timeSlots.filter((timeSlot) => timeSlot !== selected_time);
		}
	}

	function addScheduleTime(e) {
		var selected_time = e.target.getAttribute("value");
		if (!meetingSchedule.scheduleResponseList[selectedWhichDay].timeSlots.includes(selected_time)) {
			meetingSchedule.scheduleResponseList[selectedWhichDay].timeSlots.push(selected_time);
		}
	}

	function changeColor(e) {
		var class_name = e.currentTarget.className;
		var active_class_name = "col body__schedule time active m-2";
		var inactive_class_name = "col body__schedule time m-2";

		if (class_name === inactive_class_name) {
			e.currentTarget.className = active_class_name;
			addScheduleTime(e);
		} else {
			e.currentTarget.className = inactive_class_name;
			removeScheduleTime(e);
		}
	}

	function OutputBoxes(values) {
		var time_slots_arrangement = values.index;

		return meetingSchedule.scheduleResponseList[time_slots_arrangement].timeSlots.map((data, index) => {
			return (
				<div className="calendar-settings__input" key={index}>
					{data}
				</div>
			);
		});
	}
	//two inputs
	const handleChange = (e) => {
		var name = e.target.name;
		var value = e.target.value;
		value = value.replace(/\D/, "");
		setMeetingSchedule({ ...meetingSchedule, [name]: value });
	};

	const updateMeetingSchedule = async () => {
		var data = {
			latestDayAfter: meetingSchedule.latestDayAfter,
			maxSlot: meetingSchedule.maxSlot,
			scheduleRequestList: meetingSchedule.scheduleResponseList,
		};

		// console.log("Data>>>", JSON.stringify(data));

		let token = null;
		token = localStorage.getItem("@storage_token");
		fetch(
			// "http://49.0.203.50/ivc-app/bo/meeting/api/v1/meetingSchedule/update",
			process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.UPDATE_MEETING_SCHEDULE,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "*/*",
					"Accept-language": "en",
					Authorization: "Bearer " + token,
				},

				body: JSON.stringify(data),
			}
		)
			.then(function (response) {
				return response.json();
			})
			.then(function (data) {
				const response = data;
				console.log(response);
				if (response.responseCode === 200) {
					messageDialog({ isOpen: true, condition: CONSTANSTS.DIALOG.success, title: CONSTANSTS.DIALOG_TITLE.general_success, message: response.message });
				} else {
					messageDialog({ isOpen: true, condition: CONSTANSTS.DIALOG.failed, title: CONSTANSTS.DIALOG_TITLE.general_failed, message: response.message, defaultOnClick: false });
				}
			});
	};

	const onHandleUpdateTime = (e) => {
		selectTimeToggle();
		getSelectedDay(e);
	};

	return (
		<div className="page-calander-settings">
			<div className="calendar-settings">
				<div className="calendar-settings__header">
					<div className="calendar-settings__title">Calendar Settings</div>
					<button className="calendar-settings__update-button" onClick={updateMeetingSchedule}>
						Update
					</button>
				</div>

				<div className="calendar-settings__body">
					{days.map((d) => (
						<div key={d.id} className="calendar-settings__times">
							<p className="calendar-settings__label">{d.label}</p>
							<div className="calendar-settings__time">
								<OutputBoxes data={meetingSchedule.scheduleResponseList[d.id].timeSlots} index={d.id} />
							</div>
							<button className="calendar-settings__button" value={d.id} onClick={onHandleUpdateTime}>
								Select Time
							</button>
						</div>
					))}
				</div>

				<div className="calendar-settings__footer">
					<div className="calendar-settings__input-wrapper">
						<div className="calendar-settings__slots">
							<p className="calendar-settings__label">Latest Date Available</p>
							<input type="text" className="calendar-settings__input" name="latestDayAfter" value={meetingSchedule.latestDayAfter} onChange={handleChange} maxLength={3} />
						</div>
						<div className="calendar-settings__slots">
							<p className="calendar-settings__label">Maximum Interview per slot</p>
							<input type="text" className="calendar-settings__input" name="maxSlot" value={meetingSchedule.maxSlot} onChange={handleChange} maxLength={3} />
						</div>
					</div>
					<p className="calendar-settings__updated">
						Last Updated by: {meetingSchedule.auditTrail.updatedBy} {meetingSchedule.auditTrail.timestamp}
					</p>
				</div>
			</div>
			<Modal isOpen={showSelectTime} className="calendar-settings-popup" contentClassName="calendar-settings-popup__content" animation="false">
				<ModalHeader className="header">
					<AiOutlineClose className="header__close-button" onClick={selectTimeToggle} />
				</ModalHeader>
				<ModalBody className="body">
					<div className="row row-cols-auto body__schedule">
						<SelectTime select={timeSlots}></SelectTime>
					</div>
				</ModalBody>
				<ModalFooter className="footer">
					<button className="footer__centerButton" onClick={selectTimeToggle}>
						OK
					</button>
				</ModalFooter>
			</Modal>
		</div>
	);
};

export default PageCalendarSettings;
