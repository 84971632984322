// libraries
import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
// import { useDispatch } from "react-redux";
//component
// import AppButton from "components/app-button";
// import pathnames from "routes/pathnames";
import { useEffect } from "react";
// import { updateUserList } from "redux/slices/user-list-slice";
import appToast from "components/app-toast";
import AppCustomToast, { PageContext } from "components/app-custom-toast";
// api
// import api from "services/api";
// commons
import CONSTANSTS from "common/constansts";
import classNames from "common/class-names";
//assest
import iconPlay from "assets/images/pages/icon-play-video.png";
import iconDownload from "assets/images/pages/icon-download-video.png";
import iconUpload from "assets/images/pages/icon-upload-w-border.png";
import AppReactTable from "components/app-react-table";
import { Player } from "video-react";
import "video-react/dist/video-react.css";
import { PieChart, Pie, Cell } from "recharts";
import iconLHDNSubmission from "assets/images/pages/LHDN_Submission.png";
import iconAttestorSign from "assets/images/pages/Attestor_Signature.png";
import iconAuthApproveSign from "assets/images/pages/Authorized_Approver_Signature.png";
// import Calendar from "react-calendar";
// import { IoClose } from "react-icons/io5";
// import DateTimePicker from 'react-datetime-picker';
import { format } from "date-fns";

const PageLoanContractView = () => {
	const indicatorValue = useContext(PageContext);
	function messageDialog(indicator) {
		AppCustomToast({
			showIndicator: indicator,
			reintIndicator: indicatorValue[1](indicator),
		});
	}

	// const history = useHistory();
	//const dispatch = useDispatch();
	const inputField = {
		assign: {
			value: "",
		},
		applicantName: {
			error: false,
			value: "",
			placeholder: "Applicant Name",
			required: "Cannot be empty",
			invalid: "",
		},
		contractDate: {
			error: false,
			value: "",
			placeholder: "Contract Date",
			required: "Date required",
			invalid: "",
		},
		loanAmount: {
			error: false,
			value: "",
			placeholder: "",
			required: "Cannot be empty",
			invalid: "",
		},
		loanInterestRate: {
			error: false,
			value: "",
			placeholder: "Loan Interst Rate",
			required: "Cannot be empty",
			invalid: "",
		},
		loanTenure: {
			error: false,
			value: "",
			placeholder: "Loan Tenure",
			required: "Loan Tenure required",
			invalid: "Invalid",
		},
		contactDetails: {
			error: false,
			value: "",
			placeholder: "Contact Details",
			required: "Contact Details required",
			invalid: "",
		},
		approvedBy: {
			error: false,
			value: "",
			placeholder: "Approved",
			required: "",
			invalid: "",
		},
		customerBankDetails: {
			error: false,
			value: "",
			placeholder: "customer Bank Details",
			required: "Detail required",
			invalid: "",
			values: {
				applicantBankCode: "",
				applicantBankHolderName: "",
				applicantBankAccountNo: "",
			},
		},
		applicantEmail: {
			value: "",
		},
		applicantId: {
			value: "",
		},
	};

	const paymentField = {
		disbursementDate: {
			value: "",
		},
		paymentStatus: {
			value: "",
		},
		lastPaymentDate: {
			value: "",
		},
		lastPaymentAmount: {
			value: "",
		},
		nextPaymentDate: {
			value: "",
		},
		monthlyInstalmentAmount: {
			value: "",
		},
		numOfInstalmentRemaining: {
			value: "",
		},
		remainingPrincipalAmount: {
			value: "",
		},
		remainingInterestAmount: {
			value: "",
		},
		totalRemainingAmount: {
			value: "",
		},
	};

	const documentsTab = {
		interviewDate: {
			value: "",
		},
		interviewDownloadUrl: {
			value: "",
		},
		interviewUrl: {
			value: "",
		},
		liveBriefingDate: {
			value: "",
		},
		liveBriefingDownloadUrl: {
			value: "",
		},
		liveBriefingUrl: {
			value: "",
		},
		loanAgreementDate: {
			value: "",
		},
		loanAgreementUrl: {
			value: "",
		},
		loanAgreementStampedDate: {
			value: "",
		},
		loanAgreementStampedDownloadUrl: {
			value: "",
		},
		attestorSignatureDate: {
			value: "",
		},
		authorizedApproverSignatureDate: {
			value: "",
		},
		lhdnSubmissionDate: {
			value: "",
		},
	};
	const buttonField = {
		attestorSignatureButton: {
			value: "pointer",
		},
		authorizedApproverSignatureButton: {
			value: "pointer",
		},
		lhdnSubmissionButton: {
			value: "pointer",
		},
	};

	const [input, setInput] = useState(inputField);
	const [payDetail, setPayDetail] = useState(paymentField);
	const [documents, setDocuments] = useState(documentsTab);
	const [viewData, setViewData] = useState({});
	// const history = useHistory();
	const { contractId } = useParams();
	const [accessContractDetails, setAccessContractDetails] = useState({
		attestor_sign: false,
		authorized_approver_sign: false,
		download_document: false,
		update_ldhn_submission_date: false,
		upload_stamped_agreement: false,
		view_contract_detail: false,
		view_ctos_score: false,
		view_document: false,
		view_ivc_score: false,
	});

	const [accessContractPayment, setAccessContractPayment] = useState({
		generate_customer_contract_statement: true,
		view_invoice_history: true,
		view_transaction_history: true,
	});

	useEffect(() => {
		const appId = contractId;
		// const appId = id.location.state
		// console.log("ID", appId)
		let loanAccess = null;
		loanAccess = JSON.parse(localStorage.getItem("accessMatrix"));
		if (loanAccess) {
			let conDetail = loanAccess.accessMatrix.Loan_Contract_Detail;
			let conPymt = loanAccess.accessMatrix.Loan_Contract_Payment;
			setAccessContractDetails(conDetail);
			setAccessContractPayment(conPymt);

			// change default score tab display when ctos is not accessible and ivc is able to access
			if (!conDetail.view_ctos_score && conDetail.view_ivc_score) {
				setScoreTab("ivc");
				// hide the score details when both ctos and ivc are not accessible
			} else if (!conDetail.view_ctos_score && !conDetail.view_ivc_score) {
				setScoreTab("ctos");
			}

			// change default payment tab display when invoice history is not accessible (invoice history alone)
			if (!conPymt.view_invoice_history && conPymt.view_transaction_history) {
				setPaymentTab({
					invoice: 0,
					transaction: 1,
				});
			}
		}

		try {
			let token = null;
			token = localStorage.getItem("@storage_token");
			// let response = fetch("http://49.0.203.50/ivc-app/bo/contract/api/v1/get/" + appId, {
			fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.GET_CONTRACT_BYID + appId, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Accept: "*/*",
					"Accept-language": "en",
					Authorization: "Bearer " + token,
				},
			})
				.then((response) => response.json())
				//   console.log("res: ", response);
				//   if (response.ok) {
				//     return response.json();
				//   } else {
				//     appToast(response.status,false)
				//     return;
				//   }
				// })
				.then((data) => {
					// console.log("res: ",typeof(data.success));
					if (data.success) {
						// console.log("in")
						// appToast("Sucessfully", true);
						let result = data.result;

						setViewData(result);

						if (result.attestorSignatureDate != null) {
							//setButtonDisable({attestorSignatureButton:{value:"defaultPointer"}})
							buttonDisable.attestorSignatureButton.value = "defaultPointer";
						}

						if (result.authorizedApproverSignatureDate != null) {
							buttonDisable.authorizedApproverSignatureButton.value = "defaultPointer";
						}

						if (result.lhdnSubmissionDate != null) {
							buttonDisable.lhdnSubmissionButton.value = "defaultPointer";
						}

						setInput({
							...input,
							applicantName: {
								error: false,
								value: result.applicantName,
							},
							contractDate: {
								error: false,
								value: result.contractDate,
							},
							loanAmount: {
								value: result.loanAmount,
							},
							loanInterestRate: {
								value: result.loanInterestRate,
							},
							loanTenure: {
								value: result.loanTenure,
							},
							contactDetails: {
								value: result.applicantContactNo,
							},
							approvedBy: {
								value: result.approvedBy,
							},
							customerBankDetails: {
								value: result.applicantBankCode + " " + result.applicantBankHolderName + " " + result.applicantBankAccountNo,
								values: {
									applicantBankCode: result.applicantBankCode,
									applicantBankHolderName: result.applicantBankHolderName,
									applicantBankAccountNo: result.applicantBankAccountNo,
								},
							},
							applicantEmail: {
								value: result.applicantEmail,
							},
							applicantId: {
								value: result.username,
							},
						});
						setPayDetail({
							...payDetail,
							disbursementDate: {
								value: result.disbursementDate,
							},
							paymentStatus: {
								value: result.paymentStatus,
							},
							lastPaymentDate: {
								value: result.dateLastPaymentMade,
							},
							lastPaymentAmount: {
								value: result.lastPaymentAmount,
							},
							nextPaymentDate: {
								value: result.dateNextPayment,
							},
							monthlyInstalmentAmount: {
								value: result.monthlyInstalmentAmount,
							},
							numOfInstalmentRemaining: {
								value: result.numberOfRemainingInstalment,
							},
							remainingPrincipalAmount: {
								value: result.remainingPrincipalAmount,
							},
							remainingInterestAmount: {
								value: result.remainingInterestAmount,
							},
							totalRemainingAmount: {
								value: result.totalRemainingAmount,
							},
						});
						setDocuments({
							...documents,
							interviewDate: {
								value: result.interviewDate,
							},
							interviewDownloadUrl: {
								value: result.interviewDownloadUrl,
							},
							interviewUrl: {
								value: result.interviewUrl,
							},
							liveBriefingDate: {
								value: result.liveBriefingDate,
							},
							liveBriefingDownloadUrl: {
								value: result.liveBriefingDownloadUrl,
							},
							liveBriefingUrl: {
								value: result.liveBriefingUrl,
							},
							loanAgreementDate: {
								value: result.loanAgreementDate,
							},
							loanAgreementUrl: {
								value: result.loanAgreementUrl,
							},
							loanAgreementStampedDate: {
								value: result.loanAgreementStampedDate,
							},
							loanAgreementStampedDownloadUrl: {
								value: result.loanAgreementStampedDownloadUrl,
							},
							attestorSignatureDate: {
								value: result.attestorSignatureDate,
							},
							authorizedApproverSignatureDate: {
								value: result.authorizedApproverSignatureDate,
							},
							lhdnSubmissionDate: {
								value: result.lhdnSubmissionDate,
							},
						});
					} else {
						messageDialog({ isOpen: true, condition: CONSTANSTS.DIALOG.failed, title: CONSTANSTS.DIALOG_TITLE.general_failed, message: data.message, defaultOnClick: false });
					}
				});
			//  dispatch(updateUserList(response));
		} catch (error) {
			console.log("error", error);
		}

		//  loadData();
		invoiceHistoryList(tableSearchFilter.pageNo);
		transactionHistory(tableSearchFilter1.pageNo);
	}, []);

	const generateCustomerContractStatment = async () => {
		let token = null;
		token = localStorage.getItem("@storage_token");
		fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.GENERATE_CUSTOMER_CONTRACT_STATEMENT + "?contractId=" + contractId, {
			method: "PUT",
			headers: {
				"Accept-language": "en",
				Authorization: "Bearer " + token,
				Accept: "*/*",
				"Content-Type": "application/json",
			},
		}).then(function (response) {
			if (response.status === 200) {
				let filename = response.headers.get("Content-Disposition");
				let dialogMessage = response.headers.get("Message");

				if (filename != null) {
					filename = filename.split(";")[1].split("filename=")[1];
					////dialogMessage = dialogMessage.split("message=")[1];
				}

				response.blob().then((blob) => {
					let url = window.URL.createObjectURL(blob);

					let a = document.createElement("a");
					a.href = url;
					a.download = filename;
					a.click();

					messageDialog({
						isOpen: true,
						condition: CONSTANSTS.DIALOG.success,
						title: CONSTANSTS.DIALOG_TITLE.general_success,
						message: dialogMessage ?? CONSTANSTS.DIALOG_MESSAGE.download_successful,
						defaultOnClick: false,
					});
				});
			} else {
				response.json().then(function (data) {
					const response = data;

					messageDialog({
						isOpen: true,
						condition: CONSTANSTS.DIALOG.failed,
						title: CONSTANSTS.DIALOG_TITLE.general_failed,
						message: response.message,
						defaultOnClick: false,
					});
				});
			}
		});
	};

	const [tab, setTab] = useState("1");
	const [firstTab, setFirstTab] = useState("display");
	const [secondTab, setSecondTab] = useState("hide");
	const [thirdTab, setThirdTab] = useState("hide");
	const tabClicked = (e) => {
		var tabNo = e;
		setTab(tabNo);

		if (tabNo === "1") {
			setFirstTab("display");
			setSecondTab("hide");
			setThirdTab("hide");
		}
		if (tabNo === "2") {
			setFirstTab("hide");
			setSecondTab("display");
			setThirdTab("hide");
		}
		if (tabNo === "3") {
			setFirstTab("hide");
			setSecondTab("hide");
			setThirdTab("display");
		}
	};

	const [tableSearchFilter, setTableSearchFilter] = useState({
		// insert the key value requested by BE here
		pageNo: 1,
		pageSize: 12,
		sortBy: "id",
		sortDir: "ASCENDING",
		dateRangeType: null,
		dateFrom: "string",
		dateTo: "string",
		keyword: "",
	});

	// trigger when user click on header when sorting
	const headerFilterClick = (header) => {
		let column = header.column;
		if (tableSearchFilter.sortBy === column.searchFilterValue) {
			// if click on the same column previously clicked will enter here
			if (tableSearchFilter.sortDir === "ASCENDING") {
				// if previously the column is sort by ascending order, sortDir will change to sort by descending order
				setTableSearchFilter({
					...tableSearchFilter,
					sortBy: column.searchFilterValue,
					sortDir: "DESCENDING",
					pageNo: 1,
				});
			} else {
				// if previously the column is sort by descending order, sortDir will change to sort by ascending order
				setTableSearchFilter({
					...tableSearchFilter,
					sortBy: column.searchFilterValue,
					sortDir: "ASCENDING",
					pageNo: 1,
				});
			}
		} else {
			// if click on a new column, it will sort by ascending order with the column value (column.searchFilterValue)
			setTableSearchFilter({
				...tableSearchFilter,
				sortBy: column.searchFilterValue,
				sortDir: "ASCENDING",
				pageNo: 1,
			});
		}
	};

	const headerArrowRotate = (active) => {
		// current column header arrow icon will rotate depending on ascending order or descending order (tableSearchFilter.sortDir)
		const classNames = ["react-table__icon-wrapper"];
		if (active && tableSearchFilter.sortDir === "DESCENDING") classNames.push("react-table__icon-wrapper-down");
		if (active) classNames.push("react-table__icon-wrapper--show");
		return classNames.join(" ");
	};

	// tableCoumn is to create table column
	const tableColumn = [
		{
			id: "invoiceRepaymentDate",
			// header is the table header for id column
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick(header)} className="react-table__header">
						<span className="react-table__header-title">Date</span>
						<div className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			// accessor will list out the table data based on the data key. In this case, the mockData key of id
			accessor: "invoiceRepaymentDate",
			// searchFilterValue is the column value that will send to BE to inform BE to sort according to which column. it is use to update tableSearchFilter.searchValue
			searchFilterValue: "invoiceRepaymentDate",
			// minWidth, width, maxWidth is to adjust the width of this current column
			minWidth: 50,
			width: 50,
		},
		{
			id: "referenceNumber",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick(header)} className="react-table__header">
						<span className="react-table__header-title" style={{ textAlign: "center" }}>
							Invoice ID
						</span>
						<div className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			searchFilterValue: "referenceNumber",
			accessor: "referenceNumber",
			minWidth: 80,
			width: 80,
		},
		{
			id: "invoiceAmount",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick(header)} className="react-table__header">
						<span className="react-table__header-title" style={{ textAlign: "center" }}>
							Invoice Amt
						</span>
						<div className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			searchFilterValue: "invoiceAmount",
			accessor: "invoiceAmount",
			minWidth: 43,
			width: 43,
		},
		{
			id: "lateCharges",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick(header)} className="react-table__header">
						<span className="react-table__header-title">Late Charge</span>
						<div className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			searchFilterValue: "lateCharges",
			accessor: "lateCharges",
			minWidth: 43,
			width: 43,
		},
		{
			id: "totalOutstandingAmount",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick(header)} className="react-table__header">
						<span className="react-table__header-title">Outstanding Amt</span>
						<div className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			searchFilterValue: "totalOutstandingAmount",
			accessor: "totalOutstandingAmount",
			minWidth: 43,
			width: 43,
		},
		{
			id: "totalInvoiceAmount",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick(header)} className="react-table__header">
						<span className="react-table__header-title" style={{ textAlign: "center" }}>
							Total Amt
						</span>
						<div className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			searchFilterValue: "totalInvoiceAmount",
			accessor: "totalInvoiceAmount",
			minWidth: 43,
			width: 43,
		},
		{
			id: "paidDate",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick(header)} className="react-table__header">
						<span className="react-table__header-title">Paid Date</span>
						<div className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			searchFilterValue: "paidDate",
			accessor: "paidDate",
			minWidth: 50,
			width: 50,
		},
		{
			id: "paidAmount",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick(header)} className="react-table__header">
						<span className="react-table__header-title">Paid</span>
						<div className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			searchFilterValue: "paidAmount",
			accessor: "paidAmount",
			minWidth: 50,
			width: 50,
		},
		{
			id: "isValid",

			searchFilterValue: "",
			accessor: "isValid",
			minWidth: 0,
			width: 0,
		},
		// {
		//     id: "button",
		//     Header: "",
		//     Cell: (row) => {
		//         return (
		//             <div onClick={(e) => e.stopPropagation()}>
		//                 <button onClick={() => [setCancelPopUp(true), setTransactionId(row.row.original)]} className="react-table__search-button">
		//                     <IoClose size={24} />
		//                 </button>
		//                 <PopupCancel trigger={cancelPopUp}>
		//                     <div className="pop-up-message">
		//                         Are you sure you want to cancel this transaction?
		//                     </div>
		//                 </PopupCancel>
		//             </div>
		//         );
		//     },
		//     minWidth: 100,
		//     width: 100,
		// },
	];

	const [appList, setAppList] = useState(undefined);
	const [paginationLocker, setPaginationLocker] = useState(true);
	const invoiceHistoryList = async (pageNo) => {
		let token = null;
		token = localStorage.getItem("@storage_token");
		// fetch("http://49.0.203.50/ivc-app/bo/contract/api/v1/invoiceHistory?contractId=" + staffId + "&pageNumber=" + tableSearchFilter.pageNo, {
		fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.GET_CONTRACT_INVOICE_HISTORY + "?contractId=" + contractId + "&pageNumber=" + pageNo, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				"Accept-language": "en",
				Authorization: "Bearer " + token,
			},
			// "body": JSON.stringify({
			//     criteria: {
			//         dateFrom: tableSearchFilter.dateFrom,
			//         dateRangeType: tableSearchFilter.dateRangeType,
			//         dateTo: tableSearchFilter.dateTo,
			//         keyword: tableSearchFilter.keyword,
			//     },
			//     pageNumber: tableSearchFilter.pageNo,
			//     pageSize: tableSearchFilter.pageSize,
			//     sortDirection: tableSearchFilter.sortDir,
			//     sortKey: tableSearchFilter.sortBy
			// }),
		})
			.then((response) => response.json())
			.then((json) => {
				let result = json.result;
				setPaginationLocker(false);
				setAppList(result);
			});
	};

	const nextPage = async () => {
		if (appList.currentPageNumber < appList.totalPageNumber && !paginationLocker) {
			setTableSearchFilter({
				pageNo: tableSearchFilter.pageNo + 1,
				pageSize: tableSearchFilter.pageSize,
				sortBy: tableSearchFilter.sortBy,
				sortDir: tableSearchFilter.sortDir,
				dateRangeType: tableSearchFilter.dateRangeType,
				dateFrom: tableSearchFilter.dateFrom,
				dateTo: tableSearchFilter.dateTo,
				keyword: tableSearchFilter.keyword,
			});
			setPaginationLocker(true);
			invoiceHistoryList(tableSearchFilter.pageNo + 1);
		} else if (appList.currentPageNumber === appList.totalPageNumber) {
			setPaginationLocker(false);
		}
	};

	const previousPage = async () => {
		if (appList.currentPageNumber > 1 && !paginationLocker) {
			setTableSearchFilter({
				pageNo: tableSearchFilter.pageNo - 1,
				pageSize: tableSearchFilter.pageSize,
				sortBy: tableSearchFilter.sortBy,
				sortDir: tableSearchFilter.sortDir,
				dateRangeType: tableSearchFilter.dateRangeType,
				dateFrom: tableSearchFilter.dateFrom,
				dateTo: tableSearchFilter.dateTo,
				keyword: tableSearchFilter.keyword,
			});
			setPaginationLocker(true);
			invoiceHistoryList(tableSearchFilter.pageNo - 1);
		} else if (appList.currentPageNumber === 1) {
			setPaginationLocker(false);
		}
	};

	const downloadViewInterview = () => {
		console.log("url", documents.interviewDownloadUrl.value);
		if (documents.interviewDownloadUrl.value == null) {
			return;
		}
		let token = null;
		token = localStorage.getItem("@storage_token");
		fetch(documents.interviewDownloadUrl.value, {
			method: "GET",
			headers: {
				"Accept-language": "en",
				Authorization: "Bearer " + token,
			},
		}).then((response) => {
			let filename = response.headers.get("Content-Disposition");
			let dialogMessage = response.headers.get("Message");
			if (filename != null) {
				filename = filename.split(";")[1].split("filename=")[1];
				// //dialogMessage = dialogMessage.split("message=")[1];
			}

			appToast("Download...", true, true);
			// messageDialog({ isOpen: true, condition: CONSTANSTS.DIALOG.loading, title: CONSTANSTS.DIALOG_TITLE.general_loading, message: CONSTANSTS.DIALOG_MESSAGE.general_loading });
			response.blob().then((blob) => {
				console.log(blob);
				// messageDialog({ isOpen: true, condition: CONSTANSTS.DIALOG.success, title: CONSTANSTS.DIALOG_TITLE.general_success, message: CONSTANSTS.DIALOG_MESSAGE.general_download });
				appToast("", true);
				messageDialog({
					isOpen: true,
					condition: CONSTANSTS.DIALOG.success,
					title: CONSTANSTS.DIALOG_TITLE.general_success,
					message: dialogMessage ?? CONSTANSTS.DIALOG_MESSAGE.download_successful,
					defaultOnClick: false,
				});
				let url = window.URL.createObjectURL(blob);
				let a = document.createElement("a");
				a.href = url;
				a.download = filename;
				a.click();
			});
		});
	};
	const downloadLiveBriefing = () => {
		if (documents.liveBriefingDownloadUrl.value == null) {
			return;
		}
		let token = null;
		token = localStorage.getItem("@storage_token");
		fetch(documents.liveBriefingDownloadUrl.value, {
			method: "GET",
			headers: {
				"Accept-language": "en",
				Authorization: "Bearer " + token,
				Accept: "application/json",
				"Content-Type": "application/json",
			},
		}).then((response) => {
			console.log(response);
			let filename = response.headers.get("Content-Disposition");
			let dialogMessage = response.headers.get("Message");
			if (filename != null) {
				filename = filename.split(";")[1].split("filename=")[1];
				// dialogMessage = dialogMessage.split("Message")
			}

			appToast("Downloading...", true, true);
			// console.log(xhr.getResponseHeader('content-disposition').split('filename=')[1].split(';')[0]);
			response.blob().then((blob) => {
				appToast("", true);
				messageDialog({
					isOpen: true,
					condition: CONSTANSTS.DIALOG.success,
					title: CONSTANSTS.DIALOG_TITLE.general_success,
					message: dialogMessage ?? CONSTANSTS.DIALOG_MESSAGE.download_successful,
					defaultOnClick: false,
				});
				let url = window.URL.createObjectURL(blob);
				let a = document.createElement("a");
				a.href = url;
				a.download = filename;
				a.click();
			});
		});
	};
	const downloadLoanAgreement = () => {
		if (documents.loanAgreementUrl.value == null) {
			return;
		}
		let token = null;
		token = localStorage.getItem("@storage_token");
		fetch(documents.loanAgreementUrl.value, {
			method: "GET",
			headers: {
				"Accept-language": "en",
				Authorization: "Bearer " + token,
			},
		}).then((response) => {
			let filename = response.headers.get("Content-Disposition");
			let dialogMessage = response.headers.get("Message");
			if (filename != null) {
				filename = filename.split(";")[1].split("filename=")[1];
				//dialogMessage = dialogMessage.split("Message");
			}
			// appToast("Downloading...", true, true);
			response.blob().then((blob) => {
				console.log(blob);
				appToast("", true);
				messageDialog({
					isOpen: true,
					condition: CONSTANSTS.DIALOG.success,
					title: CONSTANSTS.DIALOG_TITLE.general_success,
					message: dialogMessage ?? CONSTANSTS.DIALOG_MESSAGE.download_successful,
					defaultOnClick: false,
				});
				let url = window.URL.createObjectURL(blob);
				let a = document.createElement("a");
				a.href = url;
				a.download = filename;
				a.click();
				//.csv
			});
		});
	};
	const downloadloanAgreementStamped = () => {
		if (documents.loanAgreementStampedDownloadUrl.value == null) {
			return;
		}
		let token = null;
		token = localStorage.getItem("@storage_token");
		fetch(documents.loanAgreementStampedDownloadUrl.value, {
			method: "GET",
			headers: {
				"Accept-language": "en",
				Authorization: "Bearer " + token,
			},
		}).then((response) => {
			let filename = response.headers.get("Content-Disposition");
			let dialogMessage = response.headers.get("Message");
			if (filename != null) {
				filename = filename.split(";")[1].split("filename=")[1];
				//dialogMessage = dialogMessage.split("Message");
			}

			// appToast("Downloading...", true, true);
			response.blob().then((blob) => {
				console.log(blob);
				appToast("", true);
				messageDialog({
					isOpen: true,
					condition: CONSTANSTS.DIALOG.success,
					title: CONSTANSTS.DIALOG_TITLE.general_success,
					message: dialogMessage ?? CONSTANSTS.DIALOG_MESSAGE.download_successful,
					defaultOnClick: false,
				});
				let url = window.URL.createObjectURL(blob);
				let a = document.createElement("a");
				a.href = url;
				a.download = filename;
				a.click();
			});
		});
	};

	const [playVideo, setPlayVideo] = useState("");
	const [playBriefingVideo, setPlayBriefingVideo] = useState("");
	const selectedVideo = (e) => {
		console.log("selectVideo", e, documents.interviewDownloadUrl.value);
		var videoLink = null;
		videoLink = e;
		if (e === null) {
			return;
		} else {
			setPlayBriefingVideo("");
			setPlayVideo(videoLink);
		}
	};
	const selectedLiveBriefingVideo = (e) => {
		console.log("selectVideo", e);
		var videoLink = null;
		videoLink = e;
		if (e === null) {
			return;
		} else {
			setPlayVideo("");
			setPlayBriefingVideo(videoLink);
		}
	};

	// const [file, setFile] = useState();

	const handleFileChange = (event) => {
		let inputFile = "";
		inputFile = event.target.files[0];
		console.log("->", inputFile);
		if (inputFile !== "") {
			let token = null;
			token = localStorage.getItem("@storage_token");
			const formData = new FormData();
			formData.append("agreementDocument", inputFile);
			formData.append("contractId ", contractId);
			// fetch("http://49.0.203.50/ivc-app/bo/contract/api/v1/uploadStampedAgreement", {
			fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.UPLOAD_STAMPED_AGREEMENT, {
				method: "PUT",
				headers: {
					// "Content-Type": "application/json",
					Accept: "*/*",
					"Accept-language": "en",
					Authorization: "Bearer " + token,
				},
				body: formData,
				// body: {
				//     "agreementDocument": file,
				//     "contractId": Number(staffId),
				// }
			})
				.then(function (response) {
					return response.json();
				})
				.then(function (data) {
					const items = data;
					console.log(items);
					if (data.responseCode === 200) {
						inputFile = "";
						// window.location.reload(true);
						// fetch("http://49.0.203.50/ivc-app/bo/contract/api/v1/get/" + staffId, {
						fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.GET_CONTRACT_BYID + contractId, {
							method: "GET",
							headers: {
								"Content-Type": "application/json",
								Accept: "*/*",
								"Accept-language": "en",
								Authorization: "Bearer " + token,
							},
						})
							.then(function (response) {
								if (response.ok) {
									return response.json();
								} else {
									return;
								}
							})
							.then(function (data) {
								if (data !== null || data !== undefined) {
									let result = data.result;
									console.warn("res: ", result);
									setViewData(result);
									setInput({
										...input,
										applicantName: {
											error: false,
											value: result.applicantName,
										},
										contractDate: {
											error: false,
											value: result.contractDate,
										},
										loanAmount: {
											value: result.loanAmount,
										},
										loanInterestRate: {
											value: result.loanInterestRate,
										},
										loanTenure: {
											value: result.loanTenure,
										},
										contactDetails: {
											value: result.applicantContactNo + " " + result.applicantEmail,
										},
										approvedBy: {
											value: result.agentName,
										},
										customerBankDetails: {
											value: result.applicantBankCode + " " + result.applicantBankHolderName + " " + result.applicantBankAccountNo,
											values: {
												applicantBankCode: result.applicantBankCode,
												applicantBankHolderName: result.applicantBankHolderName,
												applicantBankAccountNo: result.applicantBankAccountNo,
											},
										},
										applicantEmail: {
											value: result.applicantEmail,
										},
									});
									setPayDetail({
										...payDetail,
										disbursementDate: {
											value: result.disbursementDate,
										},
										paymentStatus: {
											value: result.paymentStatus,
										},
										lastPaymentDate: {
											value: result.dateLastPaymentMade,
										},
										lastPaymentAmount: {
											value: result.lastPaymentAmount,
										},
										nextPaymentDate: {
											value: result.dateNextPayment,
										},
										monthlyInstalmentAmount: {
											value: result.monthlyInstalmentAmount,
										},
										numOfInstalmentRemaining: {
											value: result.numberOfRemainingInstalment,
										},
										remainingPrincipalAmount: {
											value: result.remainingPrincipalAmount,
										},
										remainingInterestAmount: {
											value: result.remainingInterestAmount,
										},
										totalRemainingAmount: {
											value: result.totalRemainingAmount,
										},
									});
									setDocuments({
										...documents,
										interviewDate: {
											value: result.interviewDate,
										},
										interviewDownloadUrl: {
											value: result.interviewDownloadUrl,
										},
										interviewUrl: {
											value: result.interviewUrl,
										},
										liveBriefingDate: {
											value: result.liveBriefingDate,
										},
										liveBriefingDownloadUrl: {
											value: result.liveBriefingDownloadUrl,
										},
										liveBriefingUrl: {
											value: result.liveBriefingUrl,
										},
										loanAgreementDate: {
											value: result.loanAgreementDate,
										},
										loanAgreementUrl: {
											value: result.loanAgreementUrl,
										},
										loanAgreementStampedDate: {
											value: result.loanAgreementStampedDate,
										},
										loanAgreementStampedDownloadUrl: {
											value: result.loanAgreementStampedDownloadUrl,
										},
										attestorSignatureDate: {
											value: result.attestorSignatureDate,
										},
										authorizedApproverSignatureDate: {
											value: result.authorizedApproverSignatureDate,
										},
										lhdnSubmissionDate: {
											value: result.lhdnSubmissionDate,
										},
									});
								} else {
									messageDialog({ isOpen: true, condition: CONSTANSTS.DIALOG.failed, title: CONSTANSTS.DIALOG_TITLE.general_failed, message: data.message, defaultOnClick: false });
								}
							});
					}
				});
		}
	};

	const getTabScoreClassNames = (tabStatus) => {
		const classNames = ["loan-application-view__score-point"];
		if (tabStatus === 2) classNames.push("loan-application-view__score-point--active");

		return classNames.join(" ");
	};

	const scoreTabClicked = (name) => {
		setScoreTab(name);
	};

	const [scoreTab, setScoreTab] = useState("ctos");

	const pieDataCtos = [
		{
			name: "remaining",
			value: 100 - viewData.ctosCreditScore,
		},
		{
			name: "score",
			value: viewData.ctosCreditScore,
		},
	];

	const pieColors = {
		remaining: "#F6F6F6",
		score: "#FACB57",
	};

	const pieDataIvc = [
		{
			name: "remaining",
			value: 100 - viewData.internalScore,
		},
		{
			name: "score",
			value: viewData.internalScore,
		},
	];

	const [videoTab, setVideoTab] = useState({
		// 0 = unselect
		// 1 = selected
		interview: 1,
		briefing: 0,
	});

	const [paymentTab, setPaymentTab] = useState({
		// 0 = hide
		// 1 = select
		invoice: 1,
		transaction: 0,
	});

	const videoTabHandler = (type) => {
		if (type === "interview") {
			setVideoTab({
				interview: 1,
				briefing: 0,
			});
			setPaymentTab({
				invoice: 1,
				transaction: 0,
			});
		} else if (type === "briefing") {
			setVideoTab({
				interview: 0,
				briefing: 1,
			});
			setPaymentTab({
				invoice: 0,
				transaction: 1,
			});
		} else {
			setVideoTab({
				interview: 0,
				briefing: 0,
			});
		}
	};

	const getVideoTabClassNames = (videoTab, currentTab) => {
		const classNames = ["loan-application-view__video-tab-button"];
		if (videoTab[currentTab] === 1) classNames.push("loan-application-view__video-tab-button--active");
		return classNames.join(" ");
	};

	const [transactionList, setTransactionList] = useState(undefined);
	const transactionHistory = (pageNo) => {
		let token = null;
		token = localStorage.getItem("@storage_token");
		// fetch("http://49.0.203.50/ivc-app/bo/contract/api/v1/transactionHistory?contractId=" + staffId + "&pageNumber=" + tableSearchFilter1.pageNo, {
		fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.GET_CONTRACT_TRANSACTION_HISTORY + "?contractId=" + contractId + "&pageNumber=" + pageNo, {
			method: "GET",
			headers: {
				"Accept-language": "en",
				Authorization: "Bearer " + token,
				"X-APP-VERSION": "1.0",
				"X-DEVICE-MODEL": "INE-LX2r",
				"X-DEVICE-OS": "android",
				"X-OS-VERSION": "android 10",
			},
		})
			.then((response) => response.json())
			.then((json) => {
				let result = json.result;
				setTransactionList(result);
				setPaginationLocker(false);
			});
	};

	const [tableSearchFilter1, setTableSearchFilter1] = useState({
		// insert the key value requested by BE here
		pageNo: 1,
		pageSize: 12,
		sortBy: "id",
		sortDir: "ASCENDING",
		dateRangeType: null,
		dateFrom: "string",
		dateTo: "string",
		keyword: "",
	});

	// trigger when user click on header when sorting
	const headerFilterClick1 = (header) => {
		let column = header.column;
		if (tableSearchFilter1.sortBy === column.searchFilterValue) {
			// if click on the same column previously clicked will enter here
			if (tableSearchFilter1.sortDir === "ASCENDING") {
				// if previously the column is sort by ascending order, sortDir will change to sort by descending order
				setTableSearchFilter1({
					...tableSearchFilter1,
					sortBy: column.searchFilterValue,
					sortDir: "DESCENDING",
					pageNo: 1,
				});
			} else {
				// if previously the column is sort by descending order, sortDir will change to sort by ascending order
				setTableSearchFilter1({
					...tableSearchFilter1,
					sortBy: column.searchFilterValue,
					sortDir: "ASCENDING",
					pageNo: 1,
				});
			}
		} else {
			// if click on a new column, it will sort by ascending order with the column value (column.searchFilterValue)
			setTableSearchFilter1({
				...tableSearchFilter1,
				sortBy: column.searchFilterValue,
				sortDir: "ASCENDING",
				pageNo: 1,
			});
		}
	};

	const headerArrowRotate1 = (active) => {
		// current column header arrow icon will rotate depending on ascending order or descending order (tableSearchFilter1.sortDir)
		const classNames = ["react-table__icon-wrapper"];
		if (active && tableSearchFilter1.sortDir === "DESCENDING") classNames.push("react-table__icon-wrapper-down");
		if (active) classNames.push("react-table__icon-wrapper--show");
		return classNames.join(" ");
	};

	// tableCoumn is to create table column
	const tableColumn1 = [
		{
			id: "transactionDate",
			// header is the table header for id column
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick1(header)} className="react-table__header">
						<span className="react-table__header-title">Transaction Date</span>
						<div className={headerArrowRotate1(tableSearchFilter1.sortBy === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			// accessor will list out the table data based on the data key. In this case, the mockData key of id
			accessor: "transactionDate",
			// searchFilterValue is the column value that will send to BE to inform BE to sort according to which column. it is use to update tableSearchFilter1.searchValue
			searchFilterValue: "transactionDate",
			// minWidth, width, maxWidth is to adjust the width of this current column
			minWidth: 180,
			width: 180,
		},
		{
			id: "details",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick1(header)} className="react-table__header">
						<span className="react-table__header-title">Details</span>
						<div className={headerArrowRotate1(tableSearchFilter1.sortBy === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			searchFilterValue: "details",
			accessor: "details",
			minWidth: 200,
		},
		{
			id: "debitAmount",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick1(header)} className="react-table__header">
						<span className="react-table__header-title">Debit</span>
						<div className={headerArrowRotate1(tableSearchFilter1.sortBy === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			searchFilterValue: "debitAmount",
			accessor: "debitAmount",
			minWidth: 140,
			width: 140,
		},
		{
			id: "creditAmount",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick1(header)} className="react-table__header">
						<span className="react-table__header-title">Credit</span>
						<div className={headerArrowRotate1(tableSearchFilter1.sortBy === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			searchFilterValue: "creditAmount",
			accessor: "creditAmount",
			minWidth: 140,
			width: 140,
		},
	];

	const nextPage1 = async () => {
		if (transactionList.currentPageNumber < transactionList.totalPageNumber && !paginationLocker) {
			setTableSearchFilter1({
				pageNo: tableSearchFilter1.pageNo + 1,
				pageSize: tableSearchFilter1.pageSize,
				sortBy: tableSearchFilter1.sortBy,
				sortDir: tableSearchFilter1.sortDir,
				dateRangeType: tableSearchFilter1.dateRangeType,
				dateFrom: tableSearchFilter1.dateFrom,
				dateTo: tableSearchFilter1.dateTo,
				keyword: tableSearchFilter1.keyword,
			});
			setPaginationLocker(true);
			transactionHistory(tableSearchFilter1.pageNo + 1);
		} else if (transactionList.currentPageNumber === transactionList.totalPageNumber) {
			setPaginationLocker(false);
		}
	};

	const previousPage1 = async () => {
		if (transactionList.currentPageNumber > 1 && !paginationLocker) {
			setTableSearchFilter1({
				pageNo: tableSearchFilter1.pageNo - 1,
				pageSize: tableSearchFilter1.pageSize,
				sortBy: tableSearchFilter1.sortBy,
				sortDir: tableSearchFilter1.sortDir,
				dateRangeType: tableSearchFilter1.dateRangeType,
				dateFrom: tableSearchFilter1.dateFrom,
				dateTo: tableSearchFilter1.dateTo,
				keyword: tableSearchFilter1.keyword,
			});
			setPaginationLocker(true);
			transactionHistory(tableSearchFilter1.pageNo - 1);
		} else if (transactionList.currentPageNumber === 1) {
			setPaginationLocker(false);
		}
	};

	const [buttonDisable, setButtonDisable] = useState(buttonField);
	const appendAction = (value) => {
		// console.log(value)

		let token = null;
		token = localStorage.getItem("@storage_token");
		console.log("token " + token);

		fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.SIGN_LOAN_AGREEMENT + value + "&id=" + contractId, {
			method: "PUT",
			headers: {
				Accept: "*/*",
				"Accept-language": "en",
				Authorization: "Bearer " + token,
			},
		})
			.then((response) => response.json())
			.then((response) => {
				// console.log(response)
				if (!response.success) {
					appToast(response.message, false);
				} else {
					if (value === "attestor_sign") {
						setButtonDisable({ ...buttonDisable, attestorSignatureButton: { value: "defaultPointer" } });
						setDocuments({ ...documents, attestorSignatureDate: { value: response.result.attestorSignatureDate } });
					}
					if (value === "authorized_approver_sign") {
						setButtonDisable({ ...buttonDisable, authorizedApproverSignatureButton: { value: "defaultPointer" } });
						setDocuments({ ...documents, authorizedApproverSignatureDate: { value: response.result.attestorSignatureDate } });
					}
				}
			});
	};
	const [calendarPopUp, setCalendarPopUp] = useState(false);
	function PopUpCalendar(props) {
		return props.trigger && buttonDisable.lhdnSubmissionButton.value == "pointer" ? (
			<div className="pop-up">
				<div className="pop-up-data" style={{ width: "32% !important" }}>
					{/* <div>Hello Im Pop up</div> */}

					{props.children}

					<div
						className="row"
						style={{
							marginTop: "10px",
							display: "flex",
							justifyContent: "center",
						}}
					>
						<button
							className="app-button__button app-button__orange-button"
							style={{ width: "100px", marginRight: "10px" }}
							onClick={() => {
								uploadLHDNSubmission(), setCalendarPopUp(false);
							}}
						>
							Save
						</button>

						<button
							className="app-button__button app-button__orange-button"
							style={{ width: "100px" }}
							onClick={() => {
								setCalendarPopUp(false);
							}}
						>
							Cancel
						</button>
					</div>
				</div>
			</div>
		) : (
			""
		);
	}
	const uploadLHDNSubmission = () => {
		if (uploadLHDNDateTime.value === null || uploadLHDNDateTime.value === "") {
			appToast("Please Select Date Time", false);
			return;
		}
		console.log("uploadLHDNDateTime.value :" + uploadLHDNDateTime.value);
		// console.log(format(new Date(), 'yyyy-mm-dd hh:mm:ss a'));
		let mydate = new Date(uploadLHDNDateTime.value);
		let submissionDate = format(mydate, "dd/MM/yyyy hh:mm:ss a");
		console.log(submissionDate);
		// submissionDate=submissionDate.replace(' ','\t')
		// console.log(submissionDate)
		let token = localStorage.getItem("@storage_token");
		console.log(token);
		//   const formData = new FormData();
		// formData.append("submissionDate", submissionDate);
		fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.UPDATE_LHDN_SUBMISSION_DATE + contractId + "?submissionDate=" + submissionDate, {
			method: "PUT",
			headers: {
				Accept: "*/*",
				"Accept-language": "en",
				Authorization: "Bearer " + token,
				// "Content-Type": "application/json",
				// 'content-type': 'multipart/form-data',
			},
			// body: formData,
		})
			.then((response) => response.json())
			.then((response) => {
				// console.log(response)
				if (!response.success) {
					appToast(response.message, false);
				} else {
					setButtonDisable({ ...buttonDisable, lhdnSubmissionButton: { value: "defaultPointer" } });
					setDocuments({ ...documents, lhdnSubmissionDate: { value: response.result.LHDNSubmissionDate } });
				}
			});
	};

	const uploadLHDNDateTime = {
		value: "",
	};

	const renderUnauthorizedMsg = () => {
		return (
			<div style={{ display: "flex", justifyContent: "center", alignSelf: "center", paddingTop: "180px" }}>
				<div style={{ height: "100%", fontFamily: "gotham-bold", fontSize: "22px" }}>Sorry, but you do not have the authority to view this section.</div>
			</div>
		);
	};

	const tabClassNames = (no) => {
		return classNames({
			tablinks: true,
			[`tab${no}`]: true,
			"tab-selected": tab === no,
		});
	};

	const getTabClassNames = (type) => {
		const classNames = ["loan-application-view__score-button"];

		if (scoreTab === type) {
			classNames.push("loan-application-view__score-button--active");
		}

		return classNames.join(" ");
	};

	return (
		<div className="contract-view">
			<div className="top-context">
				<div className="tabs">
					<div className={tabClassNames("1")} onClick={() => tabClicked("1")}>
						<span>Contract Details</span>
					</div>
					{accessContractDetails.view_document && (
						<div className={tabClassNames("2")} onClick={() => tabClicked("2")}>
							<span>Documents</span>
						</div>
					)}
					<div className={tabClassNames("3")} onClick={() => tabClicked("3")}>
						<span>Payment</span>
					</div>
					{tab === "tab3" ? <div className="tablinks"></div> : ""}
					{tab !== "tab3" && accessContractPayment.generate_customer_contract_statement && (
						<div className="search-button1">
							<button onClick={() => generateCustomerContractStatment()}>Generate Customer Statement</button>
						</div>
					)}
				</div>
			</div>
			<div className="contract-detail">
				<div className="wrapper">
					<div className="review">
						<span className="largeText">{viewData !== undefined || viewData !== null ? viewData.referenceNumber : "IVC-C-A000001"}</span>
						<hr />
						<div className={firstTab}>
							<div className="input-form">
								<div className="input-content">
									<div className="input-container">
										<span>Applicant Name</span>
										<div className="input-wrapper">
											<input type="text" value={input.applicantName.value} readOnly />
										</div>
									</div>
									<div className="input-container">
										<span>Contract Date</span>
										<div className="input-wrapper">
											<input type="text" value={input.contractDate.value} readOnly />
										</div>
									</div>
								</div>
								<div className="input-content">
									<div className="input-container">
										<span>Loan Amount</span>
										<div className="input-wrapper">
											<input type="text" value={input.loanAmount.value} readOnly />
										</div>
									</div>
									<div className="input-container">
										<span>Loan Interest Rate</span>
										<div className="input-wrapper">
											<input type="text" value={parseFloat(input.loanInterestRate.value).toFixed(2) + "%"} readOnly />
										</div>
									</div>
								</div>
								<div className="input-content">
									<div className="input-container">
										<span>Loan Tenure</span>
										<div className="input-wrapper">
											<input type="text" value={input.loanTenure.value} readOnly />
										</div>
									</div>
									<div className="input-container">
										<span>User Id</span>
										<div className="input-wrapper">
											<input type="text" value={input.applicantId.value ? input.applicantId.value : ""} readOnly />
										</div>
									</div>
								</div>
								<div className="input-content">
									<div className="input-container">
										<span>Mobile Phone</span>
										<div className="input-wrapper">
											<input type="text" value={input.contactDetails.value} readOnly />
										</div>
									</div>
									<div className="input-container">
										<span>Email</span>
										<div className="input-wrapper">
											<input type="text" value={input.applicantEmail.value} readOnly />
										</div>
									</div>
								</div>
								<div className="input-content">
									<div className="input-container">
										<span>Approved By</span>
										<div className="input-wrapper">
											<input type="text" value={input.approvedBy.value} readOnly />
										</div>
									</div>
								</div>
							</div>
							<div className="input-form">
								<div className="input-content">
									<div className="input-container">
										<span>Customer Bank Details</span>
										<div className="input-wrapper">
											<input type="text" value={input.customerBankDetails.values.applicantBankCode} readOnly />
										</div>
									</div>
									<div className="input-container">
										<span></span>
										<div className="input-wrapper">
											<input type="text" value={input.customerBankDetails.values.applicantBankHolderName} readOnly />
										</div>
									</div>
								</div>
								<div className="input-content">
									<div className="input-container">
										<div className="input-wrapper">
											<input type="text" value={input.customerBankDetails.values.applicantBankAccountNo} readOnly />
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className={secondTab}>
							<PopUpCalendar trigger={calendarPopUp}>
								{/* <DateTimePicker   onChange={setLhdnDateTime} value={lhdnDateTime}></DateTimePicker > */}
								<input min={new Date().toISOString().slice(0, -8)} id="date" type="datetime-local" onFocus={(event) => (uploadLHDNDateTime.value = event.currentTarget.value)} />
							</PopUpCalendar>

							<div className="grid-container">
								<div className="grid-wrapper">
									<div className="item1">Video Interview</div>
									<div className="item2">{documents.interviewDate.value !== null ? documents.interviewDate.value : "—"}</div>
								</div>
								<div className="grid-wrapper">
									<div className="item3">
										<img src={iconPlay} alt="play video icon" onClick={() => selectedVideo(documents.interviewUrl.value)} />
									</div>
									<div className="item4">
										<img src={iconDownload} alt="download video icon" onClick={() => downloadViewInterview()} className="pointer" />
									</div>
								</div>
							</div>
							<div className="grid-container">
								<div className="grid-wrapper">
									<div className="item1">Live Briefing</div>
									<div className="item2">{documents.liveBriefingDate.value !== null ? documents.liveBriefingDate.value : "—"}</div>
								</div>
								<div className="grid-wrapper grid-wrapper--column">
									<div className="item3">
										<img src={iconPlay} alt="play video icon" onClick={() => selectedLiveBriefingVideo(documents.liveBriefingUrl.value)} />
									</div>
									<div className="item4">
										<img src={iconDownload} alt="download video icon" onClick={downloadLiveBriefing} className="pointer" />
									</div>
								</div>
							</div>
							<div className="grid-container">
								<div className="grid-wrapper">
									<div className="item1">e-Agreement</div>
									<div className="item2">{documents.loanAgreementDate.value !== null ? documents.loanAgreementDate.value : "—"}</div>
								</div>
								<div className="grid-wrapper grid-wrapper--column">
									<div className="item3">
										<img src={iconPlay} alt="play video icon" style={{ visibility: "hidden" }} />
									</div>
									{accessContractDetails.download_document && (
										<div className="item4">
											<img src={iconDownload} alt="download video icon" onClick={downloadLoanAgreement} className="pointer" />
										</div>
									)}
								</div>
							</div>
							<div className="grid-container">
								<div className="grid-wrapper">
									<div className="item1">Attestor Signature</div>
									<div className="item2">{documents.attestorSignatureDate.value !== null ? documents.attestorSignatureDate.value : "—"}</div>
								</div>
								<div className="grid-wrapper grid-wrapper--column">
									<div className="item3">
										<img src={iconPlay} alt="play video icon" style={{ visibility: "hidden" }} />
									</div>
									{accessContractDetails.attestor_sign && (
										<div className="item4">
											<img src={iconAttestorSign} alt="Attestor Signature icon" onClick={() => appendAction("attestor_sign")} className={buttonDisable.attestorSignatureButton.value} />
										</div>
									)}
								</div>
							</div>
							<div className="grid-container">
								<div className="grid-wrapper">
									<div className="item1">Authorized Approver Signature</div>
									<div className="item2">{documents.authorizedApproverSignatureDate.value !== null ? documents.authorizedApproverSignatureDate.value : "—"}</div>
								</div>
								<div className="grid-wrapper grid-wrapper--column">
									<div className="item3">
										<img src={iconPlay} alt="play video icon" style={{ visibility: "hidden" }} />
									</div>
									{accessContractDetails.authorized_approver_sign && (
										<div className="item4">
											<img
												src={iconAuthApproveSign}
												alt=">Authorized Approver Signature icon"
												onClick={() => appendAction("authorized_approver_sign")}
												className={buttonDisable.authorizedApproverSignatureButton.value}
											/>
										</div>
									)}
								</div>
							</div>
							<div className="grid-container">
								<div className="grid-wrapper">
									<div className="item1">LHDN Submission</div>
									<div className="item2">{documents.lhdnSubmissionDate.value !== null ? documents.lhdnSubmissionDate.value : "—"}</div>
								</div>

								<div className="grid-wrapper grid-wrapper--column">
									<div className="item3">
										<img src={iconPlay} alt="play video icon" style={{ visibility: "hidden" }} />
									</div>
									{accessContractDetails.update_ldhn_submission_date && (
										<div className="item4">
											<img src={iconLHDNSubmission} alt="LHDN Submission icon" className={buttonDisable.lhdnSubmissionButton.value} onClick={() => setCalendarPopUp(true)} />
										</div>
									)}
								</div>
							</div>

							<div className="grid-container">
								<div className="grid-wrapper">
									<div className="item1">Stamped e-Agreement</div>
									<div className="item2">{documents.loanAgreementStampedDate.value !== null ? documents.loanAgreementStampedDate.value : "—"}</div>
								</div>

								<div className="grid-wrapper grid-wrapper--column">
									{accessContractDetails.upload_stamped_agreement && (
										<div className="item3">
											<label htmlFor="file-input">
												<img src={iconUpload} alt="play video icon" />
											</label>
										</div>
									)}
									{accessContractDetails.download_document && (
										<div className="item4">
											{documents.loanAgreementStampedDate.value !== null ? (
												<img src={iconDownload} alt="download video icon" onClick={downloadloanAgreementStamped} className="pointer" />
											) : (
												<img src={iconDownload} alt="download video icon" className="disable-img" />
											)}
										</div>
									)}
								</div>
							</div>
							<input type="file" name="file-input" id="file-input" accept="application/pdf" style={{ display: "none" }} onChange={handleFileChange} />
						</div>
						<div className={thirdTab}>
							<div className="input-form">
								<div className="input-content">
									<div className="input-container">
										<span>Disbursement Date</span>
										<div className="input-wrapper">
											<input type="text" value={payDetail.disbursementDate.value || "-"} readOnly />
										</div>
									</div>
									<div className="input-container">
										<span>Payment Status</span>
										<div className="input-wrapper">
											<input type="text" value={payDetail.paymentStatus.value} readOnly />
										</div>
									</div>
								</div>
								<div className="input-content">
									<div className="input-container">
										<span>Last Payment Date</span>
										<div className="input-wrapper">
											<input type="text" value={payDetail.disbursementDate.value || "-"} readOnly />
										</div>
									</div>
									<div className="input-container">
										<span>Payment Status</span>
										<div className="input-wrapper">
											<div>{payDetail.paymentStatus.value == "OVERDUE" ? <button className="redButton">!</button> : ""}</div>
											<input type="text" value={payDetail.lastPaymentDate.value || "-"} readOnly />
										</div>
									</div>
								</div>
								<div className="input-content">
									<div className="input-container">
										<span>Last Payment Date</span>
										<div className="input-wrapper">
											<div>{payDetail.paymentStatus.value === "OVERDUE" ? <button className="redButton">!</button> : ""}</div>
											<input type="text" value={payDetail.lastPaymentDate.value || "-"} readOnly />
										</div>
									</div>
									<div className="input-container">
										<span>Last Payment Amount</span>
										<div className="input-wrapper">
											<input type="text" value={payDetail.lastPaymentAmount.value || "-"} readOnly />
										</div>
									</div>
								</div>
								<div className="input-content">
									<div className="input-container">
										<span>Next Payment Date</span>
										<div className="input-wrapper">
											<input type="text" value={payDetail.nextPaymentDate.value || "-"} readOnly />
										</div>
									</div>
									<div className="input-container">
										<span>Monthly Installment Amount</span>
										<div className="input-wrapper">
											<input type="text" value={payDetail.monthlyInstalmentAmount.value || "-"} readOnly />
										</div>
									</div>
								</div>
								<div className="input-content">
									<div className="input-container">
										<span>No. of Installments Remaining</span>
										<div className="input-wrapper">
											<input type="text" value={payDetail.numOfInstalmentRemaining.value || "-"} readOnly />
										</div>
									</div>
									<div className="input-container">
										<span>Remaining Principal Amount</span>
										<div className="input-wrapper">
											<input type="text" value={payDetail.remainingPrincipalAmount.value || "-"} readOnly />
										</div>
									</div>
								</div>
								<div className="input-content">
									<div className="input-container">
										<span>Remaining Interest Amount</span>
										<div className="input-wrapper">
											<input type="text" value={payDetail.remainingInterestAmount.value || "-"} readOnly />
										</div>
									</div>
									<div className="input-container">
										<span>Total Remaining Amount</span>
										<div className="input-wrapper">
											<input type="text" value={payDetail.totalRemainingAmount.value || "-"} readOnly />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="ctosScore">
						<div className={firstTab}>
							<div className="loan-application-view__score-wrapper">
								<div className="loan-application-view__score-button-row">
									<div className={getTabClassNames("ctos")} onClick={() => scoreTabClicked("ctos")}>
										<div className="loan-application-view__score-label-column">
											<span className="loan-application-view__score-label-title">CTOS Score</span>
											<span className="loan-application-view__score-label-subtitle">CTOS info from API</span>
										</div>
										<div className="loan-application-view__score-graph-wrapper">
											<span className="loan-application-view__score-graph">
												<div className={getTabScoreClassNames(scoreTab.ctos)}>{viewData.ctosCreditScore}</div>
												<PieChart width={50} height={50}>
													<Pie line data={pieDataCtos} dataKey="value" nameKey="name" innerRadius={20} outerRadius={25} paddingAngle={1} strokeWidth={0}>
														{pieDataCtos.map((entry, index) => {
															return <Cell key={`cell-${index}`} fill={pieColors[entry.name]} />;
														})}
													</Pie>
												</PieChart>
											</span>
										</div>
									</div>
									<div className={getTabClassNames("ivc")} onClick={() => scoreTabClicked("ivc")}>
										<div className="loan-application-view__score-label-column">
											<span className="loan-application-view__score-label-title">IVC Score</span>
											<span className="loan-application-view__score-label-subtitle">IVC info (internal)</span>
										</div>
										<div className="loan-application-view__score-graph-wrapper">
											<div className="loan-application-view__score-graph">
												<div className={getTabScoreClassNames(scoreTab.ivc)}>{viewData.internalScore}</div>
												<PieChart width={50} height={50}>
													<Pie data={pieDataIvc} dataKey="value" nameKey="name" innerRadius={20} outerRadius={25} paddingAngle={1} strokeWidth={0}>
														{pieDataIvc.map((entry, index) => {
															return <Cell key={`cell-${index}`} fill={pieColors[entry.name]} />;
														})}
													</Pie>
												</PieChart>
											</div>
										</div>
									</div>
								</div>
								<div className="loan-application-view__score-content">
									{scoreTab === "ctos" && (
										<div className="loan-application-view__score-content-wrapper">
											{accessContractDetails.view_ctos_score ? (
												<>
													{/* <div className="loan-application-view__score-body">
                          <span className="loan-application-view__score-body-title">EKYC</span>
                          <span className="loan-application-view__score-body-detail">Identical (%): {viewData.ekycIdenticalPercentage}</span>
                          <span className="loan-application-view__score-body-detail">KBA Correct Questions: {viewData.ekycQuestionTotalCorrect}</span>
                        </div> */}
													<div className="loan-application-view__score-body">
														<span className="loan-application-view__score-body-title">Score Description</span>
														{viewData.ctosScoreDescription &&
															viewData.ctosScoreDescription.map((e, i) => (
																<span key={i} className="loan-application-view__score-body-detail">
																	{e}
																</span>
															))}
													</div>
													<a href={viewData.ctosScorecardUrl} target="_blank" rel="noreferrer" className="loan-application-view__score-ctos-report">
														View CTOS Report
													</a>
												</>
											) : (
												renderUnauthorizedMsg()
											)}
										</div>
									)}

									{scoreTab === "ivc" && (
										<div className="loan-application-view__score-content-wrapper">
											{accessContractDetails.view_ivc_score
												? viewData.internalScoreBreakdown &&
												  viewData.internalScoreBreakdown.length &&
												  viewData.internalScoreBreakdown.map((o, i) => {
														return (
															<div key={i} className="loan-application-view__score-content-row">
																<div className="loan-application-view__score-content-column-1">{o.code}</div>
																<div className="loan-application-view__score-content-column-2">
																	{/* {o.documentUrl ? (
                                                                    <div className="loan-application-view__download-wrapper" onClick={() => downloadIvcDocs(o.documentUrl, o.code, o.label)}>
                                                                        <BsDownload />
                                                                    </div>
                                                                ) : null} */}
																	<span>{o.label}</span>
																</div>
																<div className="loan-application-view__score-content-column-3">{o.value} pts</div>
															</div>
														);
												  })
												: renderUnauthorizedMsg()}
										</div>
									)}
								</div>
							</div>
						</div>
						<div className={secondTab}>
							<div style={{ padding: "50px 10px 0px 10px" }}>
								{playVideo !== "" ? (
									<Player>
										<source src={playVideo} />
									</Player>
								) : (
									""
								)}
								{playBriefingVideo !== "" ? (
									<Player>
										<source src={playBriefingVideo} />
									</Player>
								) : (
									""
								)}
							</div>
						</div>
						<div className={thirdTab}>
							<div className="table-border">
								<div className="loan-application-view__video-tab-row">
									{accessContractPayment.view_invoice_history && (
										<div className={getVideoTabClassNames(videoTab, "interview")} onClick={() => videoTabHandler("interview")}>
											Invoice History
										</div>
									)}
									{accessContractPayment.view_transaction_history && (
										<div className={getVideoTabClassNames(videoTab, "briefing")} onClick={() => videoTabHandler("briefing")}>
											Transaction
										</div>
									)}
								</div>
								{(accessContractPayment.view_invoice_history || accessContractPayment.view_transaction_history) && (
									<div className="loan-application-view__video-contents">
										{paymentTab.invoice === 1 ? (
											<div className="page-loan-application-list__react-table-wrapper">
												{appList !== undefined && appList.data !== undefined ? (
													<AppReactTable
														columns={tableColumn}
														data={appList.data}
														headerClass="invoiceHistoryHeader"
														headerRowClass="invoiceHistoryHeader__row"
														rowHeaderClass="invoiceHistoryHeader__row-header"
														bodyClass="invoiceHistoryBody"
														bodyRowClass="invoiceHistoryBody__row"
														bodyDataClass="invoiceHistoryBody__row-data"
													/>
												) : (
													<div></div>
												)}
												<div className="pagination-button">
													{appList !== undefined ? (
														<span>
															{tableSearchFilter.pageNo === 1 ? tableSearchFilter.pageNo : 1 + appList.pageSize * (appList.currentPageNumber - 1)} -{" "}
															{tableSearchFilter.pageNo === 1
																? appList.pageSize * (appList.currentPageNumber - 1) + appList.currentCount
																: appList.pageSize * (appList.currentPageNumber - 1) + appList.currentCount}{" "}
															of {appList.totalCount}{" "}
														</span>
													) : (
														<></>
													)}
													<button onClick={() => previousPage()}>❮</button>
													<button onClick={() => nextPage()}>❯</button>
												</div>
											</div>
										) : (
											""
										)}
										{paymentTab.transaction === 1 ? (
											<div className="page-loan-application-list__react-table-wrapper">
												{transactionList !== undefined && transactionList.data !== undefined ? (
													<AppReactTable
														columns={tableColumn1}
														data={transactionList.data}
														headerClass="invoiceHistoryHeader"
														headerRowClass="invoiceHistoryHeader__row"
														rowHeaderClass="invoiceHistoryHeader__row-header"
														bodyClass="invoiceHistoryBody"
														bodyRowClass="invoiceHistoryBody__row"
														bodyDataClass="invoiceHistoryBody__row-data"
													/>
												) : (
													<div></div>
												)}
												<div className="pagination-button">
													{transactionList !== undefined ? (
														<span>
															{tableSearchFilter1.pageNo === 1 ? tableSearchFilter1.pageNo : 1 + transactionList.pageSize * (transactionList.currentPageNumber - 1)} -{" "}
															{tableSearchFilter1.pageNo === 1
																? transactionList.pageSize * (transactionList.currentPageNumber - 1) + transactionList.currentCount
																: transactionList.pageSize * (transactionList.currentPageNumber - 1) + transactionList.currentCount}{" "}
															of {transactionList.totalCount}{" "}
														</span>
													) : (
														<></>
													)}
													<button onClick={() => previousPage1()}>❮</button>
													<button onClick={() => nextPage1()}>❯</button>
												</div>
											</div>
										) : (
											""
										)}
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PageLoanContractView;
