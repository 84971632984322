// libraries
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
// commons
import CONSTANSTS from "common/constansts";
// routes
// import pathnames from "routes/pathnames";
// components
// import AppAuthInput from "components/pages/authentication/app-auth-input";
import AppForgotPsdInput from "components/pages/authentication/app-forgot-password-input";
//asset
import logo from "assets/images/pages/white-logo.svg";
import userLockIcon from "assets/images/pages/authentication/icon-user-lock.png";
import AppButton from "components/app-button";
import IconLock from "assets/images/pages/authentication/icon-unlock.svg";
import IconMail from "assets/images/pages/authentication/icon-email_silver.png";

import "../../../src/styles/pages/authentication/forgot-password.scss";
//api
// import api from "services/api";

import AppCustomToast, { PageContext } from "components/app-custom-toast";
import classNames from "common/class-names";

const inputs = {
  email: {
    placeholder: "Enter Email",
    required: "Email required",
    invalid: "Invalid email",
    regex: CONSTANSTS.REGEX.email,
  },
  code: {
    placeholder: "Please enter verification code",
    required: "Verification code required",
    invalid: "Invalid code, please provide correct code",
    regex: CONSTANSTS.REGEX.minSixDigit,
  },
};

const defaultFields = {
  email: {
    error: "",
    value: "",
  },
  code: {
    error: "",
    value: "",
  },
};

const PageForgotPassword = () => {
  const indicatorValue = useContext(PageContext);
  function messageDialog(indicator) {
    AppCustomToast({
      showIndicator: indicator,
      reintIndicator: indicatorValue[1](indicator),
    });
  }
  const history = useHistory();
  const [field, setField] = useState(defaultFields);

  const [getCodeButton, setGetCodeButton] = useState({
    disable: false,
    label: "Get Code",
  });

  const [nextButton, setNextButton] = useState({
    disable: false,
    label: "Next",
  });

  var passTimer;
  const onHandleSubmit = async () => {
    const emailValidate = CONSTANSTS.REGEX.email.test(field.email.value);
    const codeValidate = CONSTANSTS.REGEX.minSixDigit.test(field.code.value);

    if (emailValidate && codeValidate) {
      const emailValue = field.email.value;
      const codeValue = field.code.value;
      // if (sessionStorage.getItem("emailRequested") !== emailValue) {
      //     appToast("Email invalid - not the same email used to request the OTP just now.", false);
      // } else {
      let valid = await validateOTP();
      if (valid) {
        sessionStorage.setItem("emailRequested", emailValue);
        sessionStorage.setItem("verificationCode", codeValue);
        history.push("/change-password");
      }

      // }
    } else {
      setNextButton({
        disable: false,
        label: "Next",
      });
    }
  };

  // const changePassword = async () => {
  //     history.push("/change-password");
  // }

  const onHandleOnChange = (event) => {
    const name = event.currentTarget.name;
    const value = event.currentTarget.value;

    setField({
      ...field,
      [name]: {
        ...field[name],
        error: errorMessage(name, value),
        value: value.replace(CONSTANSTS.REGEX.spaces, ""),
      },
    });
  };

  const onHandleOnBlur = (event) => {
    const name = event.currentTarget.name;
    const value = event.currentTarget.value;

    setField({
      ...field,
      [name]: {
        ...field[name],
        error: errorMessage(name, value),
        value: value.replace(CONSTANSTS.REGEX.spaces, ""),
      },
    });
  };

  const errorMessage = (name, value) => {
    if (!value) {
      return inputs[name].required;
    } else if (!inputs[name].regex.test(value)) {
      return inputs[name].invalid;
    } else {
      return "";
    }
  };

  const onHandleOnChangeNum = (event) => {
    const name = event.currentTarget.name;
    const value = event.currentTarget.value;

    setField({
      ...field,
      [name]: {
        ...field[name],
        error: errorMessage(name, value),
        value: value.replace(CONSTANSTS.REGEX.applyonlynumber, ""),
      },
    });
  };
  async function validateOTP() {
    const emailValidate = CONSTANSTS.REGEX.email.test(field.email.value);
    let validOTP = false;
    if (emailValidate) {
      var response = await fetch(
        process.env.REACT_APP_API_URL +
          CONSTANSTS.ENDPOINT_PATH.STAFF_VALIDATEOTP,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Accept-language": "en",
            "X-APP-VERSION": "1.0",
            "X-DEVICE-MODEL": "INE-LX2r",
            "X-DEVICE-OS": "android",
            "X-OS-VERSION": "android 10",
          },
          body: JSON.stringify({
            email: field.email.value,
            otp: field.code.value,
          }),
        }
      );
      var responseJson = await response.json();
      validOTP = responseJson.success;
      if (responseJson.responseCode === 200) {
        return validOTP;
      } else {
        messageDialog({
          isOpen: true,
          condition: CONSTANSTS.DIALOG.failed,
          title: CONSTANSTS.DIALOG_TITLE.general_failed,
          message: responseJson.message,
          defaultOnClick: false,
        });
      }
    }
    return validOTP;
  }
  function startTimer(timer) {
    if (timer <= -1) {
      setGetCodeButton({
        disable: false,
        label: CONSTANSTS.DISPLAY_TEXT.resend,
      });
      clearInterval(passTimer);
      return;
    }
    setGetCodeButton({
      disable: true,
      label: timer,
    });
  }

  function requestOtp() {
    const emailValidate = CONSTANSTS.REGEX.email.test(field.email.value);
    console.warn("requestOTP", emailValidate);
    if (emailValidate) {
      // setGetCodeButton({
      //     disable: true,
      //     label: "Requesting.."
      // });
      // setTimeout(() => {
      //     setGetCodeButton({
      //         disable: false,
      //         label: "Resend"
      //     });
      // }, 36000);
      // const emailRes = fetch(api.get.staffRequestOtp(field.email.value));
      // console.log("res", emailRes);
      // fetch("http://49.0.203.50/ivc-auth/staff/requestOtp/" + field.email.value, {
      fetch(
        process.env.REACT_APP_API_URL +
          CONSTANSTS.ENDPOINT_PATH.STAFF_REQUESTOTP +
          field.email.value,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Accept-language": "en",
            "X-APP-VERSION": "1.0",
            "X-DEVICE-MODEL": "INE-LX2r",
            "X-DEVICE-OS": "android",
            "X-OS-VERSION": "android 10",
          },
        }
      )
        .then((response) => response.json())
        .then((response) => {
          if (response.responseCode === 200) {
            // console.log("res", response);
            let displayTimer = 60;

            passTimer = setInterval(function () {
              startTimer(displayTimer);
              displayTimer -= 1;
            }, 1000);
            messageDialog({
              isOpen: true,
              condition: CONSTANSTS.DIALOG.success,
              title: CONSTANSTS.DIALOG_TITLE.general_success,
              message: response.message,
              defaultOnClick: false,
            });
          } else {
            messageDialog({
              isOpen: true,
              condition: CONSTANSTS.DIALOG.failed,
              title: CONSTANSTS.DIALOG_TITLE.general_failed,
              message: response.message,
              defaultOnClick: false,
            });
            // appToast(response.message, false);
          }
        });
      sessionStorage.setItem("emailRequested", field.email.value);
    }
  }

  let btn_class = getCodeButton.disable ? "_grey" : "";

  const emailInputClassName = classNames({
    "forgot-password__input-wrapper": true,
    "forgot-password__input-wrapper--error": !!field.email.error,
  });

  const codeInputClassName = classNames({
    "forgot-password__input-wrapper": true,
    "forgot-password__input-wrapper--error": !!field.code.error,
  });

  return (
    // <AppForgotPswLayout>
    <div className="page-forgot-password">
      <div className="forgot-password">
        <header>
          <a href="/login">
            <img src={logo} alt="IVC logo" />
          </a>
        </header>
        <div className="grid-container">
          <div className="forgot-password__background">
            <p className="forgot-password__title">
              Forgot <br />
              Password
            </p>
          </div>

          <div className="forgot-password__content">
            <div className="forgot-password__content-wrapper">
              <div className="grid-context">
                <div className="grid-info">
                  <img
                    className="forgot-password__icon"
                    src={userLockIcon}
                    alt="lock secure"
                  />
                </div>
                <div className="grid-info">
                  <br />
                  <p className="forgot-password__sub-title">
                    Forgot your Password?
                  </p>
                  <p className="forgot-password__description">
                    Enter your registered e-mail address below and we will send
                    you the code to reset your password.
                  </p>
                </div>
              </div>

              <div className="grid-span">
                <div className={emailInputClassName}>
                  <div className="forgot-password__input-content">
                    <img
                      className="forgot-password__input-icon"
                      src={IconMail}
                      alt="email-icon"
                      height={"50px"}
                    />
                    <AppForgotPsdInput
                      name="email"
                      variation="withIcon"
                      placeholder={inputs.email.placeholder}
                      onChange={onHandleOnChange}
                      onBlur={onHandleOnBlur}
                      value={field.email.value}
                    />
                  </div>

                  <button
                    className="forgot-password__code-button"
                    onClick={requestOtp}
                    disabled={getCodeButton.disable}
                  >
                    {getCodeButton.label}
                  </button>
                </div>

                <p className="forgot-password__error-msg">
                  {field.email.error}
                </p>

                <div className={codeInputClassName}>
                  <div className="forgot-password__input-content">
                    <img
                      className="forgot-password__input-icon"
                      src={IconLock}
                      alt="lock-icon"
                      height={"50px"}
                    />
                    <AppForgotPsdInput
                      name="code"
                      variation="withIcon"
                      placeholder={inputs.code.placeholder}
                      onChange={onHandleOnChangeNum}
                      onBlur={onHandleOnBlur}
                      value={field.code.value}
                      maxLength="6"
                    />
                  </div>
                </div>

                <p className="forgot-password__error-msg">{field.code.error}</p>
              </div>

              <AppButton
                colorType="primary"
                label={nextButton.label}
                disabled={!field.email.value && !field.code.value}
                onClick={onHandleSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    // </AppForgotPswLayout>
  );
};

export default PageForgotPassword;
