// libraries
import React from "react";
import { useTable, useFlexLayout } from "react-table";

const AppReactTable = ({ columns, data, onClickRow, headerClass, headerRowClass, rowHeaderClass, bodyClass, bodyRowClass, bodyDataClass }) => {
	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
		{
			columns,
			data,
		},
		useFlexLayout
	);
	//let strike = "";
	if (headerClass === null || headerClass === undefined) {
		headerClass = "header";
		headerRowClass = "header__row";
		rowHeaderClass = "header__row-header";
	}

	if (bodyClass === null || bodyClass === undefined) {
		bodyClass = "body";
		bodyRowClass = "body__row";
		bodyDataClass = "body__row-data";
	}
	function enableStrike(values) {
		if (bodyClass == "invoiceHistoryBody" && values.isValid == false) {
			// strike = "invoiceHistoryBody__strike ";
			bodyDataClass += " invoiceHistoryBody__text-red";
		} else if (bodyClass == "invoiceHistoryBody") {
			bodyDataClass = "invoiceHistoryBody__row-data";
		} else {
			bodyDataClass = "body__row-data";
		}
	}

	return (
		<div className="app-react-table">
			<div className="app-react-table__content">
				<table {...getTableProps()} className="app-react-table__table-wrapper">
					<thead className={headerClass}>
						{headerGroups.map((headerGroup, index) => (
							<tr key={`tr_${index}`} className={headerRowClass} {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column, index) => (
									<th key={`th_${index}`} className={rowHeaderClass} {...column.getHeaderProps()}>
										{column.render("Header")}
									</th>
								))}
							</tr>
						))}
					</thead>
					{data[0] != null || data[0] != undefined ? (
						<tbody {...getTableBodyProps()} className={bodyClass}>
							{rows.map((row, i) => {
								prepareRow(row);
								enableStrike(row.values);
								return (
									<tr key={`tr_${i}`} {...row.getRowProps()} className={bodyRowClass} onClick={() => (onClickRow ? onClickRow(row.original) : null)}>
										{/* <img className={strike} /> */}
										{row.cells.map((cell, index) => {
											return (
												<td key={`td_${index}`} className={bodyDataClass} {...cell.getCellProps()}>
													{cell.render("Cell")}
												</td>
											);
										})}
									</tr>
								);
							})}
						</tbody>
					) : (
						<tbody {...getTableBodyProps()} className={bodyClass}>
							<td className={bodyDataClass + "_empty-message"}>There are no data to be displayed.</td>
						</tbody>
					)}
				</table>
			</div>
		</div>
	);
};

export default AppReactTable;
