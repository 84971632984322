// libraries
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import AppCustomToast, { PageContext } from "components/app-custom-toast";

// commons
import CONSTANSTS from "common/constansts";
// routes
// import pathnames from "routes/pathnames";
// components
// import AppAuthInput from "components/pages/authentication/app-auth-input";
import AppForgotPsdInput from "components/pages/authentication/app-forgot-password-input";
// routes
import pathnames from "routes/pathnames";
//asset
import logo from "assets/images/pages/white-logo.svg";
import { ReactComponent as IconTick } from "assets/images/icon-green-tick.svg";
import IconCross from "assets/images/icon-red-cross.svg";
import userLockIcon from "assets/images/pages/authentication/icon-user-lock.png";
import AppButton from "components/app-button";
import classname from "common/class-names";

// api
// import api from "services/api";

const placeholderText = {
	// step1
	initialEmailPlaceholder: "Email",
	emailRequired: "Email required",
	invalidEmail: "Invalid email",
	// step2
	initialTempPasswordPlaceholder: "Enter temporary password",
	tempPasswordRequired: "Temporary password required",
	// step3
	initialNewPassword: "Enter new password",
	initialConfirmPassword: "Confirm new password",
	passwordRequired: "Can't be blank",
	invalidPassword: "Password does not meet the requirements",
	passwordDoNotMatch: "Passwords do not match",
};

const initialField = {
	password: {
		error: false,
		value: "",
		placeholder: placeholderText.initialNewPassword,
	},
	confirmPassword: {
		error: false,
		value: "",
		placeholder: placeholderText.initialConfirmPassword,
	},
};

const defaultPasswordState = {
	passwordMinEightChar: 0,
	passwordMinOneNumber: 0,
	passwordMinOneUppercase: 0,
	passwordMinOneSpecialChar: 0,
};

const defaultPPreviousasswordState = {
	passwordPreviousCantBeSame: 0,
};

const PageChangePassword = () => {
	const indicatorValue = useContext(PageContext);
	function messageDialog(indicator) {
		AppCustomToast({
			showIndicator: indicator,
			reintIndicator: indicatorValue[1](indicator),
		});
	}

	const [field, setField] = useState(initialField);
	const history = useHistory();

	// 0 = first time, -1 = error, 1 = success
	const [passwordRequirement, setPasswordRequirement] = useState(defaultPasswordState);
	const [previousPasswordRequirement, setPreviousPasswordRequirement] = useState(defaultPPreviousasswordState);

	const [changePwdButton, setChangePwdButton] = useState({
		disable: false,
		label: "Change Password",
	});

	const resetPasswordBlank = () => {
		setField({
			...field,
			password: {
				...field.password,
				error: !field.password.value,
				placeholder: !field.password.value ? placeholderText.passwordRequired : placeholderText.initialNewPassword,
			},
			confirmPassword: {
				...field.confirmPassword,
				error: !field.confirmPassword.value,
				placeholder: !field.confirmPassword.value ? placeholderText.passwordRequired : placeholderText.initialConfirmPassword,
			},
		});
		setPasswordRequirement(defaultPasswordState);
	};

	const newPasswordConfirmPasswordMatch = () => {
		const passwordMinEightChar = CONSTANSTS.REGEX.minEightChar.test(field.password.value);
		const passwordMinOneUppercase = CONSTANSTS.REGEX.atLeaseOneUppercase.test(field.password.value);
		const passwordMinOneNumber = CONSTANSTS.REGEX.atLeastOneNumber.test(field.password.value);
		const passwordMinOneSpecialChar = CONSTANSTS.REGEX.atLeaseOneSpecialChar.test(field.password.value);
		setPasswordRequirement({
			passwordMinEightChar: passwordMinEightChar ? 1 : -1,
			passwordMinOneNumber: passwordMinOneNumber ? 1 : -1,
			passwordMinOneUppercase: passwordMinOneUppercase ? 1 : -1,
			passwordMinOneSpecialChar: passwordMinOneSpecialChar ? 1 : -1,
		});
		if (!passwordMinEightChar || !passwordMinOneNumber || !passwordMinOneUppercase || !passwordMinOneSpecialChar) {
			setField({
				...field,
				password: {
					error: true,
					value: "",
					placeholder: placeholderText.invalidPassword,
				},
				confirmPassword: {
					error: true,
					value: "",
					placeholder: placeholderText.invalidPassword,
				},
			});
		}
	};

	// const newPasswordCantBeSameAsPrevious = () => {
	//     if (sessionStorage.getItem("debugError") !== undefined || sessionStorage.getItem("debugError") !== null) {
	//         if (sessionStorage.getItem("debugError") === "New password cannot be same as previous password.") {
	//             setPreviousPasswordRequirement({
	//                 passwordPreviousCantBeSame: 1
	//             });
	//         } else {
	//             setPreviousPasswordRequirement({
	//                 passwordPreviousCantBeSame: -1
	//             });
	//         }
	//     }
	// }

	const newPasswordConfirmPasswordDoNotMatch = () => {
		setField({
			...field,
			confirmPassword: {
				error: true,
				value: "",
				placeholder: placeholderText.passwordDoNotMatch,
			},
		});
		setPasswordRequirement(defaultPasswordState);
	};

	const buttonResetPassword = () => {
		if (!field.password.value || !field.confirmPassword.value) {
			resetPasswordBlank();
		} else {
			if (field.password.value === field.confirmPassword.value) {
				newPasswordConfirmPasswordMatch();
				let payload = {
					email: sessionStorage.getItem("emailRequested"),
					otp: sessionStorage.getItem("verificationCode"),
					password: field.confirmPassword.value,
				};
				// fetch(api.put.staffResetPassword(payload);

				fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.STAFF_RESET_PASSWORD, {
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						"Accept-language": "en",
					},
					body: JSON.stringify(payload),
				})
					.then((response) => response.json())
					.then((json) => {
						let result = json;
						console.log("result: ", result);
						if (result.responseCode === 200) {
							setPreviousPasswordRequirement({
								passwordPreviousCantBeSame: 1,
							});
							messageDialog({
								isOpen: true,
								condition: CONSTANSTS.DIALOG.success,
								title: CONSTANSTS.DIALOG_TITLE.general_success,
								message: result.message,
								onClick: () => history.push(pathnames.pageLogin),
							});

							// history.push("/login");
						} else {
							messageDialog({
								isOpen: true,
								condition: CONSTANSTS.DIALOG.failed,
								title: CONSTANSTS.DIALOG_TITLE.general_failed,
								message: result.message,
								defaultOnClick: false,
							});
							if (result.message === "New password cannot be same as previous password.") {
								setPreviousPasswordRequirement({
									passwordPreviousCantBeSame: -1,
								});
							}
						}
					});
				// newPasswordCantBeSameAsPrevious();
				// console.warn("respond", sessionStorage.getItem("debugError").sucess);
				if (sessionStorage.getItem("debugError").sucess === true) {
					setChangePwdButton({
						disable: true,
						label: "Redirecting...",
					});
					setTimeout(() => {
						// history.push("/login");
						history.push(pathnames.pageLogin);
						messageDialog({
							isOpen: true,
							condition: CONSTANSTS.DIALOG.success,
							title: CONSTANSTS.DIALOG_TITLE.general_success,
							message: CONSTANSTS.DIALOG_MESSAGE.forget_password_success,
						});
					}, 600);
				} else {
					setChangePwdButton({
						disable: false,
						label: "Change Password",
					});
				}
			} else {
				newPasswordConfirmPasswordDoNotMatch();
			}
		}
	};

	const { passwordMinEightChar, passwordMinOneUppercase, passwordMinOneNumber, passwordMinOneSpecialChar } = passwordRequirement;
	const { passwordPreviousCantBeSame } = previousPasswordRequirement;

	const iconClassName = (error) => {
		return classname({
			"forgot-password__tick": true,
			"forgot-password__tick--error": error,
		});
	};

	return (
		// <AppForgotPswLayout>
		<div className="page-forgot-password">
			<div className="forgot-password">
				<header>
					<a href="/login">
						<img src={logo} alt="IVC logo" />
					</a>
				</header>
				<div className="grid-container">
					<div className="forgot-password__background">
						<p className="forgot-password__title">
							Forgot <br />
							Password
						</p>
					</div>

					<div className="forgot-password__content">
						<div className="forgot-password__content-wrapper">
							<div className="grid-context">
								<div className="grid-info">
									<img className="forgot-password__icon" src={userLockIcon} alt="lock secure" />
								</div>
								<div className="grid-info">
									<br />
									<p className="forgot-password__sub-title">Change Password?</p>
									<p className="forgot-password__description">Your new password must be different from previous used password</p>
								</div>
							</div>

							<div className="grid-span">
								<div className="forgot-password__input-container">
									<label className="forgot-password__label-text">Password</label>
									<AppForgotPsdInput
										name="password"
										variation="withIcon"
										type="password"
										placeholder={field.password.placeholder}
										value={field.password.value}
										error={field.password.error}
										onChange={(e) =>
											setField({
												...field,
												password: {
													...field.password,
													value: e.target.value,
												},
											})
										}
										onFocus={() =>
											setField({
												...field,
												password: {
													...field.password,
													error: false,
													placeholder: placeholderText.initialNewPassword,
												},
											})
										}
									/>
								</div>
								<div className="forgot-password__password-requirement-wrapper">
									<div className={`forgot-password__password-requirement ${passwordMinEightChar === 1 ? `page-create-password--success` : passwordMinEightChar === -1 ? `page-create-password--error` : ""}`}>
										<IconTick className={iconClassName(passwordMinEightChar <= -1 || !passwordMinEightChar)} />
										&nbsp;&nbsp;Use at least 8 characters
									</div>
									<div
										className={`forgot-password__password-requirement ${
											passwordMinOneSpecialChar === 1 ? `page-create-password--success` : passwordMinOneSpecialChar === -1 ? `page-create-password--error` : ""
										}`}
									>
										<IconTick className={iconClassName(passwordMinOneSpecialChar <= -1 || !passwordMinOneSpecialChar)} />
										&nbsp;&nbsp;Should contain at least 1 special character
									</div>
									<div
										className={`forgot-password__password-requirement
						                ${passwordMinOneUppercase === 1 ? `page-create-password--success` : passwordMinOneUppercase === -1 ? `page-create-password--error` : ""}`}
									>
										<IconTick className={iconClassName(passwordMinOneUppercase <= -1 || !passwordMinOneUppercase)} />
										&nbsp;&nbsp;Should contain at least 1 uppercase
									</div>
									<div
										className={`forgot-password__password-requirement
						                ${passwordPreviousCantBeSame === 1 ? `page-create-password--success` : passwordPreviousCantBeSame === -1 ? `page-create-password--error` : ""}`}
									>
										<IconTick className={iconClassName(passwordPreviousCantBeSame <= -1 || !passwordPreviousCantBeSame)} />
										&nbsp;&nbsp;Cannot be same as previous password
									</div>
									<div
										className={`forgot-password__password-requirement
						                ${passwordMinOneNumber === 1 ? `page-create-password--success` : passwordMinOneNumber === -1 ? `page-create-password--error` : ""}`}
									>
										<IconTick className={iconClassName(passwordMinOneNumber <= -1 || !passwordMinOneNumber)} />
										&nbsp;&nbsp;Should contain at least 1 numeric
									</div>
								</div>

								<div className="forgot-password__input-container">
									<label className="forgot-password__label-text"> Confirm Password</label>
									<AppForgotPsdInput
										name="confirmPassword"
										variation="withIcon"
										type="password"
										placeholder={field.confirmPassword.placeholder}
										value={field.confirmPassword.value}
										error={field.confirmPassword.error}
										onChange={(e) =>
											setField({
												...field,
												confirmPassword: {
													...field.confirmPassword,
													value: e.target.value,
												},
											})
										}
										onFocus={() =>
											setField({
												...field,
												confirmPassword: {
													...field.confirmPassword,
													error: false,
													placeholder: placeholderText.initialConfirmPassword,
												},
											})
										}
									/>
								</div>
							</div>
							<AppButton colorType="primary" label={changePwdButton.label} onClick={buttonResetPassword}></AppButton>
						</div>
					</div>
				</div>
			</div>
		</div>
		// </AppForgotPswLayout>
	);
};

export default PageChangePassword;
