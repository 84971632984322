// libraries
import React, { useState } from "react";
import jwtDecode from "jwt-decode";
import { useHistory } from "react-router-dom";
// commons
import CONSTANSTS from "common/constansts";
// routes
// import pathnames from "routes/pathnames";
// api
// import api from "services/api";
// context
import refreshToken from "contexts/refresh-token";
// components
import AppButton from "components/app-button";
import AppAuthInput from "components/pages/authentication/app-auth-input";
import AppAuthLayout from "components/pages/authentication/app-auth-layout";
// assets
import IconLock from "assets/images/pages/authentication/icon-lock.png";
import IconMail from "assets/images/pages/authentication/icon-email_silver.png";

const inputs = {
	email: {
		placeholder: "Email",
		required: "Email required",
		invalid: "Invalid email",
		regex: CONSTANSTS.REGEX.email,
	},
	password: {
		placeholder: "Password",
		required: "Password required",
		invalid: "Invalid password",
		regex: CONSTANSTS.REGEX.minEightChar,
	},
};

const defaultFields = {
	email: {
		error: "",
		value: "",
	},
	password: {
		error: "",
		value: "",
	},
};

const PageLogin = () => {
	const history = useHistory();
	const [field, setField] = useState(defaultFields);
	const [appButton, setAppButton] = useState({
		disable: false,
		label: "Log In",
	});
	const [forgetButton, setForgetButton] = useState({
		disable: false,
		label: "Forgot Password",
	});

	const onHandleSubmit = async () => {
		const emailValidate = CONSTANSTS.REGEX.email.test(field.email.value);
		const passwordValidate = CONSTANSTS.REGEX.minEightChar.test(field.password.value);

		if (emailValidate && passwordValidate) {
			setAppButton({
				disable: true,
				label: "Logging In...",
			});
			setForgetButton({
				disable: true,
				label: "Redirecting...",
			});
			// let payload = {
			//     username: field.email.value,
			//     password: field.password.value,
			//     channel: "web",
			//     grant_type: "password",
			// };
			// const response = await api.post.login(payload);
			const formData = new URLSearchParams();
			formData.append("username", field.email.value);
			formData.append("password", field.password.value);
			formData.append("channel", "web");
			formData.append("grant_type", "password");
			// console.log("path:",process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.LOGIN);
			// fetch("http://49.0.203.50/ivc-auth/login", {
			fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.LOGIN, {
				method: "POST",
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
					Accept: "*/*",
					"Accept-Encoding": "gzip, deflate, br",
					Connection: "keep-alive",
					Authorization: "Basic Y2xpZW50YXBwOjEyMw==",
				},
				body: formData.toString(),
			})
				.then((response) => response.json())
				.then((response) => {
					// console.log("Response:", response);
					var res = response;
					if (res.access_token != null) {
						localStorage.setItem("@storage_token", res.access_token);
						localStorage.setItem("refresh_token", res.refresh_token);
						refreshToken(history, res.expires_in);
						let tokenDecode = jwtDecode(res.access_token);
						// console.log("decode", tokenDecode);
						localStorage.setItem("decode_token", tokenDecode.authorities[0]);

						// fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.GET_USER_PROFILE,{
						//     method:"GET",
						//     headers: {
						//         "Accept-language": "en",
						//         Authorization: "Bearer " + res.access_token
						//     }
						// }).then((response) => response.json())
						// .then((json) => {
						//     if (json.responseCode === 200) {
						//         localStorage.setItem("user_id", json.result.id)
						//     } else {
						//         return;
						//     }
						// });

						fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.COMMON_GET_INFO, {
							method: "GET",
							headers: {
								"Accept-language": "en",
								Authorization: "Bearer " + res.access_token,
							},
						})
							.then((response) => response.json())
							.then((res) => {
								if (res.responseCode === 200) {
									// console.log("res", res.result);
									localStorage.setItem("accessMatrix", JSON.stringify(res.result));
									history.push("/home-page");
								} else {
									return;
								}
							});
					} else {
						localStorage.clear();
						setField({
							email: {
								...field.email,
								error: "Please try again.",
							},
							password: {
								...field.password,
								error: res.error_description,
							},
						});
						setAppButton({
							disable: false,
							label: "Log In",
						});
						setForgetButton({
							disable: false,
							label: "Forgot Password",
						});
					}
				});
			// localStorage.setItem("@storage_token", response.data["atfc-data"].authToken);
		}
	};

	const forgotPassword = async () => {
		history.push("/forgot-password");
	};

	const errorMessage = (name, value) => {
		if (!value) {
			return inputs[name].required;
		} else if (!inputs[name].regex.test(value)) {
			return inputs[name].invalid;
		} else {
			return "";
		}
	};

	const onHandleOnChange = (event) => {
		const name = event.currentTarget.name;
		const value = event.currentTarget.value;

		setField({
			...field,
			[name]: {
				...field[name],
				error: errorMessage(name, value),
				value: value.replace(CONSTANSTS.REGEX.spaces, ""),
			},
		});
	};

	const onHandleOnBlur = (event) => {
		const name = event.currentTarget.name;
		const value = event.currentTarget.value;

		setField({
			...field,
			[name]: {
				...field[name],
				error: errorMessage(name, value),
				value: value.replace(CONSTANSTS.REGEX.spaces, ""),
			},
		});
	};

	return (
		<AppAuthLayout>
			<div className="page-login">
				<div className="page-login__input-wrapper">
					<AppAuthInput
						name="email"
						variation="withIcon"
						iconAlt="mail-icon"
						iconSrc={IconMail}
						error={field.email.error}
						placeholder={inputs.email.placeholder}
						onChange={onHandleOnChange}
						onBlur={onHandleOnBlur}
						value={field.email.value}
					/>
				</div>

				<div className="page-login__input-wrapper">
					<AppAuthInput
						name="password"
						variation="withIcon"
						type="password"
						iconAlt="password-icon"
						iconSrc={IconLock}
						error={field.password.error}
						placeholder={inputs.password.placeholder}
						onChange={onHandleOnChange}
						onBlur={onHandleOnBlur}
						value={field.password.value}
					/>
				</div>

				<div className="page-login__button-container">
					<p className="page-login__forgot-password" onClick={forgotPassword}>
						{forgetButton.label}
					</p>
					<div className="page-login__login-button">
						<AppButton colorType="primary" onClick={onHandleSubmit} label={appButton.label} disabled={appButton.disable} />
					</div>
				</div>
			</div>
		</AppAuthLayout>
	);
};

export default PageLogin;
