import React from "react";
import { useRef, useContext, useEffect, useState } from "react";
// components
import MyTextInput from "components/input";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import AppCustomToast, { PageContext } from "components/app-custom-toast";
// commons
import CONSTANSTS from "common/constansts";

const PageLoanSettings = () => {
	const indicatorValue = useContext(PageContext);
	function messageDialog(indicator) {
		AppCustomToast({
			showIndicator: indicator,
			reintIndicator: indicatorValue[1](indicator),
		});
	}
	const defaultValue = {
		creditActiveDays: "",
		interestRate: "",
		loanAmountDenominator: "1",
		maxLoanAmount: "",
		maxLoanPeriod: "",
		minLoanAmount: "",
		minLoanPeriod: "",
		minTransactionAmount: "",
	};

	//select options default values
	const options = [
		//  { code: "1", label: "1", sortOrder: "1" },
		//  { code: "10", label: "10", sortOrder: "2" },
		//  { code: "100", label: "100", sortOrder: "3" },
		//  { code: "1000", label: "1000", sortOrder: "4" },
		//  { code: "10000", label: "10000", sortOrder: "5" },
		{ code: "", label: "", sortOrder: "" },
	];

	//dynamically assigns select options data
	function Options({ options }) {
		return options.map((option) => (
			<option key={option.code} value={option.label}>
				{option.label}
			</option>
		));
	}

	//fetch updated/latest loan settings data
	const [data, setData] = useState();
	const formikRef = useRef();
	const [optionsData, setOptionsData] = useState();

	const onHandleResetForm = () => {
		formikRef.current.resetForm();
	};

	useEffect(async () => {
		try {
			let token = null;
			token = localStorage.getItem("@storage_token");
			let code = "LOAN_DENOMINATOR";
			fetch(
				// "http://49.0.203.50/ivc-app/bo/settings/api/v1/getLoanCalculator",
				process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.GET_LOAN_CALCULATOR,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Accept: "*/*",
						"Accept-language": "en",
						Authorization: "Bearer " + token,
					},
				}
			)
				.then((response) => response.json())
				.then((result) => {
					if (result !== null || result !== undefined) {
						setData(result.result);
					} else {
						defaultValues.current = defaultValue;
						setData(defaultValue);
					}
				});
			fetch(
				// "http://49.0.203.50/ivc-app/bo/settings/api/v1/findDropdownOption/" +
				process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.FIND_DROPDOWN_OPTION + code,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Accept: "*/*",
						"Accept-language": "en",
						Authorization: "Bearer " + token,
					},
				}
			)
				.then((response) => response.json())
				.then((result) => {
					if (result !== null || result !== undefined) {
						setOptionsData(result.result);
					} else {
						setOptionsData(options);
					}
				});
		} catch (error) {
			console.log("error", error);
		}
	}, []);

	const validationMsg = {
		field_required: "This field cannot be empty",
		integer: "Value must be an integer",
		minimum_value: "Value cannot be lower than",
		maximum_value: "Value cannot be more than",
		more_than: "Value more than",
	};

	const validation = Yup.object({
		creditActiveDays: Yup.number()
			.min(1, validationMsg.minimum_value + " 1")
			.max(100, validationMsg.more_than + " 100")
			.required(validationMsg.field_required)
			.integer(validationMsg.integer),
		interestRate: Yup.number()
			.min(0.01, validationMsg.minimum_value + " 0.01")
			.max(100, validationMsg.more_than + " 100")
			.required(validationMsg.field_required),
		loanAmountDenominator: Yup.string().required(validationMsg.field_required),
		maxLoanAmount: Yup.number()
			.moreThan(Yup.ref("minLoanAmount"), validationMsg.more_than + " minimum")
			.required(validationMsg.field_required)
			.integer(validationMsg.integer),
		maxLoanPeriod: Yup.number()
			.moreThan(Yup.ref("minLoanPeriod"), validationMsg.more_than + " minimum")
			.max(100, validationMsg.more_than + " 100")
			.required(validationMsg.field_required),
		minLoanAmount: Yup.number()
			.min(1, validationMsg.minimum_value + " 1")
			.required(validationMsg.field_required)
			.integer(validationMsg.integer),
		minLoanPeriod: Yup.number()
			.min(1, validationMsg.minimum_value + " 1")
			.required(validationMsg.field_required)
			.integer(validationMsg.integer),
		minTransactionAmount: Yup.number()
			.min(1, validationMsg.minimum_value + " 1")
			.max(100, validationMsg.more_than + " 100")
			.required(validationMsg.field_required)
			.integer(validationMsg.integer),
	});

	return (
		<Formik
			ref={formikRef}
			initialValues={data}
			enableReinitialize={true}
			validationSchema={validation}
			onSubmit={(defaultValue, { setSubmitting }) => {
				let token = null;
				token = localStorage.getItem("@storage_token");
				fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.UPDATE_LOAN_CALCULATOR, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Accept: "*/*",
						"Accept-language": "en",
						Authorization: "Bearer " + token,
					},
					body: JSON.stringify(defaultValue, null, 2),
				})
					.then((response) => response.json())
					.then((response) => {
						if (response.responseCode == 200) {
							messageDialog({ isOpen: true, condition: CONSTANSTS.DIALOG.success, title: CONSTANSTS.DIALOG_TITLE.general_success, message: response.message });
						} else {
							messageDialog({ isOpen: true, condition: CONSTANSTS.DIALOG.failed, title: CONSTANSTS.DIALOG_TITLE.general_failed, message: response.message, defaultOnClick: false });
						}
					});
			}}
		>
			<Form>
				<div className="page-loan-settings">
					<div className="loan-settings">
						<div className="loan-settings__content  loan-settings__content--bg">
							<p className="loan-settings__title">
								Loan
								<br />
								Settings
							</p>
						</div>
						<div className="loan-settings__content">
							<div className="loan-settings__calculator">
								<p className="loan-settings__label">Calculator Denominator</p>
								<div className="loan-settings__input">
									<div className="loan-settings__field">
										<span>RM</span>
										<MyTextInput type="select" name="loanAmountDenominator">
											<Options options={optionsData || options} />
										</MyTextInput>
									</div>
								</div>

								<div className="loan-settings__wrapper">
									<p className="loan-settings__label">Loan Amount</p>
									<div className="loan-settings__input-wrapper">
										<div className="loan-settings__input">
											<span className="loan-settings__note">Mininum</span>
											<div className="loan-settings__field">
												<span>RM</span>
												<MyTextInput placeholder="XXXX" type="number-1" name="minLoanAmount" />
											</div>
										</div>
										<span className="loan-settings__text">TO</span>
										<div className="loan-settings__input">
											<span className="loan-settings__note">Maximum</span>
											<div className="loan-settings__field">
												<span>RM</span>
												<MyTextInput placeholder="XXXX" type="number-1" name="maxLoanAmount" />
											</div>
										</div>
									</div>
								</div>

								<div className="loan-settings__wrapper">
									<p className="loan-settings__label">Tenure</p>
									<div className="loan-settings__input-wrapper">
										<div className="loan-settings__input">
											<span className="loan-settings__note">Mininum</span>
											<div className="loan-settings__field">
												<span>RM</span>
												<MyTextInput placeholder="XXXX" type="number-2" name="minLoanPeriod" />
											</div>
										</div>
										<span className="loan-settings__text">TO</span>
										<div className="loan-settings__input">
											<span className="loan-settings__note">Maximum</span>
											<div className="loan-settings__field">
												<span>RM</span>
												<MyTextInput placeholder="XXXX" type="number-2" name="maxLoanPeriod" />
											</div>
										</div>
									</div>
								</div>

								<p className="loan-settings__label">Interest Rate</p>
								<div className="loan-settings__input">
									<div className="loan-settings__field">
										<MyTextInput placeholder="XX.XX" type="decimal-number" name="interestRate" />
										<span>%</span>
									</div>
								</div>

								<div className="loan-settings__wrapper loan-settings__wrapper--row">
									<div className="loan-settings__input-container">
										<p className="loan-settings__label">Minimum Transaction Amount</p>
										<div className="loan-settings__input-wrapper">
											<div className="loan-settings__input">
												<div className="loan-settings__field">
													<MyTextInput placeholder="XXX" type="number-2" name="minTransactionAmount" />
													<span>%</span>
												</div>
											</div>
										</div>
									</div>
									<span className="loan-settings__text"></span>
									<div className="loan-settings__input-container">
										<p className="loan-settings__label">Credit Active</p>
										<div className="loan-settings__input-wrapper">
											<div className="loan-settings__input">
												<div className="loan-settings__field">
													<MyTextInput placeholder="XXXX" type="number-2" name="creditActiveDays" />
													<span>Days</span>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="loan-settings__button-container">
									<button className="loan-settings__button loan-settings__button--cancel" type="reset" onClick={onHandleResetForm}>
										Cancel
									</button>
									<button className="loan-settings__button" type="submit">
										Update
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Form>
		</Formik>
	);
};

export default PageLoanSettings;
