// libraries
import React, { useState, useEffect } from "react";
import Scrollbars from "rc-scrollbars";
import { useHistory, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppCustomToast, { PageContext } from "components/app-custom-toast";
// routes
import pathnames from "routes/pathnames";
// components
import AppNav from "components/app-nav";
// pages
import PageLogout from "pages/logout";

const AppLayout = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const [showLogout, setShowLogout] = useState(false);
  const [showIndicator, setShowIndicator] = useState({
    isOpen: false,
    condition: "",
    title: "",
    message: "",
    defaultOnClick: true,
    onClick: () => null,
  });
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const logoutToggle = () => {
    setShowLogout(!showLogout);
  };

  const logout = () => {
    localStorage.clear();
    logoutToggle();
    history.push(pathnames.pageLogin);
  };

  useEffect(() => {
    const authenticated = localStorage.getItem("@storage_token");
    if (authenticated) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, [location]);

  const getScrollBarsClassName = (isAuthenticated) => {
    const classNames = ["rc-scrollbars"];
    if (isAuthenticated) classNames.push("rc-scrollbars__with-navbar");
    return classNames.join(" ");
  };

  return (
    <PageContext.Provider value={[showIndicator, setShowIndicator]}>
      <div className="root-page app-layout">
        <ToastContainer />

        <main className="root-main">
          {isAuthenticated && <AppNav logoutToggle={logoutToggle} />}

          {isAuthenticated && (
            <Scrollbars
              id="rc-scroll"
              className={getScrollBarsClassName(isAuthenticated)}
            >
              {children}
            </Scrollbars>
          )}
          {!isAuthenticated && children}
        </main>
        <PageLogout isOpen={showLogout} toggle={logoutToggle} logout={logout} />

        <AppCustomToast
          showIndicator={showIndicator}
          reintIndicator={setShowIndicator}
        ></AppCustomToast>
      </div>
    </PageContext.Provider>
  );
};

export default AppLayout;
