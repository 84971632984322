import React, { useState, useEffect } from "react";
import { FiSearch } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import AppReactTable from "components/app-react-table";

import pathnames from "routes/pathnames";
import CONSTANSTS from "common/constansts";
import AppButton from "components/app-button";
import AppSearchBar from "components/app-search-bar";

const PageUserManagement = () => {
	// tableSearchFilter are filter payload to be send to backend
	const [tableSearchFilter, setTableSearchFilter] = useState({
		// insert the key value requested by BE here
		pageNo: 1,
		pageSize: 50,
		sortBy: "id",
		sortDir: "ASCENDING",
		keyword: "",
	});

	// trigger when user click on header when sorting
	const headerFilterClick = (header) => {
		let column = header.column;
		if (tableSearchFilter.sortBy === column.searchFilterValue) {
			// if click on the same column previously clicked will enter here
			if (tableSearchFilter.sortDir === "ASCENDING") {
				// if previously the column is sort by ascending order, sortDir will change to sort by descending order
				setTableSearchFilter({
					...tableSearchFilter,
					sortBy: column.searchFilterValue,
					sortDir: "DESCENDING",
					pageNo: 1,
				});
			} else {
				// if previously the column is sort by descending order, sortDir will change to sort by ascending order
				setTableSearchFilter({
					...tableSearchFilter,
					sortBy: column.searchFilterValue,
					sortDir: "ASCENDING",
					pageNo: 1,
				});
			}
		} else {
			// if click on a new column, it will sort by ascending order with the column value (column.searchFilterValue)
			setTableSearchFilter({
				...tableSearchFilter,
				sortBy: column.searchFilterValue,
				sortDir: "ASCENDING",
				pageNo: 1,
			});
		}
	};

	const headerArrowRotate = (active) => {
		// current column header arrow icon will rotate depending on ascending order or descending order (tableSearchFilter.sortDir)
		const classNames = ["react-table__icon-wrapper"];
		if (active && tableSearchFilter.sortDir === "DESCENDING") classNames.push("react-table__icon-wrapper-down");
		if (active) classNames.push("react-table__icon-wrapper--show");
		return classNames.join(" ");
	};

	// tableCoumn is to create table column
	const tableColumn = [
		{
			id: "id",
			// header is the table header for id column
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick(header)} className="react-table__header" style={{ paddingLeft: "115px" }}>
						<span className="react-table__header-title">Staff ID</span>
						<div className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			// accessor will list out the table data based on the data key. In this case, the mockData key of id
			accessor: "id",
			// searchFilterValue is the column value that will send to BE to inform BE to sort according to which column. it is use to update tableSearchFilter.searchValue
			searchFilterValue: "id",
			// minWidth, width, maxWidth is to adjust the width of this current column
			minWidth: 100,
			width: 100,
		},
		{
			id: "name",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick(header)} className="react-table__header" style={{ paddingLeft: "205px" }}>
						<span className="react-table__header-title">Name</span>
						<div className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			searchFilterValue: "name",
			accessor: "name",
			minWidth: 130,
			width: 130,
		},
		{
			id: "role",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick(header)} className="react-table__header" style={{ paddingLeft: "105px" }}>
						<span className="react-table__header-title">Role</span>
						<div className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			searchFilterValue: "role",
			accessor: "role",
			minWidth: 70,
			width: 70,
		},
		{
			id: "dateLastLogin",
			Header: (header) => {
				return (
					<div onClick={() => headerFilterClick(header)} className="react-table__header" style={{ paddingLeft: "120px" }}>
						<span className="react-table__header-title">Last Login Date</span>
						<div className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)}>
							<span className="react-table__icon"></span>
						</div>
					</div>
				);
			},
			searchFilterValue: "dateLastLogin",
			accessor: "dateLastLogin",
			minWidth: 100,
			width: 100,
		},
		{
			id: "button",
			Header: "",
			Cell: (row) => {
				return (
					<div onClick={(e) => e.stopPropagation()}>
						<button onClick={() => viewPage(row.row.original)} className="react-table__search-button">
							<FiSearch size={24} />
						</button>
					</div>
				);
			},
			minWidth: 100,
			width: 100,
		},
	];

	const [paginationLocker, setPaginationLocker] = useState(true);
	const [exportBtn, setExportBtn] = useState("orange");

	useEffect(() => {
		const fetchData = async () => {
			let token = null;
			token = localStorage.getItem("@storage_token");
			try {
				// let response = fetch("http://49.0.203.50/ivc-app/bo/user/api/v1/list", {
				fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.GET_USER_LIST, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Accept: "*/*",
						"Accept-language": "en",
						Authorization: "Bearer " + token,
					},
					body: JSON.stringify({
						criteria: {
							keyword: tableSearchFilter.keyword,
						},
						pageNumber: tableSearchFilter.pageNo,
						pageSize: tableSearchFilter.pageSize,
						sortDirection: tableSearchFilter.sortDir,
						sortKey: tableSearchFilter.sortBy,
					}),
				})
					.then((response) => response.json())
					.then((json) => {
						let result = json.result;
						setStaffList(result);
						setPaginationLocker(false);
						if (result.data.length === 0) {
							setExportBtn("dimmed");
						} else {
							setExportBtn("orange");
						}
					});
				// dispatch(updateUserList(response));
			} catch (error) {
				console.log("error", error);
			}
		};
		fetchData();
	}, [tableSearchFilter]);

	const nextPage = async () => {
		if (staffList.currentPageNumber < staffList.totalPageNumber && !paginationLocker) {
			setTableSearchFilter({
				pageNo: tableSearchFilter.pageNo + 1,
				pageSize: tableSearchFilter.pageSize,
				sortBy: tableSearchFilter.sortBy,
				sortDir: tableSearchFilter.sortDir,
				keyword: tableSearchFilter.keyword,
			});
			setPaginationLocker(true);
		} else if (staffList.currentPageNumber === staffList.totalPageNumber) {
			setPaginationLocker(false);
		}
	};

	const previousPage = async () => {
		if (staffList.currentPageNumber > 1 && !paginationLocker) {
			setTableSearchFilter({
				pageNo: tableSearchFilter.pageNo - 1,
				pageSize: tableSearchFilter.pageSize,
				sortBy: tableSearchFilter.sortBy,
				sortDir: tableSearchFilter.sortDir,
				keyword: tableSearchFilter.keyword,
			});
			setPaginationLocker(true);
		} else if (staffList.currentPageNumber === 1) {
			setPaginationLocker(false);
		}
	};

	const onKeyUp = (event) => {
		if (event.key === "Enter") {
			console.log("enter press here! ", event);
		}
	};

	const onBlur = (e) => {
		setSearchValue(e.target.value);
	};

	const onClick = () => {
		setTableSearchFilter({
			pageNo: tableSearchFilter.pageNo,
			pageSize: tableSearchFilter.pageSize,
			sortBy: tableSearchFilter.sortBy,
			sortDir: tableSearchFilter.sortDir,
			keyword: searchValue,
		});
	};

	/////////////////////////////////////////////////////////////////

	const [staffList, setStaffList] = useState(undefined);
	const history = useHistory();
	const [searchValue, setSearchValue] = useState(undefined);
	console.log(searchValue);

	const addNewUserPage = async () => {
		history.push(pathnames.pageAddNewUser);
	};

	const viewPage = async (value) => {
		history.push(pathnames.pageUserDetail, value.id.toString());
	};

	/*
  // Parse our locale string to [date, time]
  var date = new Date().toLocaleString("ms", { hour12: false }).split(" ");

  // Now we can access our time at date[1], and monthdayyear @ date[0]
  // var time = date[1];
  var mdy = date[0];

  // We then parse  the mdy into parts
  mdy = mdy.split("/");
  var day = parseInt(mdy[0]);
  var month = parseInt(mdy[1]);
  var year = parseInt(mdy[2]);

  // Putting it all together
  // var formattedDate = day + '-' + month + '-' + year + ' ' + time;
  var formattedDate = "";
  if (day < 10 && month > 10) {
    formattedDate = "0" + day + "" + month + "" + year;
  }
  if (month < 10 && day > 10) {
    formattedDate = "" + day + "0" + month + "" + year;
  }
  if (day < 10 && month < 10) {
    formattedDate = "0" + day + "0" + month + "" + year;
  }
  // var formattedDate = day + '' + month + '' + year;
  const [currentDate] = useState(formattedDate.toString());
  // console.log("date:", mdy, currentDate)
  */

	const exportList = (btn) => {
		if (btn === "orange") {
			let token = null;
			token = localStorage.getItem("@storage_token");
			try {
				// let response = fetch("http://49.0.203.50/ivc-app/bo/user/api/v1/export", {
				fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.GET_USER_EXPORT_LIST, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Accept: "*/*",
						"Accept-language": "en",
						Authorization: "Bearer " + token,
					},
					body: JSON.stringify({
						criteria: {
							keyword: tableSearchFilter.keyword,
						},
						pageNumber: tableSearchFilter.pageNo,
						pageSize: tableSearchFilter.pageSize,
						sortDirection: tableSearchFilter.sortDir,
						sortKey: tableSearchFilter.sortBy,
					}),
				}).then((response) => {
					let filename = response.headers.get("Content-Disposition");
					if (filename != null) {
						filename = filename.split(";")[1].split("filename=")[1];
					}
					response.blob().then((blob) => {
						console.log(blob);
						let url = window.URL.createObjectURL(blob);
						let a = document.createElement("a");
						a.href = url;
						// a.download = "User_" + currentDate + ".xlsx";
						a.download = filename;
						a.click();
					});
				});

				//dispatch(response);
			} catch (error) {
				console.log(error);
			}
		} else {
			return;
		}
	};

	return (
		<div className="page-user-management">
			<div className="fixed-content">
				<div className="top-context">
					<div className="title">User Management</div>
					<div className="top-context__wrapper">
						<div className="search-bar">
							<AppSearchBar placeholder="Search for..." onKeyEnter={onKeyUp} onBlur={onBlur} onClick={onClick}></AppSearchBar>
						</div>
						<div className="search-button">
							<AppButton colorType={exportBtn} label="Export" onClick={() => exportList(exportBtn)}></AppButton>
						</div>
						<div className="search-button">
							<AppButton colorType="orange" label="Add New User" onClick={addNewUserPage}></AppButton>
						</div>
					</div>
				</div>

				{/* <p style={{ textAlign: "right" }}>1-50 of 100   &#10092;   &#10093;</p> */}
				<div className="pagination-button">
					{staffList !== undefined ? (
						<span>
							{tableSearchFilter.pageNo === 1 && staffList.totalCount > 0
								? tableSearchFilter.pageNo
								: tableSearchFilter.pageNo === 1 && staffList.totalCount === 0
								? staffList.totalCount
								: 1 + staffList.pageSize * (staffList.currentPageNumber - 1)}{" "}
							- {staffList.pageSize * (staffList.currentPageNumber - 1) + staffList.currentCount} of {staffList.totalCount}{" "}
						</span>
					) : (
						<></>
					)}
					<button onClick={() => previousPage()}>❮</button>
					<button onClick={() => nextPage()}>❯</button>
				</div>

				<div className="page-loan-application-list__react-table-wrapper" style={{ marginTop: "10px" }}>
					{staffList !== undefined && staffList.data !== undefined ? <AppReactTable columns={tableColumn} data={staffList.data} /> : <div></div>}
				</div>
			</div>
		</div>
	);
};

export default PageUserManagement;
