// libraries
import React, { useContext, useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
// commons
import CONSTANSTS from "../../common/constansts";
//component
import AppButton from "components/app-button";
import pathnames from "routes/pathnames";
import IconUpload from "../../assets/images/pages/icon-upload.png";
import AppCustomToast, { PageContext } from "components/app-custom-toast";
import classNames from "common/class-names";

const PageMerchantAdd = () => {
	const indicatorValue = useContext(PageContext);
	function messageDialog(indicator) {
		AppCustomToast({
			showIndicator: indicator,
			reintIndicator: indicatorValue[1](indicator),
		});
	}
	const history = useHistory();
	const [categoryList, setCategoryList] = useState(undefined);
	const [stateList, setStateList] = useState(undefined);
	const [bankList, setBankList] = useState(undefined);
	const [hqList, setHqList] = useState(undefined);
	let token = localStorage.getItem("@storage_token");

	useEffect(() => {
		const fetchCategory = async () => {
			fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.COMMON_FIND_DROPDOWN_OPTION + "MERCHANT_CATEGORY", {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Accept: "*/*",
					"Accept-language": "en",
				},
			})
				.then((response) => response.json())
				.then((json) => {
					let res = json.result;
					setCategoryList(res);
				});
		};
		const fetchState = async () => {
			fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.COMMON_FIND_DROPDOWN_OPTION + "STATE", {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Accept: "*/*",
					"Accept-language": "en",
				},
			})
				.then((response) => response.json())
				.then((json) => {
					let res = json.result;
					setStateList(res);
				});
		};
		const fetchBank = async () => {
			fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.COMMON_FIND_DROPDOWN_OPTION + "BANK", {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Accept: "*/*",
					"Accept-language": "en",
				},
			})
				.then((response) => response.json())
				.then((json) => {
					let res = json.result;
					setBankList(res);
				});
		};
		const fetchHeadquarters = async () => {
			fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.GET_MAIN_MERCHANT_LIST, {
				method: "GET",
				headers: {
					// "Content-Type": "application/json",
					Accept: "*/*",
					"Accept-language": "en",
					Authorization: "Bearer " + token,
				},
			})
				.then((response) => response.json())
				.then((json) => {
					let res = json.result;
					setHqList(res);
				});
		};
		fetchCategory();
		fetchState();
		fetchBank();
		fetchHeadquarters();
	}, []);

	const inputText = {
		merchantName: {
			placeholder: "Merchant Name",
			required: "Merchant Name required",
			invalid: "Invalid merchant name",
			regex: /^[a-zA-Z0-9 ./-]*$/,
		},
		merchantEmail: {
			placeholder: "Email",
			required: "Email required",
			invalid: "Invalid email",
			regex: CONSTANSTS.REGEX.email,
		},
		category: {
			placeholder: "Category",
			required: "Category required",
			invalid: "Invalid category",
			regex: CONSTANSTS.REGEX.checkEmpty,
		},
		address1: {
			placeholder: "Address 1",
			required: "Address 1 required",
			invalid: "Invalid address 1",
			regex: CONSTANSTS.REGEX.checkEmpty,
		},
		address2: {
			placeholder: "Address 2",
			required: "Address 2 required",
			invalid: "Invalid address 2",
			regex: CONSTANSTS.REGEX.checkEmpty,
		},
		state: {
			placeholder: "State",
			required: "State required",
			invalid: "Invalid state",
			regex: /^.{5,}$/,
		},
		bankName: {
			placeholder: "Bank Name",
			required: "Bank Name required",
			invalid: "Invalid bank name",
			regex: CONSTANSTS.REGEX.checkEmpty,
		},
		accountName: {
			placeholder: "Account Name",
			required: "Account Name required",
			invalid: "Invalid account name",
			regex: /^[a-zA-Z0-9 ./-]*$/,
		},
		effectiveDate: {
			placeholder: "Effective Date",
			required: "Effective Date required",
			invalid: "Invalid effective date",
			regex: CONSTANSTS.REGEX.checkEmpty,
		},
		agreementUpload: {
			placeholder: "No file chosen",
			required: "Agreement Upload required",
			invalid: "Invalid agreement upload",
			regex: CONSTANSTS.REGEX.checkEmpty,
		},
		city: {
			placeholder: "City",
			required: "City required",
			invalid: "Invalid city",
			regex: CONSTANSTS.REGEX.checkEmpty,
		},
		businessRegNo: {
			placeholder: "Business Registration Number",
			required: "Business Reg. No required",
			invalid: "Invalid Business Reg. No",
			regex: /^[a-zA-Z0-9 ()-]*$/,
		},
		headquarters: {
			placeholder: "Headquarters Name",
			required: "Headquarters Name required",
			invalid: "Invalid headquarters name",
			regex: CONSTANSTS.REGEX.checkEmpty,
		},
	};

	const inputField = {
		merchantName: {
			value: "",
			error: "",
		},
		merchantEmail: {
			value: "",
			error: "",
		},
		mobilePhone: {
			value: "",
			error: "",
		},
		category: {
			value: "",
			error: "",
		},
		address1: {
			value: "",
			error: "",
		},
		address2: {
			value: "",
			error: "",
		},
		state: {
			value: "",
			error: "",
		},
		bankName: {
			value: "",
			error: "",
		},
		accountName: {
			value: "",
			error: "",
		},
		effectiveDate: {
			value: "",
			error: "",
		},
		agreementUpload: {
			value: "",
			error: "",
		},
		city: {
			value: "",
			error: "",
		},
		businessRegNo: {
			value: "",
			error: "",
		},
		subsidiary: {
			value: false,
			error: "",
		},
		headquarters: {
			value: "",
			error: "",
		},
	};

	const inputNum = {
		mobilePhone: {
			placeholder: "Mobile Phone",
			required: "Mobile Phone required",
			invalid: "Invalid mobile phone",
			regex: /^.{10,}$/,
		},
		postcode: {
			placeholder: "Postcode",
			required: "Postcode required",
			invalid: "Invalid Postcode",
			regex: /^.{5,}$/,
		},
		accountNo: {
			placeholder: "Account No",
			required: "Account No required",
			invalid: "Invalid Account No",
			regex: /^.{7,}$/,
		},
	};

	const numberField = {
		mobilePhone: {
			value: "",
			error: "",
		},
		postcode: {
			value: "",
			error: "",
		},
		accountNo: {
			value: "",
			error: "",
		},
	};

	const [input, setInput] = useState(inputField);
	const [numField, setNumField] = useState(numberField);

	const back = async () => {
		history.push(pathnames.pageMerchantList);
	};

	const [disableBtn, setDisableBtn] = useState(false);

	// const getInputClassName = (error) => {
	//   const classNames = ["merchant-input-text"];
	//   if (error!==null) {
	//       classNames.push("merchant-input-text--error");
	//   }
	// };

	const [validation, setValidation] = useState({});

	const [nameValidate, setNameValidate] = useState("merchant-input-text");
	const [emailValidate, setEmailValidate] = useState("merchant-input-text");
	const [categoryValidate, setCategoryValidate] = useState("select-field");
	const [address1Validate, setAddress1Validate] = useState("text-area");
	const [cityValidate, setCityValidate] = useState("merchant-input-text");
	const [stateValidate, setStateValidate] = useState("select-field");
	const [bankNameValidate, setBankNameValidate] = useState("select-field");
	const [hqNameValidate, setHqNameValidate] = useState("select-field");
	const [accountNameValidate, setAccountNameValidate] = useState("merchant-input-text");
	const [effectiveDateValidate, setEffectiveDateValidate] = useState("merchant-input-text");
	const [agreementUploadValidate, setAgreementUploadValidate] = useState("merchant-input-text");

	const [mobilePhoneValidate, setMobilePhoneValidate] = useState("merchant-input-text");
	const [postcodeValidate, setPostcodeValidate] = useState("merchant-input-text");
	const [accountNoValidate, setAccountNoValidate] = useState("merchant-input-text");
	const [businessRegNoValidate, setBusinessRegNoValidate] = useState("merchant-input-text");

	const createNewMerchant = () => {
		checkValidation();

		if (
			nameValidate === "merchant-input-text" &&
			inputText.merchantName.regex.test(input.merchantName.value) === true &&
			emailValidate === "merchant-input-text" &&
			input.merchantEmail.value !== "" &&
			CONSTANSTS.REGEX.email.test(input.merchantEmail.value) === true &&
			categoryValidate === "select-field" &&
			input.category.value !== "" &&
			address1Validate === "text-area" &&
			input.address1.value !== "" &&
			cityValidate === "merchant-input-text" &&
			input.city.value !== "" &&
			stateValidate === "select-field" &&
			input.state.value !== "" &&
			bankNameValidate === "select-field" &&
			input.bankName.value !== "" &&
			hqNameValidate === "select-field" &&
			(input.subsidiary.value ? input.headquarters.value !== "" : true) &&
			accountNameValidate === "merchant-input-text" &&
			input.accountName.value !== "" &&
			inputText.accountName.regex.test(input.accountName.value) &&
			effectiveDateValidate === "merchant-input-text" &&
			input.effectiveDate.value !== "" &&
			agreementUploadValidate === "merchant-input-text" &&
			file !== undefined &&
			mobilePhoneValidate === "merchant-input-text" &&
			numField.mobilePhone.value !== "" &&
			inputNum.mobilePhone.regex.test(numField.mobilePhone.value) === true &&
			postcodeValidate === "merchant-input-text" &&
			numField.postcode.value !== "" &&
			inputNum.postcode.regex.test(numField.postcode.value) === true &&
			accountNoValidate === "merchant-input-text" &&
			numField.accountNo.value !== "" &&
			inputNum.accountNo.regex.test(numField.accountNo.value) === true &&
			businessRegNoValidate === "merchant-input-text" &&
			input.businessRegNo.value !== "" &&
			inputText.businessRegNo.regex.test(input.businessRegNo.value) === true
		) {
			setDisableBtn(true);
			setTimeout(() => {
				setDisableBtn(false);
			}, 6000);

			// let token = null;
			// token = localStorage.getItem("@storage_token");
			let date = "";
			date = input.effectiveDate.value;

			const formData = new FormData();
			formData.append("agreementDocument", file);
			formData.append("address1", input.address1.value.trim());
			formData.append("address2", input.address2.value.trim());
			formData.append("bankAccName", input.accountName.value.trim());
			formData.append("bankAccNumber", numField.accountNo.value);
			formData.append("bankCode", input.bankName.value);
			formData.append("category", input.category.value);
			formData.append("city", input.city.value.trim());
			formData.append("effectiveDate", date.substring(10, 8) + "/" + date.substring(7, 5) + "/" + date.substring(0, 4));
			formData.append("name", input.merchantName.value.trim());
			formData.append("postcode", numField.postcode.value);
			formData.append("state", input.state.value);
			formData.append("email", input.merchantEmail.value);
			formData.append("mobileNumber", numField.mobilePhone.value);
			formData.append("businessRegNo", input.businessRegNo.value.toUpperCase());
			formData.append("mainMerchantName", input.subsidiary.value ? input.headquarters.value : "");

			// fetch("http://49.0.203.50/ivc-app/bo/merchant/api/v1/create", {
			fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.CREATE_MERCHANT, {
				method: "POST",
				headers: {
					Accept: "*/*",
					"Accept-language": "en",
					Authorization: "Bearer " + token,
				},
				body: formData,
			})
				.then(function (response) {
					return response.json();
				})
				.then(function (data) {
					const response = data;
					if (response.responseCode === 200) {
						messageDialog({
							isOpen: true,
							condition: CONSTANSTS.DIALOG.success,
							title: CONSTANSTS.DIALOG_TITLE.general_success,
							message: CONSTANSTS.DIALOG_MESSAGE.merchant_add_successful,
							defaultOnClick: false,
						});
						history.push(pathnames.pageMerchantList);
					} else {
						messageDialog({ isOpen: true, condition: CONSTANSTS.DIALOG.failed, title: CONSTANSTS.DIALOG_TITLE.general_failed, message: response.message, defaultOnClick: false });
					}
				})
				.catch((error) => {
					alert(error);
				});
		} else {
			return;
		}
	};

	const onHandleOnChange = (event) => {
		const name = event.currentTarget.name;
		const value = event.currentTarget.value;
		setInput({
			...input,
			[name]: {
				...input[name],
				error: errorMessage(name, value),
				value: value,
			},
		});

		checkEmpty(name, value);
	};

	const onHandleCheckbox = () => {
		setInput({
			...input,
			subsidiary: {
				value: !input.subsidiary.value,
				error: "",
			},
		});
	};

	const checkEmpty = (name, value) => {
		setValidation((prev) => ({
			...prev,
			[name]: !value,
		}));
	};

	const checkValidation = () => {
		const nameValidate = inputText.merchantName.regex.test(input.merchantName.value) && CONSTANSTS.REGEX.checkEmpty.test(input.merchantName.value);
		const emailValidate = CONSTANSTS.REGEX.email.test(input.merchantEmail.value) && CONSTANSTS.REGEX.checkEmpty.test(input.merchantEmail.value);
		const categoryValidate = CONSTANSTS.REGEX.checkEmpty.test(input.category.value);
		const address1Validate = CONSTANSTS.REGEX.checkEmpty.test(input.address1.value);
		const cityValidate = CONSTANSTS.REGEX.checkEmpty.test(input.city.value);
		const stateValidate = CONSTANSTS.REGEX.checkEmpty.test(input.state.value);
		const bankNameValidate = CONSTANSTS.REGEX.checkEmpty.test(input.bankName.value);
		const accountNameValidate = inputText.accountName.regex.test(input.accountName.value) && CONSTANSTS.REGEX.checkEmpty.test(input.accountName.value);
		const effectiveDateValidate = CONSTANSTS.REGEX.checkEmpty.test(input.effectiveDate.value);
		const agreementUploadValidate = file !== undefined;
		const mobilePhoneValidate = inputNum.mobilePhone.regex.test(numField.mobilePhone.value) && CONSTANSTS.REGEX.checkEmpty.test(input.accountName.value);
		const postcodeValidate = CONSTANSTS.REGEX.checkEmpty.test(numField.postcode.value) && inputNum.postcode.regex.test(numField.postcode.value);
		const accountNoValidate = inputNum.accountNo.regex.test(numField.accountNo.value);
		const bussinessRegNoValidate = CONSTANSTS.REGEX.checkEmpty.test(input.businessRegNo.value);
		const hqNameValidate = CONSTANSTS.REGEX.checkEmpty.test(input.headquarters.value);

		console.log(!agreementUploadValidate);

		setValidation((prev) => ({
			...prev,
			merchantName: !nameValidate && !input.merchantName.value,
			merchantEmail: !emailValidate && !input.merchantEmail.value,
			category: !categoryValidate,
			address1: !address1Validate && !input.address1.value,
			city: !cityValidate && !input.city.value,
			state: !stateValidate,
			bankName: !bankNameValidate,
			accountName: !accountNameValidate && !input.accountName.value,
			effectiveDate: !effectiveDateValidate && !input.effectiveDate.value,
			agreementUpload: !agreementUploadValidate,
			mobilePhone: !mobilePhoneValidate && !numField.mobilePhone.value,
			postcode: !postcodeValidate && !numField.postcode.value,
			accountNo: !accountNoValidate && !numField.accountNo.value,
			businessRegNo: !bussinessRegNoValidate && !input.businessRegNo.value,
			headquarters: !hqNameValidate && !input.subsidiary.value,
		}));
	};

	const errorMessage = (name, value) => {
		if (!inputText || !value) {
			return inputText[name].required;
		} else if (!inputText[name].regex.test(value)) {
			return inputText[name].invalid;
		} else {
			return "";
		}
	};

	const errorMessage1 = (name, value) => {
		if (!value) {
			return inputNum[name].required;
		} else if (!inputNum[name].regex.test(value)) {
			return inputNum[name].invalid;
		} else {
			return "";
		}
	};

	const onHandleOnChangeNum = (event) => {
		const name = event.currentTarget.name;
		const value = event.currentTarget.value;

		setNumField({
			...numField,
			[name]: {
				...numField[name],
				error: errorMessage1(name, value),
				value: value.replace(/\D/, ""),
			},
		});

		checkEmpty(name, value);
	};

	// const onHandleOnBlur = (event) => {
	//   const name = event.currentTarget.name;
	//   const value = event.currentTarget.value;

	//   // setNumField({
	//   //   ...numField,
	//   //   [name]: {
	//   //     ...numField[name],
	//   //     error: "errorMessage(name, value)",
	//   //     value: value.replace(CONSTANSTS.REGEX.space, ""),
	//   //   },
	//   // });
	// };

	const [file, setFile] = useState();
	const [fileName, setFileName] = useState("");
	const [exceedFileSize, setExceedFileSize] = useState("");
	const handleFileChange = (event) => {
		let inputFile = event.target.files[0];
		// console.log("->", inputFile);
		const name = event.currentTarget.name;
		const value = event.currentTarget.value;

		checkEmpty(name, value);

		// check file size
		// https://stackoverflow.com/questions/33531140/get-file-size-in-mb-or-kb?msclkid=389800bbb70111ec8058dc0ac41435f5
		var _size = inputFile.size;
		var fSExt = new Array("Bytes", "KB", "MB", "GB"),
			i = 0;
		while (_size > 900) {
			_size /= 1024;
			i++;
		}
		var num = Math.round(_size * 100) / 100;
		var exactSize = num + " " + fSExt[i];
		console.log("FILE SIZE = ", exactSize);

		if (i > 2) {
			// GB
			setExceedFileSize("Uploaded file or image cannot exceed 5 MB");
		} else if (i === 2 && num > 5) {
			// exceed 500 MB
			setExceedFileSize("Uploaded file or image cannot exceed 5 MB");
		} else {
			setFile(inputFile);
			setFileName(inputFile.name.toString());
		}

		// setFile(inputFile);
		// setFileName(inputFile.name.toString());
	};

	const inputFile = useRef();

	const openFile = () => {
		inputFile.current.click();
	};

	const inputWrapperClassName = (name) => {
		return classNames({
			"input-wrapper": true,
			"input-wrapper--error": !!validation[name],
		});
	};

	return (
		<div className="page-merchant-add">
			<div className="fixed-content">
				<div className="top-context">
					<div className="title">Add New Merchant</div>

					<div className="search-button">
						<AppButton colorType="primary" label="Cancel" onClick={back} />
						<AppButton className="primary" onClick={createNewMerchant} disabled={disableBtn} label="Add New Merchant" />
					</div>
				</div>

				<div className="input-form">
					<div className="input-field">
						<div className="row top-padding">
							<div className="cards">
								<div className="card-wrapper">
									<div className="input-content">
										<div className="input-container">
											<div className="content">
												<span>Merchant Name</span>
												<div className={inputWrapperClassName("merchantName")}>
													<input type="text" name="merchantName" value={input.merchantName.value} onChange={onHandleOnChange} required={true} maxLength={50} />
												</div>
												<p className="app-auth-input__error">{input.merchantName.error}</p>
											</div>
										</div>
									</div>
									<div className="input-content">
										<div className="input-container">
											<div className="content">
												<span>Merchant Email</span>
												<div className={inputWrapperClassName("merchantEmail")}>
													<input type="text" name="merchantEmail" value={input.merchantEmail.value} onChange={onHandleOnChange} />
												</div>
												<p className="app-auth-input__error">{input.merchantEmail.error}</p>
											</div>
										</div>
									</div>
									<div className="input-content">
										<div className="input-container">
											<div className="content">
												<span>Mobile Phone</span>
												<div className={inputWrapperClassName("mobilePhone")}>
													<input type="text" name="mobilePhone" value={numField.mobilePhone.value} onChange={onHandleOnChangeNum} maxLength={11} />
												</div>
												<p className="app-auth-input__error">{input.mobilePhone.error}</p>
											</div>
										</div>
									</div>
									<div className="input-content">
										<div className="input-container">
											<div className="content">
												<span>Category</span>
												<div className={inputWrapperClassName("category")}>
													<select className="select-field" name="category" id="category" value={input.category.value} onChange={onHandleOnChange}>
														<option value="">-- Category --</option>
														{categoryList
															?.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))
															?.map((d, i) => (
																<option value={d.code} key={i}>
																	{d.label}
																</option>
															))}
													</select>
												</div>
												<p className="app-auth-input__error">{input.category.error}</p>
											</div>
										</div>
									</div>
									<div className="input-content">
										<div className="input-container">
											<div className="content">
												<span>Address 1</span>
												<div className={inputWrapperClassName("address1")}>
													<textarea name="address1" id="address1" cols="41" rows="3" value={input.address1.value} onChange={onHandleOnChange} maxLength={100}></textarea>
												</div>
												<p className="app-auth-input__error">{input.address1.error}</p>
											</div>
										</div>
									</div>
									<div className="input-content">
										<div className="input-container">
											<div className="content">
												<span>Address 2</span>
												<div className={inputWrapperClassName("address2")}>
													<textarea name="address2" id="address2" cols="41" rows="3" className="text-area" value={input.address2.value} onChange={onHandleOnChange} maxLength={100}></textarea>
												</div>
												<p className="app-auth-input__error">{input.address2.error}</p>
											</div>
										</div>
									</div>
									<div className="input-content">
										<div className="input-container">
											<div className="content">
												<span>City / Town</span>
												<div className={inputWrapperClassName("city")}>
													<input type="text" name="city" value={input.city.value} onChange={onHandleOnChange} maxLength={50} />
												</div>
												<p className="app-auth-input__error">{input.city.error}</p>
											</div>
										</div>
									</div>
									<div className="input-content">
										<div className="input-container">
											<div className="content">
												<span>Postcode</span>
												<div className={inputWrapperClassName("postcode")}>
													<input type="text" name="postcode" value={numField.postcode.value} onChange={onHandleOnChangeNum} maxLength={5} />
												</div>
												<p className="app-auth-input__error">{numField.postcode.error}</p>
											</div>
										</div>
									</div>
									<div className="input-content">
										<div className="input-container">
											<div className="content">
												<span>State</span>
												<div className={inputWrapperClassName("state")}>
													<select className="select-field" name="state" id="state" value={input.state.value} onChange={onHandleOnChange}>
														<option value="">-- State --</option>
														{stateList
															?.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))
															?.map((d, i) => (
																<option value={d.code} key={i}>
																	{d.label}
																</option>
															))}
													</select>
												</div>
												<p className="app-auth-input__error">{input.state.error}</p>
											</div>
										</div>
									</div>
								</div>

								<div className="card-wrapper">
									<div className="input-content">
										<div className="input-container">
											<div className="content">
												<span>Subsidiary</span>
												<div className="input-wrapper input-wrapper--checkbox">
													<input type="checkbox" className="check-box" id="subsidiary-button" name="subsidiary" checked={input.subsidiary.value} onChange={onHandleCheckbox} />
												</div>
											</div>
										</div>
									</div>
									{input.subsidiary.value && (
										<div className="input-content">
											<div className="input-container">
												<div className="content">
													<span>Headquarters</span>
													<div className={inputWrapperClassName("headquarters")}>
														<select className="select-field" name="headquarters" id="headquarters" value={input.headquarters.value} onChange={onHandleOnChange}>
															<option value="">-- Headquarters --</option>
															{hqList
																?.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))
																?.map((d, i) => (
																	<option value={d.name} key={i}>
																		{d.name}
																	</option>
																))}
														</select>
													</div>
													<p className="app-auth-input__error">{input.headquarters.error}</p>
												</div>
											</div>
										</div>
									)}
									<div className="input-content">
										<div className="input-container">
											<div className="content">
												<span>Business Reg. No</span>
												<div className={inputWrapperClassName("businessRegNo")}>
													<input type="text" name="businessRegNo" value={input.businessRegNo.value} onChange={onHandleOnChange} maxLength={24} />
												</div>
												<p className="app-auth-input__error">{input.businessRegNo.error}</p>
											</div>
										</div>
									</div>
									<div className="input-content">
										<div className="input-container">
											<div className="content">
												<span>Bank Name</span>
												<div className={inputWrapperClassName("bankName")}>
													<select className="select-field" id="bankName" name="bankName" value={input.bankName.value} onChange={onHandleOnChange}>
														<option value="">-- Bank Name --</option>
														{bankList
															?.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))
															?.map((d, i) => (
																<option value={d.code} key={i}>
																	{d.label}
																</option>
															))}
													</select>
												</div>
												<p className="app-auth-input__error">{input.bankName.error}</p>
											</div>
										</div>
									</div>
									<div className="input-content">
										<div className="input-container">
											<div className="content">
												<span>Account Name</span>
												<div className={inputWrapperClassName("accountName")}>
													<input type="text" name="accountName" value={input.accountName.value} onChange={onHandleOnChange} maxLength={50} />
												</div>
												<p className="app-auth-input__error">{input.accountName.error}</p>
											</div>
										</div>
									</div>
									<div className="input-content">
										<div className="input-container">
											<div className="content">
												<span>Account No</span>
												<div className={inputWrapperClassName("accountNo")}>
													<input type="text" name="accountNo" value={numField.accountNo.value} onChange={onHandleOnChangeNum} maxLength={19} />{" "}
												</div>
												<p className="app-auth-input__error">{numField.accountNo.error}</p>
											</div>
										</div>
									</div>
									<div className="input-content">
										<div className="input-container">
											<div className="content">
												<span>Effective Date</span>
												<div className={inputWrapperClassName("effectiveDate")}>
													<input type="date" name="effectiveDate" value={input.effectiveDate.value} onChange={onHandleOnChange} />
												</div>
												<p className="app-auth-input__error">{input.effectiveDate.error}</p>
											</div>
										</div>
									</div>
									<div className="input-content">
										<div className="input-container">
											<div className="content">
												<span>Agreement Upload</span>
												<div className={inputWrapperClassName("agreementUpload")}>
													<input type="text" name="agreementUpload" placeholder={inputText.agreementUpload.placeholder} value={fileName} onChange={onHandleOnChange} readOnly onClick={openFile} />{" "}
													<p className="app-auth-input__error">{exceedFileSize}</p>
													<p className="app-auth-input__error">{input.agreementUpload.error}</p>
													<input
														type="file"
														name="file-input"
														id="file-input"
														ref={inputFile}
														accept="application/pdf, image/png, image/jpg, image/jpeg"
														style={{ display: "none" }}
														onChange={handleFileChange}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PageMerchantAdd;
