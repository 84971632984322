import React from "react";

const getButtonClassName = ({ colorType, buttonType, labelColorChange, disabled, disableTransparent }) => {
	const classNames = [];

	switch (buttonType) {
		case "transparent":
			classNames.push("app-button__transparent-button");

			if (colorType === "primary") classNames.push("app-button__primary-text");

			if (colorType === "warning") classNames.push("app-button__warning-text");

			if (labelColorChange) classNames.push("app-button--black-text");

			break;
		default:
			classNames.push("app-button__button");

			if (colorType === "primary") classNames.push("app-button__primary-button");

			if (colorType === "warning") classNames.push("app-button__warning-button");

			if (colorType === "white") classNames.push("app-button__white-button");

			if (colorType === "orange") classNames.push("app-button__orange-button");

			if (colorType === "dimmed") classNames.push("app-button__dimmed-button");

			if (colorType === "beige") classNames.push("app-button__update-button ");

			if (disabled) classNames.push("app-button--disable");

			if (disableTransparent) classNames.push("app-button--disable-transparent");

			break;
	}

	return classNames.join(" ");
};

const getRightSrcClassName = (rotateSrc) => {
	const classNames = ["app-button__icon-wrapper"];
	if (rotateSrc) classNames.push("app-button--rotate");
	return classNames.join(" ");
};

const AppButton = ({ buttonType, className, colorType, labelColorChange, onClick, label, rightSrc, rotateSrc, disabled, disableTransparent }) => {
	return (
		<button disabled={disabled} onClick={onClick} className={getButtonClassName({ colorType, buttonType, labelColorChange, disabled, disableTransparent }) + " " + className}>
			<span>{label}</span>
			{rightSrc ? <div className={getRightSrcClassName(rotateSrc)}>{rightSrc}</div> : null}
		</button>
	);
};

export default AppButton;
