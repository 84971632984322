import React, { useContext, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useHistory } from "react-router-dom";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import CONSTANSTS from "common/constansts";
import AppButton from "components/app-button";
import pathnames from "routes/pathnames";
import AppCustomToast, { PageContext } from "components/app-custom-toast";
import { useEffect } from "react";

const PageAddNewUser = () => {
	const history = useHistory();
	const formik = useFormik({
		initialValues: {
			staffID: "",
			staffName: "",
			emailAddress: "",
			role: "",
			password: "",
			reconfirmPassword: "",
		},
		validationSchema: yup.object({
			staffName: yup.string().required("This field is required"),
			emailAddress: yup.string().email().required("This field is required"),
			role: yup.string().required("This field is required"),
			password: yup
				.string()
				.min(8, "Use at least 8 characters")
				.required("This field is required")
				.matches(/(?=.*[A-Z])/, "Should contain at least 1 uppercase")
				.matches(/(?=.*[a-z])/, "Should contain at least 1 lowercase")
				.matches(/(\W|_)/, "Should contain at least 1 special character")
				.matches(/(?=.*\d)/, "Should contain at least 1 numeric"),
			reconfirmPassword: yup.string().oneOf([yup.ref("password"), null], "Password confirmation doesn't match."),
		}),
		onSubmit: (values) => {
			createNewUser(values);
		},
	});

	const options = {
		result: {
			Finance: "",
			"Processing Officer": "",
			"Credit Officer": "",
		},
	};
	//dynamically assigns select options data
	function Options({ options }) {
		return Object.keys(options).map((key, i) => (
			<option key={i} value={options[key]}>
				{key}
			</option>
		));
	}

	const [optionsData, setOptionsData] = useState();

	useEffect(() => {
		let token = null;
		token = localStorage.getItem("@storage_token");
		fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.GET_ROLE_MAPPING, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				"Accept-language": "en",
				Authorization: "Bearer " + token,
			},
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.success) {
					setOptionsData(response.result);
				} else {
					setOptionsData(options);
				}
			});
	}, []);

	const indicatorValue = useContext(PageContext);

	function messageDialog(indicator) {
		AppCustomToast({
			showIndicator: indicator,
			reintIndicator: indicatorValue[1](indicator),
		});
	}

	const inputField = {
		staffID: {
			error: false,
			value: "",
			placeholder: "Staff ID",
			required: "",
			invalid: "",
		},
		staffName: {
			error: false,
			value: "",
			placeholder: "Enter Staff Name",
			required: "Cannot be empty",
			invalid: "",
		},
		emailAddress: {
			error: false,
			value: "",
			placeholder: "Enter Email Address",
			required: "Email required",
			invalid: "Invalid email",
		},
		role: {
			error: false,
			value: "",
			placeholder: "Select Role",
			required: "Role required",
			invalid: "Invalid Role",
		},
		password: {
			error: false,
			value: null,
			placeholder: "Enter Password",
			required: "Password required",
			invalid: "Invalid, please provide correct format",
		},
		reconfirmPassword: {
			error: false,
			value: "",
			placeholder: "Please enter reconfirm Password",
			required: "Reconfirm password required",
			invalid: "Invalid, please provide correct format",
		},
	};

	const [input, setInput] = useState(inputField);
	const [showPassword, setShowPassword] = useState(false);
	const [showReconfirmPassword, setShowReconfirmPassword] = useState(false);

	const back = async () => {
		history.push(pathnames.pageUserManagement);
	};

	const createNewUser = (values) => {
		let token = null;
		token = localStorage.getItem("@storage_token");
		fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.CREATE_USER, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				"Accept-language": "en",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify({
				email: values.emailAddress,
				name: values.staffName,
				password: values.reconfirmPassword,
				role: values.role,
				hasAuthority: isApprove,
			}),
		})
			.then(function (response) {
				return response.json();
			})
			.then(function (data) {
				const items = data;
				if (items.responseCode === 200) {
					messageDialog({ isOpen: true, condition: CONSTANSTS.DIALOG.success, title: CONSTANSTS.DIALOG_TITLE.general_success, message: items.message });
					history.push(pathnames.pageUserManagement);
				} else {
					messageDialog({ isOpen: true, condition: CONSTANSTS.DIALOG.failed, title: CONSTANSTS.DIALOG_TITLE.general_failed, message: items.message, defaultOnClick: false });
				}
			});
	};

	const [isApprove, setIsApprove] = useState(false);
	const handleApprover = () => {
		setIsApprove(!isApprove);
	};

	const requirement = () => {
		const value = formik.values.password;
		const touched = formik.touched.password;

		const items = [
			{
				test: value.length >= 8,
				label: "● Use at least 8 characters",
			},
			{
				test: value.match(/(?=.*[A-Z])/),
				label: "● Should contain at least 1 uppercase",
			},
			{
				test: value.match(/(?=.*[a-z])/),
				label: "● Should contain at least 1 lowercase",
			},
			{
				test: value.match(/(?=.*\d)/),
				label: "● Should contain at least 1 numeric",
			},
			{
				test: value.match(/(\W|_)/),
				label: "● Should contain at least 1 special character",
			},
		];

		return items.map((item, index) => {
			return (
				<p key={index} className={!touched || (touched && item.test) ? "user-management__note" : "user-management__error"}>
					{item.label}
				</p>
			);
		});
	};

	return (
		<div className="page-user-management">
			<div className="top-context">
				<div className="title">Add New User</div>
				<div className="top-context__wrapper">
					<div className="search-button">
						<AppButton label="Cancel" onClick={back}></AppButton>
					</div>
					<div className="search-button">
						<AppButton label="Add New User" onClick={formik.handleSubmit}></AppButton>
					</div>
				</div>
			</div>

			<div className="user-management">
				<form className="user-management__form" autoComplete="off" onSubmit={formik.handleSubmit}>
					<p className="user-management__title">NEW USER DETAILS</p>

					<div className="user-management__input-wrapper">
						<div className="user-management__input">
							<p className="user-management__label">Staff ID</p>
							<input className="user-management__field" name="staffID" placeholder={input.staffID.placeholder} value={formik.values.staffID} disabled />
							<div className="user-management__error" />
						</div>

						<div className="user-management__input">
							<p className="user-management__label">Staff Name</p>
							<input className="user-management__field" name="staffName" placeholder={input.staffName.placeholder} value={formik.values.staffName} onChange={formik.handleChange} />
							<div className="user-management__error">{formik.errors.staffName}</div>
						</div>
					</div>

					<div className="user-management__input-wrapper">
						<div className="user-management__input">
							<p className="user-management__label">Email Address</p>
							<input className="user-management__field" name="emailAddress" autoComplete="off" placeholder={input.emailAddress.placeholder} value={formik.values.emailAddress} onChange={formik.handleChange} />
							<div className="user-management__error">{formik.errors.emailAddress}</div>
						</div>

						<div className="user-management__input">
							<p className="user-management__label">Role</p>
							<select className="user-management__field" name="role" value={formik.values.role} onChange={formik.handleChange}>
								<option value="">- Select Role -</option>
								<Options options={optionsData || options} />
							</select>
							<div className="user-management__error">{formik.errors.role}</div>
						</div>
					</div>
					<div className="user-management__input">
						<p className="user-management__label">Password</p>
						<div className="user-management__field">
							<input
								name="password"
								type={showPassword ? "text" : "password"}
								autoComplete="off"
								onFocus={() => formik.setFieldTouched("password", true)}
								placeholder={input.password.placeholder}
								value={formik.values.password}
								onChange={formik.handleChange}
							/>
							<div className="user-management__icon" onClick={() => setShowPassword(!showPassword)}>
								{showPassword ? <IoEyeOffOutline size={24} /> : <IoEyeOutline size={24} />}
							</div>
						</div>
					</div>

					{requirement()}

					<div className="user-management__error"></div>
					<div className="user-management__input">
						<p className="user-management__label">Reconfirm Password</p>
						<div className="user-management__field">
							<input
								name="reconfirmPassword"
								type={showReconfirmPassword ? "text" : "password"}
								placeholder={input.reconfirmPassword.placeholder}
								value={formik.values.reconfirmPassword}
								onChange={formik.handleChange}
							/>
							<div className="user-management__icon" onClick={() => setShowReconfirmPassword(!showReconfirmPassword)}>
								{showPassword ? <IoEyeOffOutline size={24} /> : <IoEyeOutline size={24} />}
							</div>
						</div>
					</div>
					<div className="user-management__error">{formik.errors.reconfirmPassword}</div>

					<div className="user-management__input">
						<p className="user-management__label">Approver</p>

						<div className="user-management__input-wrapper">
							<input className="user-management__checkbox" type="checkBox" id="approver-button" onChange={handleApprover} checked={isApprove} />
							<p className="user-management__text">Approver</p>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default PageAddNewUser;
