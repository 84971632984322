import React, { memo, useMemo, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import packageJson from "../../package.json";
import iconLogout from "assets/images/pages/icon-signout.png";
import logo from "assets/images/pages/white-logo.svg";
import pathnames from "routes/pathnames";
import { useHistory } from "react-router-dom";
import CONSTANSTS from "common/constansts";

const AppNav = memo(({ logoutToggle }) => {
	const location = useLocation();

	const nav = {
		loan: false,
		database: false,
		reports: false,
		admin: false,
		profile: false,
	};

	const [isAuthorities, setIsAuthorities] = useState(false);
	const [roles, setRoles] = useState(false);
	const [userMatrix, setUserMatrix] = useState(nav);
	const [viewList, setViewList] = useState({
		view_loan_list: false,
		view_contract_list: false,
	});

	useEffect(() => {
		const authorities = localStorage.getItem("decode_token");
		let userMatrixList = null;
		userMatrixList = localStorage.getItem("accessMatrix");
		const userAccess = JSON.parse(userMatrixList);
		setRoles(authorities);

		if (authorities === CONSTANSTS.ROLES.superAdmin) {
			setIsAuthorities(true);
		} else {
			setIsAuthorities(false);
		}
		if (userMatrixList) {
			setUserMatrix({
				admin: userAccess.accessMatrix.Admin_Setting.view_dropdown,
			});
			setViewList({
				view_loan_list: userAccess.accessMatrix.Loan_Application_List.view_loan_list,
				view_contract_list: userAccess.accessMatrix.Loan_Contract_List.view_contract_list,
			});
		}
	}, []);

	const links = useMemo(() => {
		const paths = [
			{
				label: "Loan",
				to: pathnames.pageLoanApplicationList,
				conditions: viewList.view_loan_list && viewList.view_contract_list,
				childs: [pathnames.pageLoanApplicationView, pathnames.pageLoanContractView],
				dropdown: [
					{
						label: "Loan Application",
						to: pathnames.pageLoanApplicationList,
						conditions: !!viewList.view_contract_list,
					},
					{
						label: "Loan Contract",
						to: pathnames.pageLoanContractList,
						conditions: !!viewList.view_contract_list,
					},
				],
			},
			{
				label: "Database",
				to: pathnames.pageMerchantList,
				conditions: true,
				childs: [pathnames.pageMerchantView, pathnames.pageCustomerView],
				dropdown: [
					{
						label: "Merchant List",
						to: pathnames.pageMerchantList,
						conditions: true,
					},
					{
						label: "Customer List",
						to: pathnames.pageCustomerList,
						conditions: roles !== CONSTANSTS.ROLES.finance,
					},
				],
			},
			{
				label: "Report",
				to: pathnames.pagePayment,
				conditions: true,
				childs: [],
				dropdown: [
					{
						label: "Payment",
						to: pathnames.pagePayment,
						conditions: roles === CONSTANSTS.ROLES.finance || roles === CONSTANSTS.ROLES.superAdmin,
					},
					{
						label: "Transaction",
						to: pathnames.pageTransaction,
						conditions: roles === CONSTANSTS.ROLES.finance || roles === CONSTANSTS.ROLES.superAdmin,
					},
					{
						label: "Others",
						to: pathnames.pageReportOthers,
					},
				],
			},
			{
				label: "Admin Settings",
				to: pathnames.pageLoanSettings,
				conditions: isAuthorities && userMatrix.admin,
				childs: [],
				dropdown: [
					{
						label: "Loan Settings",
						to: pathnames.pageLoanSettings,
						conditions: true,
					},
					{
						label: "Calendar Settings",
						to: pathnames.pageCalendarSettings,
						conditions: true,
					},
					{
						label: "Approval Matrix Settings",
						to: pathnames.pageApprovalMatrixSettings,
						conditions: true,
					},
					{
						label: "User Management",
						to: pathnames.pageUserManagement,
						conditions: true,
					},
				],
			},
			{
				label: "User Profile",
				to: pathnames.pageUserProfile,
				conditions: true,
				childs: [],
				dropdown: [],
			},
		];

		return paths;
	}, [roles, viewList, isAuthorities, userMatrix]);

	return (
		<nav className="nav-bar">
			<Link to={pathnames.pageHomePage}>
				<img className="nav-bar__logo" src={logo} alt="IVC logo" height={"100px"} />
			</Link>

			{links.map((o, i) => {
				if (!o.conditions) return null;

				const active = () => {
					const isChildPath = !!o.childs.length && o.childs.some((z) => location.pathname.includes(z.split(":")[0]));
					const isDropdownPath = !!o.dropdown.length && o.dropdown.some((z) => z.to === location.pathname);
					const locationPath = location.pathname === o.to;
					if (isChildPath || isDropdownPath || locationPath) return true;
					return false;
				};

				return (
					<div className="dropdown" key={i}>
						<Link to={o.to} className={`dropSpan ${active() ? "selected" : ""}`}>
							{o.label}
						</Link>
						{!!o.dropdown.length && (
							<div className="dropdown-content" style={{ width: "180px" }}>
								<span>{o.label}</span>
								{o.dropdown.map((j, k) => {
									if (!j.conditions) return null;
									return (
										<Link key={k} to={j.to}>
											{j.label}
										</Link>
									);
								})}
							</div>
						)}
					</div>
				);
			})}

			<div className="nav-bar__contents">
				<img onClick={logoutToggle} className="nav-bar__icon" src={iconLogout} alt="" />
				<span style={{ paddingLeft: "20px" }}>{packageJson.version}</span>
			</div>
		</nav>
	);
});

export default AppNav;
