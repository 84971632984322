// import React, { useState } from "react";
// import AppInput from "components/app-input";
// import AppItemDropdown from "components/app-item-dropdown";

import React, { useEffect, useState } from "react";
import { BarChart, Bar, ResponsiveContainer, LabelList, Cell } from "recharts";

import InReview from "assets/images/pages/in-review.png";
import InitialApproval from "assets/images/pages/initial-approval.png";
import Approved from "assets/images/pages/approved.png";
import LiveBriefing from "assets/images/pages/live-briefing.png";
import SignAgreement from "assets/images/pages/sign-agreement.png";
import AppReactTable from "components/app-react-table";
import { FiSearch } from "react-icons/fi";
import AppButton from "components/app-button";

// import { useDispatch } from "react-redux";
import pathnames from "routes/pathnames";
// import { useEffect } from "react";
import { useHistory } from "react-router-dom";

// commons
import CONSTANSTS from "common/constansts";

const PageHomePage = () => {
	//   // tableSearchFilter are filter payload to be send to backend

	//tableCoumn is to create table column
	const tableColumn = [
		{
			id: "loanApplicationReferenceNo",
			// header is the table header for id column
			Header: () => {
				return (
					// <div onClick={() => headerFilterClick(header)} className="react-table__header">
					<span className="react-table__header-title">Application ID</span>
					// <div className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)}>
					//     <span className="react-table__icon"></span>
					// </div>
					// </div>
				);
			},
			// accessor will list out the table data based on the data key. In this case, the mockData key of id
			accessor: "loanApplicationReferenceNo",
			// searchFilterValue is the column value that will send to BE to inform BE to sort according to which column. it is use to update tableSearchFilter.searchValue
			searchFilterValue: "loanApplicationReferenceNo",
			// minWidth, width, maxWidth is to adjust the width of this current column
			// minWidth: 200,
			width: 200,
		},
		{
			id: "meetingTime",
			Header: () => {
				return (
					// <div onClick={() => headerFilterClick(header)} className="react-table__header">
					<span className="react-table__header-title">Time</span>
					//     <div className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)}>
					//         <span className="react-table__icon"></span>
					//     </div>
					// </div>
				);
			},
			searchFilterValue: "meetingTime",
			accessor: "meetingTime",
			// minWidth: 150,
			width: 150,
		},
		{
			id: "assignedStaff",
			Header: () => {
				return (
					// <div onClick={() => headerFilterClick(header)} className="react-table__header">
					<span className="react-table__header-title">PIC</span>
					//     <div className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)}>
					//         <span className="react-table__icon"></span>
					//     </div>
					// </div>
				);
			},
			searchFilterValue: "assignedStaff",
			accessor: "assignedStaff",
			// minWidth: 220,
			width: 220,
		},

		{
			id: "button",
			Header: "",
			Cell: (row) => {
				return (
					<div onClick={(e) => e.stopPropagation()}>
						<button onClick={() => viewPage(row.row.original)} className="react-table__search-button">
							<FiSearch size={24} />
						</button>
					</div>
				);
			},
			// minWidth: 70,
			width: 70,
		},
	];

	// const dispatch = useDispatch();

	const viewPage = async (value) => {
		history.push(pathnames.pageLoanApplicationView.replace(":staffId", value.loanApplicationId.toString()));
	};

	const initialValue = {
		staffName: "",
		inReviewApplication: "",
		initialApprovedApplication: "",
		approvedApplication: "",
		liveBriefingApplication: "",
		signAgreementApplication: "",
		totalActiveContracts: "",
		totalCustomers: "",
		totalActiveMerchants: "",
		totalNewMerchantTransactions: "",
		upcomingInterviews: [
			{
				loanApplicationId: "",
				loanApplicationReferenceNo: "",
				meetingTime: "",
				assignedStaff: "",
			},
		],
		upcomingLiveBriefings: [
			{
				loanApplicationId: "",
				loanApplicationReferenceNo: "",
				meetingTime: "",
				assignedStaff: "",
			},
		],
	};
	const [data, setData] = useState(initialValue);

	const displayBarData = [
		{
			name: "In Review",
			value: data.inReviewApplication,
		},
		{
			name: "Initial Approval",
			value: data.initialApprovedApplication,
		},
		{
			name: "Approved",
			value: data.approvedApplication,
		},
		{
			name: "Live Briefing",
			value: data.liveBriefingApplication,
		},
		{
			name: "Sign Agreement",
			value: data.signAgreementApplication,
		},
	];

	const history = useHistory();

	useEffect(async () => {
		// const fetchData = async () => {
		let token = null;
		token = localStorage.getItem("@storage_token");
		try {
			// fetch("http://49.0.203.50/ivc-app/bo/common/api/v1/dashboard", {
			fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.COMMON_DASHBOARD, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Accept: "*/*",
					"Accept-language": "en",
					Authorization: "Bearer " + token,
				},
			})
				.then((response) => response.json())
				.then((json) => {
					let result = json.result;
					setData(result);
				});
			// dispatch((response));
		} catch (error) {
			console.log(error);
		}
	}, []);

	const icons = [
		{
			id: 1,
			name: "In Review",
			src: InReview,
		},
		{
			id: 2,
			name: "Initial Approval",
			src: InitialApproval,
		},
		{
			id: 3,
			name: "Approved",
			src: Approved,
		},
		{
			id: 4,
			name: "Live Briefing",
			src: LiveBriefing,
		},
		{
			id: 5,
			name: "Sign Agreement",
			src: SignAgreement,
		},
	];

	const disbursementTabView = () => {
		history.push(pathnames.pageTransaction);
		sessionStorage.setItem("disbursementTab", "2tab");
	};

	return (
		<div className="page-home-page">
			<div className="home-page">
				<div className="home-page__title">Home </div>
				<div className="home-page__subtitle">
					<div className="username"> Hi {data.staffName}</div>
					<div> Welcome Back!</div>
				</div>
			</div>

			<div className="body-content">
				<div className="body-content__container">
					<div className="body-content__body-content-left">
						<div className="title">Application Status (To-Date)</div>
						<div className="multiple-bar">
							<ResponsiveContainer width="100%" height="65%">
								<BarChart width={140} height={40} data={displayBarData} margin={{ top: 50 }}>
									<Bar dataKey="value" background={{ fill: "#ededed", radius: [10, 10, 0, 0], strokeWidth: "0" }} radius={[10, 10, 0, 0]}>
										{displayBarData.map((entry, index) => (
											<Cell key={index} fill={entry.value !== 0 && index % 2 === 0 ? "#37A15B" : "#FACB57"} />
										))}
										<LabelList dataKey="value" position="top" />
									</Bar>
								</BarChart>
							</ResponsiveContainer>
							<div className="row  bar-xaxis ">
								{icons.map((p) => {
									return p.id !== 5 ? (
										<div className="icons-box" key={p.id}>
											<img className="body-content__bar-icon" width="85px" height="85px" src={p.src} alt="Loading.." />
											<div className="body-content__bar-title">{p.name}</div>
										</div>
									) : (
										<div className="icons-last-box" key={p.id}>
											<img className="body-content__bar-icon" width="85px" height="85px" src={p.src} alt="Loading.." />
											<div className="body-content__bar-title">{p.name}</div>
										</div>
									);
								})}
							</div>
						</div>

						<div className="row mt-5 bottom-left">
							<div className="col">
								<div className="row bottom-left-title">
									<div className="col-5 ">Total Active Contracts</div>
									<div className="col-7 bottom-left-value">{data.totalActiveContracts}</div>
								</div>
							</div>
							<div className="col">
								<div className="row bottom-left-title">
									<div className="col-5 ">Number of Customers</div>
									<div className="col-7 bottom-left-value">{data.totalCustomers}</div>
								</div>
							</div>
							<div className="col">
								<div className="row bottom-left-title">
									<div className="col-5 ">Number of Active Merchants</div>
									<div className="col-7 bottom-left-value">{data.totalActiveMerchants}</div>
								</div>
							</div>
						</div>
					</div>

					<div className="body-content__body-content-right">
						<div className="multiple-schedule">
							<div className="table-content">
								<span className="table-title">Upcoming Live Interview Schedule:</span>
								{/* remove the class below will affected the search icons as it is rely on page-loan-application-list css created */}
								<div className="page-loan-application-list__react-table-wrapper" style={{ marginTop: "10px" }}>
									{data.upcomingInterviews !== undefined && data.upcomingInterviews !== undefined ? (
										<AppReactTable
											columns={tableColumn}
											data={data.upcomingInterviews}
											headerClass="dashBoardHeader"
											headerRowClass="dashBoardHeader__row"
											rowHeaderClass="dashBoardHeader__row-header"
											bodyClass="dashBoardBody"
											bodyRowClass="dashBoardBody__row"
											bodyDataClass="dashBoardBody__row-data"
										/>
									) : (
										<div></div>
									)}
								</div>

								<div className="row mt-4">
									<span className="table-title">Upcoming Live Briefing Schedule:</span>
									{/* remove the class below will affected the search icons as it is rely on page-loan-application-list css created */}

									<div className="page-loan-application-list__react-table-wrapper" style={{ marginTop: "10px" }}>
										{data.upcomingLiveBriefings !== undefined && data.upcomingLiveBriefings !== undefined ? (
											<AppReactTable
												columns={tableColumn}
												data={data.upcomingLiveBriefings}
												headerClass="dashBoardHeader"
												headerRowClass="dashBoardHeader__row"
												rowHeaderClass="dashBoardHeader__row-header"
												bodyClass="dashBoardBody"
												bodyRowClass="dashBoardBody__row"
												bodyDataClass="dashBoardBody__row-data"
											/>
										) : (
											<div></div>
										)}
									</div>
								</div>
							</div>
						</div>
						<div className="bottom-right">
							<div className="col">
								<div className="row bottom-right-title">
									<div className="col-3 ">New Merchants Transaction</div>
									<div className="col-5 bottom-right-value">{data.totalNewMerchantTransactions}</div>
									<div className="col-4 bottom-right-button">
										<AppButton colorType="primary" label="View List" onClick={disbursementTabView} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PageHomePage;
