// libraries
import React, { useState, useCallback, useEffect } from "react";
import { FiSearch } from "react-icons/fi";
import { useHistory, useParams } from "react-router-dom";

import pathnames from "routes/pathnames";
import CONSTANSTS from "common/constansts";
import classNames from "common/class-names";
import AppReactTable from "components/app-react-table";

let roles = localStorage.getItem("decode_token");
const PageCustomerView = () => {
	const [tableSearchFilter, setTableSearchFilter] = useState({
		pageNumber: 1,
		pageSize: 50,
		sortDirection: "DESCENDING",
		sortKey: "contractDate",
	});

	const initUserDetails = {
		result: {
			name: "null",
			nric: "null",
			username: "null",
			mobileNumber: "null",
			email: "null",
			bankName: "null",
			accName: "null",
			accNumber: "null",
			id: "null",
		},
	};
	const [userDetails, setUserDetails] = useState(initUserDetails);
	const temUserDetails = {
		result: {
			Status: userDetails.result.status,
			Date: userDetails.result.dateDisabled,
			Customer: userDetails.result.name,
			NRIC: userDetails.result.nric,
			"User Name": userDetails.result.username,
			"Mobile No": userDetails.result.mobileNumber,
			Email: userDetails.result.email,
			"Bank Name": userDetails.result.bankName,
			"Account Name": userDetails.result.accName,
			Account: userDetails.result.accNumber,
		},
	};

	// trigger when user click on header when sorting
	const headerFilterClick = (header, sort) => {
		let column = header.column;
		if (sort) {
			if (tableSearchFilter.sortKey === column.searchFilterValue) {
				// if click on the same column previously clicked will enter here
				if (tableSearchFilter.sortDirection === "ASCENDING") {
					// if previously the column is sort by ascending order, sortDir will change to sort by descending order
					setTableSearchFilter({
						...tableSearchFilter,
						// sortBy: column.searchFilterValue,
						// sortDir: "DESCENDING",
						// pageNumber: 1,

						pageNumber: tableSearchFilter.pageNumber,
						//   pageSize: 50,
						sortDirection: "DESCENDING",
						sortKey: column.searchFilterValue,
					});
				} else {
					// if previously the column is sort by descending order, sortDir will change to sort by ascending order
					setTableSearchFilter({
						...tableSearchFilter,
						// sortBy: column.searchFilterValue,
						// sortDir: "ASCENDING",
						// pageNumber: 1,
						pageNumber: tableSearchFilter.pageNumber,
						//  pageSize: 50,
						sortDirection: "ASCENDING",
						sortKey: column.searchFilterValue,
					});
				}
			} else {
				// if click on a new column, it will sort by ascending order with the column value (column.searchFilterValue)
				setTableSearchFilter({
					...tableSearchFilter,
					// sortBy: column.searchFilterValue,
					// sortDir: "ASCENDING",
					// pageNumber: 1,

					pageNumber: tableSearchFilter.pageNumber,
					//pageSize: 50,
					sortDirection: "ASCENDING",
					sortKey: column.searchFilterValue,
				});
			}
		}
	};

	const headerArrowRotate = (active, sort) => {
		// current column header arrow icon will rotate depending on ascending order or descending order (tableSearchFilter.sortDir)
		if (sort) {
			const classNames = ["react-table__icon-wrapper"];
			if (active && tableSearchFilter.sortDir === "DESCENDING") classNames.push("react-table__icon-wrapper-down");
			if (active) classNames.push("react-table__icon-wrapper--show");
			return classNames.join(" ");
		}
	};

	const nextPage = async (type) => {
		if (type === "customerDetails") {
			if (appList.currentPageNumber < appList.totalPageNumber && !paginationLocker) {
				setTableSearchFilter({
					pageNumber: tableSearchFilter.pageNumber + 1,
					pageSize: tableSearchFilter.pageSize,
					sortDirection: tableSearchFilter.sortDir,
					sortKey: tableSearchFilter.sortKey,
				});
				setPaginationLocker(true);
			} else if (appList.currentPageNumber === appList.totalPageNumber) {
				setPaginationLocker(false);
			}
		} else {
			if (userContractDetails.currentPageNumber < appList.totalPageNumber && !paginationLocker) {
				setTableSearchFilter({
					pageNumber: tableSearchFilter.pageNumber + 1,
					pageSize: tableSearchFilter.pageSize,
					sortDirection: tableSearchFilter.sortDir,
					sortKey: tableSearchFilter.sortKey,
				});
				setPaginationLocker(true);
			} else if (appList.currentPageNumber === appList.totalPageNumber) {
				setPaginationLocker(false);
			}
		}
	};

	const previousPage = async (type) => {
		if (type === "customerDetails") {
			if (appList.currentPageNumber > 1 && !paginationLocker) {
				setTableSearchFilter({
					pageNumber: tableSearchFilter.pageNumber - 1,
					pageSize: tableSearchFilter.pageSize,
					sortDirection: tableSearchFilter.sortDir,
					sortKey: tableSearchFilter.sortKey,
				});
				setPaginationLocker(true);
			} else if (appList.currentPageNumber === 1) {
				setPaginationLocker(false);
			}
		} else {
			if (userContractDetails.currentPageNumber < appList.totalPageNumber && !paginationLocker) {
				setTableSearchFilter({
					pageNumber: tableSearchFilter.pageNumber + 1,
					pageSize: tableSearchFilter.pageSize,
					sortDirection: tableSearchFilter.sortDir,
					sortKey: tableSearchFilter.sortKey,
				});
				setPaginationLocker(true);
			} else if (userContractDetails.currentPageNumber === 1) {
				setPaginationLocker(false);
			}
		}
	};

	// tableCoumn is to create table column
	const tableColumn = (value) => {
		const table = [];
		let header1_name = value.header1_name;
		let header2_name = value.header2_name;
		let header3_name = value.header3_name;
		let header4_name = value.header4_name;

		if (value.header4 !== undefined) {
			header1_name = "Contract ID";
			header2_name = "Contract Date";
			header3_name = "Payment Status";
			header4_name = "Next Payment Date";
		}
		if (value.header1 !== undefined) {
			table.push({
				id: value.header1,
				// header is the table header for id column
				Header: (header) => {
					return (
						<div onClick={() => headerFilterClick(header, value.sort)} className="react-table__header">
							<span className="react-table__header-title">{header1_name}</span>
							<div className={headerArrowRotate(tableSearchFilter.sortKey === header.column.searchFilterValue, value.sort)}>{value.sort ? <span className="react-table__icon"></span> : ""}</div>
						</div>
					);
				},
				// accessor will list out the table data based on the data key. In this case, the mockData key of id
				accessor: value.header1,
				// searchFilterValue is the column value that will send to BE to inform BE to sort according to which column. it is use to update tableSearchFilter.searchValue
				searchFilterValue: value.header1,
				// minWidth, width, maxWidth is to adjust the width of this current column
				minWidth: 200,
				width: 200,
			});
		}
		if (value.header2 !== undefined) {
			table.push({
				id: value.header2,
				Header: (header) => {
					return (
						<div onClick={() => headerFilterClick(header, value.sort)} className="react-table__header">
							<span className="react-table__header-title">{header2_name}</span>
							<div className={headerArrowRotate(tableSearchFilter.sortKey === header.column.searchFilterValue, value.sort)}>{value.sort ? <span className="react-table__icon"></span> : ""}</div>
						</div>
					);
				},
				searchFilterValue: value.header2,
				accessor: value.header2,
				minWidth: 160,
				width: 160,
			});
		}

		if (value.header3 !== undefined) {
			table.push({
				id: value.header3,
				Header: (header) => {
					return (
						<div onClick={() => headerFilterClick(header, value.sort)} className="react-table__header">
							<span className="react-table__header-title">{header3_name}</span>
							<div className={headerArrowRotate(tableSearchFilter.sortKey === header.column.searchFilterValue, value.sort)}>{value.sort ? <span className="react-table__icon"></span> : ""}</div>
						</div>
					);
				},
				searchFilterValue: value.header3,
				accessor: value.header3,
				minWidth: 180,
				width: 180,
			});
		}

		if (value.header4 !== undefined) {
			table.push({
				id: value.header4,
				Header: (header) => {
					return (
						<div onClick={() => headerFilterClick(header, value.sort)} className="react-table__header">
							<span className="react-table__header-title">{header4_name}</span>
							<div className={headerArrowRotate(tableSearchFilter.sortKey === header.column.searchFilterValue, value.sort)}>{value.sort ? <span className="react-table__icon"></span> : ""}</div>
						</div>
					);
				},

				searchFilterValue: value.header4,
				accessor: value.header4,
				minWidth: 180,
				width: 180,
			});
		}

		table.push({
			id: "button",
			Header: "",
			Cell: (row) => {
				return (
					<div onClick={(e) => e.stopPropagation()}>
						<button onClick={() => viewPage(row.row.original, value.table_title)} className="react-table__search-button">
							<FiSearch size={24} />
						</button>
					</div>
				);
			},
			minWidth: 70,
			width: 70,
		});

		return table;
	};

	const history = useHistory();
	const viewPage = async (value, view_type) => {
		if (view_type === "application") {
			history.push(pathnames.pageLoanApplicationView.replace(":staffId", value.id.toString()));
		} else {
			history.push(pathnames.pageLoanContractView.replace(":contractId", value.id.toString()));
		}
	};
	const { customerId } = useParams();
	//const dispatch = useDispatch();
	const [appList, setAppList] = useState(undefined);
	const [userContractDetails, setUserContractDetails] = useState(undefined);
	const [tab, setTab] = useState("1");
	const [paginationLocker, setPaginationLocker] = useState(true);
	const [customerList, SetCustomerViewAccess] = useState({
		view_contract_list: false,
	});
	useEffect(() => {
		const appId = customerId;
		let token = null;
		token = localStorage.getItem("@storage_token");

		let roles = localStorage.getItem("decode_token");
		if (roles == CONSTANSTS.ROLES.finance) {
			console.log("Access Denied");
			history.push(pathnames.pageCustomerList);
		}

		let customerAccess = null;
		customerAccess = JSON.parse(localStorage.getItem("accessMatrix"));
		if (customerAccess) {
			SetCustomerViewAccess({
				view_contract_list: customerAccess.accessMatrix.Customer_Contract.view_contract_list,
			});
		}

		fetch(
			// "http://49.0.203.50/ivc-app/bo/customer/api/v1/applicationList?customerID=" +
			process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.GET_CUSTOMER_APPLICATION_LIST + "?customerId=" + appId + "&pageNumber=" + tableSearchFilter.pageNumber,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Accept: "*/*",
					"Accept-language": "en",
					// customerID: appId,
					// pageNumber: tableSearchFilter.pageNumber,
					Authorization: "Bearer " + token,
				},
			}
		)
			.then((response) => response.json())
			.then((json) => {
				let result = json.result;
				setPaginationLocker(false);
				setAppList(result);
			});

		fetch(
			// "http://49.0.203.50/ivc-app/bo/customer/api/v1/get/" + appId,
			process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.GET_CUSTOMER_BYID + appId,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Accept: "*/*",
					"Accept-language": "en",
					// customerID: appId,
					// pageNumber: tableSearchFilter.pageNumber,
					Authorization: "Bearer " + token,
				},
			}
		)
			.then((response) => response.json())
			.then((json) => {
				let result = json.result;

				setUserDetails({ result });
			});

		fetch(
			// "http://49.0.203.50/ivc-app/bo/customer/api/v1/contractList?customerID=" +
			process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.GET_CUSTOMER_CONTRACT_LIST + "?customerId=" + appId,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "*/*",
					"Accept-language": "en",
					// customerID: appId,
					// pageNumber: tableSearchFilter.pageNumber,
					Authorization: "Bearer " + token,
				},

				body: JSON.stringify(tableSearchFilter),
			}
		)
			.then((response) => response.json())
			.then((json) => {
				let result = json.result;
				setUserContractDetails(result);
			});
	}, [tableSearchFilter]);

	//   const [cdTab, setCDTab] = useState("contents");
	const tabClicked = (e) => {
		var tabNo = e;
		if (roles == CONSTANSTS.ROLES.finance && roles == CONSTANSTS.ROLES.creditOfficer) {
			alert("Access Denied");
			history.push(pathnames.pageCustomerList);
		}
		setTab(tabNo);
	};

	const tabClassNames = (no) => {
		return classNames({
			tablinks: true,
			[`tab${no}`]: true,
			"tab-selected": tab === no && (no === "2" ? roles !== CONSTANSTS.ROLES.creditOfficer && roles !== CONSTANSTS.ROLES.finance : true),
		});
	};

	const customerInfo = useCallback(() => {
		let items = [];
		const customerDetails = [];

		Object.keys(temUserDetails.result).forEach((key, i) => {
			items.push(
				<div className="input-container" key={i}>
					<span>{key}</span>
					<div className="input-wrapper input-wrapper--disabled">
						<input type="text" value={temUserDetails.result[key] || "-"} readOnly />
					</div>
				</div>
			);
			if (i % 2 === 0) {
				customerDetails.push(
					<div className="input-content" key={i}>
						{items}
					</div>
				);
				items = [];
			}

			return null;
		});

		return customerDetails;
	}, [temUserDetails]);

	return (
		<div className="page-customer-view">
			<div className="customer-view">
				<div className="customer-view__header">
					<div className="customer-view__tabs">
						<div className={tabClassNames("1")} onClick={() => tabClicked("1")}>
							<span>Customer Details</span>
						</div>
						{customerList.view_contract_list && (
							<div className={tabClassNames("2")} onClick={() => tabClicked("2")}>
								<span>Contracts</span>
							</div>
						)}
					</div>
				</div>
				<div className="customer-view__body">
					{tab === "1" && (
						<div className="customer-view__content">
							<div className="customer-view__wrapper">{customerInfo()}</div>

							<div className="customer-view__wrapper">
								<div className="customer-view__pagination-container">
									<span className="customer-view__label">Application Status</span>
									<div className="pagination-button">
										<span className="row">
											{appList && (
												<span className="col m-auto">
													{tableSearchFilter.pageNumber === 1 && appList.totalCount > 0
														? tableSearchFilter.pageNumber
														: tableSearchFilter.pageNumber === 1 && appList.totalCount === 0
														? appList.totalCount
														: 1 + appList.pageSize * (appList.currentPageNumber - 1)}{" "}
													- {appList.pageSize * (appList.currentPageNumber - 1) + appList.currentCount} of {appList.totalCount} <button onClick={() => previousPage("customerDetails")}>❮</button>
													<button onClick={() => nextPage("customerDetails")}>❯</button>
												</span>
											)}
										</span>
									</div>
								</div>
								<div className="page-loan-application-list__react-table-wrapper">
									{appList !== undefined && appList.data !== undefined ? (
										<AppReactTable
											columns={tableColumn({
												header1: "referenceNumber",
												header2: "dateRequested",
												header3: "status",
												table_title: "application",
												sort: false,
												header1_name: "Application ID",
												header2_name: "Request Date",
												header3_name: "Status",
											})}
											data={appList.data}
											headerClass="customerDetailsHeader"
											headerRowClass="customerDetailsHeader__row"
											rowHeaderClass="customerDetailsHeader__row-header"
											bodyClass="customerDetailsBody"
											bodyRowClass="customerDetailsBody__row"
											bodyDataClass="customerDetailsBody__row-data"
										/>
									) : (
										<div></div>
									)}
								</div>
							</div>
						</div>
					)}
					{tab === "2" && (
						<div className="customer-view__content customer-view__content--contracts">
							<div className="customer-view__wrapper">
								<div className="input-content">
									<div className="input-container">
										<span>Customer</span>
										<div className="input-wrapper input-wrapper--disabled">
											<input type="text" value={userDetails.result.name || "-"} readOnly />
										</div>
									</div>
									<div className="input-container">
										<span>NRIC</span>
										<div className="input-wrapper input-wrapper--disabled">
											<input type="text" value={userDetails.result.nric || "-"} readOnly />
										</div>
									</div>
								</div>
							</div>

							<div className="customer-view__wrapper">
								<div className="pagination-button">
									<span className="row">
										{userContractDetails !== undefined ? (
											<span className="col me-5 text-end">
												{tableSearchFilter.pageNumber === 1 && userContractDetails.totalCount > 0
													? tableSearchFilter.pageNumber
													: tableSearchFilter.pageNumber === 1 && userContractDetails.totalCount === 0
													? userContractDetails.totalCount
													: 1 + userContractDetails.pageSize * (userContractDetails.currentPageNumber - 1)}{" "}
												- {userContractDetails.pageSize * (userContractDetails.currentPageNumber - 1) + userContractDetails.currentCount} of {userContractDetails.totalCount}{" "}
												<button onClick={() => previousPage("contractDetails")}>❮</button>
												<button onClick={() => nextPage("contractDetails")}>❯</button>
											</span>
										) : (
											<></>
										)}
									</span>
								</div>
								<div className="page-loan-application-list__react-table-wrapper">
									{userContractDetails !== undefined && userContractDetails.data !== undefined ? (
										<AppReactTable
											columns={tableColumn({
												header1: "referenceNumber",
												header2: "contractDate",
												header3: "paymentStatus",
												header4: "dateNextPayment",
												table_title: "contract",
												sort: true,
											})}
											data={userContractDetails.data}
											headerClass="userContractDetailsHeader"
											headerRowClass="userContractDetailsHeader__row"
											rowHeaderClass="userContractDetailsHeader__row-header"
											bodyClass="userContractDetailsBody"
											bodyRowClass="userContractDetailsBody__row"
											bodyDataClass="userContractDetailsBody__row-data"
										/>
									) : (
										<div></div>
									)}
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default PageCustomerView;
