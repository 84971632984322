import React from "react";
import Logo from "assets/images/components/app-auth-layout/logo.svg";
import packageJson from "../../../../package.json";

const AppAuthLayout = ({ children }) => {
  return (
    <div className="app-auth-layout">
      <div className="app-auth-layout__body">
        <div className="app-auth-layout__content">
          <div className="header">
            <img src={Logo} alt="atf logo" className="header__logo" />
          </div>

          {children}
        </div>
        {/* Semantic versioning three-part version number : major.minor.patch */}
        <span className="version-text">{packageJson.version}</span>
      </div>
    </div>
  );
};

export default AppAuthLayout;
