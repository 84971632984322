import React from "react";
// import { IoIosSearch } from "react-icons/io";
//assets
import searchIcon from "assets/images/pages/setting/icon-search.png"

const AppSearchBar = ({ placeholder, onKeyEnter, onBlur,onClick }) => {
    return (
        <div className="app-search-bar">
            <input
                type="input"
                className="app-search-bar__input"
                placeholder={placeholder}
                onKeyUp={(e) => {
                    e.key === "Enter" && onKeyEnter(e);
                }}
                onBlur={onBlur}
            />
            {/* <IoIosSearch size={22} className="app-search-bar__icon" /> */}
            <button className="app-search-bar__button" onClick={onClick}>
                <img src={searchIcon} width={"22px"} alt="search-icon"  />
            </button>
            {/* <img src={searchIcon} width={"22px"} alt="search-icon" className="app-search-bar__icon" /> */}
        </div>
        

        
    );
};

export default AppSearchBar;
