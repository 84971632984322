const CONSTANSTS = {
  ENDPOINT_PATH: {
    // PRE_SIGN_UP: "/auth/pre-signup",
    // VERIFY_PRE_SIGN_UP: "/auth/verify-pre-signup",
    // COMPLETE_SIGN_UP: "/auth/signup",
    // SIGN_IN: "/auth/signin",
    // FORGOT_PASSWORD: "/auth/forgot-password",
    // VERIFY_FORGOT_PASSWORD: "/auth/verify-forgot-password",
    // RESET_PASSWORD: "/auth/reset-password",

    SIGNUP: "/ivc-auth/signUp",
    LOGIN: "/ivc-auth/login",
    RESET_PASSWORD: "/ivc-auth/resetPassword",
    STAFF_REQUESTOTP: "/ivc-auth/staff/requestOtp/",
    STAFF_RESET_PASSWORD: "/ivc-auth/staff/resetPassword",
    STAFF_VALIDATEOTP: "/ivc-auth/staff/validateOtp",

    // ADMIN-SETTINGS
    FIND_DROPDOWN_OPTION: "/ivc-app/bo/settings/api/v1/findDropdownOption/",
    GET_APPROVAL_MATRIX: "/ivc-app/bo/settings/api/v1/getApprovalMatrix",
    GET_LOAN_CALCULATOR: "/ivc-app/bo/settings/api/v1/getLoanCalculator",
    UPDATE_APPROVAL_MATRIX: "/ivc-app/bo/settings/api/v1/updateApprovalMatrix",
    UPDATE_LOAN_CALCULATOR: "/ivc-app/bo/settings/api/v1/updateLoanCalculator",

    //COMMON
    COMMON_DASHBOARD: "/ivc-app/bo/common/api/v1/dashboard",
    COMMON_DOWNLOAD: "/bo/common/api/v1/download/",
    COMMON_FIND_DROPDOWN_OPTION: "/ivc-app/common/api/v1/findDropdownOption/",
    COMMON_GET_INFO: "/ivc-app/bo/common/api/v1/getInfo",

    // CUSTOMER
    GET_CUSTOMER_LIST: "/ivc-app/bo/customer/api/v1/list",
    GET_CUSTOMER_EXPORT_LIST: "/ivc-app/bo/customer/api/v1/export",
    GET_CUSTOMER_BYID: "/ivc-app/bo/customer/api/v1/get/",
    GET_CUSTOMER_APPLICATION_LIST: "/ivc-app/bo/customer/api/v1/applicationList",
    GET_CUSTOMER_CONTRACT_LIST: "/ivc-app/bo/customer/api/v1/contractList",

    // LOAN-APPLICATION
    GET_LOAN_LIST: "/ivc-app/bo/loanAppplication/api/v1/list",
    GET_LOAN_EXPORT_LIST: "/ivc-app/bo/loanAppplication/api/v1/export",
    GET_LOAN_BYID: "/ivc-app/bo/loanAppplication/api/v1/get/",
    ASSIGN_AGENT: "/ivc-app/bo/loanAppplication/api/v1/assign",
    GENERATE_LOAN_AGREEMENT: "/ivc-app/bo/loanAppplication/api/v1/generateLoanAgreement/",
    UPDATE_LOAN_STATUS: "/ivc-app/bo/loanAppplication/api/v1/updateStatus",
    UPDATE_LOAN_REMARK: "/ivc-app/bo/loanAppplication/api/v1/updateRemark",

    // LOAN-CONTRACT
    GET_CONTRACT_LIST: "/ivc-app/bo/contract/api/v1/list",
    GET_CONTRACT_EXPORT_LIST: "/ivc-app/bo/contract/api/v1/export",
    GET_CONTRACT_BYID: "/ivc-app/bo/contract/api/v1/get/",
    UPLOAD_STAMPED_AGREEMENT: "/ivc-app/bo/contract/api/v1/uploadStampedAgreement",
    GET_CONTRACT_INVOICE_HISTORY: "/ivc-app/bo/contract/api/v1/invoiceHistory",
    GET_CONTRACT_TRANSACTION_HISTORY: "/ivc-app/bo/contract/api/v1/transactionHistory",
    GENERATE_CUSTOMER_CONTRACT_STATEMENT: "/ivc-app/bo/contract/api/v1/customerContractStatement",
    SIGN_LOAN_AGREEMENT:"/ivc-app/bo/contract/api/v1/signLoanAgreement?action=",
    UPDATE_LHDN_SUBMISSION_DATE:"/ivc-app/bo/contract/api/v1/updateLHDNSubmissionDate/",

    // MEETING
    FIND_MEETING_SCHEDULE: "/ivc-app/bo/meeting/api/v1/meetingSchedule/find",
    UPDATE_MEETING_SCHEDULE: "/ivc-app/bo/meeting/api/v1/meetingSchedule/update",
    FINDALL_MEETING_TIMESLOT: "/ivc-app/bo/meeting/api/v1/meetingTimeSlot/find",

    // MERCHANT
    GET_MERCHANT_LIST: "/ivc-app/bo/merchant/api/v1/list",
    GET_MERCHANT_EXPORT_LIST: "/ivc-app/bo/merchant/api/v1/export",
    GET_MERCHANT_BYID: "/ivc-app/bo/merchant/api/v1/get/",
    CREATE_MERCHANT: "/ivc-app/bo/merchant/api/v1/create",
    UPDATE_MERCHANT: "/ivc-app/bo/merchant/api/v1/update/",
    TERMINATE_MERCHANT: "/ivc-app/bo/merchant/api/v1/terminate/",
    GET_MERCHANT_TRANSACTION_LIST: "/ivc-app/bo/merchant/api/v1/transactionList",
    GET_MERCHANT_EXPORT_TRANSACTION_LIST: "/ivc-app/bo/merchant/api/v1/exportTransactionList",
    CANCEL_MERCHANT_TRANSACTION: "/ivc-app/bo/merchant/api/v1/cancelTransaction/",
    GET_MAIN_MERCHANT_LIST: "/ivc-app/bo/merchant/api/v1/mainMerchantList/",

    // MERCHANT TERMINAL
    CREATE_MERCHANT_TERMINAL: "/ivc-app/bo/merchantTerminal/api/v1/create",
    DELETE_MERCHANT_TERMINAL: "/ivc-app/bo/merchantTerminal/api/v1/delete",
    LIST_MERCHANT_TERMINAL: "/ivc-app/bo/merchantTerminal/api/v1/list",

    // PROFILE
    PROFILE_RESET_PASSWORD: "/ivc-app/bo/profile/api/v1/resetPassword",
    GET_USER_PROFILE: "/ivc-app/bo/profile/api/v1/get",
    UPDATE_USER_PROFILE: "/ivc-app/bo/profile/api/v1/update/",
    SIGNATURE:"/ivc-app/bo/profile/api/v1/uploadSignature/",

    // REPORT
    GENERATE_CUSTOMER_DISBURSEMENT: "/ivc-app/bo/report/api/v1/generateCustomerDisbursement",
    GENERATE_MERCHANT_DISBURSEMENT: "/ivc-app/bo/report/api/v1/generateMerchantDisbursement",
    LIST_PAYMENT_RETURN: "/ivc-app/bo/report/api/v1/listPaymentReturnMerchant",
    LIST_CUSTOMER_RETURN: "/ivc-app/bo/report/api/v1/listPaymentReturnCustomer",
    UPLOAD_PAYMENT_RETURN_MERCHANT: "/ivc-app/bo/report/api/v1/uploadPaymentReturnMerchant",
    UPLOAD_PAYMENT_RETURN_CUSTOMER: "/ivc-app/bo/report/api/v1/uploadPaymentReturnCustomer",
    LIST_MERCHANT_DISBURSEMENT_REPORT: "/ivc-app/bo/report/api/v1/listMerchantDisbursementReport",
    LIST_CUSTOMER_DISBURSEMENT_REPORT: "/ivc-app/bo/report/api/v1/listCustomerDisbursementReport",
    GET_BATCHID: "/ivc-app/bo/report/api/v1/get/",
    UPDATE_REPORT_STATUS: "/ivc-app/bo/report/api/v1/update",
    UPLOAD_REPORT_DOC: "/ivc-app/bo/report/api/v1/uploadReportDocument",
    GENERATE_FRAUD_DETECTION: "/ivc-app/bo/report/api/v1/generateFraudDetection",
    GENERATE_AGING: "/ivc-app/bo/report/api/v1/generateAging",
    GENERATE_LOAN_APP_ACTIVITY: "/ivc-app/bo/report/api/v1/generateLoanApplicationActivity",
    GENERATE_BILLING_TRANSACTION: "/ivc-app/bo/report/api/v1/generateBillingTransaction",
    GENERATE_SETTLED_CONTRACT: "/ivc-app/bo/report/api/v1/generateSettledContract",

    // TRANSACTION
    DISBURSEMENT_TRANSACTION_LIST: "/ivc-app/bo/transaction/api/v1/disbursementTransactionList",
    EXPORT_DISBURSEMENT_TRANSACTION_LIST: "/ivc-app/bo/transaction/api/v1/exportDisbursementTransactionList",
    EXPORT_TRANSACTION_LIST: "/ivc-app/bo/transaction/api/v1/exportPaymentTransactionList",
    EXPORT_RECEIVABLE_TRANSACTION_LIST: "/ivc-app/bo/transaction/api/v1/exportReceivableTransactionList",
    PAYMENT_TRANSACTION_LIST: "/ivc-app/bo/transaction/api/v1/paymentTransactionList",
    RECEIVABLE_TRANSACTION_LIST: "/ivc-app/bo/transaction/api/v1/receivableTransactionList",

    CANCEL_TRANSACTION: "/ivc-app/bo/transaction/api/v1/cancelTransaction",
    DELETE_INVOICE: "/ivc-app/bo/transaction/api/v1/deleteInvoice/",
    FIND_INVOICE: "/ivc-app/bo/transaction/api/v1/findInvoice/",
    UPLOAD_INVOICE: "/ivc-app/bo/transaction/api/v1/uploadInvoice",
    UNDO_CANCEL_INVOICE: "/ivc-app/bo/transaction/api/v1/undoCancelTransaction",

    // USER-MANAGEMENT
    GET_USER_LIST: "/ivc-app/bo/user/api/v1/list",
    GET_USER_EXPORT_LIST: "/ivc-app/bo/user/api/v1/export",
    GET_USER_BYID: "/ivc-app/bo/user/api/v1/get/",
    CREATE_USER: "/ivc-app/bo/user/api/v1/create",
    UPDATE_USER: "/ivc-app/bo/user/api/v1/update/",
    GET_ROLE_MAPPING:"/ivc-app/bo/user/api/v1/getRoleMapping",

    // OPENVIDU
    IS_RECORDING_EXISTED: "/ivc-app/meeting/api/v1/isRecordingExisted/",
    JOIN_SESSION: "/ivc-app/meeting/api/v1/join/",
    LEAVE_SESSION: "/ivc-app/meeting/api/v1/leave",

    // PUBLIC-CONTROLLER
    BILL_PLZ_CALLBACK: "/ivc-app/public/api/v1/billplzCallback",
    BILL_PLZ_REDIRECT: "/ivc-app/public/api/v1/billplzRedirect",
    PUBLIC_EXPORT: "/ivc-app/public/api/v1/export",
    GENERATE_LOAN_PAYMENT_INVOICE: "/ivc-app/public/api/v1/generateLoanPaymentInvoice/",
    OPENVIDU_CALLBACK: "/ivc-app/public/api/v1/openviduCallback",
    UPDATE_PAYMENT_DATE: "/ivc-app/public/api/v1/updatePaymentDate/",
  },
  REGEX: {
    // eslint-disable-next-line
    email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    // eslint-disable-next-line
    minEightChar: /^.{8,}$/,
    // eslint-disable-next-line
    checkEmpty: /^(?!\s*$).+/,
    // eslint-disable-next-line
    atLeastOneNumber: /.*[0-9].*/,
    // eslint-disable-next-line
    atLeaseOneUppercase: /.*[A-Z].*/,
    // eslint-disable-next-line
    atLeaseOneSpecialChar: /\W/,
    // eslint-disable-next-line
    name: /^(?!\s*$).+/,
    // eslint-disable-next-line
    spaces: /\s/g,
    // eslint-disable-next-line
    minSixDigit: /.*[0-9]{1,6}$.*/,
    // eslint-disable-next-line
    atLeastEightChar: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,50}$/,
    characterSpaceOnly: /^[a-zA-Z ]*$/, // /^[a-zA-Z /]*$/
  },
  DISPLAY_TEXT: {
    resend: "Resend",
    empty: "-",
    invalid_email: "Invalid Email Format",
    invalid_roles: "Please select a roles",
  },

  LOG_MESSAGE: {
    info_title: "Info : ",
    custom_toast_message: "Can set onClick in messageDialog() to add custom on click function.",
  },

  CONTRACT_STATUS: {
    pending: "Pending",
    disbursed: "Disbursed",
  },

  DIALOG: {
    success: "success",
    loading: "loading",
    failed: "failed",
    default: "",
  },
  DIALOG_TITLE: {
    general_success: "Success",
    general_failed: "Error",
  },
  DIALOG_MESSAGE: {
    forget_password_success: "Successfully Changed Password",
    merchant_add_successful: "Merchant has been successfully added.",
    download_successful: "Download Successfully",
    forget_password_code: "Verification code is generated successfully",
    upload_empty: "Please select a file.",
    upload_invalid_size: "Please select a file smaller than 5MB.",
  },
  ROLES: {
    superAdmin: "ROLE_SUPER_ADMIN",
    finance: "ROLE_FINANCE",
    creditOfficer: "ROLE_CREDIT_OFFICER",
    processOfficer: "ROLE_PROCESSING_OFFICER",
},

  GLOBAL_SETTINGS: {
    isDebugging: true,
  },
};

export default CONSTANSTS;