import React, { useEffect, useContext, useState, useMemo } from "react";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import { ReactSketchCanvas } from "react-sketch-canvas";
import { useHistory } from "react-router-dom";

import pathnames from "routes/pathnames";
import CONSTANSTS from "common/constansts";
import AppButton from "components/app-button";
import AppCustomToast, { PageContext } from "components/app-custom-toast";

const PageUserDetail = (id) => {
	const styles = {
		border: "0.0625rem solid #9c9c9c",
		borderRadius: "0.25rem",
		margin: "0 auto",
	};

	const canvas = React.createRef();
	const [image, setImage] = useState();

	const indicatorValue = useContext(PageContext);
	function messageDialog(indicator) {
		AppCustomToast({
			showIndicator: indicator,
			reintIndicator: indicatorValue[1](indicator),
		});
	}
	const history = useHistory();

	const inputField = {
		staffID: {
			error: false,
			value: "",
			placeholder: "Staff ID",
			required: "",
			invalid: "",
		},
		staffName: {
			error: false,
			value: "",
			placeholder: "Enter Staff Name",
			required: "Cannot be empty",
			invalid: "",
		},
		emailAddress: {
			error: false,
			value: "",
			placeholder: "Enter Email Address",
			required: "Email required",
			invalid: "Invalid email",
		},
		role: {
			error: false,
			value: "",
			placeholder: "Select Role",
			required: "Role required",
			invalid: "Invalid Role",
		},
		password: {
			error: false,
			value: null,
			placeholder: "Enter Password",
			required: "Password required",
			invalid: "Invalid, please provide correct format",
		},
		lastLoginDate: {
			error: false,
			value: "",
			placeholder: "No Date Recorded",
			required: "",
			invalid: "",
		},
	};

	function Popup(props) {
		return (
			props.trigger && (
				<div className="pop-up">
					<div className="pop-up-data pop-up-signature">{props.children}</div>
				</div>
			)
		);
	}

	const [input, setInput] = useState(inputField);
	const [showPassword, setShowPassword] = useState(false);

	const [editMode, setEditMode] = useState(true);

	const back = async () => {
		history.push(pathnames.pageUserManagement);
	};
	const userId = id.location.state;
	const [signatureEnable, setSignatureEnable] = useState(false);
  
	useEffect(() => {
		var path = null;
		if (userId !== undefined) {
			path = process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.GET_USER_BYID + userId;
		} else {
			path = process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.GET_USER_PROFILE;
		}
		const loadData = async () => {
			try {
				let token = null;
				token = localStorage.getItem("@storage_token");
				fetch(
					// "http://49.0.203.50/ivc-app/bo/user/api/v1/get/" + userId,
					path,
					{
						method: "GET",
						headers: {
							"Content-Type": "application/json",
							Accept: "*/*",
							"Accept-language": "en",
							Authorization: "Bearer " + token,
						},
					}
				)
					.then(function (response) {
						if (response.ok) {
							return response.json();
						} else {
							return;
						}
					})
					.then(function (data) {
						if (data !== null || data !== undefined) {
							let result = null;
							if (userId !== undefined) {
								result = data.result;
							} else {
								result = data.result;
							}
							setInput({
								...input,
								staffID: {
									error: false,
									value: result.id,
								},
								staffName: {
									error: false,
									value: result.name,
								},
								emailAddress: {
									error: false,
									value: result.email,
								},
								role: {
									error: false,
									value: result.role.toUpperCase(),
								},
								lastLoginDate: {
									error: false,
									value: result.dateLastLogin,
								},
							});
							setIsChecked(result.active);
							setIsApprove(result.hasAuthority);
							setImage(result.signature);
							fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.GET_ROLE_MAPPING, {
								method: "GET",
								headers: {
									"Content-Type": "application/json",
									Accept: "*/*",
									"Accept-language": "en",
									Authorization: "Bearer " + token,
								},
							})
								.then((response) => response.json())
								.then((response) => {
									if (response.success) {
										setOptionsData(response.result);
									} else {
										setOptionsData(options);
									}
								});
						}
					});
			} catch (error) {
				console.log("error", error);
			}
		};
		loadData();
	}, []);

	const updateUserDetails = () => {
		// if (input.staffName.value !== null && input.password.value !== "" && input.role.value !== null) {
		let userId = null;
		let path = null;

    if (id.location.state !== undefined) {
			userId = id.location.state;
			path = process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.UPDATE_USER + userId;
		} else {
			// userId = input.staffID.value;
			return;
		}
		let token = null;
		token = localStorage.getItem("@storage_token");
		let payload = {
			name: input.staffName.value.trim(),
			password: input.password.value,
			role: input.role.value,
			active: isChecked,
			hasAuthority: isApprove,
		};

		// fetch("http://49.0.203.50/ivc-app/bo/user/api/v1/update/" + userId, {
		fetch(path, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				"Accept-language": "en",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload),
		})
			.then(function (response) {
				return response.json();
			})
			.then(function (data) {
				const items = data;
				if (items.responseCode == 200) {
					messageDialog({
						isOpen: true,
						condition: CONSTANSTS.DIALOG.success,
						title: CONSTANSTS.DIALOG_TITLE.general_success,
						message: items.message,
						defaultOnClick: false,
						onClick: () => routePath(),
					});
				} else {
					messageDialog({
						isOpen: true,
						condition: CONSTANSTS.DIALOG.failed,
						title: CONSTANSTS.DIALOG_TITLE.general_failed,
						message: items.message,
						defaultOnClick: false,
					});
				}
			});
		// }
	};

	const routePath = () => {
		if (id.location.state === undefined) {
			window.location.reload();
		} else {
			history.push(pathnames.pageUserManagement);
		}
	};

	const onHandleOnChange = (event) => {
		const name = event.currentTarget.name;
		const value = event.currentTarget.value;

		setInput({
			...input,
			[name]: {
				...input[name],
				error: "errorMessage(name, value)",
				value: value.replace(CONSTANSTS.REGEX.spaces, ""),
			},
		});
	};

	const onHandle = (event) => {
		const name = event.currentTarget.name;
		const value = event.currentTarget.value;

		setInput({
			...input,
			[name]: {
				...input[name],
				error: "errorMessage(name, value)",
				value: value.replace(null, ""),
			},
		});
	};

	const [isChecked, setIsChecked] = useState(false);
	const handleOnChange = () => {
		setIsChecked(!isChecked);
	};
	const [isApprove, setIsApprove] = useState(false);

	const requestOtp = () => {
		var new_password = input.password.value;

		var reset_psw_data = {
			password: new_password,
		};
		try {
			let token = null;
			token = localStorage.getItem("@storage_token");

			fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.PROFILE_RESET_PASSWORD, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Accept: "*/*",
					"Accept-language": "en",
					Authorization: "Bearer " + token,
				},

				body: JSON.stringify(reset_psw_data),
			})
				.then(function (response) {
					return response.json();
				})
				.then(function (data) {
					const items = data;

					if (items.message !== null || items.message !== undefined) {
						if (items.success) {
							messageDialog({
								isOpen: true,
								condition: CONSTANSTS.DIALOG.success,
								title: CONSTANSTS.DIALOG_TITLE.general_success,
								message: items.message,
							});
							window.location.reload(false);
						} else {
							if (items.errors !== null) {
								messageDialog({
									isOpen: true,
									condition: CONSTANSTS.DIALOG.failed,
									title: CONSTANSTS.DIALOG_TITLE.general_failed,
									message: "Fail to Change \nTips : " + items.errors[0].message,
									defaultOnClick: false,
								});
							} else if (items.responseCode == 400) {
								messageDialog({
									isOpen: true,
									condition: CONSTANSTS.DIALOG.failed,
									title: CONSTANSTS.DIALOG_TITLE.general_failed,
									message: "Fail to Change \nTips : " + items.message,
									defaultOnClick: false,
								});
							} else {
								messageDialog({
									isOpen: true,
									condition: CONSTANSTS.DIALOG.failed,
									title: CONSTANSTS.DIALOG_TITLE.general_failed,
									message: "Fail to Change \nTips : " + "Fail to Change \nTips : " + items.message + ", try add special characters",
									defaultOnClick: false,
								});
							}

							setEditMode(true);
						}
					}
				});
		} catch (e) {
			console.log(e);
		}
	};

	const signUpload = async (img) => {
		console.log("img" + img);
		const blob = await fetch(img).then((res) => res.blob());
		const formData = new FormData();
		formData.append("signature", blob);

		let token = null;
		token = localStorage.getItem("@storage_token");

		fetch(process.env.REACT_APP_API_URL + CONSTANSTS.ENDPOINT_PATH.SIGNATURE, {
			method: "POST",
			headers: {
				Accept: "*/*",
				"Accept-language": "en",
				Authorization: "Bearer " + token,
			},
			body: formData,
		}).then((json) => {
			console.log(json.message);
		});
	};

	const options = {
		result: {
			Finance: "",
			"Processing Officer": "",
			"Credit Officer": "",
		},
	};
	function Options({ options }) {
		return Object.keys(options).map((key, i) => (
			<option key={i} value={options[key]}>
				{key}
			</option>
		));
	}

	const [optionsData, setOptionsData] = useState();

	const fields = useMemo(() => {
		const items = [
			{
				type: "text",
				label: "Staff ID",
				name: "staffID",
				placeholder: input.staffID.placeholder,
				disabled: true,
				onChange: onHandleOnChange,
			},
			{
				type: "text",
				label: "Staff Name",
				name: "staffName",
				disabled: true,
				onChange: onHandle,
			},
			{
				type: "text",
				label: "Email Address",
				name: "emailAddress",
				disabled: true,
				onChange: onHandleOnChange,
			},
			{
				type: "select",
				label: "Role",
				name: "role",
				disabled: true,
				condition: userId,
				onChange: onHandleOnChange,
			},
			{
				type: "password",
				label: "Password",
				name: "password",
				disabled: true,
				onChange: onHandleOnChange,
			},
			{
				type: "text",
				label: "Last Login Date",
				name: "lastLoginDate",
				disabled: true,
			},
			{
				type: "checkbox",
			},
		];

		return items;
	}, [userId]);

	return (
		<div className="page-profile">
			<div className="profile">
				<div className="profile__header">
					<p className="profile__title">User Details</p>

					<div className="profile__actions">
						<AppButton colorType="primary" label="Update" onClick={updateUserDetails}></AppButton>
						<AppButton className="profile__cancel-button" colorType="primary" label="Cancel" onClick={back}></AppButton>
					</div>
				</div>

				<div className="profile__body">
					<div className="profile__content">
						{fields.map((o, i) => {
							if (o.type === "select" && !o.condition) {
								return (
									<div className="profile__inputs" key={i}>
										<div className="profile__input-container">
											<label>{o.label}</label>

											<div className="profile__input">
												<select name={o.name} onChange={o.onChange} value={input[o.name].value}>
													<Options options={optionsData || options} />
												</select>
											</div>
										</div>
									</div>
								);
							}

							if (o.type === "password") {
								return (
									<div className="profile__inputs" key={i}>
										<div className="profile__input-container">
											<label>{o.label}</label>
											<div className="profile__input">
												<input name="password" type={showPassword ? "text" : "password"} placeholder={input.password.placeholder} value={input.password.value} onChange={onHandleOnChange} />
												<div className="profile__input-icon" onClick={() => setShowPassword(!showPassword)}>
													{showPassword ? <IoEyeOffOutline size={24} /> : <IoEyeOutline size={24} />}
												</div>
												<button className="profile__reset-password-button" onClick={requestOtp} disabled={!editMode}>
													{editMode ? "Reset Password" : "Processing..."}
												</button>
											</div>
										</div>
									</div>
								);
							}

							if (o.type === "checkbox") {
								return (
									<div className="profile__inputs" key={i}>
										<div className="profile__input-container">
											<div className="profile__input profile__input--checkbox">
												<div className="profile__checkbox">
													<input
														type="radio"
														name="active"
														id="active"
														className="radio-check"
														onChange={handleOnChange}
														checked={isChecked}
														disabled={userId === undefined && input.role.value === "STAFF" ? true : userId === undefined ? true : false}
													/>
													<label htmlFor="active"> Active</label>
												</div>
												<div className="profile__checkbox">
													<input
														type="radio"
														name="active"
														id="disabled-checkbox"
														className="radio-check"
														onChange={handleOnChange}
														checked={!isChecked}
														disabled={userId === undefined && input.role.value === "STAFF" ? true : userId === undefined ? true : false}
													/>
													<label htmlFor="disabled-checkbox"> Disabled</label>
												</div>
											</div>
										</div>
									</div>
								);
							}

							return (
								<div className="profile__inputs" key={i}>
									<div className="profile__input-container">
										<label>{o.label}</label>

										<div className="profile__input profile__input--disabled">
											<input type={o.type} name={o.name} value={input[o.name]?.value || "-"} placeholder={input[o.name]?.placeholder} disabled={o.disabled} />
										</div>
									</div>
								</div>
							);
						})}
					</div>
					<div className="profile__content">
						<div className="profile__signature">
							<p>Signature</p>
							<img src={image} />
							<button onClick={() => setSignatureEnable(true)}>Edit</button>
						</div>
					</div>
				</div>

				<Popup trigger={signatureEnable}>
					<ReactSketchCanvas style={styles} max-width="700px" max-height="152px" min-height="152px" min-width="500px" strokeWidth={1} strokeColor="black" ref={canvas} />
					<div className="pop-up-signature__button-container">
						<button
							className="pop-up-signature__button"
							onClick={() => {
								canvas.current
									.exportImage("png")
									.then((data) => {
										setImage(data);
										signUpload(data);
									})
									.catch((e) => {
										console.log(e);
									});
								setSignatureEnable(false);
							}}
						>
							Save
						</button>
						<button
							className="pop-up-signature__button"
							onClick={() => {
								canvas.current.clearCanvas();
							}}
						>
							Clear
						</button>

						<button
							className="pop-up-signature__button"
							onClick={() => {
								setSignatureEnable(false);
							}}
						>
							Cancel
						</button>
					</div>
				</Popup>
			</div>

			{/* <div className="page-home-page">
				<div className="row home-page">
					<div className="col-11 input-field">
						<div className="row">
							<div className="col-sm-6 col-md-6">
								<div className="row top-padding">
									<div className="col-sm-3 col-md-3 text-field">Staff ID</div>
									<div className="col-sm-9 col-md-9">
										<div className="app-input">
											<input className="app-input-text" name="staffID" placeholder={input.staffID.placeholder} value={input.staffID.value} onChange={onHandleOnChange} disabled />
										</div>
									</div>
									<div className="col-sm-3 col-md-3 text-field">Staff Name</div>
									<div className="col-sm-9 col-md-9">
										<div className="app-input">
											<input className="app-input-text" name="staffName" placeholder={input.staffName.placeholder} value={input.staffName.value} onChange={onHandle} disabled={true} />
										</div>
									</div>
									<div className="col-sm-3 col-md-3 text-field">Email Address</div>
									<div className="col-sm-9 col-md-9">
										<div className="app-input">
											<input className="app-input-text" name="emailAddress" placeholder={input.emailAddress.placeholder} value={input.emailAddress.value} onChange={onHandleOnChange} disabled />
										</div>
									</div>

									<div className="col-sm-3 col-md-3 text-field">Role</div>
									<div className="col-sm-9 col-md-9">
										{userId === undefined ? (
											<div className="app-input" style={{ float: "left" }}>
												<input
													className="app-input-text"
													name="role"
													placeholder={input.role.placeholder}
													value={input.role.value}
													onChange={onHandleOnChange}
													disabled
													// style={{ width: "300px" }}
												/>
											</div>
										) : (
											<div className="app-input" style={{ float: "left" }}>
												<select className="app-input-select app-input-select__input" name="role" onChange={onHandleOnChange} value={input.role.value}>
													<Options options={optionsData || options} />
												</select>
											</div>
										)}
									</div>
									<div className="col-sm-3 col-md-3 text-field">Password</div>
									<div className="col-sm-9 col-md-9">
										
									</div>
									<div className="col-sm-3 col-md-3 text-field">Last Login Date</div>
									<div className="col-sm-9 col-md-9">
										<div className="app-input">
											<input className="app-input-text" name="lastLoginDate" type="text" placeholder={input.lastLoginDate.placeholder} value={input.lastLoginDate.value} onChange={onHandleOnChange} disabled />
										</div>
									</div>
									<div className="col-sm-3"></div>
									<div className="col-sm-2">
										<input
											type="checkbox"
											id="active-checkbox"
											className="check-box"
											value={"active"}
											onChange={handleOnChange}
											checked={isChecked}
											disabled={userId === undefined && input.role.value === "STAFF" ? true : userId === undefined ? true : false}
										/>
										<label htmlFor="active-checkbox"> Active</label>
									</div>
									<div className="col-sm-2">
										<input
											type="checkbox"
											id="disabled-checkbox"
											className="check-box"
											value={"disabled"}
											onChange={handleOnChange}
											checked={!isChecked}
											disabled={userId === undefined && input.role.value === "STAFF" ? true : userId === undefined ? true : false}
										/>
										<label htmlFor="disabled-checkbox"> Disabled</label>
									</div>
									<div></div>
								</div>
							</div>

							{userId === undefined ? (
								<div className="col-sm-6 col-md-6">
									<div className="row top-padding">
										<div className="col-sm-2 col-md-2 text-field " style={{ margin: "auto" }}>
											Signature
										</div>

										<div className="col-sm-10 ">
											<div className="row">
												<div className="col-sm-6  col-md-6">
													<img
														src={image}
														style={{
															height: "152px",
															width: "700px",
															backgroundColor: "white",
															border: "none",
															borderRadius: "4px",
														}}
													/>
												</div>
											</div>
										</div>
										<div className="col-sm-2 col-md-2 text-field " style={{ margin: "auto" }}></div>
										<div className="col-sm-10 ">
											<div className="col-sm-2 col-md-2 ">
												<button
													className="app-button__button app-button__orange-button"
													onClick={() => {
														setSignatureEnable(true);
													}}
												>
													Edit
												</button>
											</div>
										</div>
									</div>
								</div>
							) : (
								""
							)}
						</div>
					</div>
				</div>

				<Popup trigger={signatureEnable}>
					<ReactSketchCanvas style={styles} max-width="700px" max-height="152px" min-height="152px" min-width="500px" strokeWidth={1} strokeColor="black" ref={canvas} />
					<div
						className="row"
						style={{
							marginTop: "10px",
							display: "flex",
							justifyContent: "center",
						}}
					>
						<button
							className="app-button__button app-button__orange-button"
							style={{ width: "100px", marginRight: "10px" }}
							onClick={() => {
								// console.log(canvas);
								canvas.current
									.exportImage("png")
									.then((data) => {
										// console.log(data);
										setImage(data);
										signUpload(data);
									})
									.catch((e) => {
										console.log(e);
									});
								setSignatureEnable(false);
							}}
						>
							Save
						</button>
						<button
							className="app-button__button app-button__orange-button"
							style={{ width: "100px", marginRight: "10px" }}
							onClick={() => {
								canvas.current.clearCanvas();
							}}
						>
							Clear
						</button>

						<button
							className="app-button__button app-button__orange-button"
							style={{ width: "100px" }}
							onClick={() => {
								setSignatureEnable(false);
							}}
						>
							Cancel
						</button>
					</div>
				</Popup>
			</div> */}
		</div>
	);
};

export default PageUserDetail;
