import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { AiOutlineClose } from "react-icons/ai";

const PageLogout = ({ isOpen, toggle, logout }) => {
    return (
        <Modal isOpen={isOpen} className="page-logout" contentClassName="page-logout__content">
            <ModalHeader className="header">
                <AiOutlineClose className="header__close-button" onClick={toggle} />
            </ModalHeader>
            <ModalBody className="body">
                <div className="body__title">Log Out</div>
                <div className="body__description">Do you confirm to log out?</div>
            </ModalBody>
            <ModalFooter className="footer">
                <div className="footer__left" onClick={toggle}>
                    Cancel
                </div>
                <div className="footer__right" onClick={logout}>
                    Sign Out
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default PageLogout;
