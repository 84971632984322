import React, { useEffect } from "react";
import { compose } from "redux";
import { Router, Switch, Route, Redirect } from "react-router-dom";

import routes from "routes/routes";
import pathnames from "routes/pathnames";
import withAuthenticate from "contexts/with-authenticate";
import AppLayout from "components/app-layout";
import { createBrowserHistory } from "history";
import refreshToken from "contexts/refresh-token";

const App = () => {
    const history = createBrowserHistory();
    useEffect(() => {
        refreshToken(history);
    }, []);

    return (
        <Router history={history}>
            <AppLayout>
                <Switch>
                    {routes.map((route) => {
                        const EnhanceRoute = compose(withAuthenticate)(route.component, route.authenticate);

                        return <Route key={`${route.path}_index`} path={route.path} exact={true} render={(props) => <EnhanceRoute {...props} />} />;
                    })}
                    <Redirect to={pathnames.page404} />
                </Switch>
            </AppLayout>
        </Router>
    );
};

export default App;
