// libraries
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
// routes
import pathnames from "routes/pathnames";
// assets
import Logo from "assets/images/pages/logo.svg";

const PageSplashScreen = () => {
    const history = useHistory();

    useEffect(() => {
        history.push(pathnames.pageLogin);
    }, [history]);

    return (
        <div className="page-splash-screen">
            <div className="splash-screen">
                <div className="splash-screen__center">
                    <img className="splash-screen__icon" src={Logo} alt="" />
                    <div className="splash-screen__text">OGISTIC</div>
                </div>
                <div className="splash-screen__bottom">Powered by ATX</div>
            </div>
        </div>
    );
};

export default PageSplashScreen;
